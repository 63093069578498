import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { graphql } from '@apollo/client/react/hoc';
import { useLazyQuery, useQuery } from '@apollo/client';
import { flow, sortBy, find } from 'lodash';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import Logger from 'src/common/Logger';
import Instrumentation from 'src/instrumentation';
import SentryUtil from 'src/common/SentryUtil';

import { Box, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/Constants';
import { isFacebookPageAdmin, FACEBOOK_URLS } from 'src/common/FacebookUtil';
import Loading from 'src/components/Loading';
import { Events } from 'src/instrumentation/constants';

import {
  RENDER_STATES,
  ERROR_TYPES,
  LOADING_TYPES,
  POLL_FOR_TOS_PARAM,
  FACEBOOK_LINK_RETURN_URL
} from './Constants';
import { associateFacebookPageV2 } from './mutations';

import { getFacebookPagesAndUserWithToken } from './api';
import {
  DeniedScopes,
  FacebookPageSelector,
  NoFacebookPages,
  PageNotVisible,
  UnknownError,
  FacebookLeadsTos
} from './PageLinkCard';
import { getOrgDefaultFacebookPageGroup, getFacebookPageIds } from './queries';

const fbResponseHasError = response => {
  return response && (response.status === 401 || response.error);
};

const FacebookPageLinker = props => {
  const { fbData, associateFacebookPageV2, continueCreateProgramUrl } = props;

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [renderState, setRenderState] = useState(RENDER_STATES.LOADING);
  const [loadingType, setLoadingType] = useState(LOADING_TYPES.GETTING_PAGES);
  const [data, setData] = useState();
  const [pageId, setPageId] = useState();
  const [errorType, setErrorType] = useState();

  const { data: defaultFBPageGroupData, loading: defaultFBPageGroupLoading } =
    useQuery(getOrgDefaultFacebookPageGroup, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    });

  const [getLinkedPages] = useLazyQuery(getFacebookPageIds, {
    fetchPolicy: 'no-cache'
  });

  const snackbarAction = snackbarId => {
    return (
      <Button
        sx={{ color: 'white' }}
        variant="text"
        type="button"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        {t('facebookSettings:snackActions.dismiss')}
      </Button>
    );
  };

  // load data
  useEffect(() => {
    getFacebookPagesAndUserWithToken(fbData.fbAccessToken)
      .then(async promiseResponse => {
        const response = promiseResponse || {};

        // Facebook errors come back in the response rather than being
        // thrown.
        if (
          fbResponseHasError(response.pages) ||
          fbResponseHasError(response.user)
        ) {
          setRenderState(RENDER_STATES.READY);
          setLoadingType(null);
          setErrorType(ERROR_TYPES.UNAUTHORIZED);

          return;
        }

        let newPagesData = response?.pages?.data || [];

        let linkedPagesResponse;

        try {
          linkedPagesResponse = await getLinkedPages();
        } catch (error) {
          SentryUtil.addBreadcrumb({
            message:
              'Error getting linked facebook pages in facebook page link flow'
          });
          SentryUtil.captureException(error);
        }

        newPagesData = sortBy(newPagesData, page => {
          return page.name;
        }).map(page => {
          const isPageLinked = !!find(
            linkedPagesResponse?.data?.facebookPagesV2,
            {
              pageId: page.id
            }
          );

          return {
            ...page,
            isPageLinked
          };
        });

        response.pages.data = newPagesData;

        // Success - set the data in state.
        Instrumentation.logEvent(Events.FacebookFetchPages, {
          pageCount: response?.pages?.data?.length ?? 0
        });
        setRenderState(RENDER_STATES.READY);
        setLoadingType(null);
        setData(response);

        // Find the first page that is useable and set it as the initial selected page
        const initialPage = find(newPagesData, {
          is_published: true,
          promotion_eligible: true,
          isPageLinked: false
        });

        if (initialPage) {
          setPageId(initialPage.id);
        }

        return null; // eslint-disable-line consistent-return
      })
      .catch(error => {
        Logger.debug('Error getting pages: ', error);

        setRenderState(RENDER_STATES.READY);
        setLoadingType(null);
        setErrorType(ERROR_TYPES.UNKNOWN);

        return null;
      });
  }, [fbData?.deniedScopes]);

  const user = data?.user || {};
  const pages = data?.pages?.data;

  const getSelectedPage = () => {
    if (pageId === null || pageId === undefined || !Array.isArray(pages)) {
      return null;
    }

    return find(pages, ['id', pageId]);
  };

  const selectedPage = getSelectedPage();
  const isAdmin = isFacebookPageAdmin(selectedPage);

  const handlePageIdSelected = event => {
    setPageId(event.target.value);
  };

  const getSelectedPageName = () => {
    const selectedPage = getSelectedPage();

    if (!selectedPage) {
      return '';
    }

    return selectedPage.name;
  };

  const getSelectedPageToken = () => {
    const selectedPage = getSelectedPage();

    if (!selectedPage) {
      return '';
    }

    return selectedPage.access_token;
  };

  const navigateToInitialPage = queryParams => {
    if (continueCreateProgramUrl) {
      localStorage.removeItem(FACEBOOK_LINK_RETURN_URL);
      history.push(continueCreateProgramUrl);
    } else {
      history.push(`${paths.facebook.pages}${queryParams || ''}`);
    }
  };

  const goBackToPageSelector = () => {
    setErrorType(null);
  };

  const navigateToFacebookPages = () => {
    history.push(paths.facebook.pages);
  };

  const renderErrorSnackbar = () => {
    enqueueSnackbar(t('facebookSettings:snackError.actionNeeded'), {
      variant: 'error',
      action: snackbarAction,
      preventDuplicate: true
    });
  };

  // Callback that executes when the user selects their page and clicks next
  const setPage = () => {
    if (!pageId) {
      return;
    }

    if (!isAdmin) {
      setErrorType(ERROR_TYPES.NOT_PAGE_ADMIN);
      return;
    }

    setRenderState(RENDER_STATES.LOADING);
    setLoadingType(LOADING_TYPES.SETTING_PAGE);
    setErrorType(null);

    const pageName = getSelectedPageName();
    const pageToken = getSelectedPageToken();

    if (!fbData.fbAccessToken) {
      Logger.error(
        'FacebookPageLinker: In facebook mode but fbData.fbAccessToken is not set. Cannot set page. Stopping.'
      );
      return;
    }

    // Now set the facebook page.
    associateFacebookPageV2({
      variables: {
        pageId,
        pageToken,
        pageName,
        facebookUserId: user.id,
        facebookDisplayName: user.name
      }
    })
      .then(response => {
        const linkedPage = response?.data?.associateFacebookPageV2;

        const hasAcceptedTos = linkedPage?.hasAcceptedTos;

        enqueueSnackbar(
          hasAcceptedTos
            ? t('facebookSettings:snackSuccess.pageLinked')
            : t('facebookSettings:snackSuccess.pageLinkedTosNotAccepted'),
          {
            variant: 'success',
            action: snackbarAction,
            preventDuplicate: true
          }
        );

        Instrumentation.logEvent(Events.FacebookLinkPageSuccess, {
          pageId,
          tos: hasAcceptedTos
        });

        // This causes the next card in the flow to render, prompting the user to accept the facebook ToS
        if (!hasAcceptedTos) {
          setRenderState(RENDER_STATES.TOS_NOT_ACCEPTED);
          return;
        }

        // Navigate either to facebook pages or back to the program form the user came from
        navigateToInitialPage();

        // Now send the user to the Facebook page status page.
        // history.push(`${paths.facebook.status.replace(':id', id)}`);

        return null;
      })
      .catch(error => {
        Logger.error('Error setting page: ', error);
        Instrumentation.logEvent(Events.FacebookLinkPageFailure, {
          pageId,
          'error-type': error?.graphQLErrors?.[0]?.extensions?.errorName
        });
        const errorType =
          error?.graphQLErrors?.[0]?.extensions?.errorName ===
          'LithiumFacebookPageVisibilityException'
            ? ERROR_TYPES.PAGE_NOT_VISIBLE
            : ERROR_TYPES.SET_TOKEN_FAIL;

        setRenderState(RENDER_STATES.READY);
        setErrorType(errorType);
      });
  };

  const onSkipTos = () => {
    // Navigate to either program or page
    navigateToInitialPage();
  };

  const onConfirmTos = () => {
    // Navigate to either program or page with query param if /pages
    navigateToInitialPage(`?${POLL_FOR_TOS_PARAM}=true`);

    // open facebook tos URL in new tab
    window.open(FACEBOOK_URLS.leadgenTos, '_blank');
  };

  const isLoadingPages =
    renderState === RENDER_STATES.LOADING &&
    loadingType === LOADING_TYPES.GETTING_PAGES;
  const isLinkingPage =
    renderState === RENDER_STATES.LOADING &&
    loadingType === LOADING_TYPES.SETTING_PAGE;

  /*
   * Conditionals which determine the UI to be rendered:
   */

  // User has completed the auth flow but denied necessary scopes
  if (fbData?.deniedScopes) {
    renderErrorSnackbar();
    return (
      <DeniedScopes
        onCancel={navigateToFacebookPages}
        deniedScopes={fbData?.deniedScopes?.split(',')}
      />
    );
  }

  // If we have an unknown error while calling the mutation to link a page
  if (
    errorType === ERROR_TYPES.UNAUTHORIZED ||
    errorType === ERROR_TYPES.SET_TOKEN_FAIL ||
    errorType === ERROR_TYPES.NOT_PAGE_ADMIN
  ) {
    renderErrorSnackbar();
    return (
      <UnknownError
        onConfirm={goBackToPageSelector}
        onCancel={navigateToFacebookPages}
        pageName={getSelectedPageName()}
      />
    );
  }

  // BE returns an error lettings us know that the page is not visible
  // happens after the user has selected a page and clicked next
  if (errorType === ERROR_TYPES.PAGE_NOT_VISIBLE) {
    renderErrorSnackbar();
    return (
      <PageNotVisible
        onCancel={navigateToFacebookPages}
        onConfirm={goBackToPageSelector}
        pageName={getSelectedPageName()}
      />
    );
  }

  // If the user has successfully linked their page but the Facebook leads ToS has not been accepted for that particular page
  if (renderState === RENDER_STATES.TOS_NOT_ACCEPTED) {
    return <FacebookLeadsTos onSkip={onSkipTos} onConfirm={onConfirmTos} />;
  }

  if (isLoadingPages || defaultFBPageGroupLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6">
          {t('facebookSettings:loading.retrievingPages')}
        </Typography>
        <Loading loading />
      </Box>
    );
  }

  // User completed the auth flow but has no pages
  if (pages?.length === 0 && !isLoadingPages) {
    renderErrorSnackbar();
    return (
      <NoFacebookPages
        onCancel={navigateToFacebookPages}
        hasOrgDefaultPage={
          !!defaultFBPageGroupData?.getOrgDefaultFacebookPageGroup
        }
      />
    );
  }

  // User completed the auth flow and has pages to select
  return (
    <FacebookPageSelector
      isLoadingPages={isLoadingPages}
      isLinkingPage={isLinkingPage}
      pages={pages}
      selectedPageId={pageId}
      setSelectedPageId={handlePageIdSelected}
      onCancel={navigateToFacebookPages}
      onConfirm={setPage}
    />
  );
};

export default flow(
  graphql(associateFacebookPageV2, {
    name: 'associateFacebookPageV2'
  })
)(FacebookPageLinker);
