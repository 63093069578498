import { useCallback, useMemo, useState } from 'react';
import { flow, omitBy, isNil } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import {
  Button,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import {
  CREATE_FORM_NAME,
  createFacebookConfigurationInputs,
  createFacebookConfigurationAdvancedInputs
} from './Constants';
import { createFacebookConfiguration } from './mutations';
import { getFacebookConfiguration } from './queries';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  cardSubheading: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: theme.spacing(4)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
});

const pageText = () => ({
  cardHeading: t('admin:createFacebookConfiguration.cardHeading'),
  subHeading: t('admin:createFacebookConfiguration.subHeading'),
  errorMessage: t('admin:createFacebookConfiguration.errorMessage'),
  submitButton: t('admin:createFacebookConfiguration.submitButton'),
  clearButton: t('admin:createFacebookConfiguration.clearButton'),
  successSnackMessage: t('admin:createFacebookConfiguration.successSnack'),
  errorSnackMessage: t('admin:createFacebookConfiguration.errorSnack'),
  advanced: t('admin:createFacebookConfiguration.advancedPanelSummary'),
  useDefaultsButton: t('admin:createFacebookConfiguration.useDefaultsButton'),
  useDefaultsText: t('admin:createFacebookConfiguration.useDefaultsText')
});

const FacebookConfiguration = props => {
  const {
    classes,
    createFacebookConfiguration,
    dirty,
    enqueueSnackbar,
    facebookConfiguration: { refetch },
    handleSubmit,
    reset,
    submitFailed,
    submitting
  } = props;

  const text = useMemo(() => pageText(), []);
  const [advancedOpen, setAdvanced] = useState(false);

  const onSubmit = useCallback(async data => {
    // Note: Fields we don't send get default values.
    const mutationParams = omitBy(data, isNil);

    try {
      await createFacebookConfiguration({
        variables: {
          ...mutationParams
        }
      });

      enqueueSnackbar({
        message: text.successSnackMessage,
        options: {
          variant: 'success'
        }
      });
      refetch();
    } catch (error) {
      const message = text.errorSnackMessage;

      enqueueSnackbar({
        message,
        options: {
          variant: 'error'
        }
      });

      throw new SubmissionError(error);
    }
  }, []);

  const onUseDefaultConfig = useCallback(async () => {
    // The api sets default values for fields that are not passed. So we will just make the
    // create call with no variables to get all the defaults.
    try {
      await createFacebookConfiguration();

      enqueueSnackbar({
        message: text.successSnackMessage,
        options: {
          variant: 'success'
        }
      });

      refetch();
    } catch (error) {
      const message = text.errorSnackMessage;

      enqueueSnackbar({
        message,
        options: {
          variant: 'error'
        }
      });

      throw new SubmissionError(error);
    }
  }, []);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>

      <Typography variant="subtitle2" className={classes.cardSubheading}>
        {text.subHeading}
      </Typography>

      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>{text.errorMessage}</ErrorMessage>
        </div>
      )}

      <DynamicForm inputs={createFacebookConfigurationInputs()} />

      <Accordion
        expanded={advancedOpen}
        key="createFacebookConfiguration-advanced-inputs"
        onChange={() => setAdvanced(!advancedOpen)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">{text.advanced}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DynamicForm inputs={createFacebookConfigurationAdvancedInputs()} />
        </AccordionDetails>
      </Accordion>

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty || submitting}
          type="submit"
          variant="contained"
          endIcon={submitting && <CircularProgress size={15} />}
        >
          {text.submitButton}
        </Button>

        <Button onClick={reset}>{text.clearButton}</Button>
      </div>
      <br />
      <Typography variant="subtitle2" className={classes.cardSubheading}>
        {text.useDefaultsText}
      </Typography>
      <Button
        color="primary"
        disabled={submitting}
        variant="outlined"
        onClick={onUseDefaultConfig}
        endIcon={submitting && <CircularProgress size={15} />}
      >
        {text.useDefaultsButton}
      </Button>
    </form>
  );
};

const mapStateToProps = () => {
  const initialValues = getInitialValuesFromInputsConfig(
    createFacebookConfigurationInputs()
  );

  return {
    initialValues
  };
};

export default flow(
  reduxForm({
    form: CREATE_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps, { enqueueSnackbar }),
  graphql(createFacebookConfiguration, {
    name: 'createFacebookConfiguration'
  }),
  graphql(getFacebookConfiguration, {
    name: 'facebookConfiguration'
  }),
  withStyles(styles)
)(FacebookConfiguration);
