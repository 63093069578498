import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';

import { Box, Button, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import Modal, { ModalHeader } from 'src/components/Modal';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import {
  assetEventTypes,
  assetTypes,
  logAssetEvent
} from 'src/common/orgAssets';
import { BlockingOrdersList } from 'src/components/BlockingOrdersList';

import { modalTypes } from './Constants';
import {
  shareFacebookPage,
  unshareFacebookPage,
  disassociateFacebookPage
} from './mutations';

interface PageTextArgs {
  groupName: string;
}

interface FacebookCardModalProps {
  groupName: string;
  hasTeamCapabilities: boolean;
  onClose: () => void;
  open: boolean;
  type: string;
  facebookPage: any;
}

interface OrderFragment {
  id: string;
  name: string;
  architectureId: string;
  supervisorOrder: { id: string };
}

interface ActiveOrdersByType {
  purchase: OrderFragment[];
  automation: OrderFragment[];
}

const pageText = ({ groupName }: PageTextArgs) => {
  return {
    cancelButton: t('facebookPages:fbCardModal.cancelButton'),
    shareHeader: t('facebookPages:fbCardModal.shareHeader'),
    unshareHeader: t('facebookPages:fbCardModal.unshareHeader'),
    unknownType: t('facebookPages:fbCardModal.unknownType'),
    confirmShare: t('facebookPages:fbCardModal.confirmShare', {
      groupName
    }),
    confirmUnshare: t('facebookPages:fbCardModal.confirmUnshare', {
      groupName
    }),
    unableToUnshare: t('facebookPages:fbCardModal.unableToUnshare', {
      groupName
    }),
    shareConfirmButton: t('facebookPages:fbCardModal.shareConfirmButton'),
    unshareConfirmButton: t('facebookPages:fbCardModal.unshareConfirmButton'),
    shareError: t('facebookPages:fbCardModal.shareError'),
    unshareError: t('facebookPages:fbCardModal.unshareError'),
    programsListTitle: t('facebookPages:fbCardModal.programsListTitle'),
    automationsListTitle: t('facebookPages:fbCardModal.automationsListTitle')
  };
};

type TextOptions = ReturnType<typeof pageText>;

const generateBodyText = (
  text: TextOptions,
  hasTeamCapabilities: any,
  hasPendingOrders: any,
  type: string
) => {
  if (modalTypes.shareWithTeam === type) {
    return <Typography>{text.confirmShare}</Typography>;
  }

  if (modalTypes.unshareWithTeam === type) {
    return (
      <Typography>
        {hasPendingOrders ? text.unableToUnshare : text.confirmUnshare}
      </Typography>
    );
  }

  if (modalTypes.unlink === type) {
    return (
      <Typography>
        {hasPendingOrders
          ? t('facebookPages:fbCardModal.unableToUnlink')
          : t('facebookPages:fbCardModal.confirmUnlink')}
      </Typography>
    );
  }
  return <Typography>{text.unknownType}</Typography>;
};

const generateHeaderIcon = (type: string) => {
  if (modalTypes.unlink === type) {
    return <LinkOffIcon />;
  }
  // if (modalTypes.shareWithTeam === type)
  // if (modalTypes.unshareWithTeam === type)
  return <FacebookIcon />;
};
const generateHeaderText = (
  text: TextOptions,
  type: string,
  hasBlockingOrders: boolean
) => {
  if (modalTypes.shareWithTeam === type) {
    return text.shareHeader;
  }
  if (modalTypes.unshareWithTeam === type) {
    return text.unshareHeader;
  }
  if (modalTypes.unlink === type) {
    return hasBlockingOrders
      ? t('facebookPages:fbCardModal.unlinkBlockedHeader')
      : t('facebookPages:fbCardModal.unlinkHeader');
  }
  return text.unknownType;
};

const generateButtonText = (text: TextOptions, type: string) => {
  if (modalTypes.shareWithTeam === type) {
    return text.shareConfirmButton;
  }
  if (modalTypes.unshareWithTeam === type) {
    return text.unshareConfirmButton;
  }
  if (modalTypes.unlink === type) {
    return t('facebookPages:fbCard.unlink');
  }
  return text.unknownType;
};

const getEventType = (type: string) => {
  switch (type) {
    case modalTypes.shareWithTeam:
      return assetEventTypes.shareConfirm;
    case modalTypes.unshareWithTeam:
      return assetEventTypes.unshareConfirm;
    case modalTypes.unlink:
      return assetEventTypes.unlinkConfirm;
    default:
      return 'unknownType';
  }
};

const FacebookCardModal = ({
  open,
  onClose,
  groupName,
  hasTeamCapabilities,
  type,
  facebookPage
}: FacebookCardModalProps) => {
  const text = pageText({ groupName });

  const [handleShareFacebookPage] = useMutation(shareFacebookPage);
  const [handleUnshareFacebookPage] = useMutation(unshareFacebookPage);
  const [handleDisassociateFacebookPage] = useMutation(
    disassociateFacebookPage
  );

  const handleConfirm = () => {
    const properties = { object: assetTypes.facebookPage };

    const eventType = getEventType(type);

    logAssetEvent({
      type: eventType,
      properties
    });

    if (type === modalTypes.shareWithTeam) {
      // share
      handleShareFacebookPage({
        variables: { facebookPageSettingsId: facebookPage?.id }
      })
        .then(() => {
          onClose();
        })
        .catch(() => {
          return enqueueSnackbar({
            message: <span>{text.shareError}</span>,
            options: {
              variant: 'error'
            }
          });
        });
      return;
    }

    if (type === modalTypes.unshareWithTeam) {
      // unshare
      handleUnshareFacebookPage({
        variables: { facebookPageSettingsId: facebookPage?.id }
      })
        .then(() => {
          onClose();
        })
        .catch(() => {
          return enqueueSnackbar({
            message: <span>{text.unshareError}</span>,
            options: {
              variant: 'error'
            }
          });
        });
    }

    if (type === modalTypes.unlink) {
      // unlink
      handleDisassociateFacebookPage({
        variables: {
          facebookPageSettingsId: facebookPage?.id
        }
      })
        .then(() => {
          // success
          onClose();
          enqueueSnackbar({
            message: t('facebookPages:fbCard.unlinkSuccess'),
            options: {
              variant: 'success'
            }
          });
        })
        .catch(() => {
          enqueueSnackbar({
            message: t('facebookPages:fbCard.unlinkFail'),
            options: {
              variant: 'error'
            }
          });
        });
    }
  };

  const activeOrders = facebookPage?.activeOrders || [];

  const activeOrdersByType = activeOrders.reduce(
    (accum: ActiveOrdersByType, order: OrderFragment) => {
      if (order?.supervisorOrder) {
        accum.automation.push(order);
      }
      accum.purchase.push(order);

      return accum;
    },
    { purchase: [], automation: [] }
  );

  const hasActivePurchaseOrders = !isEmpty(activeOrdersByType.purchase);
  const hasActiveAutomationOrders = !isEmpty(activeOrdersByType.automaton);
  const hasBlockingOrders =
    hasActivePurchaseOrders || hasActiveAutomationOrders;

  return (
    <Modal
      showClose
      open={open}
      onClose={onClose}
      HeaderComponent={() => (
        <ModalHeader icon={generateHeaderIcon(type)}>
          {generateHeaderText(text, type, hasBlockingOrders)}
        </ModalHeader>
      )}
      FooterComponent={
        // we can share a card with pending orders, but we can't unshare a card with pending orders
        type === modalTypes.shareWithTeam || !hasBlockingOrders
          ? () => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2
                }}
              >
                <Button onClick={onClose}>{text.cancelButton}</Button>
                <Button variant="contained" onClick={handleConfirm}>
                  {generateButtonText(text, type)}
                </Button>
              </Box>
            )
          : null
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {generateBodyText(text, hasTeamCapabilities, hasBlockingOrders, type)}
        {type !== modalTypes.shareWithTeam && hasActivePurchaseOrders && (
          <Box sx={{ pt: 1 }}>
            <BlockingOrdersList
              title={text.programsListTitle}
              orders={activeOrdersByType.purchase}
            />
          </Box>
        )}
        {type !== modalTypes.shareWithTeam && hasActiveAutomationOrders && (
          <Box sx={{ pt: 1 }}>
            <BlockingOrdersList
              title={text.automationsListTitle}
              orders={activeOrdersByType.automation}
              isAutomation
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default FacebookCardModal;
