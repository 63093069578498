import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useApolloClient } from '@apollo/client';

import { dayjs } from 'src/common/dates';

import { usePrevious } from 'src/hooks/usePrevious';
import { promotionSupportType } from 'src/common/promotions';

import useProgram from 'src/pages/Program/utils/useProgram';
import { calculatePromotion } from 'src/components/PromoCode/queries';
import { PromotionSupportType } from 'src/generated/gql/graphql';

interface UsePromoCodeProps {
  spendAmount: number;
  isAutomated: boolean;
  promoCodes: any;
  change: (form: string, field: string, value: any) => void;
  formName: string;
}

const usePromoCode = ({
  spendAmount,
  isAutomated,
  promoCodes,
  change,
  formName
}: UsePromoCodeProps) => {
  const client = useApolloClient();
  const { setPromoCodeError, promoCodeError } = useProgram();

  const validatePromoCode = async (promoCode?: string) => {
    setPromoCodeError(false);

    if (!promoCode) {
      return;
    }

    let response;
    try {
      response = await client.query({
        query: calculatePromotion,
        variables: {
          promoCode,
          purchaseAmount: spendAmount,
          timeZoneRegionName: dayjs.tz.guess(),
          supportType: isAutomated
            ? (promotionSupportType?.automation
                ?.value as PromotionSupportType.Automation)
            : (promotionSupportType?.program
                ?.value as PromotionSupportType.Program)
        },
        // Note: Cache was causing the promo to not recalc after
        //       program purchase
        fetchPolicy: 'no-cache'
      });
    } catch (error) {
      setPromoCodeError('Error applying promotion');
      throw new Error('Error applying promotion');
    }

    const promotion = response?.data?.calculatePromotion;
    const isValid = promotion?.isValid;

    if (isValid) {
      return promotion;
    }

    setPromoCodeError(`${promoCode}: ${promotion?.message}`);
    throw new Error(promotion?.message || '');
  };

  const removePromo = () => {
    // TODO Look into why we're not passing a promo code into the valiate function as it will just return undefined!
    // TODO Disabled for TS migration but this should be fixed!
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validatePromoCode();
    change(formName, 'spendStep.promoCodes', []);
  };

  const validatePromoCodeDebounced = debounce(() => {
    // TODO: Disabled for TS migration but this should be fixed!
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    validatePromoCode(promoCodes?.[0]?.promoCode);
  }, 1000);

  const prevSpendAmount = usePrevious(spendAmount);

  useEffect(() => {
    // componentDidUpdate
    if (spendAmount !== prevSpendAmount && promoCodeError) {
      validatePromoCodeDebounced();
    }
  }, [spendAmount, promoCodeError, promoCodes]);

  return {
    removePromo,
    validatePromoCode,
    validatePromoCodeDebounced,
    promoCodeError,
    setPromoCodeError
  };
};

export default usePromoCode;
