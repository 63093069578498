import { useMemo } from 'react';
import { flow } from 'lodash';
import {
  FormSection as ReduxFormSection,
  getFormValues,
  clearSubmitErrors,
  change
} from 'redux-form';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';

import { t } from 'i18next';
import { connect } from 'react-redux';

import { Alert, Box, Grid } from '@mui/material';

import SpendSelector, { useMinSpendState } from 'src/components/SpendSelector';
import CustomFormMessage from 'src/components/CustomFormMessage';
import Checkout from 'src/components/Checkout';
import { useAppSettings } from 'src/AppSettings';
import { FormSection } from 'src/components/ReduxForm';
import ScheduleSelector, {
  PaymentTypeSelector
} from 'src/components/ScheduleSelector';
import { getOfferOptions } from 'src/components/ScheduleSelector/utils';
import CreditCardProvider from 'src/components/Checkout/CreditCardProvider';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import useProgram from 'src/pages/Program/utils/useProgram';

import {
  PROGRAM_FORM_SECTION_SPEND_NAME,
  PROGRAM_FORM_NAME,
  scheduleTypes
} from '../Constants';

const pageText = () => {
  return {
    paymentTypeSectionTitle: t('programCreate:spend.paymentTypeSectionTitle'),
    paymentTypeSectionDescription: t(
      'programCreate:spend.paymentTypeSectionDescription'
    )
  };
};

const getScheduleSectionDescription = ({ isAutomated, isSubscription }) => {
  if (isAutomated) {
    return t('programCreate:spend.offerTypeDescriptionAutomation');
  }

  if (isSubscription) {
    return t('programCreate:spend.offerTypeDescriptionSubscription');
  }
  return t('programCreate:spend.offerTypeDescription');
};

const getBudgetSectionTitle = ({ isSubscription }) => {
  if (isSubscription) {
    return t('programCreate:spend.budgetSectionTitleSubscription');
  }
  return t('programCreate:spend.budgetSectionTitle');
};

const getBudgetSectionDescription = ({ isAutomated, isSubscription }) => {
  if (isAutomated) {
    if (isSubscription) {
      t('programCreate:spend.budgetSectionDescriptionAutomationSubscription');
    }
    return t('programCreate:spend.budgetSectionDescriptionAutomation');
  }

  if (isSubscription) {
    t('programCreate:spend.budgetSectionDescriptionSubscription');
  }

  return t('programCreate:spend.budgetSectionDescription');
};

const ProgramStepSpend = props => {
  const {
    isAutomated,
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    formName,
    skipStep,
    setSkipStep,
    isSubscription,
    type,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    offersChanged,
    stripeSourceIdValue,
    clearSubmitErrors,
    change: reduxChange
  } = props;

  const { isDraft } = useProgram();

  const hookFormContext = useFormContext();
  const appSettings = useAppSettings();

  const text = useMemo(() => pageText(), []);

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  const {
    minSpendLoading,
    setMinSpendLoading,
    hasMinSpendError,
    setHasMinSpendError
  } = useMinSpendState();

  const paymentPlans = selectedBlueprint?.paymentPlans;

  // eslint-disable-next-line prefer-destructuring
  const stripeKey = appSettings.app.general.stripeKey;

  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  const customMessage = selectedBlueprint?.messaging?.spendMessage;
  const offers = isAutomated
    ? paymentPlans?.offers?.filter(offer => offer.isSupervisable)
    : paymentPlans?.offers;

  return (
    <ReduxFormSection name={PROGRAM_FORM_SECTION_SPEND_NAME}>
      <Grid data-cy="program-form-spend-step" container gap={2}>
        {offersChanged && !isDraft && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t('programCreate:spend.offersChanged')}
            </Alert>
          </Grid>
        )}
        <Grid
          sx={getOfferOptions(offers)?.length < 2 ? { display: 'none' } : {}}
          item
          xs={12}
        >
          <FormSection
            title={text.paymentTypeSectionTitle}
            description={text.paymentTypeSectionDescription}
          >
            <PaymentTypeSelector offers={offers} />
          </FormSection>
        </Grid>
        {!(isAutomated && isSubscription) && (
          <Grid item xs={12}>
            <FormSection
              title={
                isSubscription
                  ? t('programCreate:spend.offerTypeTitleSubscription')
                  : t('programCreate:spend.offerTypeTitle')
              }
              description={getScheduleSectionDescription({
                isAutomated,
                isSubscription
              })}
            >
              <Box sx={{ pb: 1.5 }}>
                <ScheduleSelector
                  selectedBlueprint={selectedBlueprint}
                  formName={formName}
                  isAutomated={isAutomated}
                />
              </Box>
            </FormSection>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormSection
            title={getBudgetSectionTitle({
              isSubscription
            })}
            description={getBudgetSectionDescription({
              isAutomated,
              isSubscription
            })}
          >
            <Box
              sx={{
                pb: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'flex-start'
              }}
            >
              <SpendSelector
                architecture={architecture}
                paymentPlans={paymentPlans}
                selectedBlueprint={selectedBlueprint}
                isAutomated={isAutomated}
                formName={formName}
                setMinSpendLoading={setMinSpendLoading}
                setHasMinSpendError={setHasMinSpendError}
              />
              {customMessage && (
                <Box sx={{ pb: 0.5 }}>
                  <CustomFormMessage message={customMessage} />
                </Box>
              )}
            </Box>
          </FormSection>
        </Grid>
        <Grid item xs={12}>
          <Elements stripe={stripePromise}>
            <CreditCardProvider
              formName={formName}
              stripeSourceIdValue={stripeSourceIdValue}
              change={change}
              clearSubmitErrors={clearSubmitErrors}
            >
              <Checkout
                architecture={architecture}
                selectedBusinessObjects={selectedBusinessObjects}
                selectedBlueprint={selectedBlueprint}
                isAutomated={isAutomated}
                formName={formName}
                skipStep={skipStep}
                setSkipStep={setSkipStep}
                minSpendLoading={minSpendLoading}
                hasMinSpendError={hasMinSpendError}
                handleNext={handleNext}
                lockSubmit={lockSubmit}
                showValidationErrors={showValidationErrors}
                type={type}
                submitForm={submitForm}
              />
            </CreditCardProvider>
          </Elements>
        </Grid>
      </Grid>
    </ReduxFormSection>
  );
};

const mapStateToProps = state => {
  const formValues = getFormValues(PROGRAM_FORM_NAME)(state);

  const isSubscription =
    formValues?.spendStep?.scheduleType === scheduleTypes.subscription.value;

  return {
    isSubscription,
    stripeSourceIdValue: formValues?.spendStep?.stripeSourceId
  };
};

export default flow(connect(mapStateToProps, { clearSubmitErrors, change }))(
  ProgramStepSpend
);
