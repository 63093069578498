import { flow, every } from 'lodash';
import { Field } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { CircularProgress, FormHelperText, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import GroupIcon from '@mui/icons-material/Group';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';
import shortNumber from 'short-number';
import { getAudiences, loadAudiences } from 'src/pages/Audiences/queries';
import { extractAudienceRowsFromGallery } from 'src/pages/Audiences/util';
import { AUDIENCE_STATUS } from 'src/pages/Audiences/constants';
import {
  ASSET_STATUS,
  GALLERY_SCOPES,
  GALLERY_TYPE
} from 'src/pages/Gallery/constants';
import { paths } from 'src/routes/Constants';
import { ChannelAudienceStatus } from 'src/generated/gql/graphql';
import RenderMultiSelect from '../RenderMultiSelect';

const MAX_RESULTS = 50;
const END_DIGIT_REGEX = /(\d+)$/g;

const styles = theme => ({
  loadingIcon: {
    marginRight: '5px'
  },
  messagingContainer: {
    fontStyle: 'italic',
    margin: '5px'
  },
  loadingMessage: {
    display: 'inline'
  },
  errorMessage: {
    color: theme.palette.error.light
  },
  audienceLink: {
    marginLeft: '3px'
  },
  audienceSize: {
    color: grey[500],
    paddingLeft: theme.spacing(1),
    verticalAlign: 'center'
  },
  group: {
    verticalAlign: 'bottom',
    width: '.9em',
    paddingLeft: theme.spacing(0.5)
  }
});

const RenderAudienceSelectorField = props => {
  const {
    audienceDataNew,
    audienceDataLegacy,
    classes,
    staticContext,
    multiple,
    isLegacy,
    isHookForm,
    ...fieldProps
  } = props;

  let audiences = [];
  let audienceData = {};

  if (isLegacy) {
    audienceData = audienceDataLegacy;

    audiences = extractAudienceRowsFromGallery(audienceData?.audienceGallery);
  } else {
    audienceData = audienceDataNew;
    // we get the placeholders instead of the audiences
    audiences =
      audienceData?.fetchPlaceholders?.edges?.reduce((accum, placeholder) => {
        // filter out pending audiences and return the nodes
        return placeholder?.node?.channelAudiences?.edges?.length > 0 &&
          every(
            placeholder?.node?.channelAudiences?.edges,
            edge => edge.node.status === AUDIENCE_STATUS.active
          )
          ? [...accum, placeholder.node]
          : accum;
      }, []) || [];
  }

  // This is frustrating and a little ugly. If we go the traditional route
  // of having the "make a selection" menu item as a direct child of the
  // <Field> component below and the audiences.map call just below it,
  // the RenderMultiSelect component gets confused because it assumes
  // that children are a single array but what ends up happening is
  // children end up looking like this:
  // children: [
  //     <MenuItem key="disabled" ...></MenuItem>,
  //     [
  //      <MenuItem> #1</MenuItem>
  //      <MenuItem> #2</MenuItem>
  //      <MenuItem> #3</MenuItem>
  //      <MenuItem> #4</MenuItem>
  //      <MenuItem> #5</MenuItem>
  //      <MenuItem> #3</MenuItem>
  //     ]
  // ]
  // Which then causes the code that pulls the child's name to display a
  // selected item as a chip to fail. Doing it this way, ensures all
  // children are a single array and keeps the search happy.
  const menuItems = [
    {
      key: 'disabled',
      value: 'disabled',
      disabled: true,
      content: 'Make your selection'
    },
    ...audiences.map(audience => {
      const size = parseInt(
        audience?.potentialSize?.match(END_DIGIT_REGEX)?.[0] || '',
        10
      );

      return {
        key: `rasf-aid-${audience.id}`,
        value: isLegacy ? audience.value : audience.id,
        content: (
          <>
            {audience.name}{' '}
            {!Number.isNaN(size) && (
              <Tooltip title="Potential Audience Size">
                <span className={classes.audienceSize}>
                  {size <= 1000 ? '<1k' : shortNumber(size)}
                  <GroupIcon className={classes.group} />
                </span>
              </Tooltip>
            )}
          </>
        )
      };
    })
  ];

  const allProps = {
    ...fieldProps,
    multiple,
    format: value => {
      if (multiple) {
        return Array.isArray(value) ? value : [];
      }
      return value;
    },
    menuItems,
    component: RenderMultiSelect
  };

  if (audienceData?.loading) {
    return (
      <div className={classes.messagingContainer}>
        <CircularProgress className={classes.loadingIcon} size={14} />
        <FormHelperText className={classes.loadingMessage}>
          <Trans i18nKey="audiences:selector.loading" />
        </FormHelperText>
      </div>
    );
  }

  if (audienceData?.error) {
    return (
      <div className={classes.messagingContainer}>
        <FormHelperText className={classes.errorMessage}>
          <Trans i18nKey="audiences:selector.error">
            There was an error loading audiences. Please refresh.
          </Trans>
        </FormHelperText>
      </div>
    );
  }

  const noAudiences = audiences.length === 0;

  return (
    <>
      {noAudiences && (
        <div className={classes.messagingContainer}>
          <FormHelperText error>
            <Trans
              i18nKey="audiences:selector.noAudiences"
              components={[
                <Link
                  className={classes.audienceLink}
                  component={RouterLink}
                  to={paths.dashboard.audiences}
                />
              ]}
            />
          </FormHelperText>
        </div>
      )}
      {isHookForm ? (
        <RenderMultiSelect {...allProps} disabled={noAudiences} />
      ) : (
        <Field {...allProps} disabled={noAudiences} />
      )}
    </>
  );
};

export default flow(
  graphql(loadAudiences, {
    // Note we are placing this graph call after connect so that we can
    //      access selectGallery
    name: 'audienceDataLegacy',
    skip: ({ isLegacy }) => !isLegacy,
    options: () => {
      return {
        variables: {
          first: 100,
          types: [GALLERY_TYPE.audience],
          filter: {
            audienceFilter: {
              scopes: [GALLERY_SCOPES.public, GALLERY_SCOPES.private],
              statuses: [ASSET_STATUS.complete],
              showDeleted: false
            }
          }
        },
        fetchPolicy: 'no-cache'
      };
    },
    props: ({ audienceDataLegacy }) => {
      const error = audienceDataLegacy?.error;
      const loading = audienceDataLegacy?.loading;

      const audienceGallery = audienceDataLegacy?.galleries?.[0] || [];

      // Note: All the unerlying tooling was built off of assets being in assets not
      //       assestsv3. We can remove this once we've depricated the old gallery
      //       calls and changed the query back to assets.
      if (audienceGallery && audienceGallery.assetsv3) {
        audienceGallery.assets = audienceGallery.assetsv3;
        delete audienceGallery.assetsv3;
      }

      return {
        audienceDataLegacy: {
          loading,
          error,
          audienceGallery
        }
      };
    }
  }),
  graphql(getAudiences, {
    name: 'audienceDataNew',
    skip: ({ isLegacy }) => isLegacy,
    options: ({ channelCodes }) => {
      return {
        variables: {
          placeholderFirst: MAX_RESULTS,
          channelAudienceFirst: MAX_RESULTS,
          showDeletedPlaceholders: false,
          channelAudienceQueryFilter: {
            channelCodes,
            channelAndAudienceIdFilters: [],
            showNonCreated: false,
            showDeleted: false,
            statuses: [ChannelAudienceStatus.Active],
            // This filters out any audiences that have null or -1 as their potential size
            minPotentialSize: 0
          }
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
      };
    }
  }),
  withStyles(styles),
  withRouter
)(RenderAudienceSelectorField);
