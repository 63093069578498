import { useRouteMatch } from 'react-router-dom';
import { ReactElement } from 'react';

import { paths } from 'src/routes/Constants';
import ArchitectureProvider from './ArchitectureProvider';

interface ArchitectureRouteProps {
  children: ReactElement;
}

// Define the shape of params
interface MatchParams {
  architectureId: string;
}

const ArchitectureRoute = ({ children }: ArchitectureRouteProps) => {
  const routeMatch = useRouteMatch<MatchParams>(paths.architecture.base);

  const props = {
    params: {
      architectureId: routeMatch?.params?.architectureId as string
    }
  };

  if (!routeMatch) {
    return children;
  }
  return <ArchitectureProvider {...props}>{children}</ArchitectureProvider>;
};

export default ArchitectureRoute;
