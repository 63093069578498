import { useState, useEffect } from 'react';
import { get, flow } from 'lodash';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import SentryUtil from 'src/common/SentryUtil';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';

import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import {
  setAccessToken,
  setAccessTokenExpiration,
  setEvAccessToken
} from 'src/Auth/common';

import { paths } from 'src/routes/Constants';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    width: '400px'
  }
});

const AdminImpersonateLanding = props => {
  const { classes, location, appSettings } = props;
  const [error, setError] = useState(false);

  const impersonateUser = () => {
    const { search } = location;

    const parsedQueryString = queryString.parse(search);

    const fqdn = get(parsedQueryString, 'fqdn');
    const auth0Token = get(parsedQueryString, 'auth0Token');
    const organizationId = get(parsedQueryString, 'organizationId');
    const userId = get(parsedQueryString, 'userId');
    const groupId = get(parsedQueryString, 'groupId');
    const orgUrl = get(parsedQueryString, 'orgUrl');

    const isAPILocalhost =
      appSettings?.app?.general?.apiBaseUrl ===
        'https://localhost:8080/lithium/graphql' ||
      appSettings?.app?.general?.apiBaseUrl ===
        'https://127.0.0.1:8080/lithium/graphql';

    const exchangeParams = `fqdn: "${fqdn}", targetOrganizationId: "${organizationId}", authString: "${auth0Token}", targetGroupId: "${groupId}", targetUserId: "${userId}"`;

    SentryUtil.addBreadcrumb({
      message: 'AdminImpersonateLanding: calling ExchangeToken for user.',
      data: {
        groupId,
        organizationId,
        userId
      }
    });

    const authQuery = `
mutation Authorize {
    authorize(${exchangeParams}) {
        token
    }
}
`;

    return fetch(
      isAPILocalhost
        ? 'http://localhost:8080/lithium/graphql'
        : import.meta.env.EVOCALIZE_LITHIUM_API_URL,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: authQuery })
      }
    )
      .then(response => response.json())
      .then(
        ({
          data: {
            authorize: { token }
          }
        }) => {
          // Note: If this call is successful && we have the expiration we can
          //       set the new tokens.

          let expiration = null;
          try {
            const decoded = jwtDecode(token);
            expiration = Number(get(decoded, 'exp')) * 1000;
          } catch (err) {
            setError(true);
          }

          // Set tokens
          setAccessToken(auth0Token);
          setEvAccessToken(token);
          setAccessTokenExpiration(expiration);
          if (orgUrl) {
            localStorage.setItem('evHostnameOverride', orgUrl);
          }

          // Note: I found when using history.push or any of the other route changes the
          //       app was not fully reloading. The following ensures when we get to the
          //       dashboard we have the correct user loaded.

          // 1. Clear the query params && set route to dashboard.
          window.history.replaceState(null, null, `#${paths.dashboard.base}`);
          // 2. Reload page to get to dashbaord.
          return window.location.reload();
        }
      )
      .catch(error => {
        SentryUtil.addBreadcrumb({
          message: 'Admin user impersonation failed.',
          data: {
            error,
            groupId,
            locationSearch: search,
            organizationId,
            userId
          }
        });

        SentryUtil.captureException(error);
        setError(true);
      });
  };

  useEffect(() => {
    impersonateUser();
  }, []);

  let content = (
    <>
      <Loading />
      <Typography variant="h5">
        <Trans i18nKey="admin:loading.setUser">
          Verifying and setting user.
        </Trans>
      </Typography>
    </>
  );

  if (error) {
    content = (
      <ErrorMessage className={classes.errorMessage}>
        <Trans i18nKey="admin:error.setUser">
          There was an error logging you in. If the problem persists, please
          contact support.
        </Trans>
      </ErrorMessage>
    );
  }

  return <div className={classes.container}>{content}</div>;
};

export default flow(
  withStyles(styles),
  withAppSettings,
  withRouter
)(AdminImpersonateLanding);
