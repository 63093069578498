import { useMemo } from 'react';
import { t } from 'i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Grid } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';

import useProgram from 'src/pages/Program/utils/useProgram';
import { FormSection } from 'src/components/ReduxForm';
import Offers from 'src/components/Checkout/Offers';
import CreditCardProvider from 'src/components/Checkout/CreditCardProvider';
import PriceSummary from 'src/pages/Program/ProgramSteps/PriceSummary/PriceSummary';

const Billing = () => {
  const appSettings = useAppSettings();
  const hookFormContext = useFormContext();
  const { setValue, clearErrors } = hookFormContext;
  const { selectedBlueprint, formName, isAutomated } = useProgram();

  // eslint-disable-next-line prefer-destructuring
  const stripeKey = appSettings.app.general.stripeKey;
  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  const stripeSourceIdValue = useWatch({ name: 'spendStep.stripeSourceId' });

  const change = (form: string, field: string, value: any) =>
    setValue(field, value);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FormSection
          title={t('programCreate:Checkout.billingSectionTitle')}
          description={t('programCreate:Checkout.billingSectionDescription')}
        >
          <Elements stripe={stripePromise}>
            <CreditCardProvider
              formName={formName}
              stripeSourceIdValue={stripeSourceIdValue}
              change={change}
              clearSubmitErrors={(formName: string) =>
                // TODO ensure this works when we have validations
                clearErrors(formName)
              }
            >
              <Offers
                formName={formName}
                selectedBlueprint={selectedBlueprint}
                paymentMethodIdError={undefined} // TODO: what todo here in hook form
                isHookForm
                hookFormContext={hookFormContext}
              />
            </CreditCardProvider>
          </Elements>
        </FormSection>
      </Grid>
      <Grid item xs={12}>
        <PriceSummary
          selectedBlueprint={selectedBlueprint}
          isAutomated={isAutomated}
          change={change}
          formName={formName}
          isHookForm
          hookFormContext={hookFormContext}
          isMLP
        />
      </Grid>
    </Grid>
  );
};

export default Billing;
