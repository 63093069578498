/* eslint-disable class-methods-use-this */
import { get, isNil } from 'lodash';
import jwtDecode from 'jwt-decode';
import { parse } from 'query-string';
import validator from 'validator';

import Logger from '../common/Logger';
import SentryUtil from '../common/SentryUtil';
import { paths } from '../routes/Constants';

import { getContextFromJwt } from './AuthUtil';

import {
  getAccessToken,
  setAccessToken,
  setAccessTokenExpiration,
  getAuthUrl,
  deleteAuthUrl,
  setEvAccessToken,
  AUTH0_KEYS,
  AUTH_PROVIDER_TYPES
} from './common';

class SSOAuthImpl {
  initialize({
    allowList = [],
    appSettings,
    organizationId,
    organizationDomainId,
    features,
    history,
    match,
    location
  }) {
    this.authConfig = appSettings?.app?.auth;
    this.organizationId = organizationId;
    this.organizationDomainId = organizationDomainId;
    this.appSettings = appSettings;
    this.allowList = allowList;
    this.authProviderType = appSettings?.app?.auth?.authProviderType;
    this.authenticationUrl = appSettings?.app?.auth?.authenticationUrl;
    this.organizationFqdn = appSettings?.app?.auth?.organizationFqdn;
    this.history = history;
    this.match = match;
    this.location = location;
    this.postLoginPageOverride = features?.postLoginPageOverride;
  }

  // This is called from the ApolloUtil class.
  login() {
    // clear all tokens first
    this.logout();
    return this.lithiumShowLoginPrompt();
  }

  lithiumShowLoginPrompt() {
    // Since this authenticator relies on the client's auth endpoint, we
    // simply redirect the user to the authenticationUrl depending on the provider
    if (this.authProviderType === AUTH_PROVIDER_TYPES.okta_direct_sso) {
      this.showOktaDirectSSOPrompt();
    } else {
      this.showSSOPrompt();
    }
  }

  showSSOPrompt() {
    if (this.authenticationUrl) {
      window.location = this.authenticationUrl;
    } else {
      throw new Error('missing auth url');
    }
  }

  showOktaDirectSSOPrompt() {
    const params = parse(this.location.search);
    const authUrl = this?.appSettings?.app?.oktaDirectSSO?.authorizationUrl;

    // check if they sent us a code
    if (params.code || params.error) {
      // go to the sso page so we can handle user import
      this.history.push(`${paths.auth.sso}${this.location.search}`);
    } else {
      window.location = authUrl;
    }
  }

  exchangeToken(authTokenOverride, orgId, groupId) {
    const params = parse(this.location.search);

    let redirectParam;
    // validator.isURL crashes the app if params.path is undefined
    if (params?.path) {
      // path param must be a slash path
      // ex. /#/architecture/11007/programs
      redirectParam = validator.isURL(params?.path) ? null : params?.path;
    }

    let exchangeParams = `fqdn: "${this.organizationFqdn}", authString: "${authTokenOverride}"`;

    if (!isNil(groupId)) {
      exchangeParams += `, targetGroupId: "${groupId}"`;
    }

    const authQuery = `
mutation Authorize {
    authorize(${exchangeParams}) {
        token
        pullProviderExecutionStatuses {
            executionId
            pullProviderType
            isExecutionComplete
            jobIds
            jobDetails {
                totalItems
                remainingItems
                completedItems
                successfulItems
                errorItems
                isCompleted
            }
            executionErrors
        }
    }
}
`;

    return fetch(this.appSettings.app.general.baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: authQuery })
    })
      .then(response => response.json())
      .then(({ data }) => {
        const response = data?.authorize;
        const token = get(response, 'token');

        // if token is null we are importing the user
        if (!token) {
          // if token and pullProviderExecutionStatuses are null we have a problem
          if (isNil(response?.pullProviderExecutionStatuses)) {
            throw new Error('InvalidToken');
          }
          // return current response so we can poll in Sso.jsx
          return Promise.resolve(response);
        }

        let exp = null;

        try {
          const decoded = jwtDecode(token);
          exp = Number(get(decoded, 'exp')) * 1000;
        } catch (err) {
          // Do nothing - our exp check below will catch issues.
        }

        if (!exp) {
          throw new Error('InvalidToken');
        }

        // Now that we've verified that exchange worked successfully,
        // we can set the tokens in localStorage and reset the page for
        // the user.
        // Note: We set the access token so that Auth.js's lightweight
        //       isAuthenticated() test can check the expiration easily.
        setAccessTokenExpiration(exp);
        setAccessToken(authTokenOverride);
        setEvAccessToken(token);

        // first check for override, second check local storage, third default to dashboard
        const authUrl =
          redirectParam ||
          this.postLoginPageOverride ||
          getAuthUrl() ||
          '/#/dashboard';

        // Now that we have the auth URL, delete it from localStorage so
        // we don't leave it in place.
        deleteAuthUrl();

        window.location = authUrl;

        // Note: this is a bit of a hack - later we should really plug
        //       in a way to refresh the entire app without a window
        //       reload.
        window.location.reload();

        // return response to stop polling may not be needed b/c of the location change
        // and reload but keeps things clean
        return Promise.resolve(response);
      })
      .catch(err => {
        const context = getContextFromJwt(authTokenOverride);
        const tokenSub = context?.sub;

        // Send error information off to Sentry.
        SentryUtil.addBreadcrumb({
          category: 'auth',
          data: {
            tokenSub,
            message: 'SSOAuthenticator: Evocalize API exchangeToken failed.',
            errObject: err
          }
        });
        SentryUtil.captureException(err);
        // trigger the catch in Sso.jsx for failed login
        return Promise.reject(new Error('InvalidToken'));
      });
  }

  switchOfficeToken(newOfficeId) {
    // Note: There's no need to set any of the new tokens in a .then() call
    //       because exchangeToken takes care of that above.
    return this.exchangeToken(
      getAccessToken(),
      this.organizationId,
      newOfficeId
    );
  }

  // Note: For SSO, we ignore the returnTo URL so they can stay on the Logout
  //       page. This prevents users from automatically getting redirected to
  //       the client's login page, which would typically automatically log
  //       the user back in.
  logout(/* returnTo */) {
    Object.keys(AUTH0_KEYS).forEach(auth0Key => {
      localStorage.removeItem(AUTH0_KEYS[auth0Key]);
    });

    Logger.debug('User has been logged out.');

    // Return a resolved promise so the Logout page can render the "logged
    // out" message.
    return Promise.resolve();
  }

  setAllTokens(/* { accessToken, idToken, evAccessToken, agentProfileId } */) {
    // Do nothing.
  }
}

export default SSOAuthImpl;
