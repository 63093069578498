import { useState } from 'react';
import { flow, get, some } from 'lodash';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button } from '@mui/material';

import { withGlobalContext } from 'src/GlobalContextProvider';

import { paths } from 'src/routes/Constants';
import { isCorporateFacebookPage } from 'src/common/FacebookUtil';
import {
  FACEBOOK_MODAL_SETTING,
  FACEBOOK_DEFAULT_PAGE_ID
} from 'src/common/userSettings';

import { setContinueCreateProgram } from 'src/pages/Program/actions';

import { withFeatures } from 'src/components/Feature';
import Modal from 'src/components/Modal';

import { setDismissModal } from './mutations';
import { getFacebookPagesV2 } from './queries';

const FacebookLinkModal = ({
  setDismissModal,
  history,
  location,
  setContinueCreateProgram,
  facebookPages,
  features: { facebookModal },
  globalContext
}) => {
  const userSettings = globalContext?.me?.settings;

  const [modalOpen, setModalOpen] = useState(
    // when we are redirected to the link pages this hides the modal pending refresh
    !location.pathname.includes('facebook')
  );

  if (!facebookModal) {
    return null;
  }

  const loading = get(facebookPages, 'loading');
  const error = get(facebookPages, 'error');
  const facebookPagesData = get(facebookPages, 'facebookPagesV2');

  const hasDefaultFacebook = userSettings?.[FACEBOOK_DEFAULT_PAGE_ID];
  const hasBeenDismissed = get(userSettings, FACEBOOK_MODAL_SETTING);

  if (
    loading ||
    error ||
    some(facebookPagesData, page => !isCorporateFacebookPage(page)) ||
    location.pathname.includes('facebook') ||
    !userSettings ||
    hasDefaultFacebook ||
    hasBeenDismissed ||
    !modalOpen
  ) {
    return null;
  }

  const linkNewFacebook = () => {
    setContinueCreateProgram(`${location.pathname}${location.search}`);
    setModalOpen(false);
    history.push(paths.facebook.link);
  };

  const handleDismiss = () => {
    setDismissModal({
      variables: {
        updateSettings: {
          [FACEBOOK_MODAL_SETTING]: true
        },
        updateType: 'DEEP_MERGE'
      }
    })
      .then(() => {
        setModalOpen(false);
      })
      .catch(() => {
        setModalOpen(false);
      });
  };

  return (
    <Modal
      open
      headerText={t('chrome:FBmodal.header')}
      onClose={() => setModalOpen(false)}
      showClose
      disableEscapeKeyDown
      FooterComponent={() => (
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={linkNewFacebook}
            data-amp-fb_page_modal_link
          >
            <Trans i18nKey="chrome:FBmodal.set">Link Facebook Page</Trans>
          </Button>

          <Button
            onClick={() => setModalOpen(false)}
            data-amp-fb_page_modal_dismiss
          >
            <Trans i18nKey="chrome:FBmodal.dismiss">Dismiss</Trans>
          </Button>

          <Button onClick={handleDismiss} data-amp-fb_page_modal_dont_show>
            <Trans i18nKey="chrome:FBmodal.dontshow">Don't Show Again</Trans>
          </Button>
        </>
      )}
    >
      <div>
        <Trans i18nKey="chrome:FBmodal.body" />
      </div>
    </Modal>
  );
};

export default flow(
  withRouter,
  withFeatures,
  connect(null, { setContinueCreateProgram }),
  graphql(setDismissModal, { name: 'setDismissModal' }),
  graphql(getFacebookPagesV2, {
    name: 'facebookPages',
    options: {
      // Who you gonna call? CACHE BUSTERS!
      variables: {
        antiCache: +new Date()
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  }),
  withGlobalContext
)(FacebookLinkModal);
