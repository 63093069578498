import { Field, getFormValues, change } from 'redux-form';
import { flow } from 'lodash';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import { filtersToProgramName } from 'src/components/AutomatedProgramFilter/helpers';
import { validateRequired } from 'src/common/validations';

const styles = theme => ({
  requiredText: {
    fontStyle: 'italic',
    fontSize: '14px'
  },
  info: {
    position: 'relative',
    bottom: theme.spacing(2),
    color: theme?.palette?.text?.hint
  },
  nameToggle: {
    marginBottom: theme.spacing(2)
  }
});

const CustomNameToggle = ({
  defaultName,
  input,
  formName,
  change,
  classes
}) => {
  const { value, onChange } = input;

  const handleChange = () => {
    onChange(!value);
    change(formName, 'configureStep.automatedProgramName', defaultName);
  };

  return (
    <div>
      {value ? (
        <div className={classes.nameToggle}>
          <Button color="primary" size="small" onClick={handleChange}>
            <Trans i18nKey="programCreate:automatedName.defaultButton">
              Use Default Name
            </Trans>
          </Button>
        </div>
      ) : (
        <div>
          <p>
            <span>
              <Trans i18nKey="programCreate:automatedName.defaultTitle">
                Automation Name:
              </Trans>
            </span>
            {' "'}
            <strong>{defaultName}</strong>
            {'" '}
            <Button
              color="primary"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleChange}
              data-cy="edit-automated-name"
            >
              <Trans i18nKey="programCreate:automatedName.editButton">
                Edit
              </Trans>
            </Button>
          </p>
        </div>
      )}
    </div>
  );
};

let oldName = '';

const AutomatedProgramName = ({
  classes,
  filters,
  architecture,
  contentName,
  automatedProgramNameCustom,
  formName,
  change,
  isSummary
}) => {
  const metaData = architecture?.catalog?.fieldMetadata;
  const defaultName = filtersToProgramName(filters, metaData, contentName);

  if (!automatedProgramNameCustom && defaultName !== oldName) {
    change(formName, 'configureStep.automatedProgramName', defaultName);
    oldName = defaultName;
  }

  const label = (
    <span>
      <Trans i18nKey="programCreate:automatedName.label">Custom Name</Trans>
      <span className={classes.requiredText}> (Required)</span>
    </span>
  );

  if (isSummary) {
    // if we are in the summary side panel only show the input
    return (
      <Field
        component={RenderTextField}
        // label={label}
        name="configureStep.automatedProgramName"
        validate={[validateRequired]}
        variant="standard"
      />
    );
  }

  return (
    <>
      {automatedProgramNameCustom && (
        <Field
          component={RenderTextField}
          label={label}
          name="configureStep.automatedProgramName"
          validate={[validateRequired]}
        />
      )}
      <Field
        name="configureStep.automatedProgramNameCustom"
        component={CustomNameToggle}
        defaultName={defaultName}
        change={change}
        formName={formName}
        classes={classes}
      />

      <div className={classes.info}>
        <Trans i18nKey="programCreate:automatedName.subheading">
          This name is a label for your reference. It is not displayed in any
          ads.
        </Trans>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const allFormValues = getFormValues(formName)(state);

  return {
    filters: allFormValues?.configureStep?.automatedPrograms?.filters || [],
    automatedProgramNameCustom:
      allFormValues?.configureStep?.automatedProgramNameCustom ?? false
  };
};

export default flow(
  connect(mapStateToProps, { change }),
  withStyles(styles)
)(AutomatedProgramName);
