import { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter, Link } from 'react-router-dom';
import { parse } from 'qs';

import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Box
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';

import PageTitle from 'src/components/PageTitle/PageTitle';
import Loading from 'src/components/Loading';
import { paths } from 'src/routes/Constants';
import { getTikTokAdvertiserAuthUrl } from './queries';
import { authorizeTikTokAdvertisers } from './mutations';

const styles = theme => ({
  content: {
    padding: theme.spacing(2),
    maxWidth: '700px'
  },
  linkMargin: {
    marginTop: theme.spacing(2)
  }
});

const PAGE_STATES = {
  landing: 'landing',
  authorizing: 'authorizing',
  success: 'success'
};

const getText = () => ({
  title: t('partner:tiktokOnboarding.title'),
  subtite: t('partner:tiktokOnboarding.subtitle'),
  initiate: t('partner:tiktokOnboarding.initiate'),
  instructions: t('partner:tiktokOnboarding.instructions'),
  success: t('partner:tiktokOnboarding.success'),
  authorizing: t('partner:tiktokOnboarding.authorizing'),
  error: t('partner:tiktokOnboarding.error'),
  backToPartner: t('partner:ticktokOnboarding.backToPartner'),
  backToDashboard: t('partner:ticktokOnboarding.backToDashboard')
});

const TikTok = ({
  classes,
  getTikTokAdvertiserAuthUrl,
  authorizeTikTokAdvertisers,
  location
}) => {
  const text = getText();
  const [error, setError] = useState(null);
  const query = parse(location.search, { ignoreQueryPrefix: true });
  const [pageState, setPageState] = useState(PAGE_STATES.landing);

  const handleAuthorize = async () => {
    try {
      const { data } = await authorizeTikTokAdvertisers({
        variables: {
          authCode: query.tiktok_auth_code
        }
      });
      if (data?.authorizeTikTokAdvertisers?.success) {
        setPageState(PAGE_STATES.success);
      } else {
        setError('Error authorizing tiktok advertiser');
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (pageState === PAGE_STATES.landing && query.tiktok_auth_code) {
      setPageState(PAGE_STATES.authorizing);
      handleAuthorize();
    }
  });

  if (getTikTokAdvertiserAuthUrl?.loading) {
    return <Loading />;
  }

  const authUrl =
    getTikTokAdvertiserAuthUrl?.getTikTokAdvertiserAuthUrl?.authUrl;

  return (
    <>
      <PageTitle subPageTitle={text.title} />
      <Typography variant="h5">{text.title}</Typography>
      <Typography variant="subtitle2">{text.subtitle}</Typography>
      <br />

      <Paper className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {error && (
              <Alert severity="error">
                {text.error}: {error}
              </Alert>
            )}
            {pageState === PAGE_STATES.landing && authUrl && (
              <>
                <Alert severity="info">{text.instructions}</Alert>
                <br />
                <Button href={authUrl} variant="contained" color="primary">
                  {text.initiate}
                </Button>
              </>
            )}
            {pageState === PAGE_STATES.authorizing && (
              <>
                <Alert severity="info">
                  <CircularProgress size={20} />
                  {text.authorizing}
                </Alert>
              </>
            )}
            {pageState === PAGE_STATES.success && (
              <>
                <Alert severity="success">{text.success}</Alert>
                <Box display="flex" gap={2} className={classes.linkMargin}>
                  <Link to={paths.partner.base}>{text.backToPartner}</Link>
                  <Link to={paths.dashboard.base}>{text.backToDashboard}</Link>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default flow(
  graphql(getTikTokAdvertiserAuthUrl, {
    name: 'getTikTokAdvertiserAuthUrl',
    skip: ({ location }) => {
      const query = parse(location.search, { ignoreQueryPrefix: true });
      return !!query.tiktok_auth_code;
    },
    options: ({ appSettings }) => {
      return {
        fetchPolicy: 'no-cache',
        variables: {
          redirectUrl: `https://${appSettings.organizationFqdn}/#${paths.partner.onboarding.tiktok}`
        }
      };
    }
  }),
  graphql(authorizeTikTokAdvertisers, { name: 'authorizeTikTokAdvertisers' }),
  withAppSettings,
  withRouter,
  withStyles(styles)
)(TikTok);
