import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';

import { styled } from '@mui/system';
import { Card, CardHeader, CardContent, Collapse, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { paths } from 'src/routes/Constants';
import Instrumentation from 'src/instrumentation';
import { useArchitectureAnywhere } from 'src/pages/Architecture/ArchitectureProvider';
import useOnMount from 'src/hooks/useOnMount';

type FormSectionProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
  isCollapsed?: boolean;
  isContentSelectable?: boolean;
  businessObjects?: any[];
  channel?: string;
  productId?: string;
  sectionHasError?: boolean;
  action?: React.ReactNode;
};

const FormSectionCard = styled(Card, {
  shouldForwardProp: prop => {
    return prop !== 'isProgramCreatePage' && prop !== 'isQuickAutomationsV1';
  }
})(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`
}));

const FormSectionCardHeader = styled(CardHeader)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  p: theme.spacing(1.5, 3),
  '& .MuiCardHeader-action': {
    alignSelf: 'center'
  }
}));

const FormSectionCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  '&:last-child': { paddingBottom: theme.spacing(1.5) }
}));

const ToggleExpandIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'expanded'
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: (theme as Theme).transitions.create('transform', {
    duration: (theme as Theme).transitions.duration.shortest
  }),
  '& svg': {
    fontSize: 30,
    fill: '#111'
  }
}));

const FormSection = (props: FormSectionProps) => {
  const {
    title,
    description,
    children,
    isCollapsed = false,
    isContentSelectable,
    businessObjects,
    channel,
    productId,
    sectionHasError,
    action
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [hasBOs, setHasBOs] = useState(false);
  const location = useLocation();
  const architecture = useArchitectureAnywhere();

  useOnMount(() => {
    if (isCollapsed && expanded) {
      setExpanded(false);
    }
  });

  useEffect(() => {
    // if they haven't selected BOs then ignore errors
    if (isContentSelectable && !businessObjects?.length) {
      return;
    }

    // this kinda lame but there is 1 render where BOs have been selected but errors have not been updated
    // so we use this to track that render and skip expanding the section
    if (isContentSelectable && !hasBOs) {
      setHasBOs(true);
      return;
    }

    if (sectionHasError && !expanded) {
      setExpanded(true);
    }
  }, [sectionHasError, expanded, businessObjects, isContentSelectable, hasBOs]);

  const isProgramCreateForm = matchPath(
    location.pathname,
    paths.architecture.programCreate
  );

  const isAutomationCreateForm = matchPath(
    location.pathname,
    paths.architecture.automatedProgramCreate
  );

  const isAutomationEditForm = matchPath(
    location.pathname,
    paths.architecture.automatedProgramEdit
  );

  const isNewFormSectionUI =
    isProgramCreateForm || isAutomationCreateForm || isAutomationEditForm;

  const handleExpandClick = () => {
    if (isNewFormSectionUI) {
      // Note that "expanded" is what we currently are, so we're doing the opposite
      const eventKey = expanded
        ? Instrumentation.Events.ClickInputSectionCollapsed
        : Instrumentation.Events.ClickInputSectionExpanded;

      Instrumentation.logEvent(eventKey, {
        channel,
        productId,
        architectureId: architecture?.id,
        sectionName: title
      });
    }
    setExpanded(!expanded);
  };

  const formSectionProps = {
    elevation: 1,
    sx: {
      border: 'none'
    }
  };

  const formHeaderProps = {
    title,
    titleTypographyProps: {
      fontWeight: 500
    },
    subheader: description
  };

  if (isNewFormSectionUI && !isCollapsed) {
    return (
      <FormSectionCard {...formSectionProps}>
        <FormSectionCardHeader
          {...formHeaderProps}
          subheaderTypographyProps={{
            variant: 'body1',
            sx: { mt: 0.5 }
          }}
          action={action}
        />

        <FormSectionCardContent>{children}</FormSectionCardContent>
      </FormSectionCard>
    );
  }

  if (isNewFormSectionUI && isCollapsed) {
    return (
      <FormSectionCard {...formSectionProps}>
        <FormSectionCardHeader
          {...formHeaderProps}
          action={
            isCollapsed && (
              <ToggleExpandIconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label={expanded ? 'show less' : 'show more'}
                disabled={!isCollapsed}
                expanded={expanded}
              >
                <ExpandMoreIcon />
              </ToggleExpandIconButton>
            )
          }
        />
        {/* don't add unmountOnExit prop or it breaks validation */}
        <Collapse in={expanded} timeout="auto">
          <FormSectionCardContent>{children}</FormSectionCardContent>
        </Collapse>
      </FormSectionCard>
    );
  }

  return <>{children}</>;
};

export default FormSection;
