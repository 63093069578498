import { useCallback, useMemo, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { flow } from 'lodash';
import { t } from 'i18next';

import { CircularProgress, Paper, Button } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

import withStyles from '@mui/styles/withStyles';

import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import { DynamicForm } from 'src/components/ReduxForm';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

import {
  buildCreateOrUpdateInput,
  getResourceItemFormInputs
} from './constants';
import {
  createResourceItem,
  updateResourceItem,
  deleteResourceItem
} from './queries';

const styles = theme => ({
  formContainer: {
    padding: theme.spacing(2)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark
    }
  }
});

const pageText = () => ({
  errorMessage: t('adminResourceLibrary:items.form.errorMessage'),
  successMessage: t('adminResourceLibrary:items.form.successMessage'),
  updateButton: t('adminResourceLibrary:items.form.updateButton'),
  createButton: t('adminResourceLibrary:items.form.createButton'),
  cancelButton: t('adminResourceLibrary:items.form.cancelButton'),
  resetButton: t('adminResourceLibrary:items.form.resetButton'),
  deleteButton: t('adminResourceLibrary:items.form.deleteButton'),
  deleteWarningHeader: t('adminResourceLibrary:items.form.deleteWarningHeader'),
  deleteWarningBody: t('adminResourceLibrary:items.form.deleteWarningBody'),
  deleteSuccessMessage: t(
    'adminResourceLibrary:items.form.deleteSuccessMessage'
  ),
  deleteErrorMessage: t('adminResourceLibrary:items.form.deleteErrorMessage')
});

const ResourceItemForm = ({
  isUpdate,
  isEditItemValuesOnly,
  initialValues,
  onAfterSuccess,
  classes,
  reset,
  handleSubmit,
  createResourceItem,
  updateResourceItem,
  deleteResourceItem,
  enqueueSnackbar,
  submitting,
  dirty,
  invalid,
  closeForm
}) => {
  const text = useMemo(() => pageText(), []);
  const inputs = useMemo(
    () => getResourceItemFormInputs(isUpdate, isEditItemValuesOnly),
    [isUpdate, isEditItemValuesOnly]
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = useCallback(
    async data => {
      const input = buildCreateOrUpdateInput(isUpdate, data, initialValues);

      const request = {
        variables: { input }
      };

      try {
        await (isUpdate
          ? updateResourceItem(request)
          : createResourceItem(request));
      } catch (error) {
        enqueueSnackbar({
          message: text.errorMessage,
          options: {
            variant: 'error'
          }
        });

        reset();
        return;
      }

      enqueueSnackbar({
        message: text.successMessage,
        options: {
          variant: 'success'
        }
      });

      reset();
      onAfterSuccess();
    },
    [isUpdate, initialValues, reset, onAfterSuccess]
  );

  const onDelete = useCallback(async () => {
    const request = {
      variables: { id: initialValues.id }
    };

    try {
      await deleteResourceItem(request);
    } catch (error) {
      enqueueSnackbar({
        message: text.deleteErrorMessage,
        options: {
          variant: 'error'
        }
      });

      reset();
      return;
    }

    enqueueSnackbar({
      message: text.deleteSuccessMessage,
      options: {
        variant: 'success'
      }
    });

    reset();
    onAfterSuccess();
  }, [isUpdate, initialValues, reset, onAfterSuccess]);

  return (
    <Paper className={classes.formContainer}>
      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Use the key prop here to ensure we don't keep old form data around
                    when switching between update and create. */}
        <DynamicForm key={isUpdate} disabled={false} inputs={inputs} />
        <br />
        <Button
          color="primary"
          disabled={submitting || !dirty || invalid}
          endIcon={submitting && <CircularProgress size={15} />}
          variant="contained"
          type="submit"
        >
          {isUpdate ? text.updateButton : text.createButton}
        </Button>{' '}
        {isUpdate && !isEditItemValuesOnly && (
          <>
            <Button
              className={classes.deleteButton}
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              {text.deleteButton}
            </Button>{' '}
          </>
        )}
        <Button color="secondary" variant="outlined" onClick={() => reset()}>
          {text.resetButton}
        </Button>{' '}
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            reset();
            closeForm();
          }}
        >
          {text.cancelButton}
        </Button>
        <ConfirmationModal
          icon={<WarningIcon />}
          cancelButtonText={text.cancelButton}
          confirmButtonText={text.deleteButton}
          title={text.deleteWarningHeader}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={onDelete}
        >
          {text.deleteWarningBody}
        </ConfirmationModal>
      </form>
    </Paper>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'resourceItemForm'
  }),
  graphql(createResourceItem, {
    name: 'createResourceItem'
  }),
  graphql(updateResourceItem, {
    name: 'updateResourceItem'
  }),
  graphql(deleteResourceItem, {
    name: 'deleteResourceItem'
  }),
  connect(null, { enqueueSnackbar }),
  withRouter,
  withStyles(styles)
)(ResourceItemForm);
