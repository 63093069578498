import { useState } from 'react';
import { clone, isEmpty } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  UpdateQuickstartInBulkMutationVariables,
  DeleteAutomationQuickstartMutationVariables,
  CreateAutomationQuickstartMutation,
  UpdateAutomationQuickstartMutation,
  UpsertProductQuickStartLocalizedImagesMutationVariables,
  MutationDeleteProductQuickStartLocalizedImagesArgs
} from 'src/generated/gql/graphql';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';
import { formatQuickStartItems } from 'src/common/quickstart';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';
import Heading from 'src/components/PageElements/Heading';
import { convertFiltersArrayToJSON } from 'src/components/AutomatedProgramFilter/helpers';

import EditQuickstartForm from './Components/EditQuickstartForm';
import EditQuickstartSkeleton from './Components/EditQuickstartSkeleton';
import { getManageQuickStarts } from './queries';
import {
  FormSaveValues,
  updateQuickstartInBulk,
  deleteAutomationQuickstart,
  createAutomationQuickstart,
  updateAutomationQuickstart,
  upsertProductQuickStartLocalizedImages,
  deleteProductQuickStartLocalizedImages
} from './mutations';

interface RouteParams {
  quickStartId: string;
}

const getText = () => ({
  editTitle: t('admin:manageQuickstart.editTitle'),
  crumbAdminDashboard: t('admin:crumbs.adminDashboard'),
  crumbManageQuickstarts: t('admin:crumbs.manageQuickstarts'),
  crumbEditQuickstart: t('admin:crumbs.editQuickstart'),
  error: t('admin:manageQuickstart.editError'),
  deleteQuickAutomationError: t(
    'admin:manageQuickstart.deleteQuickAutomationError'
  ),
  createQuickAutomationError: t(
    'admin:manageQuickstart.deleteQuickAutomationError'
  ),
  updateQuickAutomationError: t(
    'admin:manageQuickstart.deleteQuickAutomationError'
  ),
  success: t('admin:manageQuickstart.editSuccess'),
  addCustomDesignsError: t('admin:manageQuickstart.addCustomDesignsError')
});

const EditQuickstart = () => {
  const text = getText();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams<RouteParams>();
  const quickStartId = params?.quickStartId;

  const { data, loading, refetch } = useQuery(getManageQuickStarts);
  const activeQuickStarts = data?.activeQuickStarts || [];

  const quickStarts = formatQuickStartItems(activeQuickStarts);

  const editableQuickstarts: { [key: string]: any } = quickStarts?.reduce(
    (obj, qs) => {
      return { ...obj, [qs.id]: qs };
    },
    {}
  );
  const currentlyEditingQuickStart = editableQuickstarts[quickStartId];

  const [quickAutomationsToDelete, storeQuickAutomationToDelete] = useState<
    string[]
  >([]);

  const setQuickAutomationToDelete = (id: string) => {
    const newIdsToDelete = quickAutomationsToDelete;
    newIdsToDelete.push(id);
    storeQuickAutomationToDelete(newIdsToDelete);
  };

  const [updateQuickstartMutation, { loading: loadingMutation }] =
    useMutation<UpdateQuickstartInBulkMutationVariables>(
      updateQuickstartInBulk
    );

  const [
    createAutomationQuickstartMutation,
    { loading: loadingCreateAutomationQuickstart }
  ] = useMutation<CreateAutomationQuickstartMutation>(
    createAutomationQuickstart
  );
  const [
    updateAutomationQuickstartMutation,
    { loading: loadingUpdateAutomationQuickstart }
  ] = useMutation<UpdateAutomationQuickstartMutation>(
    updateAutomationQuickstart
  );
  const [
    deleteAutomationQuickstartMutation,
    { loading: loadingDeleteAutomationQuickStart }
  ] = useMutation<DeleteAutomationQuickstartMutationVariables>(
    deleteAutomationQuickstart
  );

  const [
    setQuickstartLocalizedImage,
    { loading: loadingLocalizedImageMutation }
  ] = useMutation<UpsertProductQuickStartLocalizedImagesMutationVariables>(
    upsertProductQuickStartLocalizedImages
  );

  const [
    deleteQuickStartLocalizedImages,
    { loading: loadingDeleteLocalizedImageMutation }
  ] = useMutation<MutationDeleteProductQuickStartLocalizedImagesArgs>(
    deleteProductQuickStartLocalizedImages
  );

  const onSave = async (values: FormSaveValues) => {
    if (!isEmpty(values?.automationQuickStarts)) {
      const addNewRules: any[] = [];
      const updateRules: any[] = [];
      values?.automationQuickStarts.forEach((item: any) => {
        if (item.newRule) {
          const newRuleCopy = clone(item);
          delete newRuleCopy.newRule;
          // eslint-disable-next-line no-underscore-dangle
          delete newRuleCopy.__typename;
          newRuleCopy.catalogFilter = convertFiltersArrayToJSON(
            newRuleCopy.catalogFilter
          );
          addNewRules.push(newRuleCopy);
        }
        if (!item.newRule) {
          const updateRuleCopy = clone(item);
          // eslint-disable-next-line no-underscore-dangle
          delete updateRuleCopy.__typename;
          // for some reason the update call does not require the productQuickStartId
          delete updateRuleCopy.productQuickStartId;
          updateRuleCopy.catalogFilter = convertFiltersArrayToJSON(
            updateRuleCopy.catalogFilter
          );
          updateRules.push(updateRuleCopy);
        }
      });

      const mutationParamasAdd = {
        automationQuickstarts: addNewRules.map((item: any) => {
          return item;
        })
      };
      const mutationParamasUpdate = {
        automationQuickstarts: updateRules.map((item: any) => {
          return item;
        })
      };

      // create new quick automations
      if (!isEmpty(mutationParamasAdd.automationQuickstarts)) {
        try {
          await createAutomationQuickstartMutation({
            variables: {
              ...mutationParamasAdd
            }
          });
        } catch (error) {
          // quick automation create error
          enqueueSnackbar(text.createQuickAutomationError, {
            variant: 'error'
          });
        }
      }

      // update existing quick automations
      if (!isEmpty(mutationParamasUpdate.automationQuickstarts)) {
        try {
          await updateAutomationQuickstartMutation({
            variables: {
              ...mutationParamasUpdate
            }
          });
        } catch (error) {
          // quick automation create error
          enqueueSnackbar(text.updateQuickAutomationError, {
            variant: 'error'
          });
        }
      }
    }

    if (!isEmpty(quickAutomationsToDelete)) {
      try {
        await deleteAutomationQuickstartMutation({
          variables: {
            quickstartAutomationIds: quickAutomationsToDelete
          }
        });
      } catch (error) {
        // quick automation delete error
        enqueueSnackbar(text.deleteQuickAutomationError, {
          variant: 'error'
        });
      }
    }

    if (!isEmpty(values?.localizedImages)) {
      try {
        const originalLocalizedImages =
          currentlyEditingQuickStart?.localizedImages || [];
        // what localizedImages have been deleted
        const deletedLocalizedImages = originalLocalizedImages.filter(
          (originalItem: any) =>
            !values?.localizedImages.some(
              updatedItem =>
                updatedItem.locale === originalItem.locale &&
                updatedItem.customImageUrl === originalItem.customImageUrl
            )
        );

        await Promise.all(
          deletedLocalizedImages.map(
            async (item: { locale: string; customImageUrl: string }) => {
              // delete locale images
              await deleteQuickStartLocalizedImages({
                variables: {
                  productQuickStartId: values.id,
                  locale: item.locale
                }
              });
            }
          )
        );

        await Promise.all(
          values?.localizedImages.map(
            async (item: { locale: string; customImageUrl: string }) => {
              // update locale images
              await setQuickstartLocalizedImage({
                variables: {
                  input: {
                    productQuickStartId: values.id,
                    locale: item.locale,
                    customImageUrl: item.customImageUrl
                  }
                }
              });
            }
          )
        );
      } catch (error) {
        // set localized image error
        enqueueSnackbar(text.addCustomDesignsError, {
          variant: 'error'
        });
      }
    }

    const mutationParams = {
      quickstarts: [
        {
          id: values.id,
          values: {
            isActive: values?.isActive,
            priority: values?.priority,
            isCustom: values?.isCustom || false,
            name: values?.name,
            description: values?.description
          }
        }
      ]
    };

    try {
      await updateQuickstartMutation({
        variables: {
          Input: { ...mutationParams }
        }
      });

      enqueueSnackbar(text.success, {
        variant: 'success'
      });

      await refetch();

      // success go back to manage quickstarts
      const linkPath = generateLinkPath(paths.admin.settings.quickStarts);
      history.push(linkPath);
    } catch (error) {
      // errrr in herrrrr
      enqueueSnackbar(text.error, {
        variant: 'error'
      });
    }
  };

  const onCancel = () => {
    const linkPath = generateLinkPath(paths.admin.settings.quickStarts);
    history.push(linkPath);
  };

  if (loading) {
    return <EditQuickstartSkeleton />;
  }

  const breadcrumbPieces = [
    {
      text: text.crumbAdminDashboard,
      to: paths.admin.base
    },
    {
      text: text.crumbManageQuickstarts,
      to: paths.admin.settings.quickStarts
    },
    {
      text: text.crumbEditQuickstart
    }
  ];

  return (
    <DndProvider backend={HTML5Backend}>
      <BreadcrumbTrail
        sx={theme => ({ marginBottom: theme.spacing(1) })}
        pieces={breadcrumbPieces}
      />
      <Heading title={text.editTitle} pageTitle={text.editTitle} />

      <EditQuickstartForm
        quickstart={currentlyEditingQuickStart}
        onSave={onSave}
        onCancel={onCancel}
        loading={loading}
        loadingMutation={
          loadingMutation ||
          loadingDeleteAutomationQuickStart ||
          loadingCreateAutomationQuickstart ||
          loadingUpdateAutomationQuickstart ||
          loadingLocalizedImageMutation ||
          loadingDeleteLocalizedImageMutation
        }
        quickStartId={quickStartId}
        setQuickAutomationToDelete={setQuickAutomationToDelete}
      />
    </DndProvider>
  );
};

export default EditQuickstart;
