import React, { useState, useEffect } from 'react';
import { uniq, some } from 'lodash';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { LocationConnection } from 'src/generated/gql/graphql';
import { t } from 'i18next';
import { Box, Button, Link } from '@mui/material';
import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';
import { formatBlueprints } from 'src/common/blueprints';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { haveOffersChanged } from 'src/pages/Program/utils/offers';
import { getInitialSpendStepValues } from 'src/pages/Program/utils/initialValues';
import { programActions, ProgramActionType } from 'src/pages/Program/Constants';
import { editMlp } from 'src/pages/ProgramPerformance/mutations';
import { businessObjectsFromOrder } from 'src/common/businessObjects';
import HookForm from 'src/components/ReduxForm/HookForm';
import { useSnackbar } from 'notistack';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';
import { ValidationErrors } from 'src/components/AdPreview/helpers';

import {
  getMLPOrder,
  getSelectedBlueprint,
  getSelectedLocations
} from './queries';

import { MainContainer } from './MainContainer';
import ProgramEditForm from './ProgramEditForm';

type ProgramEditProps = {
  type: ProgramActionType;
};

export type MlpEditSubmitData = {
  errors?: ValidationErrors | undefined;
  includeOverrides: boolean;
};

const pageText = () => ({
  title: 'Edit Multi Location Program',
  previewButtonTip: t('program:preview.button'),
  missingBlueprintSubtitle: t('program:create.missingBlueprintSubtitle'),
  blueprintError: t('blueprintSelector:messages.errorGettingBps'),
  kind: t('program:kind')
});

const ProgramEdit = ({ type }: ProgramEditProps) => {
  const text = pageText();
  const { architectureId, orderId } = useParams<{
    architectureId: string;
    orderId: string;
  }>();

  const architecture = useArchitecture();

  const [editMlpMutation] = useMutation(editMlp);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const [selectedBlueprint, setSelectedBlueprint] = useState<any>();
  const [selectedBusinessObjects, setSelectedBusinessObjects] = useState<any>({
    selectedBusinessObjects: undefined,
    loading: true,
    error: undefined
  });

  const { data, loading, error } = useQuery(getMLPOrder, {
    variables: {
      multiLocationProgramId: orderId
    }
  });

  const [getBlueprint, { loading: BPLoading, error: BPError }] =
    useLazyQuery(getSelectedBlueprint);

  const mlp = data?.getMultiLocationProgram;

  const ordersWithOverrides =
    mlp?.childOrders?.edges?.reduce<string[]>((overrides, edge) => {
      if (
        edge?.node?.multiLocationChildProgramDetails
          ?.variableValuesAreOverridden
      ) {
        return [...overrides, edge?.node?.id];
      }
      return overrides;
    }, []) || [];

  const offersChanged = haveOffersChanged({
    orderOfferId: mlp?.offerId || '',
    product: selectedBlueprint,
    isAutomated: false
  });

  const selectedLocations =
    mlp?.childOrders?.edges?.map(
      edge => edge?.node?.multiLocationChildProgramDetails?.locationId
    ) || [];

  const { data: allLocations, loading: locationsLoading } = useQuery(
    getSelectedLocations,
    {
      skip: !selectedLocations.length,
      variables: {
        filter: {
          locationIds: selectedLocations as string[]
        }
      }
    }
  );

  // fake order for getting initial valuez
  const order = {
    offer: mlp?.product?.offers?.filter(offer => offer?.id === mlp?.offerId)[0],
    billingDetails: {
      amount: mlp?.priceAmount,
      tierId: mlp?.tierId
    }
  };

  const initialValues = {
    configureStep: {},
    spendStep: {
      ...getInitialSpendStepValues({
        startDate: mlp?.scheduleStart as string,
        endDate: mlp?.scheduleEnd as string,
        preselectedPromoCodes: [],
        orderToClone: order,
        offersChanged,
        selectedBlueprint,
        architecture,
        isAutomated: false,
        isDraft: false
      }),
      programName: mlp?.name
    },
    dynamicUserInputs: mlp?.variableValues || {},
    locationsOverrideById: {},
    selectedLocations: []
  };

  const submitHandler =
    ({ errors = [], includeOverrides }: MlpEditSubmitData) =>
    async (data: any) => {
      try {
        await editMlpMutation({
          variables: {
            input: {
              multiLocationProgramId: orderId,
              // we want to exclude any errors (hacky) and any orders with overrides if they selected that option
              ...(((!includeOverrides && ordersWithOverrides.length > 0) ||
                errors.length > 0) && {
                excludedChildOrderIds: uniq([
                  ...ordersWithOverrides,
                  ...errors.map(e => e.locationId)
                ])
              }),
              variableValues: data.dynamicUserInputs,
              ...(data?.spendStep?.programName && {
                multiLocationProgramName: data?.spendStep?.programName
              })
            }
          }
        });
        enqueueSnackbar(t('programPerf:editMlp.success'), {
          variant: 'success'
        });

        // post a list of things we couldn't update because we're too lazy to figure out validation ux
        if (errors.length > 0) {
          enqueueSnackbar(
            <div>
              {t('programPerf:editMlp.unableToUpdate')}
              <ul>
                {mlp?.childOrders?.edges?.reduce<React.ReactElement[]>(
                  (errorOrders, order) => {
                    const orderLocationId =
                      order?.node?.multiLocationChildProgramDetails?.locationId;
                    if (
                      some(
                        errors,
                        error => error.locationId === orderLocationId
                      )
                    ) {
                      const location = allLocations?.locations?.edges?.find(
                        loc => loc?.node?.id === orderLocationId
                      );
                      return [
                        ...errorOrders,
                        <li key={order?.node?.id}>
                          <Link
                            component={RouterLink}
                            to={generateLinkPath(paths.architecture.program, {
                              architectureId,
                              orderId: order?.node?.id
                            })}
                          >
                            {location?.node?.name} - {location?.node?.city} -{' '}
                            {order?.node?.id}
                          </Link>
                        </li>
                      ];
                    }
                    return errorOrders;
                  },
                  []
                )}
              </ul>
            </div>,
            {
              variant: 'warning',
              persist: true,
              anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
              action: key => (
                <>
                  <Button
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                  >
                    {t('businessObjectSelector:snackbar.dismiss')}
                  </Button>
                </>
              )
            }
          );
        }

        history.push(
          generateLinkPath(paths.architecture.multiLocationProgram, {
            architectureId,
            orderId
          })
        );
      } catch (e) {
        enqueueSnackbar(t('programPerf:editMlp.errorUpdating'), {
          variant: 'error'
        });
      }
    };

  useEffect(() => {
    const blueprintId = data?.getMultiLocationProgram?.product?.id;
    if (!loading && data?.getMultiLocationProgram && blueprintId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBlueprint({
        variables: { architectureId, productIds: [blueprintId] }
      }).then(data2 => {
        setSelectedBlueprint(formatBlueprints(data2?.data?.products)[0]);
        setSelectedBusinessObjects({
          selectedBusinessObjects: businessObjectsFromOrder(
            data?.getMultiLocationProgram?.childOrders?.edges?.[0]?.node
          ),
          loading: false,
          error: null
        });
      });
    }
  }, [loading]);

  if (loading || BPLoading || locationsLoading || !selectedBlueprint) {
    return <Loading />;
  }

  if (error || BPError) {
    return <Loading error />;
  }

  // we don't support any other editing pages at the moment
  if (type !== programActions.multiLocationEdit) {
    return null;
  }

  return (
    <MainContainer data-cy="maincontainter">
      <HookForm initialValues={initialValues}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <BreadcrumbTrail
            sx={theme => ({ marginBottom: theme.spacing(1) })}
            pieces={[
              {
                text: text.kind,
                to: paths.programs.base
              },
              {
                text: text.title
              }
            ]}
          />
          <Heading
            title={selectedBlueprint?.name ?? text.missingBlueprintSubtitle}
            pageTitle={text.title}
          />

          <ProgramEditForm
            architecture={architecture}
            selectedBlueprint={selectedBlueprint}
            selectedBusinessObjects={selectedBusinessObjects}
            submitHandler={submitHandler}
            multiLocationProgram={
              data?.getMultiLocationProgram as Record<string, any>
            }
            allLocations={allLocations?.locations as LocationConnection}
            selectedLocations={selectedLocations}
          />
        </Box>
      </HookForm>
    </MainContainer>
  );
};

export default ProgramEdit;
