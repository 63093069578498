import { HTMLAttributes } from 'react';
import {
  Field,
  change as changeAction,
  clearSubmitErrors,
  FormAction
} from 'redux-form';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { t } from 'i18next';

import {
  FormControlLabel,
  Radio,
  List,
  TextField,
  Box,
  AutocompleteRenderInputParams
} from '@mui/material';

import { BILLING_METHODS } from 'src/common/paymentUtils';
import { validateRequired } from 'src/common/validations';

import { PROGRAM_FORM_SECTION_SPEND_NAME } from 'src/pages/Program/Constants';
import {
  enqueueSnackbar,
  SnackbarNotification,
  SnackbarEnqueueAction
} from 'src/components/AdmiralSnackBar/actions';
import { RenderRadioGroup } from 'src/components/ReduxForm';
import RenderAutocomplete from 'src/components/ReduxForm/RenderAutocomplete/RenderAutocomplete';
import Loading from 'src/components/Loading';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';
import CardSelectOption from 'src/components/Checkout/CardSelectOption';
import CreditCardLogo from 'src/components/Icons/CreditCardLogos/CreditCardLogo';

import CardLabel from './CardLabel';
import CheckoutController from './CheckoutController';
import { getPaymentMethods } from './queries';
import useAddCreditCard from './useCreditCard';

interface CardOfferProps {
  formName: string;
  stripeSourceIdValue: string;
  isHookForm?: boolean;
  hookFormContext?: any;
  // connect props
  change: (form: string, field: string, value: any) => void;
  clearSubmitErrors: (formName: string) => FormAction;
  enqueueSnackbar: (
    notification: SnackbarNotification
  ) => SnackbarEnqueueAction;
}

export interface CardSelectOptionInterface {
  name: string;
  value: string;
  paymentMethod: {
    stripeSourceId: string;
    stripeSource: {
      cardLastFour: string;
      cardBrand: string;
      cardExpiryMonth: number;
      cardExpiryYear: number;
    };
    id: string;
  };
}

const stripeSourceRequired = (
  val: string,
  allValues: { spendStep?: { billingMethod?: string } }
) => {
  if (allValues?.spendStep?.billingMethod === BILLING_METHODS.card) {
    return validateRequired(val);
  }
};

const CardOffer = ({ isHookForm, stripeSourceIdValue }: CardOfferProps) => {
  const {
    loading,
    error,
    data,
    refetch: paymentMethodsRefetch
  } = useQuery(getPaymentMethods);

  const allPaymentMethods = data?.paymentMethod;
  const totalPaymentMethods = allPaymentMethods?.length || 0;
  const hasManyCards = totalPaymentMethods > 1;

  const {
    updatingPayment,
    stripeError,
    handleAddCard,
    addPaymentMethodModal,
    toggleAddPaymentModal,
    onStripeReady,
    updateSourceId
  } = useAddCreditCard();

  const validations =
    allPaymentMethods && allPaymentMethods.length > 0
      ? [stripeSourceRequired]
      : [];

  if (loading || error) {
    return <Loading error={error} />;
  }

  const hiddenPaymentMethodIdProps = {
    component: 'input',
    name: 'paymentMethodId',
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    extraProps: {
      type: 'hidden'
    }
  };

  const creditCards =
    allPaymentMethods &&
    allPaymentMethods.map(paymentMethod => {
      const { stripeSourceId, stripeSource, id } = paymentMethod;

      return hasManyCards ? (
        {
          value: stripeSourceId,
          name: stripeSource?.cardLastFour,
          paymentMethod
        }
      ) : (
        <FormControlLabel
          data-cy={`cc-item-${stripeSource?.cardLastFour}`}
          sx={{
            width: '100%'
          }}
          key={stripeSourceId}
          control={<Radio />}
          label={
            <CardLabel
              {...stripeSource}
              stripeSourceId={stripeSourceId}
              refetch={paymentMethodsRefetch}
              paymentMethodId={id}
            />
          }
          value={stripeSourceId}
        />
      );
    });

  const extraProps = {
    ...(hasManyCards && { options: creditCards || [] }),
    ...(hasManyCards && {
      renderOption: (
        props: HTMLAttributes<HTMLLIElement> & { key: any },
        option: CardSelectOptionInterface
      ) => {
        const { key, ...optionProps } = props;
        return (
          <CardSelectOption
            key={key}
            optionProps={optionProps}
            option={option}
            refetch={paymentMethodsRefetch}
          />
        );
      }
    }),
    ...(hasManyCards && {
      renderInput: (params: AutocompleteRenderInputParams) => {
        const currentlySelectedCard = creditCards?.find(card => {
          // checking both of these ensures the user can still type & search for a card
          return (
            (card as CardSelectOptionInterface)?.name ===
              params.inputProps.value &&
            (card as CardSelectOptionInterface)?.value === stripeSourceIdValue
          );
        }) as CardSelectOptionInterface;

        const cardBrand =
          currentlySelectedCard?.paymentMethod?.stripeSource?.cardBrand;
        const cardLastFour =
          currentlySelectedCard?.paymentMethod?.stripeSource?.cardLastFour;

        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              value: currentlySelectedCard
                ? t('programCreate:Checkout.endingV3', {
                    cardBrand: '', // no need to show the brand in the input it makes things to long
                    mask: '••••',
                    cardLastFour
                  })
                : params.inputProps.value
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              ...params.InputProps,
              ...(currentlySelectedCard && {
                startAdornment: (
                  <Box
                    sx={{
                      borderRadius: 0.5,
                      width: 50,
                      height: 30,
                      border: theme => `1px solid ${theme.palette.grey[200]}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 1.5
                    }}
                  >
                    <CreditCardLogo brand={cardBrand} />
                  </Box>
                )
              })
            }}
            data-cy="payment-card-select-input"
            label={t('programCreate:Checkout.selectCard')}
          />
        );
      }
    })
  };

  const stripeSourceIdProps = {
    component: hasManyCards ? RenderAutocomplete : RenderRadioGroup,
    name: 'stripeSourceId',
    validate: validations,
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    onChange: updateSourceId
  };

  const stripeSourceIdPropsHooks = {
    ...stripeSourceIdProps,
    onChange: (newValue: string) => {
      // hook form doesn't pass the event
      updateSourceId(undefined, newValue);
    },
    extraProps
  };

  return (
    <>
      {/* Note we don't need the hidden component for hook form */}
      {!isHookForm && (
        <Field
          {...hiddenPaymentMethodIdProps}
          {...hiddenPaymentMethodIdProps.extraProps}
        />
      )}

      {updatingPayment && <Loading />}
      {/* If there is at least 1 credit card on file */}
      {allPaymentMethods &&
        allPaymentMethods.length > 0 &&
        (isHookForm ? (
          <HookFormWrapper {...stripeSourceIdPropsHooks}>
            {!hasManyCards && (
              <List
                data-cy="payment-cardList"
                sx={{
                  pl: 2,
                  py: 0,
                  top: '-2px'
                }}
              >
                {creditCards}
              </List>
            )}
          </HookFormWrapper>
        ) : (
          <Field {...stripeSourceIdProps} {...extraProps}>
            {!hasManyCards && (
              <List
                data-cy="payment-cardList"
                sx={{
                  pl: 2,
                  py: 0,
                  top: '-2px'
                }}
              >
                {creditCards}
              </List>
            )}
          </Field>
        ))}

      <CheckoutController
        inline={!allPaymentMethods?.length}
        updatingPayment={updatingPayment}
        stripeError={stripeError}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleAddCard={handleAddCard}
        addPaymentMethodModal={addPaymentMethodModal}
        toggleAddPayment={toggleAddPaymentModal}
        onStripeReady={onStripeReady}
      />
    </>
  );
};

export default connect(null, {
  change: changeAction,
  clearSubmitErrors,
  enqueueSnackbar
})(CardOffer);
