import { flow, isString } from 'lodash';
import {
  FormControl,
  TextField,
  FormHelperText,
  InputAdornment
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  input: {},
  error: {
    color: theme.palette.error.main
  }
});

const RenderFilterValueDate = ({
  classes,
  input: { value, onChange },
  meta: { error }
}) => {
  const showError = error;

  if (error && !isString(error)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }

  return (
    <FormControl error={showError} className={classes.input}>
      <TextField
        value={value}
        type="number"
        onChange={onChange}
        error={showError}
        variant="standard"
        InputProps={{
          endAdornment: <InputAdornment position="end">Days</InputAdornment>,
          inputProps: {
            step: 1,
            min: 0
          }
        }}
      />
      {showError && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default flow(withStyles(styles))(RenderFilterValueDate);
