/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';
import { isEmpty, snakeCase } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';

import { Divider, Grid, Typography } from '@mui/material';
import { useFeatures } from 'src/components/Feature';
import { useGlobalContext } from 'src/GlobalContextProvider';
import Loading from 'src/components/Loading';
import { usePagination } from 'src/components/Pagination/hooks';
import PaginationControls from 'src/components/Pagination/PaginationControls';

import Instrumentation from 'src/instrumentation';
import { useAppSettings } from 'src/AppSettings';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Table from 'src/components/Table';

import { BillingDetail } from 'src/generated/gql/graphql';
import ManageCreditCards from './ManageCreditCards';
import { RESULTS_PER_PAGE } from './constants';
import { useGetBillingHistory, useGetMediaBillingHistory } from './hooks';
import {
  getBillingHistoryColumnSchema,
  getMediaBillingHistoryColumnSchema
} from './billingTableColumnSchemas';

const BillingPreferences = () => {
  const billingHistory = useGetBillingHistory();
  const mediaBillingHistory = useGetMediaBillingHistory();

  const { manageCreditCards } = useFeatures();
  const appSettings = useAppSettings();
  const globalContext = useGlobalContext();
  const history = useHistory();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;
  const stripeKey = appSettings?.app?.general?.stripeKey;
  const { navigateNext, navigatePrev } = usePagination({
    edges: billingHistory?.rawRows,
    resultsPerPage: RESULTS_PER_PAGE,
    refetchCallback: billingHistory?.refetch
  });

  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  const billingHistoryColumnSchema = getBillingHistoryColumnSchema({
    billingHistory,
    isTeamsEnabled
  });
  const mediaBillingHistoryColumnSchema = getMediaBillingHistoryColumnSchema();

  const handleRowClick = (data: BillingDetail) => {
    const { architectureId, orderId } = data;
    const linkPath = generateLinkPath(paths.architecture.program, {
      architectureId,
      orderId
    } as any);

    Instrumentation.logEvent(Instrumentation.Events.BillingHistoryClicked);

    return history.push(linkPath);
  };

  const billingHistoryRows = billingHistory?.rawRows.map(row => ({
    ...row!.node,
    key: `payment${snakeCase(row!.node!.createdOn!)}`
  }));

  const mediaBillingHistoryRows = mediaBillingHistory?.rawRows.map(row => ({
    ...row!.node,
    key: `payment${snakeCase(row!.node!.mediaAssetId)}`
  }));

  return (
    <Grid container spacing={3}>
      {(manageCreditCards ?? true) && (
        <Grid item xs={12}>
          <Elements stripe={stripePromise}>
            <ManageCreditCards />
          </Elements>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2">
          <Trans i18nKey="billing:billingHistoryHeader">Billing History</Trans>
        </Typography>
        <Divider />

        {(billingHistory?.loading || billingHistory?.error) && (
          <Loading error={billingHistory?.error} />
        )}
        {!(billingHistory?.loading || billingHistory?.error) && (
          <>
            {isEmpty(billingHistoryRows) && (
              <TableEmptyState
                emptyMessage={t('billing:messages.noBillingHistory')}
                title={t('billing:messages.billingHistory')}
              />
            )}

            {!isEmpty(billingHistoryRows) && (
              <>
                <Table
                  loading={billingHistory?.loading}
                  columnSchema={billingHistoryColumnSchema}
                  rows={billingHistoryRows}
                  onClickBodyRow={handleRowClick}
                  dataCy="billing-history-table"
                />
                <PaginationControls
                  pageInfo={billingHistory?.pageInfo}
                  navigateNext={navigateNext}
                  navigatePrev={navigatePrev}
                />
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          <Trans i18nKey="billing:mediaBillingHistoryHeader">
            Media Billing History
          </Trans>
        </Typography>
        <Divider />

        {(mediaBillingHistory?.loading || mediaBillingHistory?.error) && (
          <Loading error={mediaBillingHistory?.error} />
        )}
        {!(mediaBillingHistory?.loading || mediaBillingHistory?.error) && (
          <>
            {isEmpty(mediaBillingHistoryRows) && (
              <TableEmptyState
                emptyMessage={t('billing:messages.noMediaBillingHistory')}
                title={t('billing:messages.medaiBillingHistory')}
              />
            )}

            {!isEmpty(mediaBillingHistoryRows) && (
              <Table
                loading={mediaBillingHistory?.loading}
                columnSchema={mediaBillingHistoryColumnSchema}
                rows={mediaBillingHistoryRows}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default BillingPreferences;
