import { flow, isString } from 'lodash';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import numeral from 'numeral';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { formatNumber } from 'src/common/numbers';

const styles = theme => ({
  input: {},
  error: {
    color: theme.palette.error.main
  }
});

const RenderFilterValueNumber = ({
  classes,
  input: { value, onChange },
  meta: { error },
  selectedColumn
}) => {
  const showError = error;

  const isCurrency =
    selectedColumn?.displayMethodId === INPUT_TYPES.PRICE_INT ||
    selectedColumn?.displayMethodId === INPUT_TYPES.PRICE_DECIMAL;

  const updateValue = ({ target: { value } }) => {
    if (value === '') {
      return onChange(value);
    }
    const newVal = isCurrency ? numeral(value).value() : value;
    return onChange(newVal);
  };

  const formatedValue = isCurrency
    ? `$${value === '' ? '' : formatNumber(value)}`
    : value;

  if (error && !isString(error)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }

  return (
    <FormControl error={showError} className={classes.input}>
      <TextField
        value={formatedValue}
        onChange={updateValue}
        error={showError}
        placeholder="Enter Value"
        variant="standard"
      />
      {showError && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default flow(withStyles(styles))(RenderFilterValueNumber);
