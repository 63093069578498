import {
  get,
  keyBy,
  keys,
  isString,
  isEmpty,
  uniq,
  sortBy,
  forEach,
  isNil,
  values
} from 'lodash';
import { t } from 'i18next';

import {
  minValue,
  maxValue,
  getExtraValidationRules,
  validateLength,
  getValidationsByDynamicField,
  validateRequired,
  validateArrayMinLength,
  validateArrayMaxLength,
  minMaxArrayValue
} from 'src/common/validations';
import { getChannelKeys } from 'src/common/blueprints';
import { translateMaps } from 'src/common/templateTranslator';
import {
  getValidationsByDynamicField as getHookFormValidationsByDynamicField,
  getExtraValidationRules as getHookFormExtraValidationRules
} from 'src/common/validationsHookFormHelpers';

import {
  getTemplateMeta,
  TEMPLATE_TAG_REGEX
} from '../RenderTemplateStringTextField';
import { LOCATION_COLUMNS } from './constants';

export const getFieldName = data => get(data, 'name') || get(data, 'fieldName');

// Outputs the valid and missing columns for a given content set like user metadata or
// user content.It's then used to determine valid chips in template string inputs.
// Currently used for the following content sets:
//   contentColumns && businessObjects
//   userContentColumns && userMetadataFields
export const getColumnValidation = ({ columns = [], columnData = [] }) => {
  if (isEmpty(columns) && isEmpty(columnData)) {
    return {
      validColumns: new Set(),
      missingColumns: new Set()
    };
  }

  const columnNames = columns.map(column => column?.fieldName);

  // we are assuming content & locations are required so when there is no data return all
  // columns as valid for selection
  if (isEmpty(columnData)) {
    return {
      validColumns: columnNames,
      missingColumns: new Set()
    };
  }

  const validColumns = new Set();
  const columnsInData = new Set();

  columnData.forEach(data => {
    keys(data).forEach(key => {
      if (columnNames.includes(key)) {
        validColumns.add(key);
      }
      columnsInData.add(key);
    });
  });

  // missing columns are in columns but not found in columnData
  const missingColumns = new Set(
    columnNames.filter(name => !columnsInData.has(name))
  );

  // identify valid columns that have missing data
  const validColumnsMissingData = new Set();

  columnData.forEach(data => {
    validColumns.forEach(column => {
      if (isEmpty(data[column])) {
        validColumnsMissingData.add(column);
      }
    });
  });

  const missingColumnsMissingData = new Set(
    uniq([...missingColumns.keys(), ...validColumnsMissingData.keys()])
  );

  return {
    validColumns,
    missingColumns: missingColumnsMissingData
  };
};

export const validateDynamic = (
  value = '',
  contentColumns = [],
  businessObjects = [],
  errorMessage,
  userContentColumns = [],
  userMetadataFields = [],
  locations = []
) => {
  if (!value) {
    return;
  }

  // failsafe if they set the wrong type of field as dynamic this will prevent
  // the page from blowing up.
  if (!isString(value)) {
    return;
  }

  let matches = [];

  matches = Array.from(value.matchAll(TEMPLATE_TAG_REGEX));

  const locationColumnValidations = getColumnValidation({
    columns: LOCATION_COLUMNS,
    columnData: locations
  });

  const contentValidations = getColumnValidation({
    columns: contentColumns,
    columnData: businessObjects
  });

  const userColumnValidation = getColumnValidation({
    columns: userContentColumns,
    columnData: userMetadataFields
  });

  const validColumns = new Set([
    ...contentValidations?.validColumns,
    ...userColumnValidation?.validColumns,
    ...locationColumnValidations?.validColumns
  ]);

  const missingColumns = new Set([
    ...contentValidations?.missingColumns,
    ...userColumnValidation?.missingColumns,
    ...locationColumnValidations?.missingColumns
  ]);

  const dynamicValues = [
    ...contentColumns,
    ...userContentColumns,
    ...LOCATION_COLUMNS
  ];
  const dynamicValuesMap = keyBy(dynamicValues, 'fieldName');

  const errors = new Set();
  matches.forEach(tag => {
    const { label, fieldName: value } = getTemplateMeta(
      tag[0],
      dynamicValuesMap
    );

    // column can be valid but have no data if this is the case its captured in missing columns
    if (!validColumns.has(value) || missingColumns.has(value)) {
      errors.add(label);
    }
  });

  if (errors.size > 0) {
    return errorMessage;
  }
};

export const validateSimpleDynamicLength = (value, max) => {
  if (!value || !max) {
    return;
  }

  if (!isString(value)) {
    return;
  }

  if (value.length > max) {
    return t(
      'renderTemplateStringTextField:maxLengthExceededSimpleValidation',
      {
        max
      }
    );
  }
};

// returns a single validation function that returns the first error from translated BOs vs an array of validations
export const hookFormValidateWithTranslations =
  (
    validations = [],
    businessObjects = [],
    userMetadataFields = {},
    locations = []
  ) =>
  (value, ...rest) => {
    if (!value || isEmpty(validations)) {
      return;
    }
    // failsafe if they set the wrong type of field as dynamic this will prevent
    // the page from blowing up.
    if (!isString(value)) {
      return;
    }

    let error;

    if (isEmpty(businessObjects) && isEmpty(locations)) {
      // if no business objects just translate & validate on user metadata
      forEach(validations, validationFn => {
        const translatedValue = translateMaps(value, userMetadataFields);

        error = validationFn(translatedValue, ...rest);

        if (error) {
          // we exit the loop and only return the first error
          // (this is the same as redux form error handling)
          return false;
        }
      });

      return error;
    }

    if (isEmpty(businessObjects) && !isEmpty(locations)) {
      // we loop through all BOS and all validations and return the first one with errors
      forEach(locations, location => {
        forEach(validations, validationFn => {
          const translatedValue = translateMaps(value, {
            ...location,
            ...userMetadataFields
          });

          error = validationFn(translatedValue, ...rest);

          if (error) {
            // we exit the loop and only return the first error
            // (this is the same as redux form error handling)
            return false;
          }
        });
        // we exit loop as soon as we have an error
        if (error) {
          return false;
        }
      });
      return error;
    }

    if (!isEmpty(businessObjects) && isEmpty(locations)) {
      forEach(businessObjects, businessObject => {
        forEach(validations, validationFn => {
          const translatedValue = translateMaps(value, {
            ...businessObject,
            ...userMetadataFields
          });

          error = validationFn(translatedValue, ...rest);

          if (error) {
            // we exit the loop and only return the first error
            // (this is the same as redux form error handling)
            return false;
          }
        });
        // we exit loop as soon as we have an error
        if (error) {
          return false;
        }
      });

      return error;
    }

    // we loop through all BOS/locations and validate
    forEach(businessObjects, businessObject => {
      forEach(locations, location => {
        forEach(validations, validationFn => {
          const translatedValue = translateMaps(value, {
            ...businessObject,
            ...location,
            ...userMetadataFields
          });

          error = validationFn(translatedValue, ...rest);

          if (error) {
            // we exit the loop and only return the first error
            // (this is the same as redux form error handling)
            return false;
          }
        });
      });

      // we exit loop as soon as we have an error
      if (error) {
        return false;
      }
    });

    return error;
  };

// returns a single validation function that returns the first error from translated BOs vs an array of validations
export const validateWithTranslations =
  (validations = {}, businessObjects = [], userMetadataFields = {}) =>
  (value, ...rest) => {
    if (!value || isEmpty(validations)) {
      return;
    }
    // failsafe if they set the wrong type of field as dynamic this will prevent
    // the page from blowing up.
    if (!isString(value)) {
      return;
    }

    let error;

    if (isEmpty(businessObjects)) {
      // if no business objects just translate & validate on user metadata
      forEach(validations, validationFn => {
        const translatedValue = translateMaps(value, userMetadataFields);

        error = validationFn(translatedValue, ...rest);

        if (error) {
          // we exit the loop and only return the first error
          // (this is the same as redux form error handling)
          return false;
        }
      });

      return error;
    }

    // we loop through all BOS and all validations and return the first one with errors
    forEach(businessObjects, businessObject => {
      forEach(validations, validationFn => {
        const translatedValue = translateMaps(value, {
          ...businessObject,
          ...userMetadataFields
        });

        error = validationFn(translatedValue, ...rest);

        if (error) {
          // we exit the loop and only return the first error
          // (this is the same as redux form error handling)
          return false;
        }
      });
      // we exit loop as soon as we have an error
      if (error) {
        return false;
      }
    });

    return error;
  };

export const validateDynamicByType = (
  value = '',
  businessObjects = [],
  validationsByType = []
) => {
  if (!value || !businessObjects.length) {
    return;
  }
  // failsafe if they set the wrong type of field as dynamic this will prevent
  // the page from blowing up.
  if (!isString(value)) {
    return;
  }

  const errorSet = new Set();
  const businessObjectsWithErrors = new Set();

  // go through all business objects to account for different dynamic variables
  businessObjects.forEach(businessObject => {
    // get translated string
    const translatedValue = translateMaps(value, businessObject);

    // loop through validations && validate against the translated value
    validationsByType.forEach(validation => {
      const validationValue = validation(translatedValue);

      if (validationValue) {
        // if validation error set error string && id of the object with the error
        errorSet.add(validationValue);
        businessObjectsWithErrors.add(businessObject.id);
      }
    });
  });

  const errorCount = errorSet.size;
  const totalBusinessObjectsWithErrors = businessObjectsWithErrors.size;

  if (errorCount > 0) {
    const errors = Array.from(errorSet).join(', ');

    if (totalBusinessObjectsWithErrors > 1) {
      // Note If there are many business object we need to inform the user the
      //      errors could be in any of the selected objects. For now we will
      //      only use the count but in the future we have the ids if needed.
      return t('renderTemplateStringTextField:validationByTypeMany', {
        totalBusinessObjectsWithErrors,
        errors
      });
    }
    // only one business object selected
    return errors;
  }
};

// Simple function to take all of the input metadata, sort them by their
// sort order and also remove any types that shouldn't be user-set (such as IDs,
// etc).
export const sortAndFilterFormInputs = (inputs = []) => {
  return sortBy(
    inputs.filter(inputMd => {
      return get(inputMd, 'displayParameters.inputData.inputType') !== 'ID';
    }),
    inputMd => get(inputMd, 'displaySortOrder')
  );
};

export const formatSelectOptions = selectOptions => {
  return selectOptions.map(option => {
    // when we get an array of strings we likely want that to be both the name and value
    if (isString(option)) {
      return { name: option, value: option };
    }
    const { value, name, description, sx, group } = option;
    const formatted = {};

    if (!isNil(value) || !isNil(name)) {
      // let's be nice and if either of these is there { name: 'dumb' } or { value: 'stupid'}
      // assume you just want both set to whatever that is (our data returns like that sometimes)
      formatted.value = value ?? name;
      formatted.name = name ?? value;

      if (!isNil(sx)) {
        // sx will be passed to the menu item's sx prop, it can either be a function or sx style object
        formatted.sx = sx;
      }

      if (!isNil(group)) {
        // allows us to group options
        formatted.group = group;
      }

      if (!isNil(description)) {
        formatted.description = description;
      }

      return formatted;
    }

    const contents = values(option);
    if (contents?.length === 1) {
      // now I'm being really nice
      // if there's just one thing in there like { fieldName: 'mySlug' }
      return {
        value: contents[0],
        name: contents[0]
      };
    }
    return undefined;
  });
};

export const checkIfDynamic = metadata =>
  get(metadata, 'isExpressionAllowed') && !get(metadata, 'isHidden');

export const checkIfArray = metadata =>
  get(metadata, 'blueprintVariable.isArray') && !get(metadata, 'isHidden');

export const generateValidations = ({
  metadata,
  blueprint,
  businessObjects = [],
  userContentColumns = [],
  userMetadataFields = {},
  contentColumns = [],
  // Channel specific ad creative validations
  channelValidations = []
}) => {
  if (metadata?.isHidden) {
    return [];
  }
  const isDynamic = checkIfDynamic(metadata);
  const isArray = checkIfArray(metadata);
  const isMultiInput = metadata?.isMultiInput;

  const stringMinLength = metadata?.blueprintVariable?.stringMinLength;
  const stringMaxLength = metadata?.blueprintVariable?.stringMaxLength;

  const shouldValidateDynamicLength = isDynamic && !!stringMaxLength;

  const extraValidationRules = get(
    metadata,
    'displayParameters.inputData.extraValidationRules',
    []
  );

  const channels = getChannelKeys(blueprint?.blueprint?.publishers || []);

  let validations = [
    // this adds validations by type, channel, etc.
    ...getValidationsByDynamicField(metadata, channels),
    // extra validations
    ...getExtraValidationRules(extraValidationRules),
    // these are validations we add in our own forms by setting reduxValidations []
    ...(metadata?.reduxValidations || []),
    ...channelValidations
  ];

  if (shouldValidateDynamicLength) {
    validations.push(value =>
      validateSimpleDynamicLength(value, stringMaxLength)
    );
  } else if (!!stringMinLength || !!stringMaxLength) {
    validations.push(validateLength(stringMinLength || 0, stringMaxLength));
  }

  if (isDynamic) {
    validations = [
      validateWithTranslations(validations, businessObjects, userMetadataFields)
    ];
    validations.push(value =>
      validateDynamic(
        value,
        contentColumns,
        businessObjects,
        t('renderTemplateStringTextField:missingValidation'),
        userContentColumns,
        [userMetadataFields] // needs to be an array
      )
    );
  }
  // Automatically add required validator if the field is required.
  // we add this here because translated validations short circuit on '' value
  if (metadata?.isRequired) {
    // add it first :)
    validations.unshift(validateRequired);
  }

  const integerMinValue = get(metadata, 'blueprintVariable.integerMinValue');

  if (!isNil(integerMinValue)) {
    validations.push(minValue(integerMinValue));
  }

  const integerMaxValue = get(metadata, 'blueprintVariable.integerMaxValue');

  if (!isNil(integerMaxValue)) {
    validations.push(maxValue(integerMaxValue));
  }

  // if this is a multi input we have already added the array validations to the multi input component
  if (isArray && !isMultiInput) {
    const arrayMinItems = metadata?.blueprintVariable?.arrayMinItems;
    const arrayMaxItems = metadata?.blueprintVariable?.arrayMaxItems;

    if (!isNil(arrayMinItems) && !isNil(arrayMaxItems)) {
      validations.push(minMaxArrayValue(arrayMinItems, arrayMaxItems));
    } else {
      if (!isNil(arrayMinItems)) {
        validations.push(validateArrayMinLength(arrayMinItems));
      }
      if (!isNil(arrayMaxItems)) {
        validations.push(validateArrayMaxLength(arrayMaxItems));
      }
    }
  }

  return validations;
};

export const generateHookFormValidations = ({
  metadata,
  blueprint,
  businessObjects = [],
  userContentColumns = [],
  userMetadataFields = {},
  contentColumns = [],
  selectedLocationsMetadata = [],
  // Channel specific ad creative validations
  channelValidations = {}
}) => {
  if (metadata?.isHidden) {
    return {};
  }
  const isDynamic = checkIfDynamic(metadata);
  const isArray = checkIfArray(metadata);
  const isMultiInput = metadata?.isMultiInput;

  const stringMinLength = metadata?.blueprintVariable?.stringMinLength;
  const stringMaxLength = metadata?.blueprintVariable?.stringMaxLength;

  const shouldValidateDynamicLength = isDynamic && !!stringMaxLength;

  const extraValidationRules = get(
    metadata,
    'displayParameters.inputData.extraValidationRules',
    []
  );

  const channels = getChannelKeys(blueprint?.blueprint?.publishers || []);

  let validations = {
    // this adds validations by type, channel, etc.
    ...getHookFormValidationsByDynamicField(metadata, channels),
    // extra validations
    ...getHookFormExtraValidationRules(extraValidationRules),
    // these are validations we add in our own forms by setting reduxValidations []
    ...(metadata?.reduxValidations || {}),
    ...channelValidations
  };

  if (shouldValidateDynamicLength) {
    validations = {
      ...validations,
      validateSimpleDynamicLength: value =>
        validateSimpleDynamicLength(value, stringMaxLength)
    };
  } else if (!!stringMinLength || !!stringMaxLength) {
    validations = {
      ...validations,
      validateLength: validateLength(stringMinLength || 0, stringMaxLength)
    };
  }

  if (isDynamic) {
    validations = {
      validateWithTranslations: hookFormValidateWithTranslations(
        validations,
        businessObjects,
        userMetadataFields,
        selectedLocationsMetadata
      )
    };

    validations = {
      ...validations,
      validateDynamic: value =>
        validateDynamic(
          value,
          contentColumns,
          businessObjects,
          t('renderTemplateStringTextField:missingValidation'),
          userContentColumns,
          [userMetadataFields], // needs to be an array,
          selectedLocationsMetadata
        )
    };
  }
  // Automatically add required validator if the field is required.
  // we add this here because translated validations short circuit on '' value
  if (metadata?.isRequired) {
    validations = { ...validations, validateRequired };
  }

  const integerMinValue = metadata?.blueprintVariable?.integerMinValue;

  if (!isNil(integerMinValue)) {
    validations = {
      ...validations,
      integerMinValue: minValue(integerMinValue)
    };
  }

  const integerMaxValue = get(metadata, 'blueprintVariable.integerMaxValue');

  if (!isNil(integerMaxValue)) {
    validations = {
      ...validations,
      integerMaxValue: maxValue(integerMaxValue)
    };
  }

  // if this is a multi input we have already added the array validations to the multi input component
  if (isArray && !isMultiInput) {
    const arrayMinItems = metadata?.blueprintVariable?.arrayMinItems;
    const arrayMaxItems = metadata?.blueprintVariable?.arrayMaxItems;

    if (!isNil(arrayMinItems) && !isNil(arrayMaxItems)) {
      validations = {
        ...validations,
        minMaxArrayValue: minMaxArrayValue(arrayMinItems, arrayMaxItems)
      };
    } else {
      if (!isNil(arrayMinItems)) {
        validations = {
          ...validations,
          validateArrayMinLength: validateArrayMinLength(arrayMinItems)
        };
      }
      if (!isNil(arrayMaxItems)) {
        validations = {
          ...validations,
          validateArrayMaxLength: validateArrayMaxLength(arrayMaxItems)
        };
      }
    }
  }

  return validations;
};

export const generateMultipleInputValidations = ({ metadata }) => {
  const arrayMinItems = metadata?.blueprintVariable?.arrayMinItems;
  const arrayMaxItems = metadata?.blueprintVariable?.arrayMaxItems;

  const validations = [...(metadata?.reduxValidations || [])];

  // Automatically add required validator if the field is required.
  // we add this here because translated validations short circuit on '' value
  if (metadata?.isRequired) {
    // add it first :)
    validations.unshift(validateRequired);
  }

  if (!isNil(arrayMinItems) && !isNil(arrayMaxItems)) {
    validations.push(minMaxArrayValue(arrayMinItems, arrayMaxItems));
  } else {
    if (!isNil(arrayMinItems)) {
      validations.push(validateArrayMinLength(arrayMinItems));
    }
    if (!isNil(arrayMaxItems)) {
      validations.push(validateArrayMaxLength(arrayMaxItems));
    }
  }

  return validations;
};
