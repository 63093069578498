import { useCallback } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { flow, pick } from 'lodash';
import { t } from 'i18next';

import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';

import { createPricingPlan } from '../mutations';
import { createPricingConfigInputs, FORM_NAME_CREATE_PLAN } from '../constants';

const styles = theme => ({
  editButton: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

const CreatePricingForm = props => {
  const {
    reset,
    refetch,
    classes,
    dirty,
    invalid,
    formValues,
    setOpenModal,
    createPricingPlanMutation
  } = props;

  const handleClearForm = () => {
    reset();
  };

  async function createPricingPlan(formValues) {
    const formattedValues = {
      ...formValues,
      currencyCode: formValues.currencyCode.toUpperCase(),
      intervalUnit: formValues.intervalUnit.toLowerCase()
    };
    const mutationParams = pick(formattedValues, [
      'currencyCode',
      'slug',
      'name',
      'description',
      'intervalUnit',
      'intervalLength',
      'isActive'
    ]);

    try {
      await createPricingPlanMutation({
        variables: {
          Input: {
            ...mutationParams
          }
        }
      });
      enqueueSnackbar({
        message: t('admin:createPricingPlan.successSnack'),
        options: {
          variant: 'success'
        }
      });

      handleClearForm();
      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:createPricingPlan.errorSnack'),
        options: {
          variant: 'error'
        }
      });
    }
  }

  const onSubmit = useCallback(async formValues => {
    createPricingPlan(formValues);
    setOpenModal(false);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <form autoComplete="off" data-cy="create-pricing-plan-form">
          <DynamicForm inputs={createPricingConfigInputs} />
        </form>
      </Grid>
      <Grid item xs={12} className={classes.editButton}>
        <Button
          variant="contained"
          color="primary"
          disabled={!dirty || invalid}
          onClick={() => {
            onSubmit(formValues);
          }}
          data-cy="create-pricing-plan-button"
        >
          {t('admin:createPricingPlan.create')}
        </Button>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  const pricingPlanInitialValues = createPricingConfigInputs.reduce(
    (acc, input) => ({ ...acc, [input.name]: input.initialValue }),
    {}
  );
  const initialValues = pricingPlanInitialValues;
  const formValues = getFormValues(FORM_NAME_CREATE_PLAN)(state);

  return { initialValues, formValues };
}

export default flow(
  reduxForm({
    form: FORM_NAME_CREATE_PLAN,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(createPricingPlan, {
    name: 'createPricingPlanMutation'
  }),
  connect(mapStateToProps),
  withStyles(styles)
)(CreatePricingForm);
