import { reduce, has } from 'lodash';

// we depricated feature names that were "disabled" to avoid confusion
// instead all features are true/false and defaults are defined
// map old feature names to new feature names
export const depricatedFeatureMap = {
  showLoginBackLink: 'loginBackLink',
  disableGallery: 'gallery',
  disableContacts: 'contacts',
  disableLeadNotifications: 'leadNotifications',
  disableAudiences: 'audiences',
  disableAddProgram: 'addProgramButton',
  disableProgramKpis: 'programKpis',
  allowPromoCodes: 'promoCodes',
  automatedPrograms: 'automations'
} as const;

export const FEATURE_DEFAULTS = {
  // activates the audience upload button
  audienceUpload: false,
  // provides a "click here
  // to sign back in" message.
  // when creating a program
  loginBackLink: false,
  // on/off gallery page
  gallery: true,
  // on/off giphy search in gallery
  giphySearch: false,
  // on/off shutterstock search/purchase
  shutterstockSearch: false,
  // show admin images in gallery
  galleryAdminImages: true,
  // on/off contacts page
  contacts: true,
  // on/off lead notifications settings section on the notification preferences
  leadNotifications: true,
  // on/off audience page
  audiences: true,
  // on/off "Add Program" button on /architecture/{id}/programs
  addProgramButton: true,
  // on/off facebook modal (hidden by default)
  facebookModal: false,
  // on/off KPIs at the top of the dashboard
  dashboardKpis: false,
  // on/off KPIs at the top of the program performance page.
  programKpis: true,
  showInactiveBlueprints: false,
  showInactiveOffers: false,
  // defaultSubGalleryType: 'public', // (options are public || private)
  promoCodes: false,
  // on/off dashboard link in side navigation
  dashboard: true,
  // on/off facebook link in the side navigation
  facebook: true,
  // on/off architectures in the side navigation
  architectures: true,
  // on/off office selector in the side navigation
  officeSelector: true,
  // on/off account menu in the app bar navigation
  accountMenu: true,
  // on/off my account within the account menu
  myAccount: true,
  // on/off logo on click event
  logoClick: true,
  // on/off override page that user is dropped in after login when set will be a relative path ex `/#${paths.facebook.pages}`
  postLoginPageOverride: ``,
  // on/off extra button links after user has linked a Facebook page
  extraFacebookPageLinkButtons: true,
  // on/off automations formerly know as automated programs
  automations: false,
  // on/off automation promo codes modal
  automationPromoCodes: false,
  // on/off automation adoption modal
  automationAdoptionModal: false,
  // on/off userflow
  userflow: false,
  // on/off manage credit cards
  manageCreditCards: true,
  // on/off show AI generation input button when necessary
  allowAiGeneratedContent: false,
  showNotificationPanel: false,

  // Experiments
  // on/off uixProgramStepsConfigurationV2
  uixProgramStepsConfigurationV2: false
} as const;

type DeprecatedFeatureKey = keyof typeof depricatedFeatureMap;
type FeatureDefaultKey = keyof typeof FEATURE_DEFAULTS;
export type Features = typeof FEATURE_DEFAULTS;

export interface InjectedFeatures {
  features: Features;
}

// this set's feature defaults and converts from old feature name to new feature names
export const getFeaturesFromSettings = (settings: Record<string, boolean>) => {
  return reduce(
    settings,
    (features, value, featureName) => {
      // if this setting is depricated
      if (has(depricatedFeatureMap, featureName)) {
        const newName =
          depricatedFeatureMap[featureName as DeprecatedFeatureKey];
        // if the replacement has been set ignore the old one
        if (settings[newName]) {
          return features;
        }
        // otherwise convert it to new setting:
        return {
          ...features,
          // if the new feature is true by default then invert the old setting else keep same
          // eg: disableGallery -> gallery vs allowPromoCodes -> promoCodes
          [newName]: FEATURE_DEFAULTS[newName as FeatureDefaultKey]
            ? !value
            : value
        };
      }
      // overwrite default if set.
      return { ...features, [featureName]: value };
    },
    FEATURE_DEFAULTS
  );
};
