import { Field } from 'redux-form';

import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';
import { OfferType } from 'src/common/offers';

import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';
import { RenderRadioGroup } from 'src/components/ReduxForm';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from 'src/pages/Program/Constants';
import { getOfferOptions } from './utils';

export interface Offer {
  type: OfferType;
  stripeSubscriptionPlans: any[];
}

interface PaymentTypeSelectorProps {
  offers: Offer[];
  isHookForm?: boolean;
}

const PaymentTypeSelector = ({
  offers,
  isHookForm
}: PaymentTypeSelectorProps) => {
  const isProgramCreatePage = useIsProgramCreatePage();

  const scheduleTypeProps = {
    component: RenderRadioGroup,
    name: 'scheduleType',
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    extraProps: {
      sx: !isProgramCreatePage
        ? {
            m: (theme: any) => theme.spacing(2, 0)
          }
        : {},
      row: !isProgramCreatePage
    }
  };

  const offerOption = getOfferOptions(offers);

  return isHookForm ? (
    <HookFormWrapper {...scheduleTypeProps} validateBackendOnly={false}>
      {offerOption}
    </HookFormWrapper>
  ) : (
    <Field {...scheduleTypeProps} {...scheduleTypeProps.extraProps}>
      {offerOption}
    </Field>
  );
};

export default PaymentTypeSelector;
