import { find, values } from 'lodash';

import { BILLING_METHODS } from 'src/common/paymentUtils';
import { OfferBillingMethod } from 'src/generated/gql/graphql';

interface GetSpendAmountArgs {
  spendStep: any;
  isSubscription: boolean;
  billingMethod: OfferBillingMethod;
  selectedBlueprint: any;
}

export const getSpendAmount = ({
  spendStep,
  isSubscription,
  billingMethod,
  selectedBlueprint
}: GetSpendAmountArgs) => {
  let spendAmount = spendStep?.oneTimeSpend;

  if (isSubscription) {
    if (billingMethod === BILLING_METHODS.partnerInvoice) {
      spendAmount = spendStep?.subscriptionSpend;
    }

    if (billingMethod === BILLING_METHODS.card) {
      const selectedSubscriptionPlan = find(
        selectedBlueprint?.paymentPlans?.subscriptionOffers,
        {
          billingMethod
        }
      );

      spendAmount = find(selectedSubscriptionPlan?.stripeSubscriptionPlans, {
        id: spendStep?.subscription
      })?.amount;
    }
  }
  return parseFloat(spendAmount);
};

interface CalcMLPTotalArgs {
  spendStep: any;
  numLocations: number;
  locationOverridesByID: any;
  isSubscription: boolean;
  billingMethod: OfferBillingMethod;
  selectedBlueprint: any;
}

export const calcMLPTotal = ({
  spendStep,
  numLocations,
  locationOverridesByID,
  isSubscription,
  billingMethod,
  selectedBlueprint
}: CalcMLPTotalArgs) => {
  const locationOverrides = values(locationOverridesByID);
  const defaultSpendAmount = getSpendAmount({
    spendStep,
    isSubscription,
    billingMethod,
    selectedBlueprint
  });
  let runningTotal =
    defaultSpendAmount * (numLocations - locationOverrides.length);

  locationOverrides.forEach(location => {
    if (location?.spendStep) {
      const locationSpendAmount = getSpendAmount({
        spendStep: location?.spendStep,
        isSubscription,
        billingMethod,
        selectedBlueprint
      });
      if (locationSpendAmount && locationSpendAmount > 0) {
        runningTotal += locationSpendAmount;
      } else {
        runningTotal += defaultSpendAmount;
      }
    } else {
      runningTotal += defaultSpendAmount;
    }
  });
  return runningTotal;
};
