import { History } from 'history';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';
import { CreateSupervisorOrderMutation } from 'src/generated/gql/graphql';
import { formatAutomatedProgramsMutationParams } from 'src/pages/Program/utils/submitHandlers/utils';

import { submitProgramPropsInterface } from './useSubmitHandlerByType';
import { trackOrderPlacementAutomation } from './trackOrders';

// TODO: update type interface
export interface submitCreateAutomationDataInterface {
  [any: string]: any;
}

export interface createAutomationResponseInterface {
  data: CreateSupervisorOrderMutation;
}

// TODO: update type interface
export interface createAutomationSubmitDataInterface {
  data: submitCreateAutomationDataInterface;
  programProps: submitProgramPropsInterface;
  mutation: any;
  history: History;
  enqueueSnackbar: any;
  draftId?: string;
}

export const createAutomationHandleSubmit = async ({
  data,
  programProps,
  draftId,
  enqueueSnackbar,
  history,
  mutation
}: createAutomationSubmitDataInterface) => {
  const { architecture, selectedBlueprint } = programProps;

  const mutationParams = formatAutomatedProgramsMutationParams({
    data,
    architecture,
    selectedBlueprint,
    draftId,
    isEdit: false
  });

  let response: any;
  const postErrorMessage = t('automatedProgramCreate:snackbar.orderError');

  try {
    response = await mutation({
      variables: {
        input: mutationParams
      }
    });
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw {
      error,
      postErrorMessage
    };
  }

  trackOrderPlacementAutomation({
    mutationParams,
    response,
    selectedBlueprint,
    isEdit: false
  });

  return {
    response,
    afterSuccess: () => {
      enqueueSnackbar(t('automatedProgramCreate:snackbar.orderSuccess'), {
        variant: 'success',
        persist: false
      });

      history.push(
        generateLinkPath(paths.architecture.automatedProgram, {
          architectureId: architecture.id,
          programId: response?.data?.createSupervisorOrder?.id
        })
      );
    }
  };
};
