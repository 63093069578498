import { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import {
  Box,
  Button,
  Pagination,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PROGRAM_STEP_NAMES } from 'src/pages/Program/Constants';
import { paths } from 'src/routes/Constants';
import { useInterval } from 'src/hooks/useInterval';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';
import { translateMaps } from 'src/common/templateTranslator';
import { getDynamicUserInputInitialValues } from 'src/common/blueprints';
import Instrumentation from 'src/instrumentation';
import AdPreview from 'src/components/AdPreview';
import PhoneMockUp from 'src/components/PhoneMockUp';

import {
  getContentDrivenPreviews,
  getArchitectureAndCatalogByArchitectureId
} from './queries';

const useStyles = makeStyles(theme => ({
  contentAwareDashboardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    /* 
      Min height so the page doesn't shift all of its content when product name changes 
      from single to double line (max characters is 80).
    */
    minHeight: '370px',
    gap: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
      gap: theme.spacing(3)
    }
  },
  callToActionContainer: {
    maxWidth: theme.spacing(40),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center'
    }
  },
  button: {
    textTransform: 'uppercase'
  },
  callToActionHeader: {
    fontWeight: '500',
    color: theme.palette.text.primary
  },
  callToActionDescription: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  productName: {
    fontWeight: '900'
  },
  adPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  adPreviewTypographyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  adPreviewItems: {
    position: 'relative'
  },
  adPreviewItem: {
    position: 'absolute',
    transform: `scale(0.55) translateX(-50%)`,
    transformOrigin: 'top left',
    left: '50%'
  },
  hidden: {
    display: 'none'
  },
  contentTitleSkeleton: {
    fontSize: '0.875rem',
    width: '120px'
  }
}));

const DEFAULT_PAGE_NUMBER = 1;
const PAGINATION_CHANGE_INTERVAL = 5000;

const ContentAwareDashboard = () => {
  const theme = useTheme();
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [isLoadingFirstAd, setIsLoadingFirstAd] = useState(true);
  const [hasUserClickedPagination, setHasUserClickedPagination] =
    useState(false);

  const {
    data: contentDrivenPreviewsData,
    loading: contentDrivenPreviewsLoading
  } = useQuery(getContentDrivenPreviews);

  const contentDrivenPreviews =
    contentDrivenPreviewsData?.getContentDrivenPreviews;
  const contentDrivenPreviewCount = contentDrivenPreviews?.length || 0;

  const paginationChangeInterval =
    // Only auto-increment page number if first ad preview has loaded
    !isLoadingFirstAd &&
    // Pagination will not be displayed if there is only 1 item
    contentDrivenPreviewCount > 1 &&
    // If user has clicked pagination, stop auto navigating
    !hasUserClickedPagination &&
    PAGINATION_CHANGE_INTERVAL;

  const autoPaginationCallback = useCallback(() => {
    setPage(currentPage => {
      if (currentPage === contentDrivenPreviewCount) return 1;
      return currentPage + 1;
    });
  }, [contentDrivenPreviewCount, setPage]);

  useInterval(autoPaginationCallback, paginationChangeInterval, true);

  const catalogContentType =
    contentDrivenPreviewCount &&
    contentDrivenPreviews[0]?.product?.catalogContentType;

  const currentPreview = contentDrivenPreviewCount
    ? // Page is 1 indexed
      contentDrivenPreviews[page - 1]
    : {};

  const currentPreviewArchitectureId = currentPreview?.product?.architectureId;
  const currentPreviewProductId = currentPreview?.product?.id;

  const handlePaginationChange = (event, page) => {
    Instrumentation.logEvent(Instrumentation.Events.ClickQuickProgramsContent, {
      productId: currentPreviewProductId,
      architectureId: currentPreviewArchitectureId,
      contentIds: currentPreview?.contentItemIds
    });
    // Stops the useInterval from auto navigating the pagination
    setHasUserClickedPagination(true);
    setPage(page);
  };

  const handleContinueClick = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickQuickProgramsContinue,
      {
        productId: currentPreviewProductId,
        architectureId: currentPreviewArchitectureId
      }
    );
  };
  /*  
    Gets all content driven preview content ids within the currently selected preview's architecture
    Need to flatten because each contentItemIds field is an array (resulting in an array of arrays)
    Need to remove duplicate ids with lodash uniq method because some of the content driven previews may
    have the same content ids but are associated to different product previews.
    This will ensure that the getArchitectureAndCatalogByArchitectureId query doesn't return duplicate data
    for content items.
  */

  const externalIds = contentDrivenPreviews?.reduce((externalIds, preview) => {
    if (preview?.product?.architectureId === currentPreviewArchitectureId) {
      preview?.contentItemIds?.forEach(id => {
        externalIds.add(id);
      });
    }
    return externalIds;
  }, new Set());

  const {
    data: architectureAndCatalogData,
    loading: architectureAndCatalogDataLoading
  } = useQuery(getArchitectureAndCatalogByArchitectureId, {
    skip: !currentPreviewArchitectureId || !externalIds?.size,
    variables: {
      architectureId: currentPreviewArchitectureId,
      externalIds: Array.from(externalIds || [])
    }
  });

  const displayNameTemplate =
    architectureAndCatalogData?.architecture?.catalogs[0]?.displayNameTemplate;

  // Gets the data object for each content item for the selected content driven ad preview
  const businessObjects =
    architectureAndCatalogData?.architecture?.catalogs[0]?.content?.edges?.filter(
      edge => {
        const catalogContentItemId = edge?.node?.items[0]?.fields?.id;
        return currentPreview?.contentItemIds?.includes(catalogContentItemId);
      }
    );

  const contentTitle = businessObjects
    ?.map(businessObject =>
      // Get the display name of the content, e.g. for listings this is the address
      translateMaps(displayNameTemplate, businessObject?.node?.items[0]?.fields)
    )
    // Join with comma in the case that there is more than one content item for the ad preview, e.g. multi listing carousel
    ?.join(', ');

  const productName = currentPreview?.product?.name;

  const classes = useStyles();

  const isLoading =
    contentDrivenPreviewsLoading || architectureAndCatalogDataLoading;

  useEffect(
    () => {
      if (contentDrivenPreviewCount && !isLoading) {
        const allPreviewProductIds = new Set();
        const allPreviewArchitectureIds = new Set();

        contentDrivenPreviews.forEach(preview => {
          allPreviewProductIds.add(preview?.product?.id);
          allPreviewArchitectureIds.add(preview?.product?.architectureId);
        });

        Instrumentation.logEvent(Instrumentation.Events.QuickProgramsLoad, {
          productIds: Array.from(allPreviewProductIds),
          architectureIds: Array.from(allPreviewArchitectureIds)
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentDrivenPreviewCount, isLoading]
  );

  if (!contentDrivenPreviewCount || isLoading) {
    return null;
  }

  const linkToCurrentPreview = generateLinkPathWithQueryParams(
    paths.architecture.programCreate,
    {
      architectureId: currentPreviewArchitectureId
    },
    {
      productIds: currentPreview?.product?.id,
      contentIds: currentPreview?.contentItemIds,
      step: PROGRAM_STEP_NAMES.configure
    }
  );

  return (
    <Box
      className={classes.contentAwareDashboardContainer}
      data-cy="content-aware-dashboard"
    >
      {/* Call to action content and button */}
      <Box className={classes.callToActionContainer}>
        <Typography
          className={classes.callToActionHeader}
          variant="h5"
          component="h2"
        >
          <Trans i18nKey="contentAwareDashboard:header" />
        </Typography>
        <Typography className={classes.callToActionDescription} variant="body2">
          <Trans
            i18nKey="contentAwareDashboard:description"
            values={{
              content: catalogContentType || t('content:fallbacks.contentItem')
            }}
          />
        </Typography>
        <Button
          className={classes.button}
          onClick={handleContinueClick}
          to={linkToCurrentPreview}
          disabled={isEmpty(currentPreview)}
          component={Link}
          variant="contained"
          color="primary"
          size="small"
          data-cy="quick-program-continue-button"
        >
          <Trans i18nKey="contentAwareDashboard:continueButton" />
        </Button>
      </Box>
      <Box className={classes.adPreviewContainer}>
        {/* Phone mock ad preview */}
        <Link
          data-cy="quick-program-ad-preview-link"
          to={linkToCurrentPreview}
          style={{ color: theme.palette.text.primary }}
        >
          <PhoneMockUp>
            <Box className={classes.adPreviewItems}>
              {contentDrivenPreviewsData?.getContentDrivenPreviews?.map(
                (contentDrivenPreview, index) => {
                  // Joining the content item ids to account for multi-listing carousel ads which could have at least 3
                  const contentItemIds =
                    contentDrivenPreview?.contentItemIds?.join('');
                  const productId = contentDrivenPreview?.product?.id;

                  return (
                    <Box
                      className={classNames(classes.adPreviewItem, {
                        [classes.hidden]:
                          currentPreview?.contentItemIds?.join('') !==
                            contentItemIds ||
                          currentPreview?.product?.id !== productId
                      })}
                      key={contentItemIds + productId}
                      data-cy-id={`quick-program-ad-preview-${index}`}
                    >
                      <AdPreview
                        previewData={{
                          blueprint: {
                            architectureId:
                              contentDrivenPreview?.product?.architectureId,
                            blueprintId:
                              contentDrivenPreview?.product?.blueprintId
                          },
                          dynamicUserInputs:
                            getDynamicUserInputInitialValues({
                              inputSections:
                                contentDrivenPreview?.product?.inputSections
                            }) || {},
                          businessObjects: []
                        }}
                        previewProgramId={
                          contentDrivenPreview?.previewProgramId
                        }
                        loading={false}
                        error={false}
                        showIndicator={false}
                        showMessaging={false}
                        setIsLoadingAd={
                          index === 0 ? setIsLoadingFirstAd : null
                        }
                        showLoadingSkeleton
                        // Hides multi channel ad preview tabs
                        hideChannelTabs
                        // Hides the next and prev arrows in google ads
                        hideGoogleAdNavigationButtons
                        // Ensures that the ad previews don't shrink within the phone mock up frame on mobile
                        disableResponsiveStyles
                        // Makes all ad previews the same width so that they scale down to a uniform width
                        hasUniformPreviewWidth
                      />
                    </Box>
                  );
                }
              )}
            </Box>
          </PhoneMockUp>
        </Link>

        {/* Product and content name */}
        <Box className={classes.adPreviewTypographyContainer}>
          <Typography
            data-cy="quick-program-product-name"
            className={classes.productName}
            variant="body2"
          >
            {productName}
          </Typography>
          {contentTitle ? (
            <Typography data-cy="quick-program-content-title" variant="body2">
              {contentTitle}
            </Typography>
          ) : (
            <Skeleton className={classes.contentTitleSkeleton} variant="text" />
          )}
        </Box>
        {/* Only render pagination if more than one ad preview */}
        {contentDrivenPreviewCount > 1 && (
          <Pagination
            count={contentDrivenPreviews.length}
            page={page}
            onChange={handlePaginationChange}
            size="small"
            data-cy="quick-program-pagination"
          />
        )}
      </Box>
    </Box>
  );
};

export default ContentAwareDashboard;
