import { useCallback, useState, useMemo } from 'react';
import { flow, keyBy, find, filter, isEqual } from 'lodash';
import { reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Button, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';

import { FORM_NAME_CNAME, cnameInputs } from './Constants';
import { getCnames } from './queries';
import { createDomain, updateDomain } from './mutations';

const styles = theme => ({
  container: {
    width: '100%'
  },
  error: {
    marginBottom: theme.spacing(2)
  },
  errorText: {
    alignItems: 'center',
    display: 'flex',
    height: '100%'
  },
  buttonContainer: {
    display: 'flex',

    '& button:first-child': {
      marginRight: theme.spacing(2)
    }
  }
});

const pageText = () => ({
  cnameError: t('adminSkinSettings:sections.cnameFormError'),
  saveButton: t('adminSkinSettings:sections.cnameSaveButton'),
  resetButton: t('adminSkinSettings:sections.cnameResetButton'),
  successfulMessage: t('adminSkinSettings:sections.cnameAddSuccessMessage'),
  successfulDeleteMessage: t(
    'adminSkinSettings:sections.cnameAddSuccessDeleteMessage'
  )
});

const CnameSettings = props => {
  const {
    classes,
    createDomain,
    updateDomain,
    handleSubmit,
    initialActiveDomains,
    refetch,
    dirty,
    reset,
    domainsByFqdn,
    enqueueSnackbar
  } = props;
  const text = useMemo(() => pageText(), []);

  const [error, setError] = useState(false);
  const setErrorState = useCallback(
    bool => {
      setError(bool);
    },
    [error, setError]
  );

  const onSubmit = useCallback(async ({ domains }) => {
    // determine if there are any to delete
    const domainIdsToDelete = initialActiveDomains.reduce(
      (accum, initialDomain) => {
        // check initial domains to see if anything has been removed
        if (!find(domains, { id: initialDomain?.id })) {
          accum.push({ id: initialDomain?.id, isActive: false });
        }
        return accum;
      },
      []
    );

    const domainsToUpdate = domains.reduce((accum, domain) => {
      const existingDomain = domainsByFqdn?.[domain?.fqdn];
      if (existingDomain && !isEqual(domain, existingDomain)) {
        accum.push({
          id: existingDomain.id,
          authenticationUrl: domain.authenticationUrl || null,
          logoutUrl: domain.logoutUrl || null,
          isActive: true
        });
      }
      return accum;
    }, []);

    // we can update the deleted and updated domains at the same time
    await Promise.allSettled(
      [...domainsToUpdate, ...domainIdsToDelete].map(async domain => {
        try {
          await updateDomain({
            variables: {
              updateInput: {
                id: domain.id,
                authenticationUrl: domain.authenticationUrl || null,
                logoutUrl: domain.logoutUrl || null,
                isActive: domain.isActive
              }
            }
          });

          enqueueSnackbar({
            message: text.successfulMessage,
            options: {
              variant: 'success'
            }
          });

          return setErrorState(false);
        } catch (error) {
          return setErrorState(true);
        }
      })
    );

    await Promise.allSettled(
      domains.map(async domain => {
        const existingDomain = domainsByFqdn?.[domain?.fqdn];

        if (!existingDomain) {
          try {
            // create new domain
            await createDomain({
              variables: {
                createInput: {
                  fqdn: domain?.fqdn,
                  authenticationUrl: domain?.authenticationUrl,
                  logoutUrl: domain?.logoutUrl
                }
              }
            });

            enqueueSnackbar({
              message: text.successfulMessage,
              options: {
                variant: 'success'
              }
            });

            setErrorState(false);
          } catch (error) {
            setErrorState(true);
          }
        }
      })
    );

    // refetch bruh!!
    refetch();
  });

  return (
    <div className={classes.container}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {error && (
              <ErrorMessage className={classes.error}>
                <span className={classes.errorText}>{text.cnameError}</span>
              </ErrorMessage>
            )}
            <br />
            <DynamicForm inputs={cnameInputs()} />
            <br />

            <div className={classes.buttonContainer}>
              <Button
                disabled={!dirty}
                color="primary"
                type="submit"
                variant="contained"
              >
                {text.saveButton}
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => reset()}
              >
                {text.resetButton}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME_CNAME
  }),
  connect(null, { enqueueSnackbar }),
  graphql(getCnames, {
    name: 'getCnames',
    props: ({ getCnames }) => {
      const domains = getCnames?.myOrganization?.domains || [];

      return {
        domainsByFqdn: keyBy(domains, 'fqdn'),
        initialActiveDomains: filter(domains, 'isActive'),
        initialValues: {
          domains: filter(domains, 'isActive')
        },
        refetch: getCnames?.refetch
      };
    }
  }),
  graphql(createDomain, {
    name: 'createDomain'
  }),
  graphql(updateDomain, {
    name: 'updateDomain'
  }),
  withStyles(styles)
)(CnameSettings);
