import { isEmpty, isNil } from 'lodash';
import { Field } from 'redux-form';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Box, Typography, Divider, Grid, useTheme } from '@mui/material';

import { formatPriceWithCents } from 'src/common/numbers';

import usePromoCode from 'src/pages/Program/ProgramSteps/PriceSummary/usePromoCode';
import {
  PROGRAM_FORM_SECTION_SPEND_NAME,
  scheduleTypes
} from 'src/pages/Program/Constants';
import { useFeatures } from 'src/components/Feature';
import EditableField from 'src/components/EditableField';
import RenderPromoCode from 'src/components/ReduxForm/RenderPromoCode';
import { HelpTip } from 'src/components/Icons';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';
import {
  LOCATIONS_OVERRIDES_BY_ID_NAME,
  SELECTED_LOCATIONS_FIELD_NAME
} from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';
import {
  getSpendAmount,
  calcMLPTotal
} from 'src/pages/Program/ProgramSteps/PriceSummary/helpers';

import PromoCodeList from './PromoCodeList';
import { PROMO_SUMMARY_VARIANT } from './constants';

const pageText = () => {
  return {
    subtotal: t('programCreate:priceSummary.subTotal'),
    total: t('programCreate:priceSummary.total'),
    totalAutomated: t('programCreate:priceSummary.totalAutomated'),
    totalAutomatedTip: t('programCreate:priceSummary.totalAutomatedTip'),
    promoCodeFieldLabel: t('programCreate:priceSummary.promoCodeFieldLabel')
  };
};

const PriceSummary = ({
  selectedBlueprint,
  isAutomated,
  hookFormContext,
  isHookForm,
  formValues,
  change,
  formName,
  formSectionName = PROGRAM_FORM_SECTION_SPEND_NAME,
  isMLP = false
}) => {
  const theme = useTheme();
  const text = pageText();
  const features = useFeatures();

  const hookFormSpendValues = hookFormContext?.watch(formSectionName);

  const locationOverridesByID =
    hookFormContext?.watch(LOCATIONS_OVERRIDES_BY_ID_NAME) || {};
  const selectedLocations =
    hookFormContext?.watch(SELECTED_LOCATIONS_FIELD_NAME) || [];

  const spendStep = isHookForm ? hookFormSpendValues : formValues?.spendStep;
  const promoCodes = isHookForm
    ? hookFormSpendValues?.promoCodes
    : formValues?.spendStep?.promoCodes;

  const billingMethod = isHookForm
    ? hookFormSpendValues?.billingMethod
    : formValues?.spendStep?.billingMethod;

  const scheduleType = isHookForm
    ? hookFormSpendValues?.scheduleType
    : formValues?.spendStep?.scheduleType;

  const isSubscription = scheduleType === scheduleTypes.subscription.value;

  const spendAmount = getSpendAmount({
    spendStep,
    isSubscription,
    billingMethod,
    selectedBlueprint
  });

  const hasProgramPromoCodes = !!features?.promoCodes && !isAutomated;
  const hasAutomationPromoCodes =
    !!features?.automationPromoCodes && isAutomated;

  // no promo codes for MLP at this time
  const allowPromoCodes =
    !isMLP && (hasProgramPromoCodes || hasAutomationPromoCodes);

  const hasPromoCodes = allowPromoCodes && !isEmpty(promoCodes);
  const amountAfterDiscount = promoCodes?.[0]?.amountAfterDiscount; // we only allow one promo currently

  const { validatePromoCode, removePromo, promoCodeError } = usePromoCode({
    spendAmount,
    isAutomated,
    promoCodes,
    change,
    formName
  });

  const sharedPromoProps = {
    name: 'promoCodes',
    component: RenderPromoCode,
    formNamespace: formSectionName,
    extraProps: {
      selectedBlueprint,
      promoCodeError,
      spendAmount,
      validatePromoCode
    }
  };

  return (
    <Box>
      {allowPromoCodes && (
        <>
          <Box sx={{ px: 3 }}>
            <EditableField
              labelComponent={
                <Typography>{text.promoCodeFieldLabel}</Typography>
              }
              canEdit={!!promoCodes?.length}
            >
              {isHookForm ? (
                <HookFormWrapper {...sharedPromoProps} />
              ) : (
                <Field {...sharedPromoProps} {...sharedPromoProps.extraProps} />
              )}
            </EditableField>
          </Box>
          <Divider sx={{ my: 3 }} />
        </>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, pl: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {hasPromoCodes && (
            <Grid container columnSpacing={2}>
              <Grid item xs={7} sm={10}>
                <Typography variant={PROMO_SUMMARY_VARIANT}>
                  {text.subtotal}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sm={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%'
                }}
              >
                <Typography variant={PROMO_SUMMARY_VARIANT}>
                  {formatPriceWithCents(spendAmount)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {hasPromoCodes && (
            <PromoCodeList
              promoCodes={promoCodes}
              removePromo={removePromo}
              spendAmount={spendAmount}
            />
          )}
        </Box>
        <Grid container columnSpacing={2}>
          <Grid item xs sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 500,
                ...(isAutomated
                  ? {
                      alignItems: 'center',
                      display: 'flex'
                    }
                  : {})
              }}
            >
              {text.total}

              {isAutomated && (
                <span
                  style={{
                    alignItems: 'center',
                    color: theme.palette.grey[500],
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'normal'
                  }}
                >
                  <span style={{ margin: `0 ${theme.spacing()}` }}>
                    {text.totalAutomated}
                  </span>
                  <HelpTip tipText={text.totalAutomatedTip} />
                </span>
              )}
            </Typography>
          </Grid>

          <Grid item xs="auto">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                gap: 0.5
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: 'GrayText' }}
              >
                USD
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 800 }}>
                {isMLP &&
                  formatPriceWithCents(
                    calcMLPTotal({
                      spendStep,
                      numLocations: selectedLocations.length,
                      locationOverridesByID,
                      isSubscription,
                      billingMethod,
                      selectedBlueprint
                    })
                  )}
                {!isMLP &&
                  formatPriceWithCents(
                    hasPromoCodes && !isNil(amountAfterDiscount)
                      ? amountAfterDiscount
                      : spendAmount
                  )}
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            {isMLP && (
              <Typography variant="body2" sx={{ textAlign: 'right', pt: 1 }}>
                <Trans
                  i18nKey="programCreate:priceSummary.mlpPriceDisclaimer1"
                  values={{ num: selectedLocations.length }}
                />
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PriceSummary;
