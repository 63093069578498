// 🚨 Please be careful adding any imports here! 🚨
// It may work right now but this file is imported in A LOT
// of places. Meaning the risk of circular deps is extremely
// high and very annoying to fix.
// If you really need to import something here, consider
// pulling that constant out into another file like
// hiddenProps.js does.

export const EDIT_MODES = {
  BUSINESS_OBJECT: 0
} as const;

export const DEFAULT_MD_BY_DISPLAY_METHOD_ID = {
  gallery_image_url: 1,
  gallery_video_url: 1,
  radio_select: 4
} as const;

export const INPUT_TYPES = {
  ADDRESS: 'address_autocomplete',
  ANY_NUMBER: 'any_number',
  /**
   * Legacy marker for boolean inputs.
   *
   * ANY_BOOLEAN was added in order to properly render boolean
   * inputs for programs.
   * This was left so that other places like skin settings, features,
   * and the blueprint builder, wouldn't be modified.
   * In other words, "to be safer".
   */
  BOOLEAN: 'boolean',
  /**
   * Boolean marker for blueprint variable inputs.
   *
   * This was added to properly detect boolean inputs for
   * programs that use boolean inputs.
   */
  ANY_BOOLEAN: 'any_boolean',
  BUSINESS_OBJECT_SELECTOR: 'business_object_selector',
  COLOR_INPUT: 'color_input',
  CUSTOM_LINKS: 'custom_links',
  DATE_UTC: 'date_utc',
  FB_AD_ACCOUNT_ID: 'facebook_ad_account_id',
  FB_AUDIENCE_ID: 'facebook_audience_id',
  AUDIENCE_PLACEHOLDER_ID: 'audience_placeholder_id',
  FB_INSTAGRAM_ACTOR_ID: 'facebook_instagram_actor_id',
  FB_PAGE_ID: 'facebook_page_id',
  FB_REGION_CODE: 'facebook_region_code',
  GALLERY_IMAGE_URL: 'gallery_image_url',
  GALLERY_VIDEO_URL: 'gallery_video_url',
  GEO_TARGETING_ADDRESS: 'geo_targeting_address',
  IMAGE_URL: 'image_url',
  INT_SLIDER: 'integer_slider',
  JSON: 'json',
  KEY_BASED_SETTINGS: 'key_based_settings',
  LINK_URL: 'link_url',
  MEDIA_ASSET: 'media_asset',
  MULTI_LINE_STRING: 'multi_line_string',
  ORG_CONTENT_SELECTOR: 'org_content_selector',
  ORG_TAG_SELECTOR: 'org_tag_selector',
  PERCENTAGE_DECIMAL: 'percentage_decimal',
  PHONE_NUMBER: 'phone_number',
  POSITIVE_INT: 'positive_integer',
  POSITIVE_NUM: 'positive_number',
  PRICE_DECIMAL: 'price_decimal',
  PRICE_INT: 'price_integer',
  RADIO_SELECT: 'radio_select',
  SINGLE_LINE_STRING: 'single_line_string',
  SINGLE_SELECT: 'single_select',
  VIDEO_URL: 'video_url',
  ZIP: 'zip_code',
  ORGANIZATION_SELECTOR: 'organization_selector',
  WORKATO_CONNECTION_LEGACY: 'workato_connection',
  WORKATO_CONNECTION: 'workato_connection_new'
} as const;

/**
 * This is a union of all the values in INPUT_TYPES.
 * You can read this similar to:
 * ```
 * const example = {
 *   a: 'a',
 *   b: 'b',
 *   c: 'c'
 * };
 * const value = example['a'];
 * ```
 * except instead of using the literal string 'a', 'b', or 'c',
 * we'll instead say "use any keyof INPUT_TYPES."
 *
 * Then we clarify that we aren't talking about the actual JS object
 * called INPUT_TYPES but instead the TS type of the same name.
 *
 * At the end this type will be:
 * ```
 * type InputType = 'address_autocomplete' | 'any_number' | 'boolean' | ...
 * ```
 *
 * Checkout this amazing SO answer for more info: https://stackoverflow.com/a/62764510
 */
export type InputType = (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];

// These types tend to be dynamic, which causes our check below to take over so
// this enables us to override our isDynamic check.
export const CUSTOM_COMPONENT_OVERRIDES = {
  [INPUT_TYPES.FB_AUDIENCE_ID]: true,
  [INPUT_TYPES.FB_REGION_CODE]: true,
  [INPUT_TYPES.GALLERY_IMAGE_URL]: true,
  [INPUT_TYPES.GALLERY_VIDEO_URL]: true,
  [INPUT_TYPES.IMAGE_URL]: true,
  [INPUT_TYPES.VIDEO_URL]: true,
  [INPUT_TYPES.RADIO_SELECT]: true,
  [INPUT_TYPES.MEDIA_ASSET]: true
} as const;

/**
 * This represents the shape of the configuration for a dynamic input.
 * This type can be incredibly broad and is used in many places, so please
 * make sure to update the type if you find new usages!
 */
export interface DynamicInputConfig {
  name: string;
  displayName: string;
  displayMethodId: InputType;
  initialValue: any;
  reduxValidations: any[];
  isRequired: boolean;
  isHidden: boolean;
  displaySortOrder?: number;
  displayParameters: {
    inputData: {
      columnWidth: number;
      isAutocomplete?: boolean;
      options?: any[];
      tooltip?: string;
    };
  };
}

export const LOCATION_COLUMNS = [
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 310,
    label: 'Location Name',
    fieldName: 'locationName',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{name}}{{/with}}'
  },
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 320,
    label: 'Location Street',
    fieldName: 'locationStreet',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{street}}{{/with}}'
  },

  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 330,
    label: 'Location City',
    fieldName: 'locationCity',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{city}}{{/with}}'
  },
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 340,
    label: 'Location Postal Code',
    fieldName: 'locationPostalCode',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{postalCode}}{{/with}}'
  },
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 350,
    label: 'Location Region',
    fieldName: 'locationRegion',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{region}}{{/with}}'
  },
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 360,
    label: 'Location Website URL',
    fieldName: 'locationWebsiteUrl',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{websiteUrl}}{{/with}}'
  },
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 370,
    label: 'Location Phone',
    fieldName: 'locationPhone',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{phone}}{{/with}}'
  },
  {
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    displaySortOrder: 380,
    label: 'Location Email',
    fieldName: 'locationEmailAddress',
    isHidden: false,
    value: '{{#with (LOCATION locationId)}}{{emailAddress}}{{/with}}'
  }
];
