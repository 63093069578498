import { flow, get, sortBy, isUndefined, first, isEmpty, find } from 'lodash';
import { Field, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { ButtonGroup, Button, useMediaQuery } from '@mui/material';

import { withStyles, useTheme } from '@mui/styles';

import { formatPrice } from 'src/common/numbers';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from 'src/pages/Program/Constants';

const styles = theme => ({
  buttonGroupContainer: {
    flexWrap: 'wrap',
    width: '100%'
  },
  selectedOption: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,

    '&.Mui-disabled': {
      color: theme.palette.primary.contrastText
    },

    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.light
    }
  }
});

const SubscriptionSelector = ({
  classes,
  change: reduxChange,
  subscriptionOffers,
  formName,
  disabled,
  formValues,
  hookFormContext,
  isHookForm,
  formNamespace = PROGRAM_FORM_SECTION_SPEND_NAME
}) => {
  const subscriptionFieldName = `${formNamespace}.subscription`;
  const subscriptionSpendFieldName = `${formNamespace}.subscriptionSpend`;

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  const subscription = isHookForm
    ? hookFormContext?.watch(subscriptionFieldName)
    : formValues?.spendStep?.subscription;

  const billingMethod = isHookForm
    ? hookFormContext?.watch()?.spendStep?.billingMethod
    : formValues?.spendStep?.billingMethod;

  const selectedSubscriptionPlan = find(subscriptionOffers, {
    billingMethod
  });
  const stripeSubscriptionPlans =
    selectedSubscriptionPlan?.stripeSubscriptionPlans;
  const sortedSubscriptionPlans = sortBy(
    stripeSubscriptionPlans,
    o => o.amount
  );

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));

  // if nothing is selected or id is not in the current plans set the default selection to the first plan
  if (
    sortedSubscriptionPlans &&
    !isEmpty(sortedSubscriptionPlans) &&
    (isUndefined(subscription) ||
      !find(sortedSubscriptionPlans, { id: subscription }))
  ) {
    change(formName, subscriptionFieldName, first(sortedSubscriptionPlans)?.id);
    change(
      formName,
      subscriptionSpendFieldName,
      first(sortedSubscriptionPlans)?.amount
    );
  }

  const subscriptionProps = {
    name: 'subscription',
    component: 'input',
    extraProps: {
      type: 'hidden'
    }
  };
  const subscriptionSpendProps = {
    name: 'subscriptionSpend',
    component: 'input',
    extraProps: {
      type: 'hidden'
    }
  };

  return (
    <>
      {!isHookForm && (
        <Field {...subscriptionProps} {...subscriptionProps.extraProps} />
      )}
      {!isHookForm && (
        <Field
          {...subscriptionSpendProps}
          {...subscriptionSpendProps.extraProps}
        />
      )}

      <ButtonGroup
        orientation={isSmScreen ? 'vertical' : 'horizontal'}
        color="primary"
        className={classes.buttonGroupContainer}
        disabled={disabled}
      >
        {sortedSubscriptionPlans.map(item => {
          return (
            <Button
              size="large"
              key={`subscription-${get(item, 'id')}`}
              color={subscription === item?.id ? 'inherit' : 'primary'}
              className={
                subscription === item?.id ? classes.selectedOption : {}
              }
              onClick={() => {
                change(formName, subscriptionFieldName, item?.id);
                change(formName, subscriptionSpendFieldName, item?.amount);
              }}
            >
              {formatPrice(item?.amount)}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

const mapStateToProps = (state, { formName }) => {
  const formValues = getFormValues(formName)(state);

  return {
    formValues,
    subscription: formValues?.spendStep?.subscription,
    billingMethod: formValues?.spendStep?.billingMethod
  };
};

export default flow(
  connect(mapStateToProps, { change }),
  withStyles(styles)
)(SubscriptionSelector);
