import { useEffect, useState } from 'react';
import { flow, has, isNull } from 'lodash';
import { t } from 'i18next';
import { change, getFormSyncErrors } from 'redux-form';
import { Store } from 'redux';
import { connect } from 'react-redux';

import {
  Divider,
  List,
  Typography,
  Box,
  Button,
  ListItem,
  ListItemText
} from '@mui/material';

import { usePrevious } from 'src/hooks/usePrevious';

import ProgramFilterSelector from 'src/components/AutomatedProgramFilter/ProgramFilterSelector';

import { FORM_NAME } from '../constants';
import DraggableRule from './DraggableRule';
import AddNewQuickAutomationButton from './AddNewQuickAutomationButton';
import QuickAutomationDetailsModal from './QuickAutomationDetailsModal';

const pageText = () => ({
  createdRulesHeader: t('admin:manageQuickstart.headings.createdRulesHeader'),
  noRules: t('admin:manageQuickstart.emptyState.noRules'),
  cancelFilters: t('admin:manageQuickstart.buttons.cancelFilter'),
  doneFilters: t('admin:manageQuickstart.buttons.doneFilters')
});

const getPriorityFromIndex = (index: number) => {
  return (index + 1) * 1000;
};

interface QuickAutomationsBuilderProps {
  contentMeta: any;
  fields: any;
  change: any;
  quickStartId: string;
  hasFilterErrors: boolean;
  setQuickAutomationToDelete: (id: string) => void;
  contentFriendlyName: string;
  architectureData: any;
}

const QuickAutomationsBuilder = ({
  contentMeta,
  fields,
  change,
  quickStartId,
  hasFilterErrors,
  setQuickAutomationToDelete,
  contentFriendlyName,
  architectureData
}: QuickAutomationsBuilderProps) => {
  const text = pageText();

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [hasMoved, setHasMoved] = useState(false);
  const [editingIndex, setEditingIndex] = useState<null | number>(null);
  const [initialFilters, setInitialFilters] = useState({});

  const handleSetEditingIndex = (index: number | null) => {
    const indexIntialFilters = fields.get(index)?.catalogFilter;

    // store initial filters so we can reset on cancel
    setInitialFilters(indexIntialFilters);
    // set selected index to edit
    setEditingIndex(index);
  };

  const onCancel = () => {
    if (!isNull(editingIndex)) {
      // reset the filters
      change(
        FORM_NAME,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${fields.name}[${editingIndex}].catalogFilter`,
        initialFilters
      );
      // clear selected index
      setEditingIndex(null);
    }
  };

  const onDone = () => {
    // clear selected index
    setEditingIndex(null);
  };

  const prevFields = usePrevious(fields.getAll());
  useEffect(() => {
    if (prevFields === fields.getAll() && hasMoved) {
      fields.forEach((field: any, index: number) => {
        const priority = fields.get(index)?.priority;
        if (priority !== getPriorityFromIndex(index)) {
          // update the priority value
          change(
            FORM_NAME,
            `${fields.name}[${index}].priority`,
            getPriorityFromIndex(index)
          );
        }
      });
      setHasMoved(false);
    }
  }, [fields, hasMoved, prevFields, change]);

  const deleteRule = (index: number) => {
    const ruleToDelete = fields.get(index);

    // remove rule from array
    fields.remove(index);
    // clear selected rule
    setEditingIndex(null);

    if (!ruleToDelete?.newRule) {
      // set id to delete on save
      setQuickAutomationToDelete(fields.get(index)?.id);
    }
  };

  const handleDetailsModalOpen = () => {
    setDetailsModalOpen(true);
  };

  const handleDetailsModalClose = () => {
    setDetailsModalOpen(false);
  };

  const hasRules = fields.length > 0;

  return (
    <div>
      {editingIndex !== null ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <ProgramFilterSelector
            contentMeta={contentMeta}
            formName={FORM_NAME}
            fieldName={`${fields.name}.[${editingIndex}].catalogFilter`}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              maxWidth: '750px',
              mt: 2
            }}
          >
            <Button onClick={onCancel} variant="text" color="primary">
              {text.cancelFilters}
            </Button>
            <Button
              disabled={hasFilterErrors}
              variant="contained"
              color="primary"
              onClick={onDone}
              sx={{ ml: 1 }}
            >
              {text.doneFilters}
            </Button>
          </Box>
        </Box>
      ) : (
        <AddNewQuickAutomationButton setModalOpen={handleDetailsModalOpen} />
      )}

      <Typography
        sx={{
          marginTop: theme => theme.spacing(4),
          marginBottom: theme => theme.spacing(2),
          fontWeight: 'bold'
        }}
      >
        {text.createdRulesHeader}
      </Typography>
      <Divider />
      <List sx={{ width: '100%', padding: '0' }}>
        {hasRules &&
          fields.map((field: any, index: number) => {
            const fieldData = fields.get(index);
            return (
              <DraggableRule
                field={field}
                fieldData={fieldData}
                fields={fields}
                index={index}
                key={field}
                setEditingIndex={handleSetEditingIndex}
                setHasMoved={setHasMoved}
                deleteRule={deleteRule}
                setDetailModalOpen={handleDetailsModalOpen}
                contentMeta={contentMeta}
                contentFriendlyName={contentFriendlyName}
                architectureData={architectureData}
              />
            );
          })}
        {!hasRules && (
          <ListItem divider>
            <ListItemText sx={{ color: theme => theme.palette.grey[600] }}>
              {text.noRules}
            </ListItemText>
          </ListItem>
        )}
      </List>

      <QuickAutomationDetailsModal
        modalOpen={detailsModalOpen}
        setModalClose={handleDetailsModalClose}
        fields={fields}
        quickStartId={quickStartId}
        setEditingIndex={handleSetEditingIndex}
        editingIndex={editingIndex}
        setHasMoved={setHasMoved}
      />
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const formErrors = getFormSyncErrors(FORM_NAME)(state);

  // check from errors in the filter builder part of the form so we can disable "done" button when there are errors
  const hasFilterErrors = has(formErrors, 'automationQuickStarts');

  return { hasFilterErrors };
};

export default flow(connect(mapStateToProps, { change }))(
  QuickAutomationsBuilder
);
