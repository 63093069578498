import { flow, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { isApolloLoading } from 'src/common/apollo';

import { Typography, Grid, Paper, Button, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { QuickAutomation as AutomationIcon } from 'src/components/Icons';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/PageTitle';
import { paths } from 'src/routes/Constants';
import { generateLinkPath } from 'src/routes/RouteUtil';

import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';
import { getAutomation, getArchitectureById } from './queries';

import StopAutomationButton from './StopAutomationButton';
import AutomatedDetails from './AutomatedDetails';

import ProgramsTable from '../Programs/ProgramsTable';

const styles = theme => ({
  programNotFound: {
    margin: '0 auto'
  },
  section: {
    marginBottom: theme.spacing(3)
  },
  orderStatusContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  editButton: {
    display: 'inline-block'
  }
});

const AutomatedProgramDetails = props => {
  const {
    architecture,
    classes,
    loading,
    error,
    automatedProgram,
    refetch,
    t
  } = props;

  const history = useHistory();

  const handleEditNavigation = () => {
    history.push(
      generateLinkPath(paths.architecture.automatedProgramEdit, {
        architectureId: architecture?.id,
        programId: automatedProgram?.id
      })
    );
  };

  if (loading || error) {
    const errorMessage = error ? t('automatedDetails:error.apiError') : null;

    return <Loading error={errorMessage} errorMessage={errorMessage} />;
  }

  const automatedProgramId = get(props, 'match.params.programId');

  const contentFriendlyName = get(props, 'architecture.catalog.friendlyName');

  // Handle the case for when we don't find a program.
  if (!automatedProgram) {
    return (
      <ErrorMessage className={classes.programNotFound}>
        <Trans
          i18nKey="automatedDetails:messages.notFound"
          values={{ automatedProgramId }}
        />
      </ErrorMessage>
    );
  }

  let subPageTitle = t('automatedDetails:page.title');

  const programName = automatedProgram?.name;

  if (programName) {
    subPageTitle += `: ${programName}`;
  }

  const breadcrumbPieces = [
    {
      text: t('automatedDetails:breadcrumbs.automation'),
      to: paths.automations.base
    },
    {
      text: t('automatedDetails:breadcrumbs.automationDetails')
    }
  ];

  return (
    <>
      <div className={classes.section}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <PageTitle subPageTitle={subPageTitle} />
            <BreadcrumbTrail
              sx={theme => ({ marginBottom: theme.spacing(1) })}
              pieces={breadcrumbPieces}
            />
            {programName && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutomationIcon />
                <Typography
                  variant="h4"
                  sx={{ margin: '0 5px', fontWeight: 'bold' }}
                >
                  {programName}
                </Typography>
              </Box>
            )}
          </Grid>
          {!automatedProgram?.deactivatedAt && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleEditNavigation}
                className={classes.editButton}
                disabled={automatedProgram?.deactivatedAt}
                data-cy="ap-edit-button"
              >
                <Trans i18nKey="automatedDetails:edit.button">
                  Edit Automation
                </Trans>
              </Button>

              <StopAutomationButton
                id={automatedProgramId}
                refetch={refetch}
                isActive={!automatedProgram?.deactivatedAt}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <Paper className={classes.orderStatusContainer}>
        <AutomatedDetails
          automatedProgram={automatedProgram}
          contentFriendlyName={contentFriendlyName}
          refetch={refetch}
        />
      </Paper>
      <div className={classes.section}>
        <Typography variant="h5">
          <Trans i18nKey="automatedDetails:headers.programsTitle">
            Generated Programs
          </Trans>
        </Typography>
        <br />
        {automatedProgram?.orders.length ? (
          <ProgramsTable
            supervisorOrderIds={[automatedProgram?.id]}
            showDrafts={false}
          />
        ) : (
          <Typography variant="body1">
            <Trans i18nKey="automatedDetails:table.noPrograms">
              There are no generated programs for this automation
            </Trans>
          </Typography>
        )}
      </div>
    </>
  );
};

export default flow(
  withStyles(styles),
  graphql(getAutomation, {
    name: 'getAutomation',
    options: props => {
      const { match } = props;
      return {
        variables: {
          // architectureId: get(match, 'params.architectureId'),
          supervisorOrderId: get(match, 'params.programId')
        },
        fetchPolicy: 'no-cache'
      };
    },
    props: ({
      getAutomation: { error, refetch, networkStatus, automation }
    }) => {
      const loading = isApolloLoading(networkStatus);
      return {
        loading,
        error,
        refetch,
        automatedProgram: automation
      };
    }
  }),
  graphql(getArchitectureById, {
    name: 'getArchitectureById',
    props: ({ getArchitectureById: { error, loading, architecture } }) => {
      return {
        loading,
        error,
        architecture
      };
    },
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: {
        architectureId: get(props, 'match.params.architectureId')
      }
    })
  }),
  withTranslation(['automatedDetails'])
)(AutomatedProgramDetails);
