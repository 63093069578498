import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  flow,
  keyBy,
  xorWith,
  isEqual,
  isString,
  isNumber,
  isNaN,
  isUndefined,
  some,
  find
} from 'lodash';
import { Field, FieldArray, getFormValues } from 'redux-form';
import { t } from 'i18next';
import numeral from 'numeral';

import { withGlobalContext } from 'src/GlobalContextProvider';

import {
  contentColumnsFromMetadata,
  contentColumnsFromDynamicCopy
} from 'src/common/dynamicUserInputs';
import { usePrevious } from 'src/hooks/usePrevious';

import {
  GALLERY_TYPE,
  MEDIA_TYPE_FILTER_VALUES
} from 'src/pages/Gallery/constants';

import { useFeatures } from 'src/components/Feature';
import { HIDDEN_PROPS } from 'src/components/ReduxForm/DynamicForm/hiddenProps';
import { AI_ANALYSIS_FIELD_NAME } from 'src/components/AiSuggestion/utils';
import useGenAiAdCopyWritingV3 from 'src/experiments/useGenAiAdCopyWritingV3';

import { PreviewUrlButton } from 'src/components/ReduxForm/RenderLinkUrl/PreviewUrlButton';
import { useAsyncUrlValidation } from 'src/components/ReduxForm/RenderLinkUrl/helpers';
import { isTemplate, translateMaps } from 'src/common/templateTranslator';
import { useArchitectureAnywhere } from 'src/pages/Architecture/ArchitectureProvider';
import RenderWorkatoConnection from 'src/components/ReduxForm/RenderWorkatoConnection/RenderWorkatoConnection';
import RenderWorkatoConnectionLegacy from 'src/components/ReduxForm/RenderWorkatoConnection/RenderWorkatoConnectionLegacy';

import {
  getColumnValidation,
  getFieldName,
  formatSelectOptions,
  generateValidations as generateReduxFormValidations,
  checkIfDynamic,
  checkIfArray,
  generateHookFormValidations
} from './helpers';
import RenderAudienceSelectorField from '../RenderAudienceSelectorField';
import { RenderAutocomplete } from '../RenderAutocomplete';
import RenderFacebookRegionSelector from '../RenderFacebookRegionSelector';
import RenderTemplateStringTextField from '../RenderTemplateStringTextField';
import RenderGallerySelector from '../RenderGallerySelector';
import RenderTextField from '../RenderTextField';
import RenderSwitch from '../RenderSwitch';
import RenderSlider from '../RenderSlider';
import RenderSelect from '../RenderSelect';
import RenderBusinessObjectAssetSelector from '../RenderBusinessObjectAssetSelector';
import RenderDynamicRadioGroup from '../RenderDynamicRadioGroup';
import RenderColorInput from '../RenderColorInput';
import RenderCustomLinks from '../RenderCustomLinks';
import RenderKeyBasedSettings from '../RenderKeyBasedSettings';
import RenderCalendarPicker from '../RenderCalendarPicker';
import RenderTemplateField from '../RenderTemplateField';
import RenderBusinessObjectSelector from '../RenderBusinessObjectSelector';
import { RenderLinkUrl } from '../RenderLinkUrl';
import RenderOrgContentSelector from '../RenderOrgContentSelector/RenderOrgContentSelector';
import RenderAddressAutocomplete from '../RenderAddressAutocomplete';
import RenderOrganizationSelector from '../RenderOrganizationSelector';
import HookFormWrapper from './HookFormWrapper';

import {
  CUSTOM_COMPONENT_OVERRIDES,
  EDIT_MODES,
  INPUT_TYPES,
  LOCATION_COLUMNS
} from './constants';

const FormInput = props => {
  const {
    blueprint,
    metadata,
    contentColumns = [],
    disabled = false,
    editType,
    // Business Object Metadata
    businessObjects = [],
    formName,
    isContentSelectable,
    contentName,
    globalContext,
    aiTextAnalysisValues,
    dispatch,
    channelInputValidators,
    formSectionName,
    isHookForm,
    // Location Metadata
    selectedLocationsMetadata = [],
    isMultiLocation,
    labelBackground,
    // Input is inside the AdCopySuggestionMessage in the AiChat component
    isAdCopySuggestionMessageInput,
    aiAdCopyWriterInputs,
    aiChat,
    onChange,
    value
  } = props;

  const { value: isGenAiAdCopyWritingV3Enabled, experimentsLoaded } =
    useGenAiAdCopyWritingV3();
  const isAiChatAdCopyWriterInput = !!find(aiAdCopyWriterInputs, {
    id: props?.metadata?.id
  });

  // NOTE: FormInput is used outside of architecture contexts, meaning that
  // it's possible a lot of this object does not exist.
  const architecture = useArchitectureAnywhere();
  const contentNameTemplate = architecture?.catalog?.displayNameTemplate ?? '';

  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const fieldMetadata = globalContext?.me?.metadata?.fieldMetadata;
  const dynamicCopyColumns =
    contentColumnsFromDynamicCopy(blueprint?.dynamicFieldMetadata) || [];
  const inputType = metadata?.displayMethodId;

  const isDynamic = checkIfDynamic(metadata);
  const isArray = checkIfArray(metadata);
  const stringMaxLength = metadata?.blueprintVariable?.stringMaxLength;
  const userContentColumns = contentColumnsFromMetadata(fieldMetadata) || [];
  const { allowAiGeneratedContent: allowAiFeatureFlag } = useFeatures();
  const validationTypes =
    metadata?.displayParameters?.inputData?.extraValidationRules || [];

  const name = getFieldName(metadata);

  // Filter this object out because it's only prupose
  // is to pass a prop to the RenderLinkUrl component if it exists
  const channelValidations =
    channelInputValidators?.[name]?.reduce((accum, { name, validator }) => {
      if (name !== 'validateNoCrossDomainRedirectsUrl') {
        return [...accum, validator];
      }
      return accum;
    }, []) || [];

  // a little tricky with the names, but we need agreement on both the
  // feature setting and input setting for whether to allow AI content
  const allowAiGeneration =
    allowAiFeatureFlag &&
    metadata?.displayParameters?.inputData?.allowAiGeneration &&
    experimentsLoaded &&
    !isGenAiAdCopyWritingV3Enabled;

  const showAiChatInputIconButton =
    isAiChatAdCopyWriterInput &&
    experimentsLoaded &&
    isGenAiAdCopyWritingV3Enabled &&
    !isAdCopySuggestionMessageInput;

  const previousChannelValidations = useRef([]);

  const validationsConfig = {
    metadata,
    blueprint,
    businessObjects,
    userContentColumns,
    userMetadataFields,
    contentColumns,
    channelValidations,
    selectedLocationsMetadata
  };

  const generateValidations = validationsConfig => {
    if (isHookForm) {
      return generateHookFormValidations(validationsConfig);
    }

    return generateReduxFormValidations(validationsConfig);
  };

  const [validations, setValidations] = useState(() => ({
    validations: generateValidations(validationsConfig),
    businessObjects
  }));

  const prevMetaData = usePrevious(metadata);

  useEffect(() => {
    if (!isEqual(prevMetaData?.isRequired, metadata?.isRequired)) {
      // if we do this on only metadata changes, we run into infinite render loops so for now I am only doing it on isRequired changes
      setValidations({
        validations: generateValidations(validationsConfig),
        businessObjects
      });
    }
  }, [metadata]); // we only want to update this on metadata changes

  if (xorWith(validations.businessObjects, businessObjects, isEqual).length) {
    validations.validations = generateValidations(validationsConfig);
    validations.businessObjects = businessObjects;
    setValidations(validations);
  }

  // If channelValidations changee, regenerate the input validations
  // This happens when the ad preview returns the input names that need channel validations
  if (!isEqual(previousChannelValidations.current, channelValidations)) {
    previousChannelValidations.current = channelValidations;
    validations.validations = generateValidations(validationsConfig);
    validations.businessObjects = businessObjects;
    setValidations(validations);
  }

  // use this to return custom error messages from input renderer
  // see RenderAddressAutocomplete for example
  const [dynamicValidation, setDynamicValidation] = useState(
    // this just returns no error by default
    // if it isn't wrapped in an object it blows up
    { validation: () => {} }
  );

  const { validateUrl, validateMultipleUrls } = useAsyncUrlValidation(
    dispatch,
    formName,
    name
  );

  let FieldComponent = Field;
  let inputComponent = RenderTextField;
  let extraProps = {};

  if (
    isDynamic &&
    !CUSTOM_COMPONENT_OVERRIDES[inputType] &&
    (isContentSelectable || !!userMetadataFields)
  ) {
    inputComponent = RenderTemplateStringTextField;

    // This function ensures that only columns with content will be renderd as chips
    // I made this dynamic because we will be adding more verticals in the future which could require additional column types (content, userMetadata, etc)
    const getDynamicColumnValues = columnTypes => {
      let validColumns = [...dynamicCopyColumns?.map(c => c.fieldName)];
      let missingColumns = [];
      let dynamicValues = [...dynamicCopyColumns];

      columnTypes.forEach(columnType => {
        if (columnType.shouldRenderChips) {
          const validations = getColumnValidation({
            columns: columnType.columns,
            columnData: columnType.content
          });

          validColumns = [...validColumns, ...validations?.validColumns];
          missingColumns = [...missingColumns, ...validations?.missingColumns];
          dynamicValues = [...dynamicValues, ...columnType.columns];
        }
      });

      // Used reduce to filter out hidden and create a new set in a single iteration
      const visibleUniqueDynamicValues = dynamicValues?.reduce(
        (valueSet, currentValue) => {
          if (!currentValue.isHidden) {
            valueSet.add(currentValue);
          }

          return valueSet;
        },
        new Set()
      );

      return {
        // These need to remain as sets because specific set methods are used on them once passed to their `render input component`
        validColumns: new Set(validColumns),
        missingColumns: new Set(missingColumns),
        // Must be converted back into an array to render the chips
        dynamicValues: [...visibleUniqueDynamicValues]
      };
    };

    const columnTypes = [
      {
        shouldRenderChips: isContentSelectable,
        columns: contentColumns,
        content: businessObjects
      },
      {
        shouldRenderChips: userMetadataFields,
        columns: userContentColumns,
        content: [userMetadataFields]
      },
      {
        shouldRenderChips: isMultiLocation,
        columns: LOCATION_COLUMNS,
        content: selectedLocationsMetadata
      }
    ];

    const { dynamicValues, missingColumns, validColumns } =
      getDynamicColumnValues(columnTypes);

    const dynamicValuesMap = keyBy(dynamicValues, 'fieldName');

    extraProps = {
      multiline: inputType === INPUT_TYPES.MULTI_LINE_STRING,
      rows: metadata?.displayParameters?.inputData?.rows,
      blueprintVariableId: metadata?.blueprintVariableId,
      allowAiGeneration,
      dynamicValues,
      dynamicValuesMap,
      validColumns,
      missingColumns,
      businessObjects,
      formName,
      stringMaxLength,
      productId: blueprint?.id,
      validationTypes,
      displayName: metadata?.displayName,
      labelBackground,
      isAdCopySuggestionMessageInput,
      showAiChatInputIconButton,
      aiChat
    };

    if (inputType === INPUT_TYPES.LINK_URL) {
      extraProps.additionalFooterContent = additionalProps => (
        <PreviewUrlButton
          value={translateMaps(additionalProps.input.value, {
            // We only format the preview URL with the first item in the list.
            // It was deemed not important at the time to provide >1 preview URL
            // buttons. Sorry if that isn't the case anymore!
            ...(businessObjects[0] ?? []),
            ...userMetadataFields
          })}
          previewUrl={
            metadata?.displayParameters?.inputData?.previewUrl ?? true
          }
          disabled={disabled}
          readOnly={metadata?.readOnly}
          error={additionalProps.meta.error}
        />
      );

      extraProps.extraOnChangeNotification = updatedUrl => {
        const trimmedUrl = updatedUrl.trim();

        if (!isTemplate(trimmedUrl)) {
          // The provided URL isn't a template, no need to translate anything
          validateUrl.current(trimmedUrl);
        } else if (businessObjects.length > 1) {
          // We have multiple BOs. Create a map of their name -> formatted value
          const translatedValuesByLabel = {};
          businessObjects.forEach(bo => {
            const context = { ...bo, ...userMetadataFields };
            const key = translateMaps(contentNameTemplate, context);
            translatedValuesByLabel[key] = translateMaps(trimmedUrl, context);
          });
          validateMultipleUrls.current(translatedValuesByLabel);
        } else {
          // 0 or 1 business objects selected
          const translatedValue = translateMaps(trimmedUrl, {
            ...(businessObjects[0] ?? []),
            ...userMetadataFields
          });
          validateUrl.current(translatedValue);
        }
      };
    }
  } else {
    switch (inputType) {
      case INPUT_TYPES.SINGLE_LINE_STRING:
        inputComponent = RenderTextField;
        extraProps = {
          stringMaxLength,
          startAdornment:
            metadata?.displayParameters?.inputData?.startAdornment,
          endAdornment: metadata?.displayParameters?.inputData?.endAdornment,
          allowAiGeneration,
          blueprintVariableId: metadata?.blueprintVariableId,
          productId: blueprint?.id,
          validationTypes,
          displayName: metadata?.displayName,
          isAdCopySuggestionMessageInput,
          showAiChatInputIconButton,
          aiChat
        };
        break;
      case INPUT_TYPES.MULTI_LINE_STRING:
        inputComponent = RenderTextField;
        extraProps = {
          stringMaxLength,
          multiline: true,
          rows: metadata?.displayParameters?.inputData?.rows || 2,
          startAdornment:
            metadata?.displayParameters?.inputData?.startAdornment,
          endAdornment: metadata?.displayParameters?.inputData?.endAdornment,
          allowAiGeneration,
          blueprintVariableId: metadata?.blueprintVariableId,
          productId: blueprint?.id,
          validationTypes,
          displayName: metadata?.displayName,
          isAdCopySuggestionMessageInput,
          showAiChatInputIconButton,
          aiChat
        };
        break;
      case INPUT_TYPES.JSON:
        inputComponent = RenderTextField;
        extraProps = {
          format: value => {
            if (isString(value)) {
              return value;
            }
            try {
              return JSON.stringify(value);
            } catch (error) {
              return value;
            }
          },
          normalize: value => {
            try {
              return JSON.parse(value);
            } catch (error) {
              return value;
            }
          }
        };
        break;
      // Catch all forms of booleans, both the old and new boolean marker
      // along with an existing check for boolean_, just in case.
      case 'boolean_': // DB issue apparently
      case INPUT_TYPES.BOOLEAN:
      case INPUT_TYPES.ANY_BOOLEAN:
        inputComponent = RenderSwitch;
        extraProps = {
          confirmationModal:
            metadata?.displayParameters?.inputData?.confirmationModal
        };
        break;
      case INPUT_TYPES.FB_AUDIENCE_ID:
      case INPUT_TYPES.AUDIENCE_PLACEHOLDER_ID:
        inputComponent = RenderAudienceSelectorField;
        extraProps = {
          multiple: !!isArray,
          isLegacy: inputType === INPUT_TYPES.FB_AUDIENCE_ID,
          channelCodes: blueprint?.blueprint?.channels // filters audiences by channel
        };
        break;
      case INPUT_TYPES.FB_AD_ACCOUNT_ID:
        // hidden
        inputComponent = RenderTextField;
        extraProps = HIDDEN_PROPS;
        break;
      case INPUT_TYPES.FB_INSTAGRAM_ACTOR_ID:
        // hidden
        inputComponent = RenderTextField;
        extraProps = HIDDEN_PROPS;
        break;
      case INPUT_TYPES.FB_PAGE_ID:
        // hidden
        inputComponent = RenderTextField;
        extraProps = HIDDEN_PROPS;
        break;
      case INPUT_TYPES.FB_REGION_CODE:
        inputComponent = RenderFacebookRegionSelector;
        extraProps = {
          multiple: !!isArray,
          format: value => {
            if (isArray) {
              return Array.isArray(value) ? value : [];
            }
            return value;
          }
        };
        break;
      case INPUT_TYPES.GEO_TARGETING_ADDRESS:
      case INPUT_TYPES.ZIP:
      case INPUT_TYPES.ADDRESS:
        inputComponent = RenderAddressAutocomplete;
        const isMultiSelect =
          metadata?.displayParameters?.inputData?.isMultiSelect || false;
        const addressFields = metadata?.displayParameters?.inputData?.children;
        // address specificity can be changed by passing a types item in displayParameters
        // one of: https://developers.google.com/places/supported_types#table3
        let addressTypes = metadata?.displayParameters?.inputData?.types;
        const addressComponentRestrictions =
          metadata?.displayParameters?.inputData?.addressComponentRestrictions;

        // To see a full list of supported address types: https://developers.google.com/maps/documentation/places/web-service/supported_types
        if (inputType === INPUT_TYPES.ZIP) {
          addressTypes = '(regions)';
        }

        if (inputType === INPUT_TYPES.GEO_TARGETING_ADDRESS) {
          addressTypes = [
            'locality',
            'sublocality',
            'postal_code',
            'administrative_area_level_2'
          ];
        }
        extraProps = {
          addressFields,
          formName,
          setDynamicValidation,
          inputType,
          isMultiSelect,
          ...(addressTypes && { addressTypes }),
          ...(addressComponentRestrictions && { addressComponentRestrictions }),
          format: value => {
            if (isMultiSelect) {
              return Array.isArray(value) ? value : [];
            }
            return value;
          }
        };
        break;
      case INPUT_TYPES.PRICE_DECIMAL:
        inputComponent = RenderTemplateField;
        extraProps = {
          inputProps: {
            thousandSeparator: true,
            decimalScale: 2,
            fixedDecimalScale: true,
            startAdornment: '$'
          },
          decimal: true,
          // redux form props
          normalize: value => numeral(value).value()
        };
        break;
      case INPUT_TYPES.PRICE_INT:
        inputComponent = RenderTemplateField;
        extraProps = {
          inputProps: {
            thousandSeparator: true,
            startAdornment: '$'
          },
          // redux form props
          normalize: value => numeral(value).value()
        };
        break;
      case INPUT_TYPES.ANY_NUMBER:
        break;
      case INPUT_TYPES.POSITIVE_INT:
        break;
      case INPUT_TYPES.POSITIVE_NUM:
        break;
      case INPUT_TYPES.INT_SLIDER:
        inputComponent = RenderSlider;

        extraProps = {
          showInput:
            metadata?.displayParameters?.inputData?.showIntegerSliderInput ||
            true,
          type: 'number',
          min: metadata?.blueprintVariable?.integerMinValue || 0,
          max: metadata?.blueprintVariable?.integerMaxValue || 100,
          endAdornment: metadata?.displayParameters?.inputData?.endAdornment,
          showSlider: metadata?.displayParameters?.inputData?.showIntegerSlider
        };
        break;
      case INPUT_TYPES.PERCENTAGE_DECIMAL:
        extraProps = {
          format: value => {
            // weird format bug when delteing last number
            if (isUndefined(value)) {
              return '';
            }
            if (!value) {
              return value;
            }
            if (isNumber(value)) {
              return Math.round(value * 100);
            }
            return value;
          },
          normalize: value => {
            if (!value) {
              return value;
            }
            const num = parseInt(value, 10);
            if (!isNaN(num) && isNumber(num)) {
              return num / 100;
            }
            return value;
          },
          endAdornment: '%' // oh cute ʕ•ᴥ•ʔ
        };
        break;
      case INPUT_TYPES.DATE_UTC:
        inputComponent = RenderCalendarPicker;
        extraProps = {
          ...metadata?.displayParameters?.inputData?.datePickerProps
        };
        break;
      case INPUT_TYPES.GALLERY_IMAGE_URL:
        inputComponent = RenderGallerySelector;

        extraProps = {
          galleryType: GALLERY_TYPE.image,
          mediaType: MEDIA_TYPE_FILTER_VALUES.image,
          internalOnly: metadata?.displayParameters?.inputData?.internalOnly,
          internalOnlyAllowSvg:
            metadata?.displayParameters?.inputData?.internalOnlyAllowSvg,
          sizeConstraint: isUndefined(
            metadata?.displayParameters?.inputData?.sizeConstraint
          )
            ? true
            : metadata?.displayParameters?.inputData?.sizeConstraint
        };

        break;
      case INPUT_TYPES.GALLERY_VIDEO_URL:
        inputComponent = RenderGallerySelector;

        extraProps = {
          galleryType: GALLERY_TYPE.video,
          mediaType: MEDIA_TYPE_FILTER_VALUES.video,
          internalOnly: metadata?.displayParameters?.inputData?.internalOnly,
          internalOnlyAllowSvg:
            metadata?.displayParameters?.inputData?.internalOnlyAllowSvg,
          sizeConstraint: isUndefined(
            metadata?.displayParameters?.inputData?.sizeConstraint
          )
            ? true
            : metadata?.displayParameters?.inputData?.sizeConstraint
        };
        break;
      case INPUT_TYPES.MEDIA_ASSET:
        inputComponent = RenderBusinessObjectAssetSelector;

        const { missingColumns } = getColumnValidation({
          columns: contentColumns,
          columnData: businessObjects
        });

        extraProps = {
          galleryType: GALLERY_TYPE.media,
          mediaType:
            metadata?.displayParameters?.inputData?.mediaType ||
            MEDIA_TYPE_FILTER_VALUES.all,
          showFilter:
            metadata?.displayParameters?.inputData?.mediaType ===
            MEDIA_TYPE_FILTER_VALUES.all,
          internalOnly: metadata?.displayParameters?.inputData?.internalOnly,
          internalOnlyAllowSvg:
            metadata?.displayParameters?.inputData?.internalOnlyAllowSvg,
          sizeConstraint: isUndefined(
            metadata?.displayParameters?.inputData?.sizeConstraint
          )
            ? true
            : metadata?.displayParameters?.inputData?.sizeConstraint,
          displayMethodId: inputType,
          dynamicValues: contentColumns,
          missingColumns,
          allowGallery:
            metadata?.displayParameters?.inputData?.allowGallery || false,
          allowContentSelection:
            metadata?.displayParameters?.inputData?.allowContentSelection ||
            false,
          formName,
          contentName
        };
        break;
      case INPUT_TYPES.IMAGE_URL:
      // falls through to the video
      case INPUT_TYPES.VIDEO_URL:
        // For now, override the input to be a text field when this
        // is used to edit a business object. Otherwise we would render
        // a drop down column selector, which breaks the Business Object
        // edit/create use-case.
        if (editType === EDIT_MODES.BUSINESS_OBJECT) {
          inputComponent = RenderTextField;
        } else {
          inputComponent = RenderBusinessObjectAssetSelector;
          const { missingColumns } = getColumnValidation({
            columns: contentColumns,
            columnData: businessObjects
          });
          extraProps = {
            displayMethodId: inputType,
            dynamicValues: contentColumns,
            missingColumns,
            galleryType:
              INPUT_TYPES.VIDEO_URL === inputType
                ? GALLERY_TYPE.video
                : GALLERY_TYPE.image,
            mediaType:
              INPUT_TYPES.VIDEO_URL === inputType
                ? MEDIA_TYPE_FILTER_VALUES.video
                : MEDIA_TYPE_FILTER_VALUES.image,
            allowGallery:
              metadata?.displayParameters?.inputData?.allowGallery || false,
            formName,
            contentName,
            allowContentSelection: true,
            blockEmojiPicker: true
          };
        }
        break;
      case INPUT_TYPES.LINK_URL:
        inputComponent = RenderLinkUrl;
        extraProps = {
          previewUrl:
            metadata?.displayParameters?.inputData?.previewUrl ?? true,
          // If validations contain the string 'validateNoCrossDomainRedirectsUrl'
          // pass noCrossDomainRedirects to RenderLinkUrl component set to true
          noCrossDomainRedirects: some(
            channelInputValidators?.[name],
            validatorObject =>
              validatorObject.name === 'validateNoCrossDomainRedirectsUrl'
          )
        };
        break;
      case INPUT_TYPES.PHONE_NUMBER:
        inputComponent = RenderTemplateField;
        extraProps = {
          inputProps: {
            format: '+1 (###) ### ####',
            mask: '_'
          }
        };

        break;
      case INPUT_TYPES.SINGLE_SELECT:
        const isAutocomplete =
          metadata?.displayParameters?.inputData?.isAutocomplete;

        inputComponent = isAutocomplete ? RenderAutocomplete : RenderSelect;

        const selectOptions =
          metadata?.displayParameters?.inputData?.options ||
          metadata?.displayParameters?.options || // CONFIRM & REMOVE ME keeping this to be safe
          [];

        extraProps = {
          sx: metadata?.sx,
          options: formatSelectOptions(selectOptions),
          isMultiSelect:
            metadata?.displayParameters?.inputData?.isMultiSelect || false,
          showOptionCheckbox:
            metadata?.displayParameters?.inputData?.showOptionCheckbox || false, // show checkbox next to each dropdown option
          confirmationModal:
            metadata?.displayParameters?.inputData?.confirmationModal
        };
        break;
      case INPUT_TYPES.RADIO_SELECT:
        inputComponent = RenderDynamicRadioGroup;

        const radioOptions =
          metadata?.displayParameters?.inputData?.options ||
          metadata?.displayParameters?.options || // CONFIRM & REMOVE ME keeping this to be safe
          [];

        extraProps = {
          displayAsRow: metadata?.displayAsRow,
          displayName: metadata?.displayName, // radio group label
          userMetadataFields,
          options: radioOptions.map(option => {
            return {
              // should we render the value with the label
              renderValue: metadata?.displayParameters?.inputData?.renderValues,
              label: option?.name, // label displayed to the user
              value: option?.value // what we will send back
            };
          })
        };
        break;
      case INPUT_TYPES.COLOR_INPUT:
        inputComponent = RenderColorInput;
        break;
      case INPUT_TYPES.CUSTOM_LINKS:
        FieldComponent = FieldArray;
        inputComponent = RenderCustomLinks;

        extraProps = {
          actionButtonText:
            metadata?.displayParameters?.inputData?.actionButtonText,
          previewUrl: metadata?.displayParameters?.inputData?.previewUrl
        };
        break;
      case INPUT_TYPES.KEY_BASED_SETTINGS:
        inputComponent = RenderKeyBasedSettings;
        break;
      case INPUT_TYPES.BUSINESS_OBJECT_SELECTOR:
        inputComponent = RenderBusinessObjectSelector;
        extraProps = {
          isFormInput: true,
          minMaxSelection: metadata?.displayParameters?.inputData
            ?.minMaxSelection || { min: 1, max: 1 },
          contentGroupKey:
            metadata?.displayParameters?.inputData?.contentGroupKey || 'id',
          architectureId:
            metadata?.displayParameters?.inputData?.architectureId,
          contentName:
            metadata?.displayParameters?.inputData?.contentName ||
            t('businessObjectSelector:default.contentName')
        };
        break;
      case INPUT_TYPES.ORG_CONTENT_SELECTOR:
        inputComponent = RenderOrgContentSelector;

        const extraSelectOptions =
          metadata?.displayParameters?.inputData?.options || [];

        extraProps = {
          extraSelectOptions: formatSelectOptions(extraSelectOptions),
          orgCatalogSlug:
            metadata?.displayParameters?.inputData?.orgCatalogSlug,
          friendlyNameColumn:
            metadata?.displayParameters?.inputData?.friendlyNameColumn,
          valueColumn: metadata?.displayParameters?.inputData?.valueColumn,
          isMultiSelect:
            metadata?.displayParameters?.inputData?.isMultiSelect || false
        };
        break;
      case INPUT_TYPES.ORG_TAG_SELECTOR:
        inputComponent = RenderOrgContentSelector;

        extraProps = {
          extraSelectOptions: formatSelectOptions(
            metadata?.displayParameters?.inputData?.options || []
          ),
          orgCatalogSlug:
            metadata?.displayParameters?.inputData?.orgCatalogSlug,
          friendlyNameColumn:
            metadata?.displayParameters?.inputData?.friendlyNameColumn,
          valueColumn: metadata?.displayParameters?.inputData?.valueColumn,
          isMultiSelect:
            metadata?.displayParameters?.inputData?.isMultiSelect || false,
          allowTagCreationViaWebhook:
            metadata?.displayParameters?.inputData
              ?.allowTagCreationViaWebhook || false
        };
        break;
      case INPUT_TYPES.ORGANIZATION_SELECTOR:
        inputComponent = RenderOrganizationSelector;
        extraProps = {
          autoComplete: false
        };
        break;
      case INPUT_TYPES.WORKATO_CONNECTION:
        inputComponent = RenderWorkatoConnection;

        extraProps = {
          isMultiSelect:
            metadata?.displayParameters?.inputData?.isMultiSelect || false
        };
        break;
      case INPUT_TYPES.WORKATO_CONNECTION_LEGACY:
        inputComponent = RenderWorkatoConnectionLegacy;

        extraProps = {
          isMultiSelect:
            metadata?.displayParameters?.inputData?.isMultiSelect || false
        };
        break;
      default:
        break;
    }
  }

  const displayName = metadata?.displayName;
  const isRequired = metadata?.isRequired;
  const label = (
    <span>
      {displayName}
      {isRequired && (
        <span
          style={{
            fontStyle: 'italic',
            fontSize: '14px'
          }}
        >
          {' '}
          {t('common:input.labels.required')}
        </span>
      )}
    </span>
  );

  const myProps = {
    name,
    component: inputComponent,
    label,
    helperText: metadata?.displayParameters?.inputData?.helperText,
    tooltip: metadata?.displayParameters?.inputData?.tooltip,
    fullWidth: true,
    autoComplete: 'foobar',
    readOnly: metadata?.readOnly,
    validate: isHookForm
      ? { ...validations.validations, ...dynamicValidation.validation }
      : [...validations.validations, dynamicValidation.validation],
    disabled,
    businessObjects,
    userMetadataFields,
    formNamespace: formSectionName,
    formName,
    extraProps,
    isAdCopySuggestionMessageInput,
    ...(extraProps?.allowAiGeneration && { aiTextAnalysisValues }),
    selectedLocationsMetadata,
    productInputFieldId: metadata?.id,
    onChange,
    value
  };

  if (isHookForm) {
    return <HookFormWrapper key={`i-${name}`} {...myProps} />;
  }

  return <FieldComponent key={`i-${name}`} {...myProps} {...extraProps} />;
};

export default flow(
  withGlobalContext,
  connect((state, ownProps) => {
    const formValues = getFormValues(ownProps?.formName)(state);

    const aiTextAnalysisValues =
      formValues?.[AI_ANALYSIS_FIELD_NAME]?.dynamicUserInputs?.[
        ownProps?.metadata?.fieldName
      ];

    return { aiTextAnalysisValues };
  }, null)
)(FormInput);
