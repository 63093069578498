import { useMemo } from 'react';
import { flow, keys, isUndefined } from 'lodash';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { parse } from 'qs';

import { Button } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';

import { paths } from 'src/routes/Constants';

import {
  INPUT_TEST_FORM,
  supportedDisplayParameters,
  supportedInputLevers,
  supportedValidations,
  testFormInputMap,
  boolParameters
} from './Constants';

const InputComponentTesting = props => {
  const { componentIdentifier, input, queryParams, handleSubmit, formValues } =
    props;

  const updatedInput = useMemo(() => {
    const finalInput = input;

    keys(queryParams).forEach(key => {
      if (supportedDisplayParameters[key]) {
        finalInput.displayParameters.inputData[key] = boolParameters.includes(
          key
        )
          ? Boolean(queryParams[key])
          : queryParams[key];
      }

      if (supportedInputLevers[key]) {
        finalInput[key] = Boolean(queryParams[key]);
      }

      if (key === 'validations') {
        queryParams[key].split(',').forEach(validation => {
          if (supportedValidations[validation]) {
            finalInput.reduxValidations.push(supportedValidations[validation]);
          }
        });
      }
    });

    return finalInput;
  }, [componentIdentifier, input, queryParams]);

  const onSubmit = () => {
    // do nothing at this time
  };

  if (isUndefined(formValues)) {
    return null;
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Button
        variant="contained"
        component={RouterLink}
        to={paths.playground.testing.base}
      >
        Back
      </Button>

      <h1 data-cy-key="InputComponentTestingHeading">{componentIdentifier}</h1>

      {updatedInput && (
        <div data-cy-key="InputComponentTestingContainer">
          <DynamicForm formValues={formValues} inputs={[updatedInput]} />
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(INPUT_TEST_FORM)(state);

  const { match, location } = ownProps;

  const componentIdentifier = match?.params?.componentIdentifier;
  const input = testFormInputMap[componentIdentifier]?.inputData;
  const queryParams = parse(location?.search.substr(1)) || {};

  const initialValues =
    window.testingInitialValues ||
    getInitialValuesFromInputsConfig([input]) ||
    {};

  // set the form values to the window so cypress has access to them
  window.testingStore = formValues;

  return {
    componentIdentifier,
    formValues,
    initialValues,
    input,
    queryParams
  };
};

export default flow(
  reduxForm({
    form: INPUT_TEST_FORM,
    enableReinitialize: true
  }),
  connect(mapStateToProps, {}),
  withRouter
)(InputComponentTesting);
