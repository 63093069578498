import { camelCase } from 'lodash';
import { LocationConnection, Location } from 'src/generated/gql/graphql';

type LocationKey = keyof Location;

export const formatLocationMetadata = (location: Location) => {
  const keys = Object.keys(location) as LocationKey[];
  const formattedLocation: Record<string, any> = {};

  keys.forEach(key => {
    formattedLocation[camelCase(`location ${key}`)] = location[key];
  });

  return formattedLocation;
};

export const getSelectedLocationsMetadata = (
  locationsData: LocationConnection | undefined | null,
  selectedLocationIds: string[]
) => {
  const selectedLocationIdsSet = new Set(selectedLocationIds);
  return locationsData?.edges?.reduce((locationsMetadata: any[], edge) => {
    if (edge?.node && selectedLocationIdsSet.has(edge?.node?.id)) {
      return [...locationsMetadata, formatLocationMetadata(edge.node)];
    }
    return locationsMetadata;
  }, []);
};
