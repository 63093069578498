import { useEffect, useMemo } from 'react';
import { orderBy, isEmpty } from 'lodash';
import { Field, change as changeAction } from 'redux-form';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { FormControlLabel, Radio, Box } from '@mui/material';

import { Offer } from 'src/generated/gql/graphql';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import { BILLING_METHODS } from 'src/common/paymentUtils';

import {
  scheduleTypes,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';
import { RenderRadioGroup } from 'src/components/ReduxForm';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import CardOffer from './CardOffer';
import InvoiceOffer from './InvoiceOffer';

interface OffersProps {
  formName: string;
  selectedBlueprint: any;
  change: (form: string, field: string, value: any) => void;
  paymentMethodIdError: string | undefined;
  formValues?: any;
  isHookForm?: boolean;
  hookFormContext?: UseFormReturn<FieldValues, any, undefined>;
}

const pageText = () => ({
  PARTNER_INVOICE: t('programCreate:Checkout.billingMethodInvoice'),
  USER_CREDIT_CARD: t('programCreate:Checkout.billingMethodCreditCard')
});

const required = (value?: string) => (value ? undefined : 'Required');

const Offers = ({
  formName,
  selectedBlueprint,
  change: reduxChange,
  formValues,
  isHookForm,
  hookFormContext,
  paymentMethodIdError // TODO this comes from the form state what todo with it in hook form
}: OffersProps) => {
  const text = useMemo(() => pageText(), []);

  const billingMethod = isHookForm
    ? hookFormContext?.watch()?.spendStep?.billingMethod
    : formValues?.spendStep?.billingMethod;

  const isCardOffer = billingMethod === BILLING_METHODS.card;

  const offerId = isHookForm
    ? hookFormContext?.watch()?.spendStep?.offerId
    : formValues?.spendStep?.offerId;

  const scheduleType = isHookForm
    ? hookFormContext?.watch()?.spendStep?.scheduleType
    : formValues?.spendStep?.scheduleType;

  const stripeSourceIdValue = isHookForm
    ? hookFormContext?.watch()?.spendStep?.stripeSourceId
    : formValues?.spendStep?.stripeSourceId;

  const change =
    getChangeFormValue({
      reduxChange,
      hookSetValue: hookFormContext?.setValue
    }) || (() => {});

  const isSubscription = scheduleType === scheduleTypes.subscription.value;

  // ordering by billingMethod for now but future us should have a priority on the offers
  const offersBySelectedType = orderBy(
    selectedBlueprint.offers.filter((o: Offer) => {
      // filter out subscription offers that don't have plans associated with them
      if (isSubscription && isEmpty(o?.stripeSubscriptionPlans)) {
        return false;
      }

      return o.type === scheduleType;
    }),
    ['billingMethod'],
    ['asc']
  );

  useEffect(() => {
    // if we switch offer types we need to select the first offer of that type
    // or just keep the current offer and set the billing method
    const currentOffer = offersBySelectedType.find(
      (o: Offer) => o.id === offerId
    );

    if (!currentOffer) {
      change(
        formName,
        'spendStep.billingMethod',
        offersBySelectedType?.[0]?.billingMethod
      );
      change(formName, 'spendStep.offerId', offersBySelectedType?.[0]?.id);
    } else {
      change(formName, 'spendStep.billingMethod', currentOffer.billingMethod);
    }
  }, [offersBySelectedType?.[0]?.type]); // this effect only happens when we change type purchase vs subscription.

  const offerIdPropsRedux = {
    component: RenderRadioGroup,
    name: 'offerId',
    validate: [required],
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    onChange: (e: any, newVal: string) => {
      // update the billing method
      change(
        formName,
        'spendStep.billingMethod',
        (offersBySelectedType.find((o: Offer) => o.id === newVal) as Offer)
          .billingMethod
      );
    }
  };

  const offerIdPropsHook = {
    ...offerIdPropsRedux,
    // hook form doesn't pass the event so it needs its own onChange
    onChange: (newVal: string) => {
      // update the billing method
      change(
        formName,
        'spendStep.billingMethod',
        (offersBySelectedType.find((o: Offer) => o.id === newVal) as Offer)
          .billingMethod
      );
      change(formName, 'spendStep.offerId', newVal);
    }
  };

  const hiddenOfferIdProps = {
    name: 'offerId',
    component: 'input',
    validate: [required],
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    extraProps: {
      type: 'hidden'
    }
  };

  const hiddenBillingMethodProps = {
    name: 'billingMethod',
    component: 'input',
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    extraProps: {
      type: 'hidden'
    }
  };

  const offerIdOptions = offersBySelectedType.map((offer: Offer) => {
    return (
      <FormControlLabel
        data-cy={`payment-billingMethod-${offer.billingMethod}`}
        key={`${offer.type}-${offer.billingMethod}`}
        control={<Radio />}
        label={text[offer.billingMethod]}
        value={offer.id}
      />
    );
  });

  return (
    <div data-cy="payment-card-container">
      {/* eslint-disable-next-line no-nested-ternary */}
      {offersBySelectedType?.length > 1 ? (
        isHookForm ? (
          <HookFormWrapper {...offerIdPropsHook} validateBackendOnly={false}>
            {offerIdOptions}
          </HookFormWrapper>
        ) : (
          <Field {...offerIdPropsRedux}>{offerIdOptions}</Field>
        )
      ) : (
        !isHookForm && (
          // we don't need the hidden inputs for hook form
          <Field {...hiddenOfferIdProps} {...hiddenOfferIdProps.extraProps} />
        )
      )}

      {!isHookForm && (
        // we don't need the hidden inputs for hook form
        <Field
          {...hiddenBillingMethodProps}
          {...hiddenBillingMethodProps.extraProps}
        />
      )}

      {paymentMethodIdError && (
        <Box
          sx={{
            color: 'error.main'
          }}
        >
          {paymentMethodIdError}
        </Box>
      )}

      {isCardOffer && (
        <CardOffer
          stripeSourceIdValue={stripeSourceIdValue}
          formName={formName}
          isHookForm={isHookForm}
          hookFormContext={hookFormContext}
        />
      )}

      {!isCardOffer && <InvoiceOffer />}
    </div>
  );
};

export default connect(null, { change: changeAction })(Offers);
