import { useEffect, useState } from 'react';
import { flow, includes } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';

import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { paths } from 'src/routes/Constants';
import { getAdminQuickStart } from 'src/pages/Admin/Admin';
import { withGlobalContext } from 'src/GlobalContextProvider';

import { getAdminNavigationItems, getMenuIcon } from './navigationUtil';

const styles = theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    width: `${theme.evSizes.navigationWidth}px`
  },
  listItemChild: {
    paddingLeft: theme.spacing(4)
  },
  listItemIcon: {
    margin: 0,
    width: '24px !important',
    height: '24px'
  },
  listItemText: {
    '& span': {
      maxWidth: '150px',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }
});

const matchThatPath = (pathname, path) => {
  return matchPath(pathname, {
    exact: false,
    path,
    strict: false
  });
};

const AdminNavigationList = props => {
  const {
    location: { pathname },
    classes,
    location,
    globalContext
  } = props;

  const {
    appPermissions: { isEvocalizeOrg }
  } = globalContext;

  const menus = getAdminQuickStart(isEvocalizeOrg);

  const [openNavigationItems, setOpenNavigationItems] = useState([]);

  const basePath = matchThatPath(pathname, paths.admin.settings.base)?.url;

  useEffect(() => {
    if (paths.admin.settings.base === basePath) {
      setOpenNavigationItems(prevItems => [...prevItems, 'organization']);
    }
  }, [basePath]);

  const handelMenuToggle = id => {
    // Note: this is what expands a menu item

    setOpenNavigationItems(prevItems => {
      const itemIndexOf = prevItems.indexOf(id);
      const currentlyClosed = itemIndexOf === -1;
      if (currentlyClosed) {
        return [...prevItems, id];
      }
      prevItems.splice(itemIndexOf, 1);
      return [...prevItems];
    });
  };

  return (
    <List className={classes.list} component="nav">
      {getAdminNavigationItems().map(subItem => (
        <ListItem
          button
          className={classes.listItemChild}
          disabled={subItem?.disabled}
          key={subItem.key}
          component={Link}
          selected={
            !!matchPath(location.pathname, {
              exact: true,
              path: subItem.path,
              strict: false
            })
          }
          to={subItem.path}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {getMenuIcon(subItem.key)}
          </ListItemIcon>
          <ListItemText
            primary={subItem.name()}
            className={classes.listItemText}
          />
        </ListItem>
      ))}
      <Divider />
      {menus.reduce((accum, menu) => {
        accum.push(
          <ListItem
            button
            key={`${menu.key}-item`}
            onClick={() => handelMenuToggle(menu.key)}
          >
            <ListItemText
              primary={menu.heading()}
              className={classes.listItemText}
            />
            {includes(openNavigationItems, menu.key) ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </ListItem>
        );
        accum.push(
          <Collapse
            key={`${menu.key}-collapse`}
            in={includes(openNavigationItems, menu.key)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {menu.items.map(subItem => {
                const ItemIcon = subItem?.icon;
                return (
                  <ListItem
                    button
                    className={classes.listItemChild}
                    disabled={subItem?.disabled}
                    key={subItem.path}
                    component={Link}
                    selected={
                      !!matchPath(location.pathname, {
                        exact: true,
                        path: subItem.path,
                        strict: false
                      })
                    }
                    to={subItem.path}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {ItemIcon && <ItemIcon />}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.listItemText}
                      primary={subItem.name}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        );
        accum.push(<Divider key={`${menu.key}-divider`} />);
        return accum;
      }, [])}
    </List>
  );
};

export default flow(
  withStyles(styles),
  withRouter,
  withGlobalContext
)(AdminNavigationList);
