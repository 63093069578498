import { flow, isString } from 'lodash';
import { FormControl, Select, FormHelperText, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { t } from 'i18next';

const styles = theme => ({
  input: {},
  error: {
    color: theme.palette.error.main
  }
});

const RenderFilterValue = ({
  classes,
  input: { value, onChange },
  meta: { error },
  selectedColumn
}) => {
  const showError = error;
  if (error && !isString(error)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }

  const items = selectedColumn?.enumValues || [];
  return (
    <FormControl error={showError} className={classes.input}>
      <Select value={value} onChange={onChange} displayEmpty variant="standard">
        <MenuItem value="">
          {t('automatedProgramFilter:renderValueSelect.empty')}
        </MenuItem>
        {items.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {showError && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default flow(withStyles(styles))(RenderFilterValue);
