import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { paths } from 'src/routes/Constants';
import PageLinkCard from '../PageLinkCard';
import FacebookAuthButton from '../../FacebookAuthButton';
import ActionsContainer from '../ActionsContainer';

const AddFacebookAccount = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onCancel = () => {
    history.push(paths.facebook.pages);
  };

  return (
    <PageLinkCard
      title={t('facebookSettings:addFacebookAccount.title')}
      actions={
        <ActionsContainer justifyContent="flex-end">
          <Button onClick={onCancel}>
            {t('facebookSettings:buttons.cancel')}
          </Button>
          <FacebookAuthButton />
        </ActionsContainer>
      }
    >
      <Typography>
        {t('facebookSettings:addFacebookAccount.description')}
      </Typography>
    </PageLinkCard>
  );
};

export default AddFacebookAccount;
