import { connect } from 'react-redux';
import { flow, pick, isString, pickBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { reduxForm } from 'redux-form';
import { CircularProgress, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import { createOrUpdateCatalogCollapseKey } from './mutations';
import { getCollapseKeyInputs } from './constants';

const style = theme => ({
  updateButton: { marginTop: theme.spacing(2) }
});

const CollapseKeyForm = ({
  handleSubmit,
  submitting,
  invalid,
  enqueueSnackbar,
  initialValues,
  catalog,
  refetch,
  classes,
  createOrUpdateCatalogCollapseKey
}) => {
  const onSubmit = async data => {
    const onlyAPIFields = pick(data, [
      ...getCollapseKeyInputs.filter(f => !f.readOnly).map(f => f.name)
    ]);
    const onlyChanged = pickBy(onlyAPIFields, (val, key) => {
      if (isString(val)) {
        return val !== initialValues?.[key];
      }
      return true;
    });
    try {
      await createOrUpdateCatalogCollapseKey({
        variables: {
          catalogId: initialValues.id,
          input: onlyChanged
        }
      });

      enqueueSnackbar({
        message: t('admin:catalogContent.collapseKeyFormSuccess'),
        options: {
          variant: 'success'
        }
      });
      refetch();
    } catch (e) {
      enqueueSnackbar({
        message: t('admin:catalogContent.collapseKeyFormError'),
        options: {
          variant: 'error'
        }
      });
      refetch();
    }
  };

  const fieldMetaData =
    catalog.fieldMetadata?.map(field => ({
      name: field.fieldName,
      value: field.id
    })) || [];

  return (
    <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
      <DynamicForm
        inputs={getCollapseKeyInputs({
          enums: { fieldMetaData }
        })}
      />
      <Button
        color="primary"
        variant="outlined"
        type="submit"
        disabled={submitting || invalid}
        endIcon={submitting && <CircularProgress size={15} />}
        className={classes.updateButton}
      >
        <Trans i18nKey="admin:catalogContent.updateCollapseKey">
          Update Collapse Key
        </Trans>
      </Button>
    </form>
  );
};

export default flow(
  connect(null, { enqueueSnackbar }),
  reduxForm({
    // formName from parent
    // initialValues from parent
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(createOrUpdateCatalogCollapseKey, {
    name: 'createOrUpdateCatalogCollapseKey'
  }),
  withStyles(style)
)(CollapseKeyForm);
