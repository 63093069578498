import { connect } from 'react-redux';
import { flow, pick, pickBy, isNil } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';
import { reduxForm, submit } from 'redux-form';
import { CircularProgress, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import { mapIntrospectionToProps } from 'src/common/ApolloUtil';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import {
  createCatalogImplicitFilter,
  updateCatalogImplicitFilter
} from './mutations';
import { userRoleEnumIntrospection } from './queries';
import { getImplicitFilterInputs } from './constants';

const style = theme => ({
  updateButton: { marginTop: theme.spacing(2) }
});

const ImplicitFlterForm = ({
  handleSubmit,
  submitting,
  invalid,
  enqueueSnackbar,
  initialValues,
  refetch,
  createCatalogImplicitFilter,
  updateCatalogImplicitFilter,
  introspectionMeta,
  enumFields,
  close
}) => {
  const isNew = !!initialValues.isNew;

  if (introspectionMeta?.loading) {
    return <Loading />;
  }

  const implicitFilterInputs = getImplicitFilterInputs({ enumFields });
  const onSubmit = async data => {
    const onlyAPIFields = pick(data, [
      ...implicitFilterInputs.map(f => f.name)
    ]);
    const onlyFilled = pickBy(onlyAPIFields, f => !isNil(f) && f !== '');
    try {
      if (initialValues?.id) {
        await updateCatalogImplicitFilter({
          variables: {
            implicitFilterId: initialValues?.id,
            input: onlyFilled
          }
        });
      } else {
        await createCatalogImplicitFilter({
          variables: {
            catalogId: initialValues?.catalogId,
            input: onlyFilled
          }
        });
      }

      enqueueSnackbar({
        message: t('admin:catalogContent.implicitFilterFormSuccess'),
        options: {
          variant: 'success'
        }
      });
      refetch();
    } catch (e) {
      enqueueSnackbar({
        message: t('admin:catalogContent.implicitFilterFormError'),
        options: {
          variant: 'error'
        }
      });
      refetch();
    }
  };

  return (
    <Modal
      open
      headerText={
        isNew
          ? t('admin:catalogContent.createImplicitFilterModalHead')
          : t('admin:catalogContent.updateImplicitFilterModalHead')
      }
      onClose={close}
      showClose
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
      FooterComponent={() => (
        <>
          <Button onClick={close}>
            {t('admin:catalogContent.cancelImplicitFilter')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting || invalid}
            endIcon={submitting && <CircularProgress size={15} />}
          >
            {t('admin:catalogContent.implicitFilterSubmit')}
          </Button>
        </>
      )}
    >
      <DynamicForm inputs={implicitFilterInputs} />
    </Modal>
  );
};

export default flow(
  connect(null, { enqueueSnackbar, submit }),
  reduxForm({
    // formName from parent
    // initialValues from parent
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(createCatalogImplicitFilter, {
    name: 'createCatalogImplicitFilter'
  }),
  graphql(updateCatalogImplicitFilter, {
    name: 'updateCatalogImplicitFilter'
  }),
  graphql(userRoleEnumIntrospection, {
    name: 'userRoleEnumIntrospection',
    props: ({ userRoleEnumIntrospection }) =>
      mapIntrospectionToProps(userRoleEnumIntrospection)
  }),
  withStyles(style)
)(ImplicitFlterForm);
