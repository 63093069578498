import classNames from 'classnames';
import { flow, noop } from 'lodash';
import { generatePath, matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Divider, Drawer } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { withAppSettings } from 'src/AppSettings';
import { useAppState } from 'src/AppStateProvider';

import { paths } from 'src/routes/Constants';

import { withFeatures } from 'src/components/Feature';

import NavigationList from './NavigationList';
import OfficeSelector from './OfficeSelector';
import AdminNavigationList from './AdminNavigationList';

const styles = theme => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: `${theme.evSizes.navigationWidth}px`
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `${theme.evSizes.navigationWidth}px`
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: 0
  },
  companyLogoContainer: {
    width: `${theme.evSizes.navigationWidth}px`,
    padding: theme.spacing(2)
  },
  companyLogo: {
    width: '100%',
    cursor: 'pointer'
  },
  linkBackContainer: {
    padding: 0,
    width: `${theme.evSizes.navigationWidth}px`
  },
  linkBackChild: {
    paddingLeft: theme.spacing(4)
  },
  linkBackIcon: {
    margin: 0,
    width: '24px',
    height: '24px'
  }
});

// This function is designed to determine if the logo link url is overridden
// to go to an external site. If so, we use window.location. Otherwise we send
// the user to the dashboard.
const handleLogoClick = (history, linkBackTo) => {
  const linkBackUrl = linkBackTo;

  if (linkBackUrl) {
    window.location = linkBackUrl;
    return;
  }
  // Default case if no settings exist in the skin to override it.
  history.push(generatePath(paths.dashboard.base));
};

const ChromeDrawer = props => {
  const { loading, classes, features, history, location, appSettings } = props;
  const { sideNavOpen } = useAppState();

  const isAdminPath = !!matchPath(location.pathname, {
    path: paths.admin.base
  });
  const linkBackTo = appSettings?.app?.general?.linkBack?.url;

  return (
    <Drawer
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: sideNavOpen,
        [classes.drawerClose]: !sideNavOpen
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: sideNavOpen,
          [classes.drawerClose]: !sideNavOpen
        })
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      open={sideNavOpen}
      variant="permanent"
    >
      <div className={classes.companyLogoContainer}>
        <img
          alt={`${appSettings?.app.general.baseTitle} Logo`}
          className={classes.companyLogo}
          src={appSettings?.evAssets.logoUrl}
          onClick={() =>
            features?.logoClick ? handleLogoClick(history, linkBackTo) : noop
          }
        />
      </div>
      <Divider />
      <OfficeSelector />
      <Divider />
      {!loading && (
        <>{isAdminPath ? <AdminNavigationList /> : <NavigationList />}</>
      )}
    </Drawer>
  );
};

export default flow(
  withStyles(styles),
  withAppSettings,
  withRouter,
  withFeatures
)(ChromeDrawer);
