import { useCallback } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { flow, pick } from 'lodash';
import { t } from 'i18next';

import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';

import { updatePricingPlan } from '../mutations';
import { updatePricingConfigInputs, FORM_NAME_UPDATE_PLAN } from '../constants';

const styles = theme => ({
  editButton: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

const UpdatePricingForm = props => {
  const {
    refetch,
    classes,
    dirty,
    invalid,
    formValues,
    updatePricingPlanMutation
  } = props;

  async function updatePricingPlan(formValues) {
    const mutationParams = pick(formValues, [
      'id',
      'name',
      'description',
      'isActive'
    ]);

    try {
      await updatePricingPlanMutation({
        variables: {
          Input: {
            ...mutationParams
          }
        }
      });
      enqueueSnackbar({
        message: t('admin:updatePricingPlan.successSnack'),
        options: {
          variant: 'success'
        }
      });

      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:updatePricingPlan.errorSnack'),
        options: {
          variant: 'error'
        }
      });
    }
  }

  const onSubmit = useCallback(async formValues => {
    updatePricingPlan(formValues);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <form autoComplete="off" data-cy="edit-pricing-plan-form">
          <DynamicForm inputs={updatePricingConfigInputs} />
        </form>
      </Grid>
      <Grid item xs={12} className={classes.editButton}>
        <Button
          variant="contained"
          color="primary"
          disabled={!dirty || invalid}
          onClick={() => {
            onSubmit(formValues);
          }}
          data-cy="edit-pricing-plan-button"
        >
          {t('admin:updatePricingPlan.update')}
        </Button>
      </Grid>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const { pricingPlan } = ownProps;

  const initialValues = pricingPlan;
  const formValues = getFormValues(FORM_NAME_UPDATE_PLAN)(state);

  return { initialValues, formValues };
}

export default flow(
  reduxForm({
    form: FORM_NAME_UPDATE_PLAN,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(updatePricingPlan, {
    name: 'updatePricingPlanMutation'
  }),
  connect(mapStateToProps),
  withStyles(styles)
)(UpdatePricingForm);
