import { useMemo } from 'react';
import { flow, find } from 'lodash';
import { DefaultRootState, connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';

import {
  change,
  getFormSyncErrors,
  getFormValues,
  FormErrors,
  FormSubmitHandler,
  FormAction
} from 'redux-form';
import { t } from 'i18next';

import { Box, Grid } from '@mui/material';

import { AppSettingsContextType, withAppSettings } from 'src/AppSettings';

import { PROGRAM_FORM_NAME, scheduleTypes } from 'src/pages/Program/Constants';
import ProgramStepFooter from 'src/pages/Program/ProgramStepFooter';

import {
  Architecture,
  Offer,
  OfferBillingMethod
} from 'src/generated/gql/graphql';

import { BILLING_METHODS } from 'src/common/paymentUtils';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';

import usePromoCode from 'src/pages/Program/ProgramSteps/PriceSummary/usePromoCode';
import Loading from 'src/components/Loading';
import { FormSection } from 'src/components/ReduxForm';
import PriceSummary from 'src/pages/Program/ProgramSteps/PriceSummary';

import { Features } from '../Feature/constants';
import { getPaymentMethods } from './queries';

import { addPaymentMethod } from './mutations';
import Offers from './Offers';
import useAddCreditCard from './useCreditCard';

type Change = (
  form: string,
  field: string,
  value: any,
  touch?: boolean,
  persistentSubmitErrors?: boolean
) => FormAction;

interface InjectedProps {
  appSettings: AppSettingsContextType;
}

interface FormData {
  spendStep: {
    scheduleType: string;
    billingMethod: string;
    oneTimeSpend: number;
    subscriptionSpend: number;
    subscription: string;
    offerId: string;
    promoCodes: any;
    stripeSourceId: string;
    paymentMethodId: string;
  };
}

interface CheckoutProps extends InjectedProps {
  architecture: Architecture;
  paymentMethods: any;
  change: Change;
  formSyncErrors: FormErrors;
  selectedBlueprint: any;
  paymentMethodIdError: string;
  isAutomated: boolean;
  spendAmount: number;
  minSpendLoading: boolean;
  hasMinSpendError: boolean;
  billingMethod: OfferBillingMethod;
  features: Features;
  addPaymentMutation: any;
  promoCodes: any;
  client: any;
  offersBySelectedType: Offer[];
  formName: string;
  offerId: string;
  skipStep: boolean;
  setSkipStep: (value: boolean) => void;
  type: string;
  submitForm: FormSubmitHandler;
  handleNext: () => void;
  lockSubmit: boolean;
  showValidationErrors: boolean;
  selectedBusinessObjects: any;
  hookFormContext?: any;
  formValues: FormData;
}

const pageText = () => ({
  billingSectionTitle: t('programCreate:Checkout.billingSectionTitle'),
  billingSectionDescription: t(
    'programCreate:Checkout.billingSectionDescription'
  ),
  PARTNER_INVOICE: t('programCreate:Checkout.billingMethodInvoice'),
  USER_CREDIT_CARD: t('programCreate:Checkout.billingMethodCreditCard')
});

const Checkout = (props: CheckoutProps) => {
  const {
    architecture,
    paymentMethods = [],
    change: reduxChange,
    formSyncErrors,
    selectedBlueprint,
    paymentMethodIdError,
    isAutomated,
    spendAmount,
    minSpendLoading,
    hasMinSpendError,
    billingMethod,
    promoCodes = [],
    formName,
    skipStep,
    setSkipStep,
    type,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    hookFormContext,
    formValues,
    selectedBusinessObjects
  } = props;

  const { loading, error, paymentMethod = [] } = paymentMethods;
  const isCardOffer = billingMethod === BILLING_METHODS.card;

  const text = useMemo(() => pageText(), []);

  const change =
    getChangeFormValue({
      reduxChange,
      hookSetValue: hookFormContext?.setValue
    }) || (() => {});

  const { validatePromoCode } = usePromoCode({
    spendAmount,
    isAutomated,
    promoCodes,
    change,
    formName
  });

  const {
    updatingPayment,
    handleAddCard,
    stripeLoading // TODO: need to make some context so this updates everywhere
  } = useAddCreditCard();

  const handleNextWithValidation = async (
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    const { paymentMethod = [] } = paymentMethods;
    const allPaymentMethods = paymentMethod || [];

    if (isCardOffer && !allPaymentMethods.length) {
      // we try to submit the card field
      const addCardResponse: any = await handleAddCard(!!errorCallback);
      if (addCardResponse instanceof Error || addCardResponse?.error) {
        if (errorCallback) {
          errorCallback();
        }
        return null;
      }
    }

    if (promoCodes && promoCodes.length) {
      // we assume only one promocode :/
      try {
        await validatePromoCode(promoCodes[0]?.promoCode);
      } catch (error) {
        if (errorCallback) {
          errorCallback();
        }
        return;
      }
    }

    if (successCallback) {
      successCallback();
    }
  };

  const allPaymentMethods = paymentMethod;

  const waitingOnStripe =
    allPaymentMethods.length > 0 && isCardOffer ? false : stripeLoading;

  if (loading || error) {
    return <Loading error={error} />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormSection
            title={text.billingSectionTitle}
            description={text.billingSectionDescription}
          >
            <Box sx={{ pb: 1.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Offers
                    formName={formName}
                    selectedBlueprint={selectedBlueprint}
                    formValues={formValues}
                    paymentMethodIdError={paymentMethodIdError}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormSection>
        </Grid>
        <Grid item xs={12}>
          <PriceSummary
            selectedBlueprint={selectedBlueprint}
            isAutomated={isAutomated}
            change={change}
            formName={formName}
            formValues={formValues}
          />
        </Grid>
      </Grid>
      <ProgramStepFooter
        handleNextWithValidation={handleNextWithValidation}
        submitting={updatingPayment}
        formStepErrors={formSyncErrors}
        submitDisabled={hasMinSpendError}
        loading={(isCardOffer && waitingOnStripe) || minSpendLoading}
        skipStep={skipStep}
        setSkipStep={setSkipStep}
        blueprint={selectedBlueprint}
        architecture={architecture}
        type={type}
        submitForm={submitForm}
        handleNext={handleNext}
        lockSubmit={lockSubmit}
        showValidationErrors={showValidationErrors}
        isAutomated={isAutomated}
        selectedBusinessObjects={selectedBusinessObjects}
      />
    </>
  );
};

export default flow(
  connect(
    (state: DefaultRootState, ownProps: CheckoutProps) => {
      const {
        selectedBusinessObjects: { selectedBusinessObjects },
        selectedBlueprint,
        appSettings
      } = ownProps;

      const formValues = getFormValues(PROGRAM_FORM_NAME)(state) as FormData;

      const isSubscription =
        formValues?.spendStep?.scheduleType ===
        scheduleTypes.subscription.value;

      const billingMethod = formValues?.spendStep?.billingMethod;

      let spendAmount = formValues?.spendStep?.oneTimeSpend;

      if (isSubscription) {
        if (billingMethod === BILLING_METHODS.partnerInvoice) {
          spendAmount = formValues?.spendStep?.subscriptionSpend;
        }

        if (billingMethod === BILLING_METHODS.card) {
          const selectedSubscriptionPlan = find(
            selectedBlueprint?.paymentPlans?.subscriptionOffers,
            {
              billingMethod
            }
          );

          spendAmount = find(
            selectedSubscriptionPlan?.stripeSubscriptionPlans,
            {
              id: formValues?.spendStep?.subscription
            }
          )?.amount;
        }
      }

      return {
        selectedBusinessObjects,
        billingMethod,
        offerId: formValues?.spendStep?.offerId,
        formSyncErrors: getFormSyncErrors(PROGRAM_FORM_NAME)(state),
        promoCodes: formValues?.spendStep?.promoCodes,
        spendAmount,
        paymentMethodIdError: (state as any)?.form?.program?.submitErrors
          ?.paymentMethodId,
        stripeKey: appSettings?.app?.general?.stripeKey,
        formValues
      };
    },
    { change }
  ),
  graphql(addPaymentMethod, {
    name: 'addPaymentMutation'
  }),
  graphql(getPaymentMethods, {
    name: 'paymentMethods'
  }),
  withAppSettings
)(Checkout);
