import { useCallback } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { flow, pick } from 'lodash';
import { t } from 'i18next';
import { Grid, Button } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';

import { createTier, updateTier } from '../mutations';
import {
  updateTierConfigInputs,
  createTierConfigInputs,
  FORM_NAME_TIERING
} from '../constants';

const TierForm = props => {
  const {
    editModal,
    formValues,
    reset,
    refetch,
    dirty,
    invalid,
    pricingPlanId,
    createTierMutation,
    updateTierMutation
  } = props;

  const handleClearForm = () => {
    reset();
  };

  async function updateTier(formValues) {
    const mutationParams = pick(formValues, ['id', 'name', 'isActive']);

    try {
      await updateTierMutation({
        variables: {
          Input: {
            ...mutationParams
          }
        }
      });
      enqueueSnackbar({
        message: t('admin:updateTier.successSnack'),
        options: {
          variant: 'success'
        }
      });

      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:updateTier.errorSnack'),
        options: {
          variant: 'error'
        }
      });
    }
  }
  async function createTier(formValues, pricingPlanId) {
    const mutationParams = {
      pricingPlanId,
      ...pick(formValues, ['amount', 'slug', 'name', 'isActive'])
    };

    try {
      await createTierMutation({
        variables: {
          Input: {
            ...mutationParams
          }
        }
      });
      enqueueSnackbar({
        message: t('admin:createTier.successSnack'),
        options: {
          variant: 'success'
        }
      });

      handleClearForm();
      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:createTier.errorSnack'),
        options: {
          variant: 'error'
        }
      });
    }
  }

  const onSubmit = useCallback(async formValues => {
    if (editModal) {
      updateTier(formValues);
    } else {
      createTier(formValues, pricingPlanId);
    }
  }, []);

  return (
    <Grid item xs={12} md={6}>
      <form
        autoComplete="off"
        onSubmit={() => onSubmit(formValues)}
        data-cy={`${editModal ? 'edit' : 'create'}-tier-form`}
      >
        <DynamicForm
          inputs={editModal ? updateTierConfigInputs : createTierConfigInputs}
        />

        <Button
          variant="contained"
          color="primary"
          disabled={!dirty || invalid}
          onClick={() => onSubmit(formValues)}
          data-cy={`${editModal ? 'edit' : 'create'}-tier-button`}
        >
          {editModal
            ? t('admin:updateTier.update')
            : t('admin:createTier.create')}
        </Button>
      </form>
    </Grid>
  );
};

function mapStateToProps(state, ownProps) {
  const { selectedTier, editModal } = ownProps;
  const tierInitialValues = createTierConfigInputs.reduce(
    (acc, input) => ({ ...acc, [input.name]: input.initialValue }),
    {}
  );
  const initialValues = editModal ? selectedTier : tierInitialValues;

  const formValues = getFormValues(FORM_NAME_TIERING)(state);
  return { formValues, initialValues };
}

export default flow(
  reduxForm({
    form: FORM_NAME_TIERING,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps),
  graphql(createTier, {
    name: 'createTierMutation'
  }),
  graphql(updateTier, {
    name: 'updateTierMutation'
  })
)(TierForm);
