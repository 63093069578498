import { FieldArray } from 'redux-form';

import FiltersList from './FiltersList';

const fixDumReduxFormError = () => {
  // this forces the fieldarray to re-run it's validations
  // if this isn't here deleted children's validations persist
  // making life horrible
};

const ProgramFilterSelector = ({
  contentMeta,
  formName,
  fieldName = 'filters'
}) => {
  return (
    <div>
      <FieldArray
        name={fieldName}
        component={FiltersList}
        contentMeta={contentMeta}
        formName={formName}
        validate={[fixDumReduxFormError]}
      />
    </div>
  );
};

export default ProgramFilterSelector;
