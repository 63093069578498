import { t } from 'i18next';
import { useHistory } from 'react-router-dom';

import { Grid, Alert, AlertTitle, Button, Box } from '@mui/material';

import { paths } from 'src/routes/Constants';

import { FACEBOOK_LINK_RETURN_URL } from 'src/pages/FacebookLink/Constants';

const NoFacebookPage = () => {
  const history = useHistory();

  return (
    <Grid sx={{ marginTop: theme => theme.spacing(2) }} item xs={12}>
      <Alert severity="info">
        <AlertTitle>
          {t('programCreate:configure.linkFacebookTitle')}
        </AlertTitle>
        <Box
          sx={{
            marginTop: theme => theme.spacing(2),
            textAlign: 'center'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // TODO: double check this works. it appears just setting the local storage value will get this working b/c src/pages/FacebookLink/FacebookLink.jsx checks redux state and the local storage value
              localStorage.setItem(
                FACEBOOK_LINK_RETURN_URL,
                `${history.location.pathname}${history.location.search}`
              );
              history.push(paths.facebook.link);
            }}
          >
            {t('programCreate:configure.linkFacebookLink')}
          </Button>
        </Box>
      </Alert>
      {/* TODO: we ensure this all works with the new hook form */}
      {/* we need to make sure they can't move to the next step */}
      {/* <Field
        type="hidden"
        component="input"
        name="configureStep.noFacebookAccount"
        validate={[alwaysError]}
      /> */}
    </Grid>
  );
};

export default NoFacebookPage;
