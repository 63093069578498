import { useState } from 'react';
import { flow, sortBy, isUndefined, cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  reduxForm,
  SubmitHandler,
  FieldArray,
  getFormValues
} from 'redux-form';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DynamicForm } from 'src/components/ReduxForm';
import {
  contentMetaFromArchitecture,
  convertFilterJSONtoFiltersArray
} from 'src/components/AutomatedProgramFilter/helpers';

import QuickAutomationsBuilder from './QuickAutomationsBuilder';
import { labelInputs, customDesignInputs, FORM_NAME } from '../constants';
import { getCatalogByArchitectureId } from '../queries';
import LocalizedImages from './LocalizedImages';

const pageText = () => ({
  labels: t('admin:manageQuickstart.headings.labels'),
  customDesign: t('admin:manageQuickstart.headings.customDesign'),
  quickAutomations: t('admin:manageQuickstart.headings.quickAutomations'),
  save: t('admin:manageQuickstart.buttons.save'),
  cancel: t('admin:manageQuickstart.buttons.cancel')
});

const sectionsKeys = {
  labels: 'labels',
  customDesign: 'customDesign',
  quickAutomations: 'quickAutomations'
};

interface EditQuickstartFormProps {
  loading?: boolean;
  loadingMutation: boolean;
  dirty?: boolean;
  handleSubmit: SubmitHandler;
  onSave: (value: any) => void;
  onCancel: () => void;
  architectureId: string;
  quickStartId: string;
  setQuickAutomationToDelete: (value: string) => void;
  isCustomValue: boolean;
  submitSucceeded: boolean;
}

const validateHasMissingLocalizedImages = (value: any, otherProps: any) => {
  const isCustom = otherProps?.isCustom;

  // only validate if the quickstart is custom
  if (!value && !isCustom) {
    return;
  }

  let hasMissingValues = false;
  const missingLocales: string[] = [];

  value.forEach((image: any) => {
    if (isEmpty(image.customImageUrl)) {
      hasMissingValues = true;
      missingLocales.push(image.locale);
    }
  });

  if (hasMissingValues) {
    return {
      message: t('admin:manageQuickstart.error.missingLocalizedImages'),
      missingLocales
    };
  }
};

const EditQuickstartForm = ({
  loading = true,
  loadingMutation = false,
  dirty = false,
  handleSubmit,
  onSave,
  onCancel,
  architectureId,
  quickStartId,
  setQuickAutomationToDelete,
  isCustomValue,
  submitSucceeded
}: EditQuickstartFormProps) => {
  const text = pageText();

  const [openSections, setOpenSection] = useState<string[]>([
    sectionsKeys.labels,
    sectionsKeys.customDesign,
    sectionsKeys.quickAutomations
  ]);

  const handleSave = (value: any) => {
    setOpenSection([]);
    onSave(value);
  };

  const { data: architectureData, loading: loadingArchitecture } = useQuery(
    getCatalogByArchitectureId,
    {
      skip: !architectureId,
      fetchPolicy: 'no-cache',
      variables: {
        architectureId
      }
    }
  );

  const contentMeta = contentMetaFromArchitecture(
    architectureData?.architecture
  );

  const contentFriendlyName =
    architectureData?.architecture?.catalog?.friendlyName;

  const handleSectionToggle = (key: string) => {
    if (openSections.includes(key)) {
      setOpenSection(openSections.filter(item => item !== key));
    } else {
      setOpenSection([...openSections, key]);
    }
  };

  const disableSave =
    loadingArchitecture ||
    loadingMutation ||
    loading ||
    !dirty ||
    submitSucceeded;
  const buttonLoading = loadingArchitecture || loadingMutation || loading;

  return (
    <Box sx={{ marginTop: theme => theme.spacing(2) }}>
      <form autoComplete="off" onSubmit={handleSubmit(handleSave)}>
        <Accordion
          key={sectionsKeys.labels}
          onChange={() => handleSectionToggle(sectionsKeys.labels)}
          expanded={openSections.includes(sectionsKeys.labels)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {text.labels}
          </AccordionSummary>
          <AccordionDetails>
            <>
              <DynamicForm inputs={labelInputs(isCustomValue)} />
            </>
          </AccordionDetails>
        </Accordion>

        <Accordion
          key={sectionsKeys.customDesign}
          onChange={() => handleSectionToggle(sectionsKeys.customDesign)}
          expanded={openSections.includes(sectionsKeys.customDesign)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {text.customDesign}
          </AccordionSummary>
          <AccordionDetails>
            <>
              <DynamicForm inputs={customDesignInputs} />
              <FieldArray
                name="localizedImages"
                component={LocalizedImages}
                isCustomValue={isCustomValue}
                validate={[validateHasMissingLocalizedImages]}
              />
            </>
          </AccordionDetails>
        </Accordion>

        <Accordion
          key={sectionsKeys.quickAutomations}
          onChange={() => handleSectionToggle(sectionsKeys.quickAutomations)}
          expanded={openSections.includes(sectionsKeys.quickAutomations)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {text.quickAutomations}
          </AccordionSummary>
          <AccordionDetails>
            <FieldArray
              name="automationQuickStarts"
              component={QuickAutomationsBuilder}
              contentMeta={contentMeta}
              formName={FORM_NAME}
              quickStartId={quickStartId}
              setQuickAutomationToDelete={setQuickAutomationToDelete}
              contentFriendlyName={contentFriendlyName}
              architectureData={architectureData}
            />
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2
          }}
        >
          <Button onClick={onCancel} variant="outlined" color="primary">
            {text.cancel}
          </Button>
          <LoadingButton
            loading={buttonLoading}
            disabled={disableSave}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ ml: 1 }}
          >
            {text.save}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

const formatAutomationQuickStarts = (automationQuickStarts: any) => {
  if (isUndefined(automationQuickStarts)) {
    return [];
  }

  let formattedAutomationQuickStart = cloneDeep(automationQuickStarts);

  // sort so ensure we are in priority order
  formattedAutomationQuickStart = sortBy(
    formattedAutomationQuickStart || [],
    'priority'
  );

  // convert the filter JSON to an array to work in the filter builder
  formattedAutomationQuickStart = formattedAutomationQuickStart.map(
    (item: any) => {
      const updatedCatalogFilter = item;
      updatedCatalogFilter.catalogFilter = convertFilterJSONtoFiltersArray(
        updatedCatalogFilter.catalogFilter
      );

      return updatedCatalogFilter;
    }
  );

  return formattedAutomationQuickStart;
};

const mapStateToProps = (state: any, ownProps: any) => {
  const { quickstart } = ownProps;
  const formValues = getFormValues(FORM_NAME)(state) || {};

  const initialValues = {
    automationQuickStarts: formatAutomationQuickStarts(
      quickstart?.automationQuickStarts
    ),
    customImageUrl: quickstart?.customImageUrl,
    description: quickstart?.description,
    id: quickstart?.id,
    isActive: quickstart?.isActive,
    isCustom: quickstart?.isCustom || false,
    name: quickstart?.name,
    priority: quickstart?.priority,
    localizedImages: quickstart?.localizedImages || []
  };

  const isCustomValue =
    (formValues as { isCustom?: boolean })?.isCustom || false;

  return {
    initialValues,
    architectureId: quickstart?.architectureId,
    formValues,
    isCustomValue
  };
};

export default flow(
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: FORM_NAME
  }),
  connect(mapStateToProps)
)(EditQuickstartForm);
