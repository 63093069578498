import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { flow } from 'lodash';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';
import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';
import Error from '@mui/icons-material/Error';

import PageTitle from 'src/components/PageTitle';

import Authenticating from './Authenticating';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  errorIcon: {
    color: theme.palette.error.light,
    height: '300px',
    width: '300px'
  },
  errorCode: {
    margin: `${theme.spacing(2)} 0`
  },
  button: {
    margin: theme.spacing(1)
  }
});

const authRegex = /access_token/;

const FourZeroFour = ({ classes, appSettings }) => {
  // Note: Auth0 sends the user back to /#access_token=xxxxxx and /#/access_toeken=xxxxxx
  //       so we have to catch those routes here and display authenticating.
  let isAuthRoute = false;
  const fullPath = `${window.location}`;

  if (fullPath.match(authRegex) !== null) {
    isAuthRoute = true;
  }

  if (isAuthRoute) {
    return <Authenticating />;
  }

  const dashboardName =
    appSettings?.app?.strings?.dashboardName ||
    t('errorPage:buttons.dashboard');

  return (
    <Grid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle="404" />
      <Grid item xs>
        <Error className={classes.errorIcon} />
        <Typography component="h1" variant="h2">
          <Trans i18nKey="errorPage:messages.ohNo">Oh no!</Trans>
        </Typography>
        <Typography component="h2" variant="h5">
          <Trans i18nKey="errorPage:messages.cantFindPage">
            We can't seem to find the page you're looking for.
          </Trans>
        </Typography>
        <Typography className={classes.errorCode} variant="subtitle1">
          <Trans i18nKey="errorPage:messages.errorCode404">
            Error code: 404
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs>
        <Button
          className={classes.button}
          color="primary"
          component={Link}
          size="large"
          to={generateLinkPath(paths.dashboard.base)}
          variant="outlined"
        >
          {dashboardName}
        </Button>
        {/* TODO: Make support email go to correct place / be dynamic */}
        <Button
          className={classes.button}
          href="mailto:support@evocalize.com?subject=Site Error"
          size="large"
          variant="outlined"
        >
          <Trans i18nKey="errorPage:buttons.contactSupport">
            Contact Support
          </Trans>
        </Button>
      </Grid>
    </Grid>
  );
};

export default flow(withAppSettings, withStyles(styles))(FourZeroFour);
