import { useCallback } from 'react';
import { flow, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { t } from 'i18next';

import withStyles from '@mui/styles/withStyles';

import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';

import { getWebhook } from './queries';
import {
  createOrUpdateWebhookSubscription,
  deleteWebhookSubscription
} from './mutations';
import { CREATE_FORM_NAME } from './Constants';
import CreateWebhook from './CreateWebhook';
import UpdateWebhook from './UpdateWebhook';

const styles = () => ({});

const ManageWebhook = props => {
  const {
    createOrUpdateWebhookSubscription,
    enqueueSnackbar,
    refetch,
    webhookSubscription,
    deleteWebhookSubscription
  } = props;
  const hasWebhookSubscription = !!webhookSubscription;

  const onSubmit = useCallback(async (data, formName, reset) => {
    const providers = data?.providers;
    const hasAuthenticationParameters = !isEmpty(providers);

    const authenticationParameters = {
      providers:
        providers &&
        providers.map(({ name, value }) => {
          return {
            type: 'CustomHeader',
            value: {
              name,
              value
            }
          };
        })
    };

    const mutationParams = {
      url: data?.url,
      ...(hasAuthenticationParameters && { authenticationParameters })
    };

    try {
      await createOrUpdateWebhookSubscription({
        variables: {
          ...mutationParams
        }
      });

      const message =
        formName === CREATE_FORM_NAME
          ? t('admin:createWebhook.successSnack')
          : t('admin:updateWebhook.successSnack');

      enqueueSnackbar({
        message,
        options: {
          variant: 'success'
        }
      });

      // clear the form
      reset();
      // refetch the data
      refetch();
    } catch (error) {
      const message =
        formName === CREATE_FORM_NAME
          ? t('admin:createWebhook.errorSnack')
          : t('admin:updateWebhook.errorSnack');
      enqueueSnackbar({
        message,
        options: {
          variant: 'error'
        }
      });

      throw new SubmissionError(error);
    }
  }, []);

  const onRemove = useCallback(async () => {
    try {
      await deleteWebhookSubscription();
      enqueueSnackbar({
        message: t('admin:removeWebhook.successSnack'),
        options: {
          variant: 'success'
        }
      });
      // refetch the data
      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:removeWebhook.errorSnack'),
        options: {
          variant: 'error'
        }
      });

      throw new SubmissionError(error);
    }
  }, []);

  return (
    <div>
      {hasWebhookSubscription ? (
        <UpdateWebhook
          onSubmit={onSubmit}
          webhookSubscription={webhookSubscription}
          onRemove={onRemove}
        />
      ) : (
        <CreateWebhook onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default flow(
  connect(null, { enqueueSnackbar, SubmissionError }),
  graphql(getWebhook, {
    name: 'getWebhook',
    props: ({ getWebhook }) => {
      const { refetch, myOrganization } = getWebhook;
      const webhookSubscription = myOrganization?.webhookSubscription;

      return { webhookSubscription, refetch };
    }
  }),
  graphql(createOrUpdateWebhookSubscription, {
    name: 'createOrUpdateWebhookSubscription'
  }),
  graphql(deleteWebhookSubscription, {
    name: 'deleteWebhookSubscription'
  }),
  withStyles(styles)
)(ManageWebhook);
