import { ReactElement, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { t } from 'i18next';
import {
  enqueueSnackbar,
  WithEnqueueSnackbar
} from 'src/components/AdmiralSnackBar/actions';
import { connect } from 'react-redux';
import { flow } from 'lodash';

const pageText = () => ({
  defaultErrorMessage: t('common:defaultLoadingErrorMessage')
});

export interface LoadingProps {
  loading?: boolean;
  error?: unknown;
  errorMessage?: string | null;
  className?: string;
  inline?: boolean;
  size?: number;
}

const Loading = (props: LoadingProps & WithEnqueueSnackbar) => {
  const text = pageText();
  const {
    loading = true, // if we just want to wrap this in a conditional
    error = null,
    errorMessage = text.defaultErrorMessage,
    enqueueSnackbar,
    className,
    inline = false,
    size = 40 // material default
  } = props;

  const [prevError, setPrevError] = useState(error);

  if (error && !prevError) {
    enqueueSnackbar({
      message: `${errorMessage as any}`,
      options: {
        variant: 'error'
      }
    });
    setPrevError(error);
  }

  if (!loading && !error) return null;

  return (
    <div
      className={`${(className as any) && className}`}
      style={{
        textAlign: 'center',
        ...(inline
          ? {
              display: 'inline-block'
            }
          : {})
      }}
    >
      {error ? (
        <div
          style={{
            color: '#c62828'
          }}
        >
          <ErrorIcon
            style={{
              color: '#c62828',
              width: '40px',
              height: '40px'
            }}
          />
          <br />
          <Typography
            style={{
              color: '#c62828',
              fontSize: '1.1rem'
            }}
          >
            {errorMessage}
          </Typography>
        </div>
      ) : (
        <CircularProgress size={size} />
      )}
    </div>
  );
};

// ⚠️ Warning! This cannot be migrated from enqueueSnackbar via redux
// to useSnackbar hook. This component is used too high up in the react
// tree (specifically AppThemeLoading) before the actual context provider
// is created (AppThemeWrapper).
// We need to reorder that provider before migrating this.
export default flow(connect(null, { enqueueSnackbar }))(Loading) as (
  props: LoadingProps
) => ReactElement;
