import { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter, useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Button, Alert, AlertTitle } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';
import { useGlobalContext } from 'src/GlobalContextProvider';

import WarningIcon from '@mui/icons-material/Warning';

import { getAppName } from 'src/common/appBranding';
import {
  dismissDeactivationNotice,
  showDeactivationNotice,
  getDeactivationDateFromTheme
} from 'src/common/deactivation';

import { allowListPaths, paths } from 'src/routes/Constants';
import { generateLinkPath } from 'src/routes/RouteUtil';

import Auth from 'src/Auth/Auth';

import { DeactivationError } from 'src/pages/Error';
import AdminSkinResetModal from 'src/pages/Admin/SkinSettings/SkinResetModal';
import { SKIN_PREVIEW_LOCAL_STORAGE } from 'src/pages/Admin/SkinSettings/Constants';
import { formatDate, dayjs } from 'src/common/dates';

import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import ClientHtml from 'src/components/ClientHtml';

import { checkPullProviderExecutionStatuses } from './queries';
import ChromeAppBar from './ChromeAppBar';
import ChromeDrawer from './ChromeDrawer';
import LoggedInOnly from './LoggedInOnly';
import FacebookLinkModal from './FacebookLinkModal';
import AutomationAdoptionModal from './AutomationAdoptionModal';

const ImportUserContext = createContext({});

export const useImportUserContext = () => useContext(ImportUserContext);

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    flexBasis: '100%',
    marginLeft: 0,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(2),
    width: '100%',

    '& > div': {
      marginBottom: theme.spacing(2)
    },

    '& > div:last-child': {
      marginBottom: 0
    }
  },
  alertButton: {
    marginRight: theme.spacing(1)
  },
  toolbarSpacer: theme.mixins.toolbar,
  warningModalHeader: {
    alignItems: 'center',
    color: theme.palette.warning[600],
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: '24px'
  },
  warningIcon: {
    margin: `0 ${theme.spacing(2)}`
  }
});

const pageText = ({ deactivationDate, appName, orgName }) => ({
  alertHeadingTextDeactivation: t('chrome:deactivation.alertHeadingText', {
    appName
  }),
  alertBodyTextDeactivation: t('chrome:deactivation.alertBodyText', {
    appName,
    deactivationDate,
    orgName
  }),
  headingTextDeactivation: t('chrome:deactivation.headingText', {
    appName
  }),
  bodyTextDeactivation: t('chrome:deactivation.bodyText', {
    appName,
    deactivationDate,
    orgName
  }),
  modalDismissButtonDeactivation: t('chrome:deactivation.modalDismissButton'),
  siteDeactivatedMessage: t('chrome:deactivation.siteDeactivatedMessage', {
    deactivationDate
  })
});

// TODO REMOVE THIS ONCE LIONDESK FULLY DEACTIVATED
// const LionDeskBodyTextDeactivation = () => {
//     return (
//         <div>
//             Beginning Saturday, September 4th, 2021, Ad Portal will be
//             temporarily unavailable. Our team is working on upgrading our Ad
//             Portal with more features and new design.
//             <br />
//             <br />
//             <a href="https://liondeskv2.zendesk.com/hc/en-us/articles/4405529748116">
//                 Click here
//             </a>{' '}
//             to learn more about what’s coming soon!
//             <br />
//             <br />
//             In the meantime:
//             <br />
//             <ul>
//                 <li>
//                     New ads can’t be set with an end date later than Monday,
//                     October 4th, 2021
//                 </li>
//                 <li>
//                     Any existing ads with an end date later than Monday, October
//                     4th, 2021 will have their end date changed to Monday,
//                     October 4th, 2021
//                 </li>
//                 <li>
//                     Any subscription-based ads will be stopped on Monday,
//                     October 4th, 2021. A partial refund will be automatically
//                     applied to cover unused spend for the current subscription
//                     period relative to the set deactivation date
//                 </li>
//             </ul>
//         </div>
//     );
// };

// TODO: At a later point, we should reduxify this guy with an app-level loading
//       state that all sub components could use (i.e. for office-level
//       switching, etc).
const Chrome = ({ children, classes, history, importingUser, appSettings }) => {
  const { loading } = useGlobalContext();

  const isAuthenticated = Auth.isAuthenticated();
  const appName = getAppName(appSettings);
  const orgName = appSettings?.app?.organizationName;

  // scroll to the top on navigation
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const themeDeactivationDate = getDeactivationDateFromTheme(appSettings);
  const deactivationDate =
    themeDeactivationDate &&
    formatDate({ format: 'ddd, MMM DD YYYY', date: themeDeactivationDate });
  const pastDeactivationDate =
    themeDeactivationDate &&
    dayjs(deactivationDate).diff(dayjs(), 'hours') <= 0;

  const [deactivationNoticeActive, setDeactivationNoticeActive] = useState(
    showDeactivationNotice()
  );

  const handleDismissDeactivationNotice = () => {
    dismissDeactivationNotice();
    setDeactivationNoticeActive(false);
  };

  const text = useMemo(
    () => pageText({ appName, deactivationDate, orgName }),
    [appName, deactivationDate, orgName]
  );

  const [skinResetModalOpen, toggleSkinResetModal] = useState(false);

  const skinPreviewAlert = localStorage.getItem(SKIN_PREVIEW_LOCAL_STORAGE);

  const [dismissImportingUser, setDismissImportingUser] = useState(false);

  const alertHeadingTextDeactivation = `<span>${text.alertHeadingTextDeactivation}</span>`;
  const alertBodyTextDeactivation = `<span>${text.alertBodyTextDeactivation}</span>`;
  const headingTextDeactivation = `<span>${text.headingTextDeactivation}</span>`;
  const bodyTextDeactivation = `<span>${text.bodyTextDeactivation}</span>`;

  // Show error when past deactivation date so users cannot use the site
  if (pastDeactivationDate) {
    return (
      <div>
        <DeactivationError customErrorMessage={text.siteDeactivatedMessage} />
      </div>
    );
  }

  return (
    <ImportUserContext.Provider
      value={{
        importingUser
      }}
    >
      <div className={classes.container}>
        <LoggedInOnly>
          <ChromeAppBar loading={loading} />
          <ChromeDrawer loading={loading} />
          {isAuthenticated && !loading && <FacebookLinkModal />}
          {isAuthenticated && !loading && <AutomationAdoptionModal />}
        </LoggedInOnly>
        <main className={classes.content}>
          <div className={classes.toolbarSpacer} />
          <div className={classes.alert}>
            {deactivationDate && (
              <Alert severity="warning" icon={<WarningIcon />}>
                <AlertTitle>
                  <ClientHtml html={alertHeadingTextDeactivation} />
                </AlertTitle>
                <ClientHtml html={alertBodyTextDeactivation} />
              </Alert>
            )}
            {skinPreviewAlert && import.meta.env.MODE !== 'test' && (
              <Alert
                severity="warning"
                action={
                  <>
                    <Button
                      className={classes.alertButton}
                      color="inherit"
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        history.push(
                          generateLinkPath(paths.admin.settings.skin)
                        )
                      }
                    >
                      <Trans i18nKey="adminSkinSettings:alert.goToSkinSettings">
                        Skin Settings
                      </Trans>
                    </Button>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => toggleSkinResetModal(true)}
                    >
                      <Trans i18nKey="adminSkinSettings:alert.reset">
                        Reset
                      </Trans>
                    </Button>
                  </>
                }
              >
                <Trans i18nKey="adminSkinSettings:alert.previewMode">
                  You are currently in skin preview mode
                </Trans>
              </Alert>
            )}
            {importingUser && !dismissImportingUser && (
              <LoggedInOnly>
                <Alert
                  severity="info"
                  icon={<Loading size={24} />}
                  action={
                    <>
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => setDismissImportingUser(true)}
                      >
                        <Trans i18nKey="adminSkinSettings:alert.dismiss">
                          Dismiss
                        </Trans>
                      </Button>
                    </>
                  }
                >
                  <AlertTitle>
                    <Trans i18nKey="adminSkinSettings:alert.importing">
                      Importing
                    </Trans>
                  </AlertTitle>
                  <Trans i18nKey="adminSkinSettings:alert.importingUserData">
                    One moment while we update and import your data. Once your
                    data is imported, you will be able to create a program.
                  </Trans>
                </Alert>
              </LoggedInOnly>
            )}
          </div>
          {!loading ? (
            children
          ) : (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Loading />
            </div>
          )}
        </main>
        <AdminSkinResetModal
          open={skinResetModalOpen}
          close={() => toggleSkinResetModal(false)}
        />
        {isAuthenticated && deactivationDate && deactivationNoticeActive && (
          <Modal // `disableBackdropClick` is removed by codemod.
            // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

            fullWidth
            showClose={false}
            open
            HeaderComponent={() => (
              <span className={classes.warningModalHeader}>
                <WarningIcon className={classes.warningIcon} />

                <ClientHtml html={headingTextDeactivation} />

                <WarningIcon className={classes.warningIcon} />
              </span>
            )}
            FooterComponent={() => (
              <>
                <Button onClick={handleDismissDeactivationNotice}>
                  {text.modalDismissButtonDeactivation}
                </Button>
              </>
            )}
          >
            <div className={classes.container}>
              <ClientHtml html={`${bodyTextDeactivation}`} />
            </div>
          </Modal>
        )}
      </div>
    </ImportUserContext.Provider>
  );
};

export default flow(
  graphql(checkPullProviderExecutionStatuses, {
    name: 'checkPullProviderExecutionStatuses',
    skip: ({ location: { pathname } }) => {
      if (allowListPaths.filter(url => pathname === url).length) {
        // Note: skip the me query if at an allowed url so we can show unauthed pages
        //       like the activation page
        return true;
      }
    },
    props: ({ checkPullProviderExecutionStatuses }) => {
      const statuses =
        checkPullProviderExecutionStatuses?.me?.pullProviderExecutionStatuses;

      let importingUser = false;
      // check to see if any pullProviderExecutionStatuses are incomplete
      if (
        statuses &&
        statuses.some(status => status.isExecutionComplete === false)
      ) {
        importingUser = true;
        checkPullProviderExecutionStatuses.startPolling(4000);
      } else {
        checkPullProviderExecutionStatuses.stopPolling();
      }

      return { importingUser };
    }
  }),
  withStyles(styles),
  withRouter,
  withAppSettings
)(Chrome);
