import { some } from 'lodash';
import { t } from 'i18next';
import { Chip, Box, Tooltip } from '@mui/material';

import { programOrderStatusCode } from 'src/components/Status/Constants';
import {
  convertFilterJSONtoFiltersArray,
  translateFiltersWithMeta
} from 'src/components/AutomatedProgramFilter/helpers';

export const getBlueprintOptionsFromArchitectures = (
  architectures: any,
  automationOnly = false
) => {
  const options = architectures.reduce((acc: any, architecture: any) => {
    let filteredProducts = architecture.products;

    if (automationOnly) {
      filteredProducts = filteredProducts.filter((p: any) =>
        some(p.offers, { isSupervisable: true })
      );
    }

    filteredProducts.forEach((product: any) => {
      acc.push({
        value: product.id,
        label: product.name
      });
    });
    return acc;
  }, []);

  return options;
};

export const getRulesAsChips = (
  catalogFilter: any,
  fieldMetadata: any,
  collapseView = false,
  friendlyName?: string | undefined | null,
  convertFilters = true // there are some cases where the filters are already converted like in Quick Automations and some places where they are not like Automation Table Rules
) => {
  const filters = convertFilters
    ? convertFilterJSONtoFiltersArray(catalogFilter)
    : catalogFilter;
  const translatedFilters = translateFiltersWithMeta(filters, fieldMetadata);
  const catalogName =
    friendlyName ?? t('automatedPrograms:programsTable.contentFriendlyName');

  const newText = t('automatedPrograms:programsTable.filterNew');
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const baseChipLabel = `${newText} ${catalogName}${
    translatedFilters.length > 0 && collapseView
      ? `, +${translatedFilters.length}`
      : ''
  }`;

  const baseChip = (
    <Chip label={baseChipLabel} key="moreFilters" sx={{ margin: '0 2px' }} />
  );

  const tooltipText = (
    <Box
      component="ul"
      sx={{
        margin: '0',
        padding: theme => `${theme.spacing()} ${theme.spacing(2)}`
      }}
    >
      <li>{`${newText} ${catalogName}`}</li>
      {translatedFilters.map((filter: any, i: number) => (
        <li>{`${
          i > 0
            ? t('automatedPrograms:programsTable.filterAnd')
            : t('automatedPrograms:programsTable.filterWhere')
        }
       ${filter.toString()}`}</li>
      ))}
    </Box>
  );

  const showTip = translatedFilters.length > 0 && collapseView;

  return (
    <Box>
      <Tooltip arrow title={showTip ? tooltipText : null}>
        {baseChip}
      </Tooltip>
      {!collapseView &&
        translatedFilters.length > 0 &&
        translatedFilters.map((filter: any, i: number) => (
          <Chip
            label={`${
              i > 0
                ? t('automatedPrograms:programsTable.filterAnd')
                : t('automatedPrograms:programsTable.filterWhere')
            } ${filter.toString()}`}
            key={filter?.key}
            sx={{ margin: '0 2px' }}
          />
        ))}
    </Box>
  );
};

export const getTypeByIdFromArchitectures = (
  architectures: any,
  automationOnly = false
) => {
  interface TypesByIdInterface {
    [name: string]: string;
  }
  const typesById: TypesByIdInterface = {};

  architectures.forEach((architecture: any) => {
    let filteredProducts = architecture.products;

    if (automationOnly) {
      filteredProducts = filteredProducts.filter((p: any) =>
        some(p.offers, { isSupervisable: true })
      );
    }

    filteredProducts.forEach((product: { id: string; name: string }) => {
      typesById[product.id] = product.name;
    });
  });

  return typesById;
};

export const formatDraftData = (node: any, isAutomation = false) => {
  const draftGrouping = t('programs:messages.orderDraftGrouping');

  if (isAutomation) {
    return {
      ...node,
      orderItem: {
        status: programOrderStatusCode.draft
      },
      hierarchy: [draftGrouping, node?.id]
    };
  }

  return {
    ...node,
    billingDetails: {
      amount: node?.priceAmount
    },
    orderItem: {
      status: programOrderStatusCode.draft
    },
    hierarchy: [draftGrouping, node?.id]
  };
};
