import { useState, useEffect } from 'react';
import { flow, get } from 'lodash';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import Auth from 'src/Auth/Auth';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { paths } from 'src/routes/Constants';

import PageTitle from 'src/components/PageTitle';
import SuccessContainer from 'src/components/Containers/SuccessContainer';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import { withAppSettings } from 'src/AppSettings';

import { redeemTokenAndSetPassword } from './mutations';
import UpdatePasswordForm from './UpdatePasswordForm';

const style = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  maxWidthContainer: {
    display: 'flex',
    flexGrow: '1',
    maxWidth: '500px',
    minWidth: '300px'
  },
  centeredWrap: {
    padding: theme.spacing(2),
    width: '100%'
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  companyLogo: {
    width: `${theme.evSizes.navigationWidth}px`
  },
  statusContainer: {
    padding: theme.spacing(2)
  }
});

const Activate = props => {
  const {
    classes,
    appSettings,
    location: { search },
    enqueueSnackbar,
    redeemTokenAndSetPassword
  } = props;

  const activationToken =
    search.includes('token=') && decodeURI(parse(search).token);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!activationToken) {
      enqueueSnackbar({
        message: t('activation:password.missingTokenError'),
        options: {
          variant: 'error'
        }
      });
    }
  }, []);

  const handleUpdatePassword = ({ activationToken, password }) => {
    redeemTokenAndSetPassword({
      variables: {
        activationToken,
        password
      }
    })
      .then(({ data: { auth0AccessToken, auth0IdToken, evAccessToken } }) => {
        Auth.setAllTokens({
          accessToken: auth0AccessToken,
          idToken: auth0IdToken,
          evAccessToken
        });
        setSuccess(true);
      })
      .catch(() => {
        enqueueSnackbar({
          message: t('activation:password.updatePasswordError'),
          options: {
            variant: 'error'
          }
        });
      });
  };

  let Content = (
    <UpdatePasswordForm
      onSubmit={handleUpdatePassword}
      activationToken={activationToken}
    />
  );

  if (success) {
    Content = (
      <SuccessContainer className={classes.statusContainer}>
        <Trans
          i18nKey="activation:password.successMessage"
          components={[<Link to={paths.auth.login} />]}
        />
      </SuccessContainer>
    );
  }

  if (success) {
    // if successful we can just redirect to dashboard to login
    return (
      <Redirect
        to={{
          pathname: paths.dashboard.base,
          state: { from: get(window, 'location.href') }
        }}
      />
    );
  }

  return (
    <div className={classes.container}>
      <PageTitle subPageTitle="Reset Password" />
      <div className={classes.maxWidthContainer}>
        <Paper className={classes.centeredWrap}>
          <div className={classes.logoContainer}>
            <img
              alt={`${appSettings.app.general.baseTitle} Logo`}
              className={classes.companyLogo}
              src={appSettings.evAssets.logoUrl}
            />
          </div>
          <h1 style={{ margin: '20px 0' }}>
            <Trans i18nKey="activation:password.header" />
          </h1>
          {Content}
        </Paper>
      </div>
    </div>
  );
};

export default flow(
  connect(() => {}, {
    enqueueSnackbar
  }),
  graphql(redeemTokenAndSetPassword, {
    name: 'redeemTokenAndSetPassword'
  }),
  withStyles(style),
  withRouter,
  withAppSettings
)(Activate);
