import { paths } from 'src/routes/Constants';

class GoogleAnalyticsTracker {
  constructor(evGaId, clientGaId) {
    this.name = 'GoogleAnalyticsTracker';

    this.pixelIds = [];

    // Set the Evocalize pixel ID - this comes from the .env file.
    if (import.meta.env.EVOCALIZE_GOOGLE_ANALYTICS_ID) {
      this.pixelIds.push(import.meta.env.EVOCALIZE_GOOGLE_ANALYTICS_ID);
    }

    // Set the EV white-label-specific pixel ID - this comes from skin
    // settings.
    if (evGaId) {
      this.pixelIds.push(evGaId);
    }

    // Set the client-specific pixel ID - this comes from skin settings.
    if (clientGaId) {
      this.pixelIds.push(clientGaId);
    }

    if (!this.hasPixelIds()) {
      return;
    }

    this.gtag = window.gtag;
    this.ga = window.ga;
  }

  hasPixelIds = () => {
    return this.pixelIds.length > 0;
  };

  init = path => {
    if (this.hasGa() && this.hasPixelIds()) {
      this.gtag('js', new Date());

      this.initialized = true;

      this.config();

      // Fire of a page view.
      this.pageView(path);
    }
  };

  config = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.pixelIds.forEach(pixelId => {
      this.gtag('config', pixelId, {
        custom_map: {
          dimension1: 'order_id',
          dimension2: 'order_item_id'
        }
      });
    });
  };

  pageView = path => {
    if (!this.isEnabled()) {
      return;
    }

    this.pixelIds.forEach(pixelId => {
      this.gtag('config', pixelId, { page_path: path });
    });
  };

  trackPurchase = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: `checkout`,
      event_label: 'confirm_purchase'
    });

    this.gtag('event', 'page_view', {
      page_title: 'virtual/step4',
      page_path: 'virtual/step4'
    });

    this.gtag('event', 'purchase', {
      transaction_id: data?.orderId,
      value: data?.paymentAmount,
      currency: 'USD',
      items: [
        {
          id: data?.offerId,
          price: data?.paymentAmount,
          quantity: 1
        }
      ]
    });
  };

  trackOrderView = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'view_item', {
      items: [
        {
          id: data?.orderId
        }
      ]
    });
  };

  trackCancelOrder = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'cancel_order', {
      order_id: data?.orderId,
      order_item_id: data?.orderItemId
    });
  };

  trackQuickStart = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'get_started',
      event_label: 'open_ad_create',
      architecture_id: data?.architectureId,
      product_ids: data?.productIds
    });
  };

  trackProgramPreviewVisibility = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'create_ad',
      event_label: data?.showPreview ? 'show_preview' : 'hide_preview'
    });
  };

  trackProgramChooseBlueprintModal = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'create_ad',
      event_label: 'choose_blueprint'
    });
  };

  trackProgramLinkFacebookPage = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'create_ad_fb_settings',
      event_label: 'link_new_page'
    });
  };

  trackProgramSelectFacebookPage = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'create_ad_fb_settings',
      event_label: 'select_fb_page'
    });
  };

  trackProgramStartStep = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'create_ad_open_step_1',
      event_label: 'initial_load'
    });

    // sets a virtual page view
    this.gtag('event', 'page_view', {
      page_title: 'virtual/step1',
      page_path: 'virtual/step1'
    });
  };

  trackProgramSelectStep = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: `create_ad_open_step_${data?.step}`,
      event_label: 'next_button'
    });

    // sets a virtual page view
    this.gtag('event', 'page_view', {
      page_title: `virtual/step${data?.label}`,
      page_path: `virtual/step${data?.step}`
    });
  };

  trackProgramSubmitPromoCode = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.action === success || fail
    this.gtag('event', 'click', {
      event_category: 'create_ad_program',
      event_label: `enter_promo_code_${data?.action}`
    });
  };

  trackEditProgram = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'edit_program',
      event_label: 'edit_program'
    });
  };

  trackEditProgramActions = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.action === save || cancel
    this.gtag('event', 'click', {
      event_category: 'edit_program',
      event_label: `edit_program_${data?.action}`
    });
  };

  trackCancelProgram = data => {
    if (!this.isEnabled()) {
      return;
    }
    // if actions do not exist we will assume it's just the edit button to open the modal
    // data.action === confirm || cancel
    this.gtag('event', 'click', {
      event_category: 'edit_program',
      event_label: data?.actions
        ? `cancel_program_${data?.actions}`
        : 'cancel_program'
    });
  };

  trackProgramsAddNew = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.type === program || automated
    this.gtag('event', 'click', {
      event_category:
        data?.type === 'automated' ? 'automated_programs' : 'programs',
      event_label: 'add_new',
      architecture_id: data?.architectureId
    });
  };

  trackViewProgram = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.type === program || automated
    this.gtag('event', 'click', {
      event_category:
        data?.type === 'automated' ? 'automated_programs' : 'programs',
      event_label: 'view_program',
      architecture_id: data?.architectureId,
      program_id: data?.programId
    });
  };

  trackGoogleAdDetails = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.type === open || close
    this.gtag('event', 'click', {
      event_category: 'programs',
      event_label: `google_ad_details_${data?.action}`
    });
  };

  trackContacts = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.action === view || download
    this.gtag('event', 'click', {
      event_category: 'manage_contacts',
      event_label:
        data?.action === 'view' ? 'view_contacts' : 'download_contacts'
    });
  };

  trackGallery = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data.action === upload || delete
    this.gtag('event', 'click', {
      event_category: 'manage_gallery',
      event_label: data?.action === 'upload' ? 'upload_asset' : 'delete_asset'
    });
  };

  trackAudience = data => {
    if (!this.isEnabled()) {
      return;
    }
    let eventLabel = 'unknown';
    // data.action === add || delete || deleteConfirm
    switch (data.action) {
      case 'add':
        eventLabel = 'add_audience';
        break;
      case 'delete':
        eventLabel = 'delete_audience';
        break;
      case 'deleteConfirm':
        eventLabel = 'delete_audience_confirm';
        break;
      default:
        break;
    }
    this.gtag('event', 'click', {
      event_category: 'manage_audience',
      event_label: eventLabel
    });
  };

  trackManageFacebookPages = data => {
    if (!this.isEnabled()) {
      return;
    }
    let eventLabel = 'unknown';
    switch (data.action) {
      case 'link':
        eventLabel = 'link_a_fb_page';
        break;
      case 'default':
        eventLabel = 'set_as_default_page';
        break;
      case 'details':
        eventLabel = 'view_page_details';
        break;
      case 'complete':
        eventLabel = 'complete_page_setup';
        break;
      case 'unlink':
        eventLabel = 'unlink_a_fb_page';
        break;
      default:
        break;
    }
    this.gtag('event', 'click', {
      event_category: 'manage_facebook_pages',
      event_label: eventLabel
    });
  };

  trackSupport = data => {
    if (!this.isEnabled()) {
      return;
    }
    // data?.location === top || side
    let eventLabel = 'unknown';
    switch (data.action) {
      case 'open':
        eventLabel =
          data?.location === 'top'
            ? 'open_support_menu_top'
            : 'open_support_menu';
        break;
      case 'link':
        eventLabel = data?.location === 'top' ? 'top_link' : 'link';
        break;
      case 'faq':
        eventLabel = data?.location === 'top' ? 'faq_link_top' : 'faq_link';
        break;
      default:
        break;
    }
    this.gtag('event', 'click', {
      event_category: 'support',
      event_label: eventLabel
    });
  };

  trackAccountMenu = data => {
    if (!this.isEnabled()) {
      return;
    }

    // data?.action === menu || link
    if (data?.action === 'menu') {
      this.gtag('event', 'click', {
        event_category: 'my_account',
        event_label: 'open_my_account'
      });
      return;
    }

    let eventLabel = 'unknown';
    switch (data?.path) {
      case paths.account.base:
        eventLabel = 'open_account_info';
        break;
      case paths.auth.logout:
        eventLabel = 'log_out';
        break;
      default:
        break;
    }
    this.gtag('event', 'click', {
      event_category: 'my_account',
      event_label: eventLabel
    });
  };

  trackBillingHistory = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'billing_details',
      event_label: 'billing_history'
    });
  };

  trackNotificationPreferences = data => {
    if (!this.isEnabled()) {
      return;
    }

    let eventLabel = 'unknown';
    switch (data?.action) {
      case 'add':
        // data?.type === email || sms
        eventLabel = `add_${data?.type}`;
        break;
      case 'remove':
        eventLabel = 'delete';
        break;
      default:
        break;
    }

    this.gtag('event', 'click', {
      event_category: 'notification_preferences',
      event_label: eventLabel
    });
  };

  trackAudiences = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.gtag('event', 'click', {
      event_category: 'manage_audience',
      event_label: `${data.action}_audience`
    });
  };

  hasGa = () => {
    return this.gtag !== null && this.gtag !== undefined;
  };

  isEnabled = () => {
    return this.hasGa() && this.initialized === true;
  };
}

export default GoogleAnalyticsTracker;
