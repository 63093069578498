import { useState } from 'react';
import { connect } from 'react-redux';
import { flow, isEmpty, debounce } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { FormSection, getFormValues } from 'redux-form';
import { withGlobalContext } from 'src/GlobalContextProvider';

import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import ContentCount from './ContentCount';
import ProgramFilterSelector from './ProgramFilterSelector';
import { AUTOMATED_PROGRAM_SECTION_NAME } from './constants';
import { trimFilterRuleValues, convertFiltersArrayToJSON } from './helpers';

const styles = theme => ({
  container: {},
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
    textAlign: 'center'
  },
  subHeading: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  subHeadingTwo: {
    fontSize: '18px',
    textAlign: 'center',
    margin: `${theme.spacing(7)} 0 ${theme.spacing(2)} 0`
  },
  textContainer: {
    margin: theme.spacing(2),
    textAlign: 'center'
  },
  title: {
    alignItems: 'center',
    display: 'flex'
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  testRulesTableHeader: {
    marginTop: theme.spacing(3)
  }
});

const getTableFiltersFromFormValues = formValues => {
  const filtersWithValue =
    formValues?.configureStep?.automatedPrograms?.filters.reduce(
      (accum, filter) => {
        accum.push(trimFilterRuleValues(filter));
        return accum;
      },
      []
    );
  if (isEmpty(filtersWithValue)) {
    return {};
  }
  return convertFiltersArrayToJSON(filtersWithValue);
};

const handleTestRules = debounce(fn => fn(), 1000);

const AutomatedProgramFilter = props => {
  const {
    architecture,
    classes,
    contentMeta,
    contentNameLowerCase,
    formName,
    formValues
  } = props;

  const currentFilters = getTableFiltersFromFormValues(formValues);
  const hasFilters = !isEmpty(
    formValues?.configureStep?.automatedPrograms?.filters
  );

  const [tableFilters, setTableFilters] = useState(currentFilters);
  const [showContent, toggleShowContent] = useState(false);

  const testRules = () => {
    setTableFilters(currentFilters);
  };

  // Check filters to see if we need to re-query the table
  if (JSON.stringify(tableFilters) !== JSON.stringify(currentFilters)) {
    handleTestRules(testRules);
  }

  return (
    <div className={classes.container} data-cy="filter-step">
      {hasFilters ? (
        <Typography variant="h3" className={classes.heading}>
          {t('automatedProgramFilter:Rules.body', {
            contentNameLowerCase
          })}
        </Typography>
      ) : (
        <>
          <Typography variant="h3" className={classes.heading}>
            {t('automatedProgramFilter:Rules.header', {
              contentNameLowerCase
            })}
          </Typography>

          <Typography variant="h4" className={classes.subHeading}>
            <Trans i18nKey="automatedProgramFilter:RulesEmpty.body">
              Here are some suggestions based on your data:
            </Trans>
          </Typography>
        </>
      )}
      <div data-amp-program-automation-wizard-filter-selector-click>
        <FormSection name={AUTOMATED_PROGRAM_SECTION_NAME}>
          <ProgramFilterSelector
            contentMeta={contentMeta}
            formName={formName}
          />
        </FormSection>
      </div>

      {!hasFilters && (
        <div>
          <Typography variant="h4" className={classes.subHeadingTwo}>
            {t('automatedProgramFilter:rulesEmpty.autoGenerateAll', {
              contentNameLowerCase
            })}
          </Typography>
        </div>
      )}

      {hasFilters && (
        <>
          <ContentCount
            architectureId={architecture?.id}
            tableFilters={tableFilters}
            displayCollapseKey={
              architecture?.catalog?.displayCollapseKey ?? 'id'
            }
            toggleShowContent={toggleShowContent}
            showContent={showContent}
            contentNameLowerCase={contentNameLowerCase}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const formValues = getFormValues(formName)(state);
  return {
    formValues
  };
};

export default flow(
  connect(mapStateToProps),
  withStyles(styles),
  withGlobalContext
)(AutomatedProgramFilter);
