import { connect } from 'react-redux';
import { flow } from 'lodash';
import { getFormValues } from 'redux-form';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { t } from 'i18next';

import { List } from '@mui/material';

import { translateFiltersWithMeta } from './helpers';
import SelectedFilterListItem from './SelectedFilterListItem';

const SelectedFilterList = ({ contentMeta, baseSet, filters = [] }) => {
  const globalContext = useGlobalContext();
  const officeName = globalContext?.office?.officeName;
  const translatedFilters = translateFiltersWithMeta(filters, contentMeta);
  const displayName = baseSet?.displayName;
  const baseSetDisplayNameWithOffice = t(
    'automatedProgramFilter:selectedFilterList.baseSet',
    {
      displayName,
      officeName
    }
  );

  return (
    <List id="selectedFiltersList">
      <SelectedFilterListItem name={baseSetDisplayNameWithOffice} />
      {translatedFilters.map(f => (
        <SelectedFilterListItem key={f.key} filter={f} />
      ))}
    </List>
  );
};
const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const formValues = getFormValues(formName)(state);
  const filters = formValues?.configureStep?.automatedPrograms?.filters || [];
  const baseSet = formValues?.configureStep?.automatedPrograms?.baseSet;
  return {
    filters,
    baseSet
  };
};

export default flow(connect(mapStateToProps))(SelectedFilterList);
