import { useState, useCallback } from 'react';
import { flow } from 'lodash';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Button, Typography, CircularProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import { DynamicForm } from 'src/components/ReduxForm';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import { createArchitecture } from './mutations';
import { CREATE_FORM_NAME, createArchitectureInputs } from './Constants';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
});

const CreateArchitecture = props => {
  const {
    classes,
    handleSubmit,
    dirty,
    createArchitecture,
    enqueueSnackbar,
    reset
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const handleClearForm = () => {
    setSubmitting(false);
    setError(false);

    reset();
  };

  const onSubmit = useCallback(async data => {
    const createInput = data;

    const mutationParams = {
      createInput
    };

    setSubmitting(true);
    setError(false);

    try {
      await createArchitecture({
        variables: {
          ...mutationParams
        }
      });

      enqueueSnackbar({
        message: t('admin:createArchitecture.successSnack'),
        options: {
          variant: 'success'
        }
      });

      handleClearForm();

      // NOTE: Doing a page refresh because the chrome side navigation is not updating properly.
      window.location.reload();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:createArchitecture.errorSnack'),
        options: {
          variant: 'error'
        }
      });
      setSubmitting(false);
      setError(true);
    }
  }, []);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h2" className={classes.cardHeading}>
        <Trans i18nKey="admin:headings.createArchitectureCardHeading">
          Setup a new architecture
        </Trans>
      </Typography>

      {error && (
        <div className={classes.errorContainer}>
          <ErrorMessage>
            <Trans i18nKey="admin:createArchitecture.errorMessage">
              Oh no! There was an error creating your new architecture. Please
              ensure everything was filled out correctly.
            </Trans>
          </ErrorMessage>
        </div>
      )}

      <DynamicForm inputs={createArchitectureInputs()} />

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty || submitting}
          type="submit"
          variant="contained"
          endIcon={submitting && <CircularProgress size={15} />}
        >
          <Trans i18nKey="admin:createArchitecture.submitButton">
            Create Architecture
          </Trans>
        </Button>

        <Button disabled={submitting} onClick={handleClearForm}>
          <Trans i18nKey="admin:createArchitecture.clearButton">
            Clear Form
          </Trans>
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = () => {
  const initialValues = getInitialValuesFromInputsConfig(
    createArchitectureInputs()
  );

  return {
    initialValues
  };
};

export default flow(
  reduxForm({
    form: CREATE_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps, { enqueueSnackbar }),
  graphql(createArchitecture, {
    name: 'createArchitecture'
  }),
  withStyles(styles)
)(CreateArchitecture);
