import { cloneDeep, flow, isNil, omitBy } from 'lodash';
import { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { t } from 'i18next';

import { Button, Grid, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import Loading from 'src/components/Loading';
import { DynamicForm } from 'src/components/ReduxForm';
import Heading from 'src/components/PageElements/Heading';

import {
  createBillingConfigInputs,
  FORM_NAME,
  updateBillingConfigInputs
} from './constants';
import { createBillingConfig, updateBillingConfig } from './mutations';
import { getBillingConfig } from './queries';
import AdminBillingSettingsSubmitModal from './AdminBillingSettingsSubmitModal';
import PrePaymentOrganization from './PrePaymentOrganization';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: '300px'
  },
  paperSuccess: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    '& svg': {
      color: theme.palette.success[500]
    }
  },
  successList: {
    marginTop: theme.spacing(1),
    padding: 0,
    listStyle: 'none'
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
});

const AdminBillingSettings = props => {
  const {
    reset,
    createBillingConfig,
    isUpdate,
    updateBillingConfig,
    enqueueSnackbar,
    billingConfigData: { loading, refetch },
    classes,
    dirty,
    invalid,
    handleSubmit,
    stripeSecretKeyFormValue
  } = props;

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const openSubmitModal = () => {
    setIsSubmitModalOpen(true);
  };

  const closeSubmitModal = () => {
    setIsSubmitModalOpen(false);
  };

  const clearForm = () => {
    reset();
  };

  const onSubmit = async data => {
    const allMutationParameters = cloneDeep(data);

    // for the update mutation, only use variables that are not null or undefined
    const resolvedMutationParameters = isUpdate
      ? omitBy(allMutationParameters, isNil)
      : { ...allMutationParameters };

    const mutationRequest = isUpdate
      ? updateBillingConfig({
          variables: { ...resolvedMutationParameters }
        })
      : createBillingConfig({
          variables: { ...resolvedMutationParameters }
        });

    try {
      await mutationRequest;

      enqueueSnackbar({
        message: t('admin:billingSettings.successDescription'),
        options: {
          variant: 'success'
        }
      });

      refetch();
    } catch (error) {
      enqueueSnackbar({
        message: t('admin:billingSettings.errorMessage'),
        options: {
          variant: 'error'
        }
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <form autoComplete="off">
        <Heading
          title={t('admin:headings.billingSettingsTitle')}
          subTitle={t('admin:headings.billingSettingsDescription')}
          pageTitle={t('admin:headings.billingSettingsTitle')}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <DynamicForm
                inputs={
                  isUpdate
                    ? updateBillingConfigInputs
                    : createBillingConfigInputs
                }
              />

              <div className={classes.buttonContainer}>
                <Button
                  color="primary"
                  disabled={!dirty || invalid}
                  variant="contained"
                  onClick={openSubmitModal}
                >
                  {isUpdate ? (
                    <Trans i18nKey="admin:billingSettings.submitButtonForUpdate">
                      Update Billing Config
                    </Trans>
                  ) : (
                    <Trans i18nKey="admin:billingSettings.submitButtonForCreate">
                      Create Billing Config
                    </Trans>
                  )}
                </Button>

                <Button onClick={clearForm}>
                  <Trans i18nKey="admin:billingSettings.clearButton">
                    Reset Form
                  </Trans>
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </form>
      <AdminBillingSettingsSubmitModal
        isOpen={isSubmitModalOpen}
        onClose={closeSubmitModal}
        onSubmit={() => {
          handleSubmit(onSubmit)();
          closeSubmitModal();
        }}
        isUpdatingSecretKey={!!stripeSecretKeyFormValue}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <PrePaymentOrganization />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

// helper used in the graphql component configuration below to parse the
// existing billing config (if any) from the graphql query and create the
// component props from the respone
function mapBillingConfigToProps(billingConfiguration) {
  const loading = billingConfiguration?.loading;
  const refetch = billingConfiguration?.refetch;
  const billingConfig = cloneDeep(
    billingConfiguration?.myOrganization?.billingConfiguration
  );

  const isUpdate = !!billingConfig;

  const initialValues = isUpdate
    ? { ...billingConfig }
    : createBillingConfigInputs.reduce(
        (acc, input) => ({ ...acc, [input.name]: input.initialValue }),
        {}
      );

  return {
    isUpdate,
    initialValues,
    billingConfigData: {
      loading,
      refetch
    }
  };
}

function mapStateToProps(state) {
  const formValues = getFormValues(FORM_NAME)(state);

  return { stripeSecretKeyFormValue: formValues?.stripeKey };
}

export default flow(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(getBillingConfig, {
    name: 'billingConfiguration',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }),
    props: ({ billingConfiguration }) =>
      mapBillingConfigToProps(billingConfiguration)
  }),
  graphql(createBillingConfig, {
    name: 'createBillingConfig'
  }),
  graphql(updateBillingConfig, {
    name: 'updateBillingConfig'
  }),
  connect(mapStateToProps, { enqueueSnackbar }),
  withStyles(styles)
)(AdminBillingSettings);
