import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { FunctionComponent, MouseEvent, ReactElement, useState } from 'react';
import { t } from 'i18next';
import { Field, Form, reduxForm } from 'redux-form';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import HookFormWrapper from '../ReduxForm/DynamicForm/HookFormWrapper';

export const aiSuggestionReduxFormName = 'aiSuggestion';

const getText = () => ({
  header: t('aiSuggestion:header'),
  closeLabel: t('aiSuggestion:closeLabel'),
  applyButton: t('aiSuggestion:applyButton'),
  textFieldLabel: t('aiSuggestion:textFieldLabel'),
  openAiModal: t('aiSuggestion:openAiModal')
});

export interface AiSuggestionIconProps {
  onAiTextChange: (text: string) => void;
  initialValue: string;
  textField: {
    // These could be better typed, but that would require importing
    // the types from RenderTemplateStringTextField and RenderTextField
    // which would cause a cyclic dependency.
    // In the end these are really just pass-through props, so we don't really
    // care too much about their contents.
    props: object;
    render: FunctionComponent;
  };
  displayName: string | undefined;
  /**
   * The name of the field that this component should use.
   * Implementation note: DO NOT use this directly in AiSuggestionIcon.
   * Instead, prefer to use the fieldNameSanitized prop.
   */
  fieldName: string;
  parentFormName: string;
  parentFieldName: string;
  hookFormContext?: UseFormReturn<FieldValues, any, undefined>;
  change: (form: string, field: string, value: any) => void;
}

/**
 * Our internal props that include all the redux stuff.
 */
type AiSuggestionIconPropsWithRedux = AiSuggestionIconProps &
  InjectedFormProps & {
    /**
     * The field name sanitized for use in the redux form.
     */
    fieldNameSanitized: string;
  };

/**
 * Renders an icon button that creates a modal to generate AI suggestions
 * and stylization.
 * This wraps the provided textField component.
 *
 * Implementation note: This component usually involves a fair amount of
 * "recursion". The parent component probably passes itself in as the textField
 * and this component is rendered inside the parent component. This means that
 * some things may not work well, and you'll see notes about things like stopping
 * propagation of events to prevent bugs.
 */
const AiSuggestionIconInternal = ({
  fieldNameSanitized,
  textField,
  onAiTextChange,
  displayName,
  handleSubmit: reduxFormSubmit,
  parentFormName,
  parentFieldName,
  hookFormContext,
  change
}: AiSuggestionIconPropsWithRedux) => {
  const text = getText();
  // Split this text out into a separate string since we rely on a translation
  // to help fill in undefined values.
  const subHeaderText = t('aiSuggestion:subHeader', {
    fieldName: displayName || text.textFieldLabel
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isHookForm = !!hookFormContext;

  // This really should be typed with our redux form state data but...
  // I'll leave it as any for now since we aren't really doing much with it.
  const handleSubmit = (data: any) => {
    handleClose();

    const value = data[fieldNameSanitized];
    onAiTextChange(value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'ai-suggestion-popover' : undefined;
  return (
    <>
      <Tooltip title={t('aiSuggestion:tooltip')}>
        <IconButton
          aria-describedby={id}
          onClick={handleOpenPopover}
          aria-label={text.openAiModal}
        >
          <AutoFixHighIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // Similar to the stopPropagation in handleApply, if we let focus restore
        // back to the RenderTemplateStringTextField then DraftJS will overwrite
        // our changes.
        // This technically is a bit of a negative for accessibility, but it's
        // better than the alternative of DraftJS overwriting our changes.
        disableRestoreFocus
        transformOrigin={{
          horizontal: 'center',
          vertical: 'center'
        }}
        PaperProps={{
          sx: theme => ({
            width: '500px',
            [theme.breakpoints.down('sm')]: {
              width: '100%'
            },
            padding: theme.spacing(3)
          })
        }}
        sx={{
          backgroundColor: 'rgba(0,0,0,0.2)'
        }}
        // Without stopping propagation here the popover will refocus itself
        // and the TextField will be blurred, meaning you can't type
        onClick={event => event.stopPropagation()}
      >
        <Form
          onSubmit={(event: SubmitEvent) => {
            // Without stopping propagation here the parent form
            // aka most likely the program, will submit too!
            event.stopPropagation();

            if (isHookForm) {
              event.preventDefault();
              const data = hookFormContext?.watch();
              handleSubmit(data);
            } else {
              reduxFormSubmit(handleSubmit)(event);
            }
          }}
        >
          <Typography variant="h5">{text.header}</Typography>
          <IconButton
            onClick={handleClose}
            aria-label={text.closeLabel}
            sx={{
              position: 'absolute',
              top: theme => theme.spacing(1),
              right: theme => theme.spacing(1)
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="body2"
            sx={{ color: theme => theme.palette.text.secondary }}
          >
            {subHeaderText}
          </Typography>
          <Divider
            sx={{
              marginTop: theme => theme.spacing(2),
              marginBottom: theme => theme.spacing(2)
            }}
          />
          {isHookForm ? (
            <HookFormWrapper
              component={textField.render}
              name={fieldNameSanitized}
              extraProps={{
                parentFormName,
                formName: aiSuggestionReduxFormName,
                parentFieldName,
                ...textField.props
              }}
            />
          ) : (
            <Field
              component={textField.render}
              {...textField.props}
              formName={aiSuggestionReduxFormName}
              parentFormName={parentFormName}
              // Overwrite the provided field name and use our sanitized one
              // this way we're synced up with the rest of the redux code
              name={fieldNameSanitized}
              parentFieldName={parentFieldName}
              change={change}
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              data-cy="aiSuggestion-modal-apply"
            >
              {text.applyButton} <NavigateNextIcon />
            </Button>
          </div>
        </Form>
      </Popover>
    </>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
export const AiSuggestionIcon: (props: AiSuggestionIconProps) => ReactElement =
  flow(
    reduxForm({
      form: aiSuggestionReduxFormName,
      enableReinitialize: true
    }),
    connect<unknown, unknown, AiSuggestionIconProps, any>((state, ownProps) => {
      const fieldNameSanitized = ownProps.fieldName.replace('.', '_');
      return {
        fieldNameSanitized,
        initialValues: {
          // We need to carry over any existing initial values here.
          // Since this form is used in multiple inputs if we don't
          // carry over initial values it'll get wiped and only the last
          // will be in state.
          ...state?.form?.[aiSuggestionReduxFormName]?.initial,
          [fieldNameSanitized]: ownProps.initialValue
        }
      };
    })
  )(AiSuggestionIconInternal);
