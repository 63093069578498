import {
  createContext,
  cloneElement,
  useContext,
  ReactElement,
  Component
} from 'react';
import { useQuery } from '@apollo/client/';
import Loading from 'src/components/Loading';
import { getArchitectureById } from './queries';

export interface FieldMetadata {
  displayMethodId: string;
  displayMethod: {
    id: string;
    name: string;
  };
  displayParameters: Record<string, unknown> | null;
  displaySortOrder: number;
  fieldName: string;
  displayName: string;
  validationRules: string[];
  isRequired: boolean;
  isEditable: boolean;
  isHidden: boolean;
  contentColumnType: 'STRING' | 'NUMBER' | 'BOOLEAN' | 'DATE_TIME';
  isFilterable: boolean;
  enumValues: string[] | null;
}

export interface Catalog {
  id: string;
  contentSetId: string;
  defaultGroupKey: string;
  displayCollapseKey: string | null;
  displayNameTemplate: string | null;
  displayImageTemplate: string | null;
  friendlyName: string;
  fieldMetadata: FieldMetadata[];
}

/**
 * The actual architecture data consumers can use.
 */
export interface ArchitectureProviderType {
  id: string;
  name: string;
  catalog: Catalog;
  isContentSelectable: boolean;
  hasSupervisableOffer: boolean;
}

/**
 * The actual object stored in the context object.
 * This should be kept "private" to the architecture provider.
 * We'll unwrap it here and pass back the inner-inner architecture
 * object.
 */
interface ArchitectureContextType {
  architecture: any;
}

export const ArchitectureContext = createContext<ArchitectureContextType>(
  {} as ArchitectureContextType
);

/**
 * Uses architecture anywhere in the app. This means that it may be undefined!
 * A common example is using architecture in FormInput. That component is used
 * both in an architecture-context (ProgramCreate page) and in an architecture-less
 * context (BlueprintBuilder/admin forms).
 */
export const useArchitectureAnywhere = () => {
  const archContext = useContext(ArchitectureContext);
  return archContext?.architecture?.architecture;
};

/**
 * Uses architecture in a place where we know the architecture context will exist.
 */
export const useArchitecture = () => {
  const { architecture } = useContext(ArchitectureContext);
  return architecture?.architecture;
};

export const withArchitecture = (component: typeof Component) => {
  const Component = component;

  return (props: object) => {
    const { architecture } = useContext(ArchitectureContext);

    return (
      <Component
        {...props}
        architecture={architecture?.architecture}
        architectureId={architecture?.architecture?.id}
      />
    );
  };
};

interface ArchitectureProviderProps {
  children: ReactElement;
  params: {
    architectureId: string;
  };
}

const ArchitectureProvider = ({
  children,
  params
}: ArchitectureProviderProps) => {
  const { loading, data: architecture } = useQuery(getArchitectureById, {
    skip: !params?.architectureId,
    fetchPolicy: 'no-cache',
    variables: {
      architectureId: params?.architectureId
    }
  });
  if (loading) {
    return <Loading />;
  }

  const context = {
    architecture
  };

  return (
    <ArchitectureContext.Provider value={context}>
      {cloneElement(children, {
        context
      })}
    </ArchitectureContext.Provider>
  );
};

export default ArchitectureProvider;
