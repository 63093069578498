import { useRef, useEffect, useMemo, useCallback } from 'react';
import { flow, isString, find, get } from 'lodash';
import {
  Field,
  change,
  registerField,
  getFormValues,
  blur,
  touch
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import numeral from 'numeral';
import { graphql } from '@apollo/client/react/hoc';

import {
  Grid,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  Box,
  Tooltip
} from '@mui/material';
import { HelpOutlineOutlined as HelpIcon } from '@mui/icons-material';

import { facebookCreativeTypes } from 'src/common/adChannels';
import { getTemplateData } from 'src/common/blueprints';
import { formatDailySpend, formatPrice } from 'src/common/numbers';
import { dayjs } from 'src/common/dates';
import {
  getChangeFormValue,
  getRegisterField
} from 'src/common/utilities/inputConversionHelpers';

import {
  PROGRAM_FORM_NAME,
  scheduleTypes,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';
import { EDIT_PROGRAM_FORM_NAME } from 'src/pages/ProgramPerformance/Constants';

import RenderTemplateField from 'src/components/ReduxForm/RenderTemplateField';
import { RenderSlider } from 'src/components/ReduxForm';
import Loading from 'src/components/Loading';
import { useIsProgramOrAutomationPage } from 'src/routes/useIsProgramOrAutomationPage';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import SubscriptionSelector from './SubscriptionSelector';
import { computeMinimumSpend } from './queries';
import { getMinSpendInput, validateSpend } from './utilites';
import OneTimeSpendMessage from './OneTimeSpendMessage';
import { usePerformancePredictions } from './hooks/usePerformancePredictions';
import { SPEND_VALIDATION_MESSAGES } from './constants';

/*
  ! Attention: The use of `!important` seems rampant in this component. This is because
  ! of the need to override Grid item padding discretely, so that the padding added by the `spacing`
  ! prop in the container only adds space between columns and rows and not add spacing
  ! (padding) on the perimeter of the left and top-most Grid items.

  When this component is cleaned up, post-experiemnt, I think it could make sense to convert
  the use of the Mui Grid system to Box with flex styling. I chose to stick with Grid because
  it's what the control treatment is using.
*/

// TODO: I just made these numbers up
// these are just fallbacks in case the min and max is not defined
// prices are in dollars
const MIN_PRICE = 25;
const MAX_PRICE = 15000;
const PRICE_STEPS = 25;
// rough calculation of our default slider steps on this page
// may change drastically but just used for dividing the rounding nicely
const NUM_OF_SLIDER_STEPS = 300;

const getText = ({ renewsOn }) => ({
  spendError: t('programCreate:spend.spendFieldError'),
  budgetLabel: t('programCreate:spend.budgetLabel'),
  budgetLabelMultiLocation: t('programCreate:spend.budgetLabelMultiLocation'),
  editSpendHeader: t('programEdit:spend.editHeader'),
  scheduleEditSpendHeader: t('programEdit:spend.editHeaderScheduled', {
    renewsOn
  }),
  invalidEndDate: t('programEdit:spend.invalidBudgetIncreaseEndDate'),
  subscriptionPendingChangeDisabled: t(
    'programEdit:spend.subscriptionPendingTip'
  ),
  purchasePendingChangeDisabled: t('programEdit:spend.purchasePendingTip')
});

const SpendSelector = props => {
  const {
    isAutomated,
    paymentPlans: { purchaseOffers, subscriptionOffers },
    registerField: registerFieldRedux,
    change: reduxChange,
    classes,
    minDailySpend,
    minDailySpendLoading,
    setHasMinSpendError,
    isEdit,
    totalMinDailySpendInflated,
    setMinSpendLoading,
    architecture,
    formValues,
    offerId,
    orderItemId,
    formName,
    subscriptionTierChangeRenewalDate,
    currentEndDate,
    currentStartDate,
    currentSpend,
    orderIsPending,
    isHookForm,
    hookFormContext,
    selectedBlueprint,
    isMultiLocation = false,
    // For hook form only!
    formSectionName = PROGRAM_FORM_SECTION_SPEND_NAME
  } = props;
  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  const registerField = getRegisterField({
    registerFieldRedux,
    hookRegister: hookFormContext?.register
  });

  const hookFormValues = hookFormContext?.watch();
  const hookFormSpendValues = get(hookFormValues, formSectionName);

  const scheduleType = isHookForm
    ? hookFormValues?.spendStep?.scheduleType
    : formValues?.spendStep?.scheduleType;
  const isSubscription = scheduleType === scheduleTypes.subscription.value;
  const today = dayjs();
  const startDate = isHookForm
    ? hookFormSpendValues?.startDate
    : formValues?.spendStep?.startDate;
  const endDate = isHookForm
    ? hookFormSpendValues?.endDate
    : formValues?.spendStep?.endDate;
  const scheduleDays = isHookForm
    ? hookFormSpendValues?.scheduleDays
    : formValues?.spendStep?.scheduleDays;
  const oneTimeSpend = isHookForm
    ? hookFormSpendValues?.oneTimeSpend
    : formValues?.spendStep?.oneTimeSpend;
  const billingMethod = isHookForm
    ? hookFormSpendValues?.billingMethod
    : formValues?.spendStep?.billingMethod;

  const minDays =
    getTemplateData(selectedBlueprint)?.creativeType ===
    facebookCreativeTypes.dynamicAdCreative
      ? 7 // DARE min 7 days
      : 2;

  const selectedPurchaseOffers = find(purchaseOffers, {
    billingMethod
  });

  const min = selectedPurchaseOffers?.purchasePriceUserSetMin || MIN_PRICE;
  const max = selectedPurchaseOffers?.purchasePriceUserSetMax || MAX_PRICE;

  // we cannot edit spend if we are within 24 hours of the end date
  const spendDisabled = !!(
    isEdit && dayjs(endDate || currentEndDate).diff(today, 'hours') < 24
  );

  const isProgramCreatePage = useIsProgramOrAutomationPage();
  const theme = useTheme();
  const text = getText({ renewsOn: subscriptionTierChangeRenewalDate });

  const gridContainerDesktopSpacing = isProgramCreatePage ? 5 : 3;

  const minSpendInput = useMemo(
    () =>
      getMinSpendInput({
        architecture,
        formValues,
        endDate,
        startDate,
        isEdit,
        orderItemId,
        offerId
      }),
    [architecture, formValues, endDate, startDate, isEdit, orderItemId, offerId]
  );

  const {
    loading: performancePredictionLoading,
    data: performancePredictions,
    isTreatment: isPredictionTreatment,
    error: performancePredictionError
  } = usePerformancePredictions(oneTimeSpend, minSpendInput, isSubscription);

  // totalMinDailySpendInflated takes into account spend that has already happened in the edit case
  const minDailySpendAmount = isEdit
    ? totalMinDailySpendInflated
    : minDailySpend;

  const { dailySpend, message } = useMemo(
    () =>
      validateSpend({
        spend: oneTimeSpend,
        startDate,
        endDate,
        minDays,
        min,
        max,
        isAutomated,
        scheduleDays,
        minDailySpendAmount,
        performancePredictions,
        isPredictionTreatment,
        performancePredictionError,
        isEdit,
        currentEndDate,
        currentSpend,
        currentStartDate
      }),
    [
      oneTimeSpend,
      startDate,
      endDate,
      minDays,
      min,
      max,
      isAutomated,
      scheduleDays,
      minDailySpendAmount,
      performancePredictions,
      isPredictionTreatment,
      performancePredictionError,
      isEdit,
      currentEndDate,
      currentSpend,
      currentStartDate
    ]
  );

  const spendFieldValidator = useRef();

  const roundingDivisor = useMemo(() => {
    const steps = [1, 5, 10, 25, 50, 100];
    const fullRange = max - min;
    const minStep = fullRange / NUM_OF_SLIDER_STEPS;
    return find(steps, (val, i) => {
      // always return the biggest if none other are selected
      if (i === steps.length - 1) {
        return true;
      }
      if (minStep < val) {
        return true;
      }
      return false;
    });
  }, [max, min]);

  const roundingFunction = useCallback(
    value => {
      const divisor = roundingDivisor;

      // allows lowest values to be unrounded eg: $99
      if (value <= min + Math.floor(divisor / 2)) {
        return value;
      }

      // closest divisor to value
      const remainder = value % divisor;
      return remainder <= Math.floor(divisor / 2)
        ? value - remainder
        : value + divisor - remainder;
    },
    [min, max, roundingDivisor]
  );

  /*
    If there is no oneTimeSpend form value and the order is not a
    subscription, set the oneTimeSpend form value to a default minimum.
    This is for when the SpendSelector is used in the ProgramStepSpend
    step of ProgramCreate.
  */
  useEffect(() => {
    if (!oneTimeSpend && !isSubscription) {
      const fieldName = `${formSectionName}.oneTimeSpend`;
      registerField(
        isEdit ? EDIT_PROGRAM_FORM_NAME : PROGRAM_FORM_NAME,
        fieldName,
        'Field'
      );

      change(
        isEdit ? EDIT_PROGRAM_FORM_NAME : PROGRAM_FORM_NAME,
        fieldName,
        selectedPurchaseOffers?.purchasePriceAmount || min
      );
    }
  }, [
    isSubscription,
    min,
    oneTimeSpend,
    change,
    registerField,
    selectedPurchaseOffers
  ]);

  useEffect(() => {
    // any time these requirements change update the spend field validator
    // otherwise use cached version
    spendFieldValidator.current = value => {
      const { validationFail, message } = validateSpend({
        spend: value,
        startDate,
        endDate,
        minDays,
        min,
        max,
        isAutomated,
        scheduleDays,
        minDailySpendAmount,
        performancePredictions,
        isPredictionTreatment,
        performancePredictionError,
        isEdit,
        currentEndDate,
        currentSpend,
        currentStartDate
      });

      if (
        validationFail &&
        message === SPEND_VALIDATION_MESSAGES.increaseEndDate
      ) {
        return text.invalidEndDate;
      }

      if (validationFail) {
        return text.spendError;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneTimeSpend,
    startDate,
    endDate,
    minDays,
    min,
    max,
    isAutomated,
    scheduleDays,
    minDailySpendAmount,
    performancePredictions,
    isPredictionTreatment,
    performancePredictionError,
    isEdit,
    currentEndDate,
    currentSpend,
    currentStartDate
  ]);

  useEffect(() => {
    if (setMinSpendLoading) {
      setMinSpendLoading(minDailySpendLoading);
    }
  }, [minDailySpendLoading]);

  const marks = [
    {
      value: min,
      label: formatPrice(min)
    },
    {
      value: max,
      label: formatPrice(max)
    }
  ];

  const performancePredictionGridSmall = isProgramCreatePage ? null : 6;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let oneTimeSpendLabel = isProgramCreatePage ? text.budgetLabel : '';
  if (isMultiLocation) {
    oneTimeSpendLabel = text.budgetLabelMultiLocation;
  }

  const oneTimeSpendTextProps = {
    name: 'oneTimeSpend',
    formNamespace: formSectionName,
    label: oneTimeSpendLabel,
    component: RenderTemplateField,
    validate: isHookForm
      ? {
          ...(spendFieldValidator.current && {
            spendValidation: spendFieldValidator.current
          })
        }
      : [...(spendFieldValidator.current ? [spendFieldValidator.current] : [])],

    disabled: spendDisabled || orderIsPending,
    extraProps: {
      startAdornment: (
        <span
          style={{
            position: 'relative',
            top: 1,
            ...(isProgramCreatePage
              ? {
                  color: theme.palette.text.primary,
                  fontSize: '1.25rem'
                }
              : {})
          }}
        >
          $
        </span>
      ),
      normalize: value => numeral(value).value(),
      sx: {
        padding: '12.5px 10px'
      },
      inputProps: {
        thousandSeparator: true,
        ...(isProgramCreatePage
          ? { fixedDecimalScale: true, decimalScale: 2 }
          : {}),
        sx: {
          padding: '0',
          fontSize: '3.75rem',
          ...(isProgramCreatePage
            ? {
                fontSize: '1.25rem'
              }
            : {})
        }
      }
    }
  };

  const oneTimeSpendSliderProps = {
    name: 'oneTimeSpend',
    formNamespace: formSectionName,
    component: RenderSlider,
    autoComplete: 'completely-off',
    disabled: spendDisabled || orderIsPending,
    // adding this validation specifically for hook form the validations don't work without it
    validate: isHookForm
      ? {
          ...(spendFieldValidator.current && {
            spendValidation: spendFieldValidator.current
          })
        }
      : [],

    extraProps: {
      roundingFunction,
      min,
      max,
      marks,
      step: PRICE_STEPS,
      sx: isProgramCreatePage && {
        margin: 0,
        '& .MuiSlider-markLabel[data-index="0"]': {
          left: '10px !important'
        },
        [theme.breakpoints.down('sm')]: {
          '& .MuiSlider-markLabel[data-index="1"]': {
            right: '-20px !important',
            left: 'unset !important'
          }
        }
      }
    }
  };

  return (
    <>
      {/* This header should only display when editing a program, not an automation */}
      {isEdit && !isAutomated && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2">
              {subscriptionTierChangeRenewalDate
                ? text.scheduleEditSpendHeader
                : text.editSpendHeader}
            </Typography>
            {orderIsPending && (
              <Tooltip
                title={
                  isSubscription
                    ? text.subscriptionPendingChangeDisabled
                    : text.purchasePendingChangeDisabled
                }
              >
                <HelpIcon
                  fontSize="inherit"
                  sx={{
                    color: 'grey.500',
                    position: 'relative'
                  }}
                />
              </Tooltip>
            )}
          </Box>
          <Divider />
        </>
      )}
      <Grid
        sx={{
          margin: 0,
          width: '100%'
        }}
        container
        spacing={isMobile ? 1 : gridContainerDesktopSpacing}
      >
        {isSubscription && (
          <Grid
            item
            xs={12}
            sx={
              isProgramCreatePage
                ? { pl: '0 !important', pt: '0 !important' }
                : {
                    [theme.breakpoints.down('md')]: {
                      paddingLeft: '0 !important'
                    }
                  }
            }
          >
            <SubscriptionSelector
              subscriptionOffers={subscriptionOffers}
              billingMethod={billingMethod}
              formName={formName}
              disabled={orderIsPending}
              hookFormContext={hookFormContext}
              isHookForm={isHookForm}
              formNamespace={formSectionName}
            />
          </Grid>
        )}
        {!isSubscription && (
          <>
            <Grid
              item
              xs={12}
              sm={isProgramCreatePage ? 3 : 6}
              sx={
                isProgramCreatePage && {
                  pl: '0 !important',
                  pt: '0 !important'
                }
              }
            >
              {isHookForm ? (
                <HookFormWrapper
                  {...oneTimeSpendTextProps}
                  validateBackendOnly={false}
                />
              ) : (
                <Field
                  {...oneTimeSpendTextProps}
                  {...oneTimeSpendTextProps.extraProps}
                />
              )}
              <Box
                sx={
                  isProgramCreatePage
                    ? {
                        pt: 1,
                        pb: 2
                      }
                    : {}
                }
              >
                {isHookForm ? (
                  <HookFormWrapper
                    {...oneTimeSpendSliderProps}
                    validateBackendOnly={false}
                  />
                ) : (
                  <Field
                    {...oneTimeSpendSliderProps}
                    {...oneTimeSpendSliderProps.extraProps}
                  />
                )}
              </Box>
            </Grid>
          </>
        )}

        {!isSubscription && (
          <Grid
            item
            xs={isMobile ? 12 : 9}
            sm={performancePredictionGridSmall}
            sx={
              isProgramCreatePage
                ? {
                    pt: `11px !important`,
                    pl: isMobile && '0 !important'
                  }
                : {
                    [theme.breakpoints.down('md')]: {
                      paddingLeft: '0 !important'
                    }
                  }
            }
          >
            {minDailySpendLoading ? (
              <Loading />
            ) : (
              <>
                <Typography variant={isProgramCreatePage ? 'body1' : 'h5'}>
                  <Trans
                    i18nKey="programCreate:spend.dailySpend"
                    values={{
                      amount: isString(dailySpend)
                        ? dailySpend
                        : formatDailySpend(dailySpend)
                    }}
                  />
                </Typography>
                {performancePredictionLoading ? (
                  <Loading />
                ) : (
                  <OneTimeSpendMessage
                    performancePredictions={performancePredictions}
                    message={message}
                    classes={classes}
                    min={min}
                    max={max}
                    minDays={minDays}
                    currentEndDate={currentEndDate}
                    setHasMinSpendError={setHasMinSpendError}
                  />
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state, { isEdit, isHookForm, hookFormContext }) => {
  const reduxFormValues = getFormValues(
    isEdit ? EDIT_PROGRAM_FORM_NAME : PROGRAM_FORM_NAME
  )(state);

  const formValues = isHookForm ? hookFormContext?.watch() : reduxFormValues;

  return {
    formValues
  };
};

export default flow(
  graphql(computeMinimumSpend, {
    name: 'computeMinimumSpend',
    options: ({
      architecture,
      formValues,
      endDate,
      startDate,
      isEdit,
      orderItemId,
      offerId,
      currentStartDate,
      currentEndDate
    }) => {
      const minSpendInput = getMinSpendInput({
        architecture,
        formValues,
        endDate: formValues?.spendStep?.endDate || endDate,
        startDate: formValues?.spendStep?.startDate || startDate,
        isEdit,
        orderItemId,
        offerId,
        currentStartDate,
        currentEndDate
      });

      return {
        variables: {
          minSpendInput
        }
      };
    },
    props: ({ computeMinimumSpend }) => {
      return {
        minDailySpendLoading: computeMinimumSpend?.loading,
        minDailySpend: computeMinimumSpend?.computeMinimumSpend?.minDailySpend,
        totalMinDailySpendInflated:
          computeMinimumSpend?.computeMinimumSpend?.totalMinDailySpendInflated
      };
    }
  }),
  connect(mapStateToProps, {
    registerField,
    change,
    blur,
    touch
  })
)(SpendSelector);
