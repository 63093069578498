import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { flow, isString, pick, sortBy, pickBy, differenceWith } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Table from 'src/components/Table';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';

import { FORM_STATES, getFieldMetaFields } from './constants';
import FieldMetaForm from './FieldMetaForm';
import { createFieldMetadata, updateFieldMetadata } from './mutations';

const styles = () => ({});

const FieldMetaList = ({
  catalog,
  contentTableColumns,
  refetch,
  createFieldMetadata,
  updateFieldMetadata,
  enqueueSnackbar
}) => {
  const [editState, setEditState] = useState(FORM_STATES.closed);
  const [initialValues, setinitialValues] = useState({});

  const { fieldMetadata = [] } = catalog;

  const handleRowClick = useCallback(row => {
    setinitialValues(row);
    setEditState(FORM_STATES.edit);
  });

  const handleNewClick = useCallback(() => {
    setinitialValues(
      getInitialValuesFromInputsConfig(
        getFieldMetaFields({
          formState: FORM_STATES.new,
          numExistingColumns: fieldMetadata?.length
        })
      )
    );
    setEditState(FORM_STATES.new);
  });

  const handleCloseModal = useCallback(() => {
    setinitialValues({}); // reset
    setEditState(FORM_STATES.closed);
  });

  const successModal = () => {
    enqueueSnackbar({
      message: t('admin:catalogContent.fieldMetaFormSuccess'),
      options: {
        variant: 'success'
      }
    });
  };
  const errorModal = () => {
    enqueueSnackbar({
      message: t('admin:catalogContent.fieldMetaFormError'),
      options: {
        variant: 'error'
      }
    });
  };

  const handleSaveMeta = useCallback(
    async data => {
      // allowed inputs
      const inputs = getFieldMetaFields({
        formState: editState,
        numExistingColumns: fieldMetadata?.length
      });
      // only send fields that are in the api
      const onlyAPIFields = pick(
        data,
        inputs.map(f => f.name)
      );

      if (editState === FORM_STATES.edit) {
        // only send data that has changed from initialValues
        const onlyChanged = pickBy(onlyAPIFields, (val, key) => {
          if (isString(val)) {
            return val !== initialValues?.[key];
          }
          // non strings just always send?
          // TODO:
          // we could also do a deep compare here "messy"
          // this is arrays and nestted objects
          return true;
        });

        // required field
        onlyChanged.originalFieldName = initialValues.fieldName;
        if (onlyChanged.fieldName) {
          // if they chagned the field name
          onlyChanged.updatedFieldName = onlyChanged.fieldName;
        }
        delete onlyChanged.fieldName;

        try {
          await updateFieldMetadata({
            variables: {
              catalogId: catalog.id,
              input: [onlyChanged]
            }
          });
          successModal();
        } catch (e) {
          errorModal();
        }
      } else {
        // create state
        try {
          await createFieldMetadata({
            variables: {
              catalogId: catalog.id,
              input: [onlyAPIFields]
            }
          });
          successModal();
        } catch (e) {
          errorModal();
        }
      }

      // then always
      handleCloseModal();
      refetch();
    },
    [refetch, editState]
  );

  const columnSchema = [
    {
      key: 'editme',
      columnName: '',
      accessor: 'editme',
      CellComponent: () => (
        <div>
          <EditIcon />
        </div>
      )
    },
    {
      columnName: 'fieldName',
      accessor: 'fieldName',
      type: 'STRING'
    },
    {
      columnName: 'displayName',
      accessor: 'displayName',
      type: 'STRING'
    },
    {
      columnName: 'id',
      accessor: 'id',
      type: 'ID'
    },
    {
      columnName: 'contentColumnType',
      accessor: 'contentColumnType',
      type: 'STRING'
    },
    {
      columnName: 'displayMethodId',
      accessor: 'displayMethodId',
      type: 'STRING'
    },
    {
      columnName: 'Display Method',
      accessor: 'displayMethod.name',
      type: 'STRING'
    },
    {
      columnName: 'includeInLeadNotifications',
      accessor: 'includeInLeadNotifications',
      type: 'boolean'
    },
    {
      columnName: 'filterColumn',
      accessor: 'filterColumn',
      type: 'STRING'
    },
    {
      columnName: 'isFilterable',
      accessor: 'isFilterable',
      type: 'boolean'
    },
    {
      columnName: 'isHidden',
      accessor: 'isHidden',
      type: 'boolean'
    },
    {
      columnName: 'isRequired',
      accessor: 'isRequired',
      type: 'boolean'
    },
    {
      columnName: 'Display Parameters',
      accessor: 'displayParameters',
      type: 'STRING',
      CellComponent: ({ data }) => {
        return <div>{JSON.stringify(data)}</div>;
      }
    },
    {
      columnName: 'Enum Values',
      accessor: 'enumValues',
      type: 'STRING',
      CellComponent: ({ data }) => {
        return <div>{data ? `${data.length}` : '-'}</div>;
      }
    },
    // we don't actually use validation rules so might as well not expose them
    // {
    //     columnName: 'validationRules',
    //     accessor: 'validationRules',
    //     type: 'STRING',
    //     CellComponent: ({ data }) => {
    //         return <div>TBD</div>;
    //     }
    // },
    {
      columnName: 'displaySortOrder',
      accessor: 'displaySortOrder',
      type: 'STRING'
    }
  ];

  const filteredContentTableColumns = differenceWith(
    contentTableColumns,
    fieldMetadata,
    (a, b) => {
      return a === b.fieldName;
    }
  );

  return (
    <div>
      <Table
        columnSchema={columnSchema}
        rows={sortBy(fieldMetadata, 'displaySortOrder')}
        onClickBodyRow={handleRowClick}
        loading={false}
      />
      <br />
      {filteredContentTableColumns.length > 0 && (
        <Button
          onClick={handleNewClick}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          {t('admin:catalogContent.fieldMetaFormAddColumn')}
        </Button>
      )}
      {editState !== FORM_STATES.closed && (
        <FieldMetaForm
          form={`catalog${catalog.id}fieldMeta${
            initialValues?.id ?? ''
          }${editState}`}
          onSubmit={handleSaveMeta}
          formState={editState}
          handleCloseModal={handleCloseModal}
          initialValues={initialValues}
          contentTableColumns={filteredContentTableColumns}
        />
      )}
    </div>
  );
};

export default flow(
  connect(null, { enqueueSnackbar }),
  graphql(createFieldMetadata, {
    name: 'createFieldMetadata'
  }),
  graphql(updateFieldMetadata, {
    name: 'updateFieldMetadata'
  }),
  withStyles(styles)
)(FieldMetaList);
