import { flow, find, head } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Divider, Grid, Paper, Typography, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { facebookCreativeTypes } from 'src/common/adChannels';

import { contentSelectable, getTemplateData } from 'src/common/blueprints';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';

import AdsList from 'src/pages/ProgramPerformance/AdsList';

import PageTitle from 'src/components/PageTitle';
import Loading from 'src/components/Loading';

import { getLeads, getProgram, getArchitectureById } from './queries';
import LeadQuestions from './LeadQuestions';
import LeadContact from './LeadContact';
import LeadOrderDetails from './LeadOrderDetails';

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2)} 0`
  }
});

const phoneRegExp = /phone/;

const Lead = props => {
  const { classes, history, lead, program, architecture } = props;

  const navigatetoProgram = () => {
    history.push(
      generateLinkPath(paths.architecture.program, {
        architectureId: program?.order?.architectureId,
        orderId: program?.order?.id
      })
    );
  };

  const pageTitle = t('lead:header.title');

  const isContentSelectable = contentSelectable(
    architecture?.data,
    program?.order?.orderItem?.product
  );

  // Lead data
  const leadFields = lead?.data?.fields;
  const leadLoading = lead?.loading;
  const leadError = lead?.error ? (
    <Trans i18nKey="lead:error.noLead">
      There was an error getting your lead.
    </Trans>
  ) : null;
  const firstName = leadFields?.first_name || '';
  const lastName = leadFields?.last_name || '';

  const blueprint = program?.order?.orderItem?.product?.blueprint;
  const creativeType = blueprint?.publishers[0]?.prototype?.code;

  const templateData = getTemplateData({
    blueprint
  });
  const channel = templateData?.channel;

  const contentFriendlyName =
    architecture?.data?.catalog?.friendlyName || 'Content';
  const isDynamicAdCreative =
    creativeType === facebookCreativeTypes?.dynamicAdCreative;

  const adsLoading = program?.loading || architecture?.loading;
  const adsError =
    program?.error || architecture?.error ? (
      <Trans i18nKey="lead:error.noAds">
        There was an error getting your ads.
      </Trans>
    ) : null;

  // Order data
  const order = program?.order;
  const orderLoading = program?.loading;
  const orderError = program?.error ? (
    <Trans i18nKey="lead:error.order">
      There was an error getting your order.
    </Trans>
  ) : null;

  return (
    <>
      <div className={classes.header}>
        <PageTitle subPageTitle={pageTitle} />
        <Typography variant="h5">{pageTitle}</Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <>
              {leadLoading ||
                (leadError && (
                  <Loading error={leadError} errorMessage={leadError} />
                ))}
              <div className={classes.nameContainer}>
                <Typography className={classes.name} variant="h4">
                  {`${firstName} ${lastName}`}
                </Typography>

                <Button variant="outlined" onClick={navigatetoProgram}>
                  <Trans i18nKey="lead:button.viewProgram">View Program</Trans>
                </Button>
              </div>
              <Divider />

              <Grid container spacing={2}>
                <Grid item>
                  <LeadContact
                    date={lead?.data?.date}
                    leadFields={leadFields}
                  />
                </Grid>
                <Grid item>
                  <LeadQuestions leadFields={leadFields} />
                </Grid>
              </Grid>
            </>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <>
              {(orderLoading || orderError) && (
                <Loading error={orderError} errorMessage={orderError} />
              )}
              <LeadOrderDetails
                architecture={architecture?.data}
                contentFriendlyName={contentFriendlyName}
                isDynamicAdCreative={isDynamicAdCreative}
                order={order}
                isContentSelectable={isContentSelectable}
              />
            </>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <>
            {(adsLoading || adsError) && (
              <Loading error={adsError} errorMessage={adsError} />
            )}
            <AdsList
              architecture={architecture?.data}
              program={order}
              contentFriendlyName={contentFriendlyName}
              channel={channel}
            />
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default flow(
  graphql(getArchitectureById, {
    name: 'getArchitectureById',
    skip: ({ lead }) => {
      // only make query if we have architectureId and orderId
      const loading = lead?.loading;
      const hasArchitectureId = lead?.data?.architectureId || false;
      const hasOrderId = lead?.data?.orderId || false;

      return !!loading || !hasArchitectureId || !hasOrderId;
    },
    props: ({ getArchitectureById: { error, loading, architecture } }) => {
      return {
        architecture: {
          data: architecture,
          loading,
          error
        }
      };
    },
    options: ({ lead }) => ({
      fetchPolicy: 'no-cache',
      variables: {
        architectureId: lead?.data?.architectureId
      }
    })
  }),
  graphql(getProgram, {
    name: 'program',
    skip: ({ lead }) => {
      // only make query if we have architectureId and orderId
      const loading = lead?.loading;
      const hasArchitectureId = lead?.data?.architectureId || false;
      const hasOrderId = lead?.data?.orderId || false;

      return !!loading || !hasArchitectureId || !hasOrderId;
    },
    options: ({ lead }) => {
      return {
        variables: {
          orderId: lead?.data?.orderId
        },
        fetchPolicy: 'no-cache'
      };
    }
  }),
  graphql(getLeads, {
    name: 'userLeads',
    // TODO: Update to pass filer for leadId once BE has added the filter
    // options: ({ match }) => ({
    //     variables: {
    //         leadIds: [get(match, 'params.leadId')]
    //     }]
    // }),
    props: ({ userLeads, ownProps }) => {
      const { userLeads: leads, error, loading } = userLeads;

      const leadId = ownProps?.match?.params?.leadId;
      const allLeads = leads?.edges || [];
      const filteredLead = find(allLeads, { node: { id: leadId } })?.node;

      const lead = !!filteredLead && {
        id: filteredLead.id,
        key: filteredLead.id,
        architectureId: filteredLead.architectureId,
        programId: filteredLead.programId,
        orderId: filteredLead.orderId,
        date: filteredLead.submittedAt,
        fields: filteredLead.fields.reduce((prev, next) => {
          if (phoneRegExp.test(next.name)) {
            // normalize phone || phone_number to phoneNumber
            return {
              ...prev,
              phoneNumber: head(next.values)
            };
          }
          return {
            ...prev,
            [next.name]: head(next.values)
          };
        }, {})
      };

      return {
        lead: {
          data: lead,
          error,
          loading
        }
      };
    }
  }),
  withRouter,
  withStyles(styles)
)(Lead);
