import { useCallback, useMemo } from 'react';
import { flow } from 'lodash';

import { withRouter } from 'react-router-dom';

import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { Button } from '@mui/material';

import Table from 'src/components/Table';
import Heading from 'src/components/PageElements/Heading';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/Constants';

import Loading from 'src/components/Loading';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import { CREATE_NEW_KEY } from './constants';
import { getContentTables } from './queries';

const pageText = () => ({
  pageTitle: t('admin:contentTable.pageTitle'),
  pageHeading: t('admin:contentTable.pageHeading'),
  pageSubheading: t('admin:contentTable.pageSubheading'),
  createNew: t('admin:contentTable.createNew'),
  error: t('admin:contentTable.loadingError')
});

const columnSchema = [
  {
    columnName: 'Content Table Name (id)',
    accessor: 'contentTable',
    key: 'name',
    type: 'String'
  },
  {
    columnName: 'Latest Version',
    accessor: 'versionTimestamp',
    key: 'versionTimestamp',
    type: INPUT_TYPES.DATE_UTC
  },
  {
    columnName: 'Status',
    accessor: 'status',
    key: 'status',
    type: 'String'
  }
];

const ContentTablesLanding = props => {
  const { loading, error, content, history } = props;
  const text = useMemo(pageText, []);

  const handleRowClick = useCallback(
    ({ contentTable }) => {
      const linkPath = generateLinkPath(paths.admin.settings.contentTables, {
        contentTable
      });

      return history.push(linkPath);
    },
    [history]
  );

  if (loading || error) {
    return <Loading error={error} errorMessage={text.error} />;
  }

  return (
    <div>
      <Heading
        title={text.pageHeading}
        subTitle={text.pageSubheading}
        pageTitle={text.pageTitle}
        divider
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleRowClick({ contentTable: CREATE_NEW_KEY });
        }}
      >
        {text.createNew}
      </Button>
      {content.length > 0 && (
        <Table
          rows={content}
          columnSchema={columnSchema}
          loading={false}
          onClickBodyRow={handleRowClick}
        />
      )}
    </div>
  );
};

const mapDocumentsToProps = ({ getContentTables }) => {
  const content = getContentTables?.contentDocumentVersions ?? [];
  return {
    content,
    loading: getContentTables.loading,
    error: getContentTables.error
  };
};

export default flow(
  graphql(getContentTables, {
    name: 'getContentTables',
    options: () => {
      return { fetchPolicy: 'no-cache' };
    },
    props: mapDocumentsToProps
  }),
  withRouter
)(ContentTablesLanding);
