import { flow, isString } from 'lodash';
import { FormControl, TextField, FormHelperText } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  input: {},
  error: {
    color: theme.palette.error.main
  }
});

const RenderFilterValue = ({
  classes,
  input: { value, onChange },
  meta: { error }
}) => {
  const showError = error;
  if (error && !isString(error)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }
  return (
    <FormControl error={showError} className={classes.input}>
      <TextField
        value={value}
        onChange={onChange}
        error={showError}
        placeholder="Enter Value"
        variant="standard"
      />
      {showError && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default flow(withStyles(styles))(RenderFilterValue);
