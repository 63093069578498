import { Field } from 'redux-form';
import { t } from 'i18next';
import numeral from 'numeral';

import { Grid, useTheme, Box } from '@mui/material';

import { RenderSlider, RenderTemplateField } from 'src/components/ReduxForm';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import { AUTOMATED_MIN_DAYS } from '../Constants';

interface DaySelectorProps {
  isHookForm: boolean;
  formSectionName: string;
}

const pageText = () => ({
  header: t('programCreate:daysSelect.header'),
  days: t('programCreate:daysSelect.days'),
  label: t('programCreate:daysSelect.label')
});

const DaysSelector = ({ isHookForm, formSectionName }: DaySelectorProps) => {
  const theme = useTheme();
  const text = pageText();

  const scheduleDaysProps = {
    name: 'scheduleDays',
    label: text.label,
    component: RenderTemplateField,
    formNamespace: formSectionName,
    extraProps: {
      sx: {
        padding: '12.5px 10px'
      },
      inputProps: {
        thousandSeparator: true,
        fixedDecimalScale: true,
        decimalScale: 0,
        sx: {
          padding: '0',
          fontSize: '1.25rem'
        }
      },
      normalize: (value: string | number) => numeral(value).value()
    }
  };

  const scheduleDaysSliderProps = {
    component: RenderSlider,
    name: 'scheduleDays',
    formNamespace: formSectionName,

    extraProps: {
      sx: {
        margin: 0,
        '& .MuiSlider-markLabel[data-index="0"]': {
          left: '10px !important'
        },
        [theme.breakpoints.down('sm')]: {
          '& .MuiSlider-markLabel[data-index="1"]': {
            right: '-20px !important',
            left: 'unset !important'
          }
        }
      },
      min: AUTOMATED_MIN_DAYS,
      max: 100,
      step: 1
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={3}
      sx={{
        pl: '0 !important',
        pt: '0 !important'
      }}
    >
      {isHookForm ? (
        <HookFormWrapper {...scheduleDaysProps} validateBackendOnly={false} />
      ) : (
        <Field {...scheduleDaysProps} {...scheduleDaysProps.extraProps} />
      )}

      <Box
        sx={{
          pt: 1,
          pb: 2
        }}
      >
        {isHookForm ? (
          <HookFormWrapper
            {...scheduleDaysSliderProps}
            validateBackendOnly={false}
          />
        ) : (
          <Field
            {...scheduleDaysSliderProps}
            {...scheduleDaysSliderProps.extraProps}
          />
        )}
      </Box>
    </Grid>
  );
};

export default DaysSelector;
