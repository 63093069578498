import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';

const FilterList = ({ name, filter }) => {
  return (
    <ListItem sx={{ paddingLeft: '0' }}>
      <ListItemIcon>
        <ListAltSharpIcon />
      </ListItemIcon>
      {name && <ListItemText primary={name} />}
      {filter && <ListItemText primary={`Where ${filter?.toString()}`} />}
    </ListItem>
  );
};

export default FilterList;
