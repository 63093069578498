import { matchPath } from 'react-router';
import i18next, { t } from 'i18next';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CarouselIcon from '@mui/icons-material/ViewCarousel';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MediaIcon from '@mui/icons-material/PermMedia';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PeopleIcon from '@mui/icons-material/People';
import ImpersonateIcon from '@mui/icons-material/TransferWithinAStation';
import CloudinaryTemplateIcon from '@mui/icons-material/FilterDrama';
import BlueprintBuilderIcon from '@mui/icons-material/SettingsInputComponent';
import BackIcon from '@mui/icons-material/ArrowBack';
import SkinSettingsIcon from '@mui/icons-material/Tune';
import FacebookSupportToolsIcon from '@mui/icons-material/Facebook';
import PlusIcon from '@mui/icons-material/Add';

import KeyIcon from '@mui/icons-material/VpnKey';
import WebhookIcon from '@mui/icons-material/SettingsInputAntenna';
import ContentIcon from '@mui/icons-material/ListAlt';
import ArchitectureIcon from '@mui/icons-material/AccountTree';
import CatalogContentIcon from '@mui/icons-material/LibraryBooks';
import AddUserIcon from '@mui/icons-material/PersonAdd';
import ResourceLibraryIcon from '@mui/icons-material/MenuBook';
import SearchIcon from '@mui/icons-material/Search';
import StorageIcon from '@mui/icons-material/Storage';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CampaignIcon from '@mui/icons-material/Campaign';

import FolderClosedIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HelpClosedIcon from '@mui/icons-material/Help';
import HelpOpenIcon from '@mui/icons-material/HelpOutline';
import ResourcesOpenIcon from '@mui/icons-material/TurnedInNot';
import ResourcesClosedIcon from '@mui/icons-material/TurnedIn';

import { AppSettingsContextType } from 'src/AppSettings';
import {
  Facebook as FacebookIcon,
  QuickAutomation as QuickAutomationIcon
} from 'src/components/Icons';
import { paths } from 'src/routes/Constants';
import { isSuperAdmin, isDeveloper, isOrgAdmin } from 'src/Auth/common';
import ErrorConsole from 'src/components/Admin/ErrorConsole';
import { ArchitectureProviderType } from '../Architecture/ArchitectureProvider';
import { MenuItem } from './DropdownMenuListItem';

const PromotionsIcon = ContentIcon; // these are the same right now
const isDev = isDeveloper();
const isOrgAdminUser = isOrgAdmin();

export const NAVIGATION_ITEM_TYPES = {
  dropdown: 'dropdown',
  link: 'link'
} as const;

export type NavigationItemType = keyof typeof NAVIGATION_ITEM_TYPES;

const getAssetMenuIcon = (open: boolean) => {
  return open ? <FolderOpenIcon /> : <FolderClosedIcon />;
};

const getSupportMenuIcon = (open: boolean) => {
  return open ? <HelpOpenIcon /> : <HelpClosedIcon />;
};

const getResourcesIcon = (open: boolean) => {
  return open ? <ResourcesOpenIcon /> : <ResourcesClosedIcon />;
};

export const MENU_ICONS = {
  audiences: PeopleIcon,
  dashboard: HomeIcon,
  leads: ContactMailIcon,
  programs: CreditCardIcon,
  automatedPrograms: QuickAutomationIcon,
  content: BusinessCenterIcon,
  blueprints: CarouselIcon,
  gallery: MediaIcon,
  settings: SettingsIcon,
  default: RadioButtonUncheckedIcon,
  facebook: FacebookIcon,
  impersonate: ImpersonateIcon,
  imageTemplateBuilder: CloudinaryTemplateIcon,
  blueprintBuilder: BlueprintBuilderIcon,
  adminDashboard: HomeIcon,
  back: BackIcon,
  skinSettings: SkinSettingsIcon,
  facebookSupportTools: FacebookSupportToolsIcon,
  createOrganization: PlusIcon,
  billingSettings: CreditCardIcon,
  facebookSettings: FacebookIcon,
  authenticationSettings: KeyIcon,
  webhookSettings: WebhookIcon,
  architectureSettings: ArchitectureIcon,
  catalogContentSettings: CatalogContentIcon,
  contentTablesSettings: ContentIcon,
  promotionsSettings: PromotionsIcon,
  createUser: AddUserIcon,
  catalogContentSettingsLanding: CatalogContentIcon,
  contentTablesLanding: ContentIcon,
  ResourceLibraryManager: ResourceLibraryIcon,
  GoogleSearchSettings: SearchIcon,
  ContentDataManager: StorageIcon,
  partnerLinkTool: KeyIcon,
  manuallyLinkOrders: AddLinkIcon,
  campaign: CampaignIcon,
  quickAutomation: QuickAutomationIcon
} as const;

export type MenuIcon = keyof typeof MENU_ICONS;

interface Features {
  dashboard: boolean;
  audiences: boolean;
  facebook: boolean;
  contacts: boolean;
  gallery: boolean;
  automations: boolean;
  myAccount: boolean;
}

type StringOverrides = { [key: string]: string };

interface GetDashboardNavigationItemArgs {
  features: Features | Record<string, never>;
  appSettings: AppSettingsContextType | Record<string, never>;
  stringOverrides: StringOverrides;
  isAssetMenuItemSelected: (path: string) => boolean;
  getDefaultAssetMenuState: () => boolean;
  architectures: ArchitectureProviderType[];
}

interface CustomLink {
  text: { [key: string]: string };
  link: string;
  newTab?: boolean;
}

interface NavigationItem {
  key: string;
  name: string;
  path?: string;
  type?: NavigationItemType;
  menuItems?: MenuItem[];
  getIcon?: (open: boolean) => JSX.Element;
  isSelected?: (path: string) => boolean;
  getDefaultState?: () => boolean;
  hasDivider?: boolean;
  target?: string;
  subtitle?: string;
  customProps?: object;
}

export const getDashboardNavigationItems = ({
  features = {},
  appSettings = {},
  stringOverrides = {},
  isAssetMenuItemSelected,
  getDefaultAssetMenuState,
  architectures
}: GetDashboardNavigationItemArgs): NavigationItem[] => {
  const linkBackTo = appSettings?.app?.general?.linkBack?.url;
  const linkBackPrimary = appSettings?.app?.general?.linkBack?.primary;
  const linkBackSecondary = appSettings?.app?.general?.linkBack?.secondary;
  const enableSupport = appSettings?.app?.support?.enableSideNav;

  const customMenuLinks: CustomLink[] =
    appSettings?.app?.general?.customMenuLinks || [];

  const hasCustomMenuLinks =
    Array.isArray(customMenuLinks) && customMenuLinks.length;
  const hasBackToLink = linkBackTo && linkBackPrimary;
  const hasResourcesSection = hasCustomMenuLinks || hasBackToLink;
  const hasSupervisableOffer = !!architectures.filter(
    architecture => architecture.hasSupervisableOffer
  ).length;

  return [
    ...(features.dashboard
      ? [
          {
            type: NAVIGATION_ITEM_TYPES.link,
            name: stringOverrides?.dashboardName || t('chrome:menus.home'),
            path: paths.dashboard.base,
            key: 'dashboard'
          }
        ]
      : []),

    {
      type: NAVIGATION_ITEM_TYPES.link,
      name: t('chrome:menus.myPrograms'),
      path: paths.programs.base,
      key: 'campaign'
    },
    ...(features.automations && hasSupervisableOffer
      ? [
          {
            type: NAVIGATION_ITEM_TYPES.link,
            name: t('chrome:menus.myAutomations'),
            path: paths.automations.base,
            key: 'quickAutomation',
            hasDivider: true
          }
        ]
      : []),
    {
      type: NAVIGATION_ITEM_TYPES.dropdown,
      name: t('chrome:menus.myAssets'),
      key: 'myAssets',
      isSelected: isAssetMenuItemSelected,
      getIcon: getAssetMenuIcon,
      hasDivider: hasResourcesSection || enableSupport || hasBackToLink,
      getDefaultState: getDefaultAssetMenuState,
      menuItems: [
        ...(features.audiences
          ? [
              {
                name: t('chrome:menus.subMenu-audiences'),
                path: paths.dashboard.audiences,
                key: 'audiences'
              }
            ]
          : []),
        ...(features.facebook
          ? [
              {
                name: t('chrome:menus.subMenu-facebook'),
                path: paths.facebook.pages,
                key: 'facebook'
              }
            ]
          : []),
        ...(features.contacts
          ? [
              {
                name: t('chrome:menus.subMenu-leads'),
                path: paths.dashboard.leads,
                key: 'leads'
              }
            ]
          : []),
        ...(features.gallery
          ? [
              {
                name: t('chrome:menus.subMenu-gallery'),
                path: paths.dashboard.gallery,
                key: 'gallery'
              }
            ]
          : [])
      ]
    },
    ...(hasResourcesSection
      ? [
          {
            type: NAVIGATION_ITEM_TYPES.dropdown,
            name: t('chrome:menus.resourcesDropdown'),
            key: 'resources',
            getIcon: getResourcesIcon,
            hasDivider: enableSupport || hasBackToLink,
            menuItems: customMenuLinks.reduce(
              (
                formattedCustomLinks: {
                  name: string;
                  path: string;
                  key: string;
                  props: object;
                }[],
                customLink
              ) => {
                const linkText = customLink?.text?.[i18next.language];
                const linkUrl = customLink?.link;
                const newTab = customLink?.newTab;

                if (!linkText || !linkUrl) {
                  return formattedCustomLinks;
                }

                return [
                  ...formattedCustomLinks,
                  {
                    name: linkText,
                    path: linkUrl,
                    key: linkText,
                    props: { target: newTab ? '_blank' : '_self' }
                  }
                ];
              },
              []
            )
          }
        ]
      : []),
    ...(hasBackToLink
      ? [
          {
            type: NAVIGATION_ITEM_TYPES.link,
            name: linkBackPrimary || t('chrome:navigation.backTo'),
            subtitle: linkBackSecondary,
            target: '_self',
            key: 'back',
            hasDivider: enableSupport
          }
        ]
      : []),
    ...(enableSupport
      ? [
          {
            type: NAVIGATION_ITEM_TYPES.dropdown,
            name: t('chrome:menus.supportDropdown'),
            key: 'settings',
            getIcon: getSupportMenuIcon,
            customProps: {
              'data-amp-click-support': JSON.stringify({
                action: 'open',
                location: 'side'
              })
            },
            menuItems: [
              ...(appSettings?.app?.support?.link
                ? [
                    {
                      name:
                        appSettings.app.support.linkText ||
                        t('chrome:navigation.supportLink'),
                      path: appSettings?.app?.support?.link,
                      key: 'supportLink',
                      customProps: {
                        target: '_blank',
                        'data-amp-click-support': JSON.stringify({
                          action: 'link',
                          location: 'side'
                        })
                      }
                    }
                  ]
                : []),
              ...(appSettings?.app?.support?.faqLink
                ? [
                    {
                      name: appSettings.app.support.faqText,
                      path: appSettings?.app?.support?.faqLink,
                      key: 'faqLink',
                      customProps: {
                        target: '_blank',
                        'data-amp-click-support': JSON.stringify({
                          action: 'faq',
                          location: 'side'
                        })
                      }
                    }
                  ]
                : []),
              ...(appSettings?.app?.support?.contactLink
                ? [
                    {
                      name: appSettings.app.support.contactText,
                      path: appSettings?.app?.support?.contactLink,
                      key: 'contactLink',
                      customProps: {
                        target: '_blank',
                        'data-amp-click-support': JSON.stringify({
                          action: 'contact',
                          location: 'side'
                        })
                      }
                    }
                  ]
                : [])
            ]
          }
        ]
      : [])
  ];
};

// Note: order here matters
export const getArchitecturesSubNavigationItems = (
  architecture: ArchitectureProviderType,
  features: Features | Record<string, never> = {}
) => {
  const contentName = architecture?.catalog?.friendlyName;
  const isContentSelectable = architecture?.isContentSelectable;
  const hasSupervisableOffer = architecture?.hasSupervisableOffer;

  return [
    {
      name: t('chrome:menus.programs'),
      path: paths.architecture.programs,
      key: 'programs'
    },
    ...(features.automations && hasSupervisableOffer
      ? [
          {
            name: t('chrome:menus.automations'),
            path: paths.architecture.automatedPrograms,
            key: 'automatedPrograms'
          }
        ]
      : []),
    /* eslint-disable */
    ...(isContentSelectable
      ? [
          {
            name: contentName || t('chrome:menus.content'),
            path: paths.architecture.content,
            key: 'content'
          }
        ]
      : [])
    /* eslint-enable */

    // Note: Keeping these around for now incase we would like ot add them back in
    // {
    //     name: t('chrome:menus.blueprints', ),
    //     path: paths.architecture.blueprints
    // },
    // {
    //     name: t('chrome:menus.settings', ),
    //     path: paths.architecture.settings
    // }
  ];
};

// Note: order here matters
export const getAppBarNavigationItems = (
  settings: AppSettingsContextType,
  features: Features
) => {
  const evAssets = settings?.evAssets;
  const extraItems = [];

  if (isSuperAdmin()) {
    extraItems.push({
      name: t('chrome:menus.adminDashboard'),
      path: paths.admin.base,
      extraAction: <ErrorConsole />
    });
  }

  if (evAssets?.tosUrl) {
    // if tosUrl show tos menu item
    extraItems.push({
      name: evAssets?.tosName,
      path: evAssets?.tosUrl
    });
  }

  if (evAssets?.privacyUrl) {
    // if privacyUrl show privacy menu item
    extraItems.push({
      name: evAssets?.privacyName,
      path: evAssets?.privacyUrl
    });
  }

  return [
    // my account should always be first
    ...(features.myAccount
      ? [
          {
            name: t('chrome:menus.myAccount'),
            path: paths.account.base
          }
        ]
      : []),
    ...extraItems,
    // logout should always be last
    { name: t('chrome:menus.logout'), path: paths.auth.logout }
  ];
};

export const getMenuIcon = (key: MenuIcon) => {
  const Icon = MENU_ICONS[key] || MENU_ICONS.default;
  return <Icon fill="#757575" />;
};

export const isSideNavigationItemSelected = (
  name: string,
  locationPathname: string,
  checkPathname: string
) => {
  // Note: matchPath returns an object or null
  let pathMatches = matchPath<{ architectureId?: string }>(locationPathname, {
    path: checkPathname,
    exact: true,
    strict: false
  });

  switch (name) {
    case 'Programs':
      // account for: /#/programs
      const matchesPrograms = matchPath(locationPathname, {
        path: paths.programs.base,
        exact: true,
        strict: false
      });

      // account for: /#/architecture/11006/programs (legacy)
      // account for: /#/architecture/11006/programs/123456789 (program details)
      const matchesProgram = matchPath(locationPathname, {
        path: paths.architecture.program,
        exact: true,
        strict: false
      });

      // account for: /#/architecture/11006/programCreate
      const matchesCreateProgram = matchPath(locationPathname, {
        path: paths.architecture.programCreate,
        exact: true,
        strict: false
      });

      if (matchesPrograms) {
        pathMatches = matchesPrograms;
      }

      if (matchesCreateProgram) {
        pathMatches = matchesCreateProgram;
      }

      if (matchesProgram) {
        pathMatches = matchesProgram;
      }
      break;
    case 'Automations':
      // account for: /#/automations
      const matchesAutomations = matchPath(locationPathname, {
        path: paths.automations.base,
        exact: true,
        strict: false
      });

      // account for: /#/architecture/11006/automatedPrograms
      // account for: /#/architecture/11006/automatedPrograms/123456789 (automation details)
      const matchesAutomation = matchPath(locationPathname, {
        path: paths.architecture.automatedProgram,
        exact: true,
        strict: false
      });

      // account for: /#/architecture/11006/automatedProgramCreate
      const matchesCreateAutomation = matchPath(locationPathname, {
        path: paths.architecture.automatedProgramCreate,
        exact: true,
        strict: false
      });

      // account for: /#/architecture/11006/automatedProgramEdit/123456789
      const matchesAutomationEdit = matchPath(locationPathname, {
        path: paths.architecture.automatedProgramEdit,
        exact: true,
        strict: false
      });

      if (matchesAutomations) {
        pathMatches = matchesAutomations;
      }

      if (matchesAutomation) {
        pathMatches = matchesAutomation;
      }

      if (matchesCreateAutomation) {
        pathMatches = matchesCreateAutomation;
      }

      if (matchesAutomationEdit) {
        pathMatches = matchesAutomationEdit;
      }
      break;
    default:
      break;
  }

  // the new consolidated programs / automations does not have an architectureId in the path any longer
  return !!pathMatches;
};

// Note: order here matters
export const getAdminNavigationItems = () => {
  return [
    {
      name: () => t('chrome:menus.backToDashboard'),
      path: paths.dashboard.base,
      key: 'back'
    },
    {
      name: () => t('chrome:menus.adminHome'),
      path: paths.admin.base,
      key: 'adminDashboard'
    },
    {
      name: () => t('chrome:menus.impersonate'),
      path: paths.admin.impersonate,
      key: 'impersonate'
    },
    ...(isOrgAdminUser
      ? [
          {
            name: () => t('chrome:menus.blueprintBuilder'),
            path: paths.admin.blueprintBuilderOverview,
            key: 'blueprintBuilder'
          }
        ]
      : [])
  ];
};

// Note: order here matters
export const getAdminNavigationSettings = () => {
  return [
    ...(isDev
      ? [
          {
            name: () => t('chrome:menus.createOrganization'),
            path: paths.admin.createOrganization,
            key: 'createOrganization'
          }
        ]
      : []),
    {
      name: () => t('chrome:menus.skinSettings'),
      path: paths.admin.settings.skin,
      key: 'skinSettings'
    },
    ...(isDev
      ? [
          {
            name: () => t('admin:headings.billingSettingsTitle'),
            path: paths.admin.settings.billing,
            key: 'billingSettings'
          },
          {
            name: () => t('chrome:menus.facebookSettings'),
            path: paths.admin.settings.facebook,
            key: 'facebookSettings'
          },
          {
            name: () => t('chrome:menus.authentication'),
            path: paths.admin.settings.authentication,
            key: 'authenticationSettings'
          },
          {
            name: () => t('chrome:menus.webhook'),
            path: paths.admin.settings.webhook,
            key: 'webhookSettings'
          },
          {
            name: () => t('chrome:menus.architecture'),
            path: paths.admin.settings.architecture,
            key: 'architectureSettings'
          },
          {
            name: () => t('chrome:menus.promotions'),
            path: paths.admin.settings.promotions,
            key: 'promotionsSettings'
          },
          {
            name: () => t('chrome:menus.galleries'),
            path: paths.admin.settings.galleries,
            key: 'gallery'
          },
          {
            name: () => t('chrome:menus.catalogContent'),
            path: paths.admin.settings.catalogContentLanding,
            key: 'catalogContentSettingsLanding'
          },
          {
            name: () => t('chrome:menus.contentTables'),
            path: paths.admin.settings.contentTablesLanding,
            key: 'contentTablesLanding'
          },
          {
            name: () => t('chrome:menus.resourceLibrary'),
            path: paths.admin.settings.resourceLibrary,
            key: 'ResourceLibraryManager'
          },
          {
            name: () => t('chrome:menus.googleSearchSettings'),
            path: paths.admin.settings.googleSearch,
            key: 'GoogleSearchSettings'
          },
          {
            name: () => t('chrome:menus.contentDataManager'),
            path: paths.admin.settings.contentDataManager,
            key: 'ContentDataManager'
          },
          ...(isOrgAdminUser
            ? [
                {
                  name: () => t('chrome:menus.createUser'),
                  path: paths.admin.createUser,
                  key: 'createUser'
                }
              ]
            : [])
        ]
      : [])
  ];
};

// Note: order here matters
export const getAdminNavigationTools = () => {
  return [
    {
      name: () => t('chrome:menus.facebookSupportTools'),
      path: paths.admin.tools.facebook,
      key: 'facebookSupportTools'
    },
    {
      name: () => t('chrome:menus.partnerLinkTool'),
      path: paths.admin.tools.partner,
      key: 'partnerLinkTool'
    },
    {
      name: () => t('chrome:menus.manuallyLinkOrders'),
      path: paths.admin.tools.manuallyLinkOrders,
      key: 'manuallyLinkOrders'
    }
  ];
};

// Note: order here matters
export const getAdminNavigationUtilities = () => {
  return [
    // Tool is not that useful right now so removing from UI
    // {
    //     name: () =>
    //         t('chrome:menus.imageTemplateBuilder', ),
    //     path: paths.admin.utilities.imageTemplateBuilder,
    //     key: 'imageTemplateBuilder'
    // }
  ];
};
