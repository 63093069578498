import { useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flow } from 'lodash';
import { t } from 'i18next';
import { connect } from 'react-redux';

import {
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  ListItemSecondaryAction,
  Box
} from '@mui/material';
import {
  DeleteOutline as TrashIcon,
  Delete as TrashIconFilled
} from '@mui/icons-material';

import { extractCcErrorMessage } from 'src/common/paymentUtils';
import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';
import {
  SnackbarNotification,
  enqueueSnackbar
} from 'src/components/AdmiralSnackBar/actions';
import CreditCardLogo from 'src/components/Icons/CreditCardLogos/CreditCardLogo';

import { markPaymentMethodAsRemoved } from './mutations';

interface CardLabelPageTextArgs {
  cardBrand: string;
  cardLastFour: string;
  cardExpiry: string;
}

const pageText = ({
  cardBrand,
  cardLastFour,
  cardExpiry
}: CardLabelPageTextArgs) => {
  return {
    removeError: t('programCreate:Checkout.removeError'),
    removeAssociatedError: t('programCreate:Checkout.removeAssociatedError'),
    removeTooltip: t('programCreate:Checkout.removeCcTip'),
    maskedCardNumber: t('programCreate:Checkout.ending', {
      cardBrand,
      cardLastFour
    }),
    maskedCardNumberV3: t('programCreate:Checkout.endingV3', {
      cardBrand,
      mask: '••••',
      cardLastFour
    }),
    cardExpiry: t('programCreate:Checkout.expires', {
      cardExpiry
    }),
    cardExpiryV3: t('programCreate:Checkout.expiresV3', {
      cardExpiry
    })
  };
};

type Refetch = () => Promise<void>;

interface CardLabelProps {
  cardBrand: string;
  cardExpiryMonth: number;
  cardExpiryYear: number;
  cardLastFour: string;
  enqueueSnackbar: (notification: SnackbarNotification) => void;
  markPaymentMethodAsRemoved: (args: any) => Promise<void>;
  paymentMethodId: string;
  refetch?: Refetch;
}

const CardLabel = ({
  cardBrand,
  cardExpiryMonth,
  cardExpiryYear,
  cardLastFour,
  enqueueSnackbar,
  markPaymentMethodAsRemoved,
  paymentMethodId,
  refetch
}: CardLabelProps) => {
  const [pending, setSubmitting] = useState(false);
  const isProgramCreatePage = useIsProgramCreatePage();

  let cardExpiry = '';

  if (cardExpiryMonth && cardExpiryYear) {
    const cardExpiryMonthFormatted = cardExpiryMonth
      ?.toString()
      ?.padStart(2, '0');

    const cardExpiryYearFormatted = isProgramCreatePage
      ? cardExpiryYear?.toString()?.slice(2, 4)
      : cardExpiryYear;

    cardExpiry = `${cardExpiryMonthFormatted}/${cardExpiryYearFormatted}`;
  }

  const text = pageText({
    cardBrand,
    cardLastFour,
    cardExpiry
  });

  const handleRemove = () => {
    setSubmitting(true);
    return markPaymentMethodAsRemoved({ paymentMethodId })
      .then(() => {
        // TODO: Refetch could be undefined according to line 187, "as Refetch" is a hacky type assertion for the refactor
        return (refetch as Refetch)();
      })
      .catch(error => {
        let errorMessage = text.removeError;

        if (error.toString().includes('associated')) {
          errorMessage = text.removeAssociatedError;
        }

        // Note: The api will throw an exception if the payment method is
        //       still associated with an active order.
        const extraErrorMessage = extractCcErrorMessage(error);

        return enqueueSnackbar({
          message: (
            <span>
              {errorMessage}
              {extraErrorMessage && (
                <>
                  <br />
                  {extraErrorMessage}
                </>
              )}
            </span>
          ),
          options: {
            variant: 'error'
          }
        });
      });
  };

  return (
    <ListItem
      sx={{
        mr: '0',
        ...(isProgramCreatePage ? { pl: 0 } : {})
      }}
    >
      {isProgramCreatePage && (
        <Box
          sx={{
            borderRadius: 0.5,
            width: 50,
            height: 30,
            border: theme => `1px solid ${theme.palette.grey[200]}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1.5
          }}
        >
          <CreditCardLogo brand={cardBrand} />
        </Box>
      )}
      <ListItemText
        primary={
          isProgramCreatePage ? text.maskedCardNumberV3 : text.maskedCardNumber
        }
        secondary={isProgramCreatePage ? text.cardExpiryV3 : text.cardExpiry}
        data-cy="cc-label"
        primaryTypographyProps={
          // TODO Null is not an acceptable value for sx, fix and remove as Record<string, any>
          (isProgramCreatePage as Record<string, any>) && {
            sx: { fontWeight: 600 },
            variant: 'body2',
            component: 'span'
          }
        }
        secondaryTypographyProps={
          // TODO Null is not an acceptable value for sx, fix and remove as Record<string, any>
          (isProgramCreatePage as Record<string, any>) && {
            variant: 'caption',
            component: 'span'
          }
        }
      />
      {/*
                Note: Without refetch we are assuming you are using this to just display the card
                      info so you cannot remove the card
            */}
      {refetch && paymentMethodId && (
        <ListItemSecondaryAction sx={isProgramCreatePage && { right: 1 }}>
          <Tooltip title={text.removeTooltip} aria-label={text.removeTooltip}>
            {/* we need this span to remove material error warning */}
            <span>
              <IconButton
                data-cy={`payment-remove-card-button-${cardLastFour}`}
                // TODO Refactor so that we're passing a function that returns void instead of Promise<void>
                onClick={handleRemove as () => void}
                disabled={pending}
                edge="end"
                aria-label="remove"
                size="large"
              >
                {isProgramCreatePage ? (
                  <TrashIconFilled sx={{ color: 'text.primary' }} />
                ) : (
                  <TrashIcon />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default flow(
  connect(null, { enqueueSnackbar }),
  graphql(markPaymentMethodAsRemoved, { name: 'markPaymentMethodAsRemoved' })
)(CardLabel);
