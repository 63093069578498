import { connect } from 'react-redux';
import { Field, unregisterField } from 'redux-form';
import { flow, toNumber, isNaN, get } from 'lodash';
import classnames from 'classnames';
import { IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddCircleIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';

import RenderFilterOperator from './RenderFilterOperator';
import {
  getInputByColumn,
  getValidationByColumn,
  getDefaultFilterValueByColumn
} from './helpers';
import { CONTENT_COL_TYPES } from './constants';

// Note: Styles for filter table are also defined
// in FiltersList.jsx style definition
const styles = theme => ({
  cell: {
    position: 'relative',
    '& > div': {
      paddingRight: theme.spacing(2)
    }
  },
  headerCell: {
    position: 'relative',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    verticalAlign: 'top',
    fontSize: '1rem',
    textAlign: 'right !important',
    background: theme.palette.grey[100],
    whiteSpace: 'nowrap',
    '& div:first-child': {
      paddingTop: '7px'
    }
  },
  headerTitle: {
    position: 'relative',
    top: '6px'
  },
  cellOr: {},
  lastRowCell: {
    borderBottom: 'none'
  },
  rangeAnd: {
    position: 'relative',
    top: '5px',
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    padding: `0 ${theme.spacing(1)}`
  },
  column: {
    textAlign: 'center'
  },
  wrapper: {},
  addCell: {
    padding: `${theme.spacing(1)} !important`,
    position: 'relative',
    textAlign: 'center !important',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  icon: {
    display: 'inline-block',
    width: theme.spacing(4),
    height: theme.spacing(4),
    lineHeight: theme.spacing(4),
    borderRadius: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold',
    background: theme.palette.grey[100],
    padding: '0',
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: theme.spacing(-2)
  },
  leftIcon: {
    position: 'absolute',
    left: '0px',
    top: '10px',
    transform: 'translateX(-50%)'
  },
  add: {
    paddingTop: `6px`
  },
  addButton: {},
  rule: {}
});

const validateNumberRange = (value, allValues, props, fieldName) => {
  const values = get(allValues, fieldName.slice(0, -3)); // { gt:0, lt:100 }
  const operator = fieldName.slice(-2); // 'gt' or 'lt';

  if (operator === 'gt' && value >= values?.lt) {
    return 'must be less than high value';
  }
  if (operator === 'lt' && value <= values?.gt) {
    return 'must be greater than low value';
  }
};

const memoizedValidations = {};
const getMemoizedValidationByColumn = (column, operator = '') => {
  // some operators need extra validation (eg range)
  const key = `${column?.displayMethodId}-${operator}`;
  if (!memoizedValidations[key]) {
    memoizedValidations[key] = getValidationByColumn(column);
    // if there is more than range we can add a get validation by operator func
    if (operator === 'range') {
      memoizedValidations[key].push(validateNumberRange);
    }
  }
  return memoizedValidations[key];
};

const Filter = ({
  fields,
  contentMeta,
  columnValue,
  columnDisplayName,
  classes,
  deleteColumn,
  forceValidation
}) => {
  const selectedColumn =
    (columnValue?.column &&
      contentMeta.find(c => c.fieldName === columnValue?.column)) ||
    null;

  return (
    <>
      {fields.map((field, index, fields) => {
        const value = fields.get(index);
        const selectedOperator = value?.operator;
        const isRange = selectedOperator === 'range';

        const ValueComponent = getInputByColumn(selectedColumn);

        const onChangeOperator = e => {
          const value = getDefaultFilterValueByColumn(
            selectedColumn,
            e.target.value
          );
          if (e.target.value === 'range') {
            fields.splice(index, 1, {
              operator: e.target.value,
              value
            });
          } else {
            fields.splice(index, 1, {
              operator: e.target.value,
              value
            });
          }
        };

        const normalize = value => {
          if (selectedColumn.contentColumnType === CONTENT_COL_TYPES.NUMBER) {
            const number = toNumber(value);
            if (!value || isNaN(number)) {
              return value;
            }

            return number;
          }
          return value;
        };
        return (
          <tr
            className={classes.rule}
            data-cy="filter-rule"
            data-cy-key={field}
            key={field}
          >
            <th className={classes.headerCell}>
              <span style={{ position: 'relative' }}>
                {index > 0 && <span className={classes.icon}>or</span>}
                <span className={classes.headerTitle}>{columnDisplayName}</span>
              </span>
            </th>
            <td
              className={classnames(classes.cell, {
                [classes.cellOr]: index > 0,
                [classes.lastRowCell]: index === fields.length - 1
              })}
            >
              <Field
                name={`${field}.operator`}
                component={RenderFilterOperator}
                onChangeOperator={onChangeOperator}
                selectedColumn={selectedColumn}
              />
              {isRange ? (
                <>
                  <Field
                    name={`${field}.value.gt`}
                    component={ValueComponent}
                    selectedColumn={selectedColumn}
                    validate={getMemoizedValidationByColumn(
                      selectedColumn,
                      selectedOperator
                    )}
                    normalize={normalize}
                  />
                  <span className={`${classes.rangeAnd}`}>AND</span>
                  <Field
                    name={`${field}.value.lt`}
                    component={ValueComponent}
                    selectedColumn={selectedColumn}
                    validate={getMemoizedValidationByColumn(
                      selectedColumn,
                      selectedOperator
                    )}
                    normalize={normalize}
                  />
                </>
              ) : (
                <Field
                  name={`${field}.value`}
                  component={ValueComponent}
                  selectedColumn={selectedColumn}
                  validate={getMemoizedValidationByColumn(
                    selectedColumn,
                    selectedOperator
                  )}
                  normalize={normalize}
                />
              )}
            </td>
            <td>
              <IconButton
                size="small"
                data-cy="delete-rule"
                onClick={() => {
                  fields.remove(index);
                  // remove the whole column if this is the last rule
                  if (fields.length <= 1) {
                    deleteColumn();
                  }
                  forceValidation();
                }}
              >
                <CancelIcon />
              </IconButton>
            </td>
          </tr>
        );
      })}
      <tr>
        <td
          className={`${classes.addCell}`}
          colSpan="3"
          onClick={() => {
            fields.push({
              operator: 'eq',
              value: getDefaultFilterValueByColumn(selectedColumn, 'eq')
            });
          }}
        >
          <AddCircleIcon
            color="primary"
            fontSize="small"
            style={{ verticalAlign: 'middle' }}
          />{' '}
          Add {columnDisplayName} Rule
        </td>
      </tr>
    </>
  );
};

export default flow(
  connect(null, { unregisterField }),
  withStyles(styles)
)(Filter);
