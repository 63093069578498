import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { flow, find, filter, sortBy, uniqueId } from 'lodash';
import { unregisterField, registerField, change, FieldArray } from 'redux-form';
import { Trans } from 'react-i18next';

import {
  Button,
  Grid,
  Chip,
  Menu,
  MenuItem,
  TableContainer
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import Filter from './Filter';

import { getOperatorsByColumn, getDefaultFilterValueByColumn } from './helpers';

const styles = theme => ({
  tableContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  table: {
    // width: '100%',
    display: 'table',
    borderSpacing: 0,
    borderCollapse: 'separate',
    marginBottom: theme.spacing(2)
  },
  headerCell: {
    border: `1px solid ${grey[400]}`,
    borderRight: '0',
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    verticalAlign: 'top',
    fontSize: '1rem',
    textAlign: 'right !important',
    whiteSpace: 'nowrap',
    '& div:first-child': {
      paddingTop: '7px'
    }
  },
  tbody: {
    display: 'table-row-group',
    '& tr': {
      display: 'tableRow',
      outline: 0,
      verticalAlign: 'middle'
    },
    '& td, & th': {
      display: 'table-cell',
      textAlign: 'left',
      padding: theme.spacing(2),
      borderBottom: `1px solid ${grey[400]}`
    },
    '& td:last-child': {
      borderRight: `1px solid ${grey[400]}`
    },
    '& th:first-child, & td:first-child': {
      borderLeft: `1px solid ${grey[400]}`
    },
    '& tr:nth-child(1) td, & tr:nth-child(1) th': {
      borderTop: `1px solid ${grey[400]}`,
      '&:last-child': {
        borderTopRightRadius: theme.spacing(1)
      },
      '&:first-child': {
        borderTopLeftRadius: theme.spacing(1)
      }
    },
    '& tr:last-child td': {
      borderBottom: `1px solid ${grey[400]}`,
      '&:last-child': {
        borderBottomRightRadius: theme.spacing(1)
      },
      '&:first-child': {
        borderBottomLeftRadius: theme.spacing(1)
      }
    }
  },
  spacerRow: {
    position: 'relative',
    padding: theme.spacing(1)
  },
  roundIcon: {
    display: 'inline-block',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    lineHeight: theme.spacing(5),
    textAlign: 'center',
    fontWeight: 'bold',
    background: '#fff',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '0',
    // and positioning
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  button: {},
  addIcon: {
    height: '40px',
    width: '40px'
  },
  addText: {
    fontSize: '18px'
  },
  testRulesButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
});

const fixValidation = () => {};

const getDefaultColumn = columns => {
  return columns?.[0];
};

const defaultFilterFromColumn = (column, operator) => ({
  column: column?.fieldName,
  rules: [
    {
      operator: operator || getOperatorsByColumn(column)[0].value,
      value: getDefaultFilterValueByColumn(column, operator),
      key: uniqueId()
    }
  ],
  key: uniqueId()
});

const fixSyncErrorInputName = 'fixSyncErrorInputNameFilters';

const FiltersList = props => {
  const {
    classes,
    fields,
    meta,
    contentMeta,
    formName,
    unregisterField,
    registerField,
    change
  } = props;
  const [addRuleMenuAnchorEl, setAddRuleMenuAnchorEl] = useState(null);

  useEffect(() => {
    registerField(formName, fixSyncErrorInputName, 'Field');
    change(formName, fixSyncErrorInputName, Math.random());
    return () => {
      change(formName, fixSyncErrorInputName, Math.random());
      unregisterField(formName, fixSyncErrorInputName);
    };
  }, []);

  const hasFilters = fields.length > 0;

  const getUnfilteredColumns = () => {
    const filteredColumns = new Set(fields.getAll().map(field => field.column));
    return sortBy(
      filter(contentMeta, cm => !filteredColumns.has(cm.fieldName)),
      'displayName'
    );
  };

  const closeAddRuleMenu = () => {
    setAddRuleMenuAnchorEl(null);
  };

  const openAddRuleMenu = event => {
    setAddRuleMenuAnchorEl(event.currentTarget);
  };

  const handleAddFilter = column => {
    if (contentMeta.length <= 0) {
      return;
    }
    fields.push(
      defaultFilterFromColumn(column || getDefaultColumn(contentMeta))
    );
  };

  const forceValidation = () => {
    setTimeout(() => {
      change(formName, fixSyncErrorInputName, Math.random());
    }, 10);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.listContainer}>
        {!hasFilters && (
          <Grid item xs={12} key="automatedProgramChipList">
            <div className={classes.chipRoot}>
              {sortBy(contentMeta, 'displayName').map(meta => {
                return (
                  <Chip
                    label={meta.displayName}
                    color="primary"
                    onClick={() => handleAddFilter(meta)}
                    clickable
                    variant="outlined"
                    key={meta.fieldName}
                    data-cy={`content-rule-chip-${meta.fieldName}`}
                  />
                );
              })}
            </div>
          </Grid>
        )}
        {hasFilters && (
          <TableContainer className={classes.tableContainer}>
            <div>
              <table className={classes.table} aria-label="spanning table">
                {fields.map((field, i, fields) => {
                  const deleteColumn = () => {
                    fields.remove(i);
                    // unregister fields since our form doesn't auto update on destroy
                    unregisterField(formName, `${fields.name}[${i}]`);
                  };
                  const fieldColumn = fields.get(i).column;
                  const columnDisplayName =
                    find(contentMeta, ['fieldName', fieldColumn])
                      ?.displayName ?? fieldColumn;
                  return (
                    <Fragment key={field}>
                      {i > 0 && (
                        <tbody>
                          <tr>
                            <td className={classes.spacerRow}>
                              <span className={classes.roundIcon}>And</span>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <tbody
                        className={classes.tbody}
                        data-cy="filter-column"
                        data-cy-key={field}
                      >
                        <FieldArray
                          component={Filter}
                          formName={formName}
                          name={`${field}.rules`}
                          index={i}
                          meta={meta}
                          contentMeta={contentMeta}
                          columnValue={fields.get(i)}
                          columnDisplayName={columnDisplayName}
                          deleteColumn={deleteColumn}
                          forceValidation={forceValidation}
                          validate={[fixValidation]}
                        />
                      </tbody>
                    </Fragment>
                  );
                })}
              </table>
              {hasFilters && (
                <Button
                  color="primary"
                  data-cy="add-content-button"
                  focusRipple
                  disabled={contentMeta.length <= 0}
                  onClick={openAddRuleMenu}
                  className={classes.button}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  <Trans i18nKey="automatedProgramFilter:addButton">
                    Add Content Rule
                  </Trans>
                </Button>
              )}
            </div>
          </TableContainer>
        )}
        {Boolean(addRuleMenuAnchorEl) && (
          <Menu
            id="automated-wizard-ad-rules-menu"
            anchorEl={addRuleMenuAnchorEl}
            keepMounted
            open
            onClose={closeAddRuleMenu}
          >
            {getUnfilteredColumns().map(meta => {
              return (
                <MenuItem
                  key={meta?.fieldName}
                  onClick={() => {
                    handleAddFilter(meta);
                    closeAddRuleMenu();
                  }}
                >
                  {meta.displayName}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </Grid>
    </>
  );
};

export default flow(
  connect(null, {
    unregisterField,
    registerField,
    change
  }),
  withStyles(styles)
)(FiltersList);
