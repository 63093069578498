import { gql } from 'src/generated/gql';

export const fetchEmbeddedLink = gql(`
  query fetchEmbeddedLink {
    fetchEmbeddedLink {
      link
      errorMessage
    }
  }
`);

export const fetchContactGroups = gql(`
  query fetchContactGroups {
    fetchContactGroups {
      groupName
      groupId
    }
  }
`);

export const initialFetchContactGroupCounts = gql(`
  query initialFetchContactGroupCounts {
    fetchContactGroupCounts {
      jobId
      jobStatus
      totalRetrievedContactCount
      contactGroupCounts {
        groupName
        size
      }
    }
  }
`);

export const fetchContactGroupCounts = gql(`
  query fetchContactGroupCounts($jobId: ID!) {
    fetchContactGroupCounts(jobId: $jobId) {
      jobId
      jobStatus
      totalRetrievedContactCount
      contactGroupCounts {
        groupName
        size
      }
    }
  }
`);

export const checkForExistingActiveConnection = gql(`
  query checkForExistingActiveConnection {
    checkForExistingActiveConnection {
      isActive
      connectionExists
    }
  }
`);
