import { Component } from 'react';
import { bindActionCreators, Dispatch, Store } from 'redux';
import { connect } from 'react-redux';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  withSnackbar
} from 'notistack';
import { differenceBy, flow } from 'lodash';

import { removeSnackbar, SnackbarState } from './actions';

// Snack Bar Documentation: https://iamhosseindhv.com/notistack

export interface AdmiralSnackBarProps {
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  notifications: SnackbarState[];
  removeSnackbar: (key?: SnackbarKey) => void;
}

class AdmiralSnackBar extends Component<AdmiralSnackBarProps> {
  displayed: number[] = [];

  shouldComponentUpdate({
    notifications: newSnacks = []
  }: AdmiralSnackBarProps) {
    const { notifications: currentSnacks } = this.props;
    // only update if "new" snacks don't already exist
    return !!differenceBy(newSnacks, currentSnacks, 'key').length;
  }

  componentDidUpdate() {
    const { enqueueSnackbar, removeSnackbar, notifications = [] } = this.props;

    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      enqueueSnackbar(
        <span data-cy="admiral-snack-bar-snack">{notification.message}</span>,
        {
          ...notification.options
        }
      );
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      removeSnackbar(notification.key);
    });
  }

  storeDisplayed = (id: number) => {
    this.displayed = [...this.displayed, id];
  };

  render() {
    return null;
  }
}

const mapStateToProps = (store: Store) => ({
  notifications: (store as any).admiralSnackBar.notifications
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ removeSnackbar }, dispatch);

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(AdmiralSnackBar);
