import { flow } from 'lodash';
import { Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import {
  Box,
  Divider,
  Typography,
  Tooltip,
  useTheme,
  Grid
} from '@mui/material';
import DaysSelector from 'src/pages/Program/ProgramSteps/DaysSelector';
import { Trans } from 'react-i18next';

import {
  Info as InfoIcon,
  Help as HelpIcon,
  HelpOutlineOutlined as HelpOutlineIcon
} from '@mui/icons-material';

import { validateRequired, isTodayOrAfter } from 'src/common/validations';
import { getDeactivationDateFromTheme } from 'src/common/deactivation';

import { EDIT_PROGRAM_FORM_NAME } from 'src/pages/ProgramPerformance/Constants';
import { canMakeScheduleUpdates } from 'src/pages/ProgramPerformance/helpers';
import { PROGRAM_FORM_NAME, scheduleTypes } from 'src/pages/Program/Constants';
import { withAppSettings } from 'src/AppSettings';
import { dayjs } from 'src/common/dates';
import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';

import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';
import { RenderCalendarPicker } from 'src/components/ReduxForm';
import CustomFormMessage from 'src/components/CustomFormMessage';
import ClientHtml from 'src/components/ClientHtml';

const disableDate = ({ date, endDateEditable, isEdit }) => {
  if (endDateEditable && isEdit) {
    const today = dayjs();
    // You cannot select today if its less than 24 hours
    return dayjs(date).diff(today, 'hours') < 24;
  }
  return false;
};

const ScheduleSelector = ({
  isAutomated,
  selectedBlueprint,
  appSettings,
  startDateEditable,
  endDateEditable,
  isEdit,
  orderIsPending,
  formName,
  isHookForm,
  hookFormContext,
  formSectionName,
  formValues
}) => {
  const scheduleType = isHookForm
    ? hookFormContext?.watch()?.spendStep?.scheduleType
    : formValues?.spendStep?.scheduleType;
  const isSubscription = scheduleType === scheduleTypes.subscription.value;

  const deactivationDate = getDeactivationDateFromTheme(appSettings);

  const isProgramCreatePage = useIsProgramCreatePage();
  const theme = useTheme();

  const endDateHelpText = selectedBlueprint?.messaging?.endDateHelpText;
  const scheduleInfo = selectedBlueprint?.messaging?.scheduleInfo;
  // hide selector if only one option

  const editScheduleTitle = t('programCreate:startDate.editSchedule');

  const startDateProps = {
    component: RenderCalendarPicker,
    name: 'startDate',
    label: t('programCreate:scheduleSelector.inputs.startDate.label'),
    validate: startDateEditable ? [validateRequired, isTodayOrAfter] : [], // user can't change if disabled so we don't want isTodayOrAfter validations
    formNamespace: formSectionName,
    extraProps: {
      disablePast: startDateEditable,
      readOnly: !startDateEditable || orderIsPending,
      minDateMessage: t('programCreate:startDate.minDateMessage'),
      shouldDisableDate: date => {
        disableDate({ date, endDateEditable, isEdit });
      },
      ...(deactivationDate && {
        // we want to exclude the deactivation date itself from the date picker
        maxDate: dayjs(deactivationDate).subtract(1, 'days')
      })
    }
  };

  const endDateProps = {
    component: RenderCalendarPicker,
    label: t('programCreate:scheduleSelector.inputs.endDate.label', 'End Date'),
    name: 'endDate',
    validate: [validateRequired],
    formNamespace: formSectionName,
    extraProps: {
      disablePast: true,
      readOnly: !endDateEditable || orderIsPending,
      shouldDisableDate: date => {
        disableDate({ date, endDateEditable, isEdit });
      },
      ...(deactivationDate && {
        // we want to exclude the deactivation date itself from the date picker
        maxDate: dayjs(deactivationDate).subtract(1, 'days')
      })
    }
  };

  return (
    <Box
      sx={{
        ...(isProgramCreatePage
          ? {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 3
            }
          : {})
      }}
    >
      {isEdit && (
        <>
          <Box
            sx={{
              mt: isEdit ? theme.spacing(1) : 0,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Typography variant="body2">{editScheduleTitle}</Typography>
            {orderIsPending && (
              <Tooltip
                title={
                  isSubscription
                    ? t(
                        'programEdit:scheduleSelector.subscriptionPendingChangeDisabled'
                      )
                    : t(
                        'programEdit:scheduleSelector.purchasePendingChangeDisabled'
                      )
                }
              >
                <HelpOutlineIcon
                  fontSize="inherit"
                  sx={{
                    color: 'grey.500',
                    position: 'relative'
                  }}
                />
              </Tooltip>
            )}
          </Box>
          <Divider />
        </>
      )}
      {!isAutomated && (
        <Box
          sx={{
            margin: `${theme.spacing(4)} 0`,
            display: 'flex',
            alignItems: 'center',
            ...(isProgramCreatePage
              ? {
                  gap: theme.spacing(1),
                  margin: `${theme.spacing(2)} 0 0 0`,
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }
                }
              : {})
          }}
        >
          {isHookForm ? (
            <HookFormWrapper {...startDateProps} validateBackendOnly={false} />
          ) : (
            <Field {...startDateProps} {...startDateProps.extraProps} />
          )}

          {!isSubscription && (
            <>
              <Typography
                sx={{
                  margin: `0 ${theme.spacing(2)}`,
                  ...(isProgramCreatePage
                    ? { alignSelf: 'center', fontSize: '1.6875rem' }
                    : {})
                }}
                variant="h5"
              >
                {isProgramCreatePage ? '-' : 'TO'}
              </Typography>

              {isHookForm ? (
                <HookFormWrapper
                  {...endDateProps}
                  validateBackendOnly={false}
                />
              ) : (
                <Field {...endDateProps} {...endDateProps.extraProps} />
              )}

              {endDateHelpText && (
                <Tooltip title={endDateHelpText}>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              )}
            </>
          )}
        </Box>
      )}

      {scheduleInfo && !isProgramCreatePage && (
        <p>
          <InfoIcon style={{ verticalAlign: 'bottom' }} />{' '}
          <ClientHtml html={scheduleInfo} />
        </p>
      )}
      {scheduleInfo && isProgramCreatePage && (
        <CustomFormMessage message={scheduleInfo} />
      )}
      {isAutomated && !isSubscription && (
        <Grid container>
          <DaysSelector
            formName={formName}
            isHookForm={isHookForm}
            formSectionName={formSectionName}
          />
          <Grid item xs={12} sm={9}>
            <Typography
              variant="body2"
              sx={{
                padding: theme.spacing(2),
                background: theme.palette.grey[100],
                borderRadius: '5px',
                marginLeft: theme.spacing(2)
              }}
            >
              <Trans i18nKey="programCreate:automatedStart.disclaimer" />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const mapStateToProps = (
  state,
  { isEdit, programStartDate, programEndDate, orderStatus }
) => {
  const formValues = getFormValues(
    isEdit ? EDIT_PROGRAM_FORM_NAME : PROGRAM_FORM_NAME
  )(state);

  const { startDateEditable, endDateEditable } = canMakeScheduleUpdates({
    isEdit,
    orderStatus,
    programStartDate,
    programEndDate
  });

  return {
    startDateEditable,
    endDateEditable,
    formValues
  };
};

export default flow(
  connect(mapStateToProps),
  withAppSettings
)(ScheduleSelector);
