import { useCallback, useMemo, useState } from 'react';
import { flow } from 'lodash';
import { reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Grid, Typography, Button, CircularProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { configureInputs } from 'src/components/ReduxForm/helpers';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import Modal from 'src/components/Modal';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import {
  CLONE_BLUEPRINT_FORM,
  getCloneFromInputs,
  getCloneToInputs
} from './Constants';
import { cloneProduct } from './mutations';

const styles = theme => ({
  icon: {
    height: '50px',
    width: '50px'
  },
  sectionHeadings: {
    fontSize: '18px'
  },
  cloneButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  errorMessage: {
    maxWidth: '80%'
  }
});

const pageText = () => ({
  cloneModalHeader: t('admin:blueprintBuilder.cloneModalHeader'),
  headingFrom: t('admin:blueprintBuilder.cloneModalHeaderFrom'),
  headingTo: t('admin:blueprintBuilder.cloneModalHeaderTo'),
  submitButton: t('admin:blueprintBuilder.cloneModalSubmitButton'),
  cancelButton: t('admin:blueprintBuilder.cloneModalCancelButton'),
  errorMessage: t('admin:blueprintBuilder.cloneModalErrorMessage')
});

const CloneBlueprint = props => {
  const {
    onClose,
    classes,
    architectures,
    handleSubmit,
    dirty,
    submitting,
    invalid,
    cloneProduct,
    enqueueSnackbar,
    refetchData
  } = props;
  const text = useMemo(() => pageText(), []);

  const [errorMessage, setErrorMessage] = useState(null);

  const updatedCloneToInputs = useMemo(() => {
    return configureInputs({
      inputs: getCloneToInputs(),
      enumInputs: {
        destinationArchitectureId: 'destinationArchitectureId'
      },
      enumerationValues: [
        {
          enumeration: 'destinationArchitectureId',
          values: architectures.map(architecture => {
            return {
              name: architecture.name,
              value: architecture.id
            };
          })
        }
      ]
    });
  }, [architectures, getCloneToInputs]);

  const onSubmit = useCallback(
    async ({ sourceProductId, destinationArchitectureId, newProductName }) => {
      try {
        await cloneProduct({
          variables: {
            sourceProductId,
            destinationArchitectureId,
            newProductName
          }
        });
        setErrorMessage(null);
        refetchData();
        onClose();
      } catch (error) {
        const cloneErrorMessage = `${text.errorMessage} : ${error?.graphQLErrors?.[0]?.message}`;

        setErrorMessage(cloneErrorMessage);

        return enqueueSnackbar({
          message: cloneErrorMessage,
          options: {
            variant: 'error'
          }
        });
      }
    },
    [refetchData, cloneProduct]
  );

  const cloneFromInputs = getCloneFromInputs();

  return (
    <Modal
      // `handleOnEscapeKeyDown` is removed by codemod.

      fullWidth
      headerText={text.cloneModalHeader}
      maxWidth="md"
      onClose={onClose}
      open
      FooterComponent={() => (
        <Button onClick={onClose}>{text.cancelButton}</Button>
      )}
      data-cy="clone-blueprint-modal"
    >
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {errorMessage && (
            <Grid item xs={12} className={classes.errorContainer}>
              <ErrorMessage className={classes.errorMessage}>
                {errorMessage}
              </ErrorMessage>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" className={classes.sectionHeadings}>
              {text.headingFrom}
            </Typography>
            <DynamicForm inputs={cloneFromInputs} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" className={classes.sectionHeadings}>
              {text.headingTo}
            </Typography>
            <DynamicForm inputs={updatedCloneToInputs} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.cloneButtonContainer}>
          {/*
                        handleSubmit does not trigger without putting the button inside the form tag. it does not trigger wrapping the whole modal in <form>
                    */}
          <Button
            color="primary"
            disabled={!dirty || submitting || invalid}
            startIcon={
              submitting ? <CircularProgress size={20} /> : <SyncAltIcon />
            }
            variant="contained"
            type="submit"
            data-cy="clone-blueprint-submit"
          >
            {text.submitButton}
          </Button>
        </Grid>
      </form>
    </Modal>
  );
};

export default flow(
  reduxForm({
    form: CLONE_BLUEPRINT_FORM,
    enableReinitialize: true
  }),
  connect(null, {
    enqueueSnackbar
  }),
  graphql(cloneProduct, {
    name: 'cloneProduct'
  }),
  withStyles(styles)
)(CloneBlueprint);
