import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { Grid, Box } from '@mui/material';

import useProgram from 'src/pages/Program/utils/useProgram';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';

import { FormSection } from 'src/components/ReduxForm';
import CustomFormMessage from 'src/components/CustomFormMessage';
import SpendSelector from 'src/components/SpendSelector/SpendSelector';
import useMinSpendState from 'src/components/SpendSelector/hooks/useMinSpendState';

interface BudgetProps {
  formSectionName?: string;
}

const Budget = ({ formSectionName }: BudgetProps) => {
  const hookFormContext = useFormContext();
  const architecture = useArchitecture();
  const { selectedBlueprint, formName, isAutomated, isMultiLocation } =
    useProgram();
  const { setMinSpendLoading, setHasMinSpendError } = useMinSpendState();

  const customMessage = selectedBlueprint?.messaging?.spendMessage;
  const paymentPlans = selectedBlueprint?.paymentPlans;

  return (
    <Grid item xs={12}>
      <FormSection
        title={t('programCreate:spend.budgetSectionTitle')}
        description={t(
          'programCreate:spend.budgetSectionDescriptionMultiLocation'
        )}
      >
        <Box
          sx={{
            pb: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start'
          }}
        >
          <SpendSelector
            architecture={architecture}
            paymentPlans={paymentPlans}
            selectedBlueprint={selectedBlueprint}
            isAutomated={isAutomated}
            formName={formName}
            setMinSpendLoading={setMinSpendLoading}
            setHasMinSpendError={setHasMinSpendError}
            hookFormContext={hookFormContext}
            formSectionName={formSectionName}
            isHookForm
            isMultiLocation={isMultiLocation}
          />
          {customMessage && (
            <Box sx={{ pb: 0.5 }}>
              <CustomFormMessage message={customMessage} />
            </Box>
          )}
        </Box>
      </FormSection>
    </Grid>
  );
};

export default Budget;
