import { useState, useEffect } from 'react';
import { flow, some, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { getFormValues, getFormSyncErrors, touch } from 'redux-form';
import { parse } from 'qs';
import { withGlobalContext } from 'src/GlobalContextProvider';

import { Button, Typography, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';

import {
  automatedWizardCompleteStep,
  automatedWizardSelectExactStep,
  automatedWizardSelectNextStep,
  automatedWizardSelectPreviousStep,
  automatedWizardCompleteAllSteps,
  automatedWizardSetSteps,
  setAutomatedProgramsModal
} from 'src/pages/Program/actions';
import { programActions } from 'src/pages/Program/Constants';
import Instrumentation from 'src/instrumentation';

import AutomatedProgramFilter from 'src/components/AutomatedProgramFilter/AutomatedProgramFilter';
import Modal from 'src/components/Modal';
import { QuickAutomation as QuickAutomationIcon } from 'src/components/Icons';
import { AUTOMATED_PROGRAMS_WIZARD_MODAL_DISMISS } from 'src/common/userSettings';
import SelectedFilterList from './SelectedFilterList';
import { contentMetaFromArchitecture } from './helpers';

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%'
  },
  title: {
    alignItems: 'center',
    display: 'flex'
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  testRulesTableHeader: {
    marginTop: theme.spacing(3)
  },
  stepperRoot: {
    background: 'inherit',
    padding: 0,
    margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`
  },
  stepperLabel: {
    cursor: 'pointer'
  },
  stepperLineContainer: {
    left: 'calc(-50% + 100px)',
    right: 'calc(50% + 100px)',
    top: theme.spacing(3)
  },
  stepperLine: {
    borderTopStyle: 'dashed',
    borderTopWidth: '4px'
  }
});

const AutomatedProgramFilterWizard = props => {
  const {
    architecture,
    automatedWizardSelectExactStep,
    automatedWizardCompleteStep,
    setAutomatedProgramsModal,
    modalOpen,
    queryShowWizard,
    classes,
    contentName,
    formErrors = [],
    formName,
    formValues,
    selectedStep,
    steps,
    blueprints,
    type,
    automatedWizardCompleteAllSteps,
    automatedWizardSetSteps,
    globalContext,
    selectedBlueprint,
    handleSelectBlueprint
  } = props;
  const settings = globalContext?.me?.settings;

  if (isEmpty(steps)) {
    automatedWizardSetSteps(type);
  }

  const contentNameLowerCase = contentName
    ? contentName.toLowerCase()
    : 'content';

  if (
    type === programActions.automatedEdit &&
    some(steps, { completed: false })
  ) {
    // in edit mode all steps should be completed
    automatedWizardCompleteAllSteps();
  }

  const dismissHelpUserSetting =
    settings?.[AUTOMATED_PROGRAMS_WIZARD_MODAL_DISMISS];

  const [error, setError] = useState(false);

  // we have to wait for user settings to load before we decide if we show the modal
  useEffect(() => {
    if (
      modalOpen === undefined && // if it has been set at all we want to ignore this for step switching
      !isEmpty(steps) &&
      queryShowWizard &&
      type === programActions.automatedCreate &&
      selectedStep === 0
    ) {
      setAutomatedProgramsModal(true);

      if (dismissHelpUserSetting) {
        automatedWizardSelectExactStep(1);
        automatedWizardCompleteStep(0);
      }
    }
  }, [dismissHelpUserSetting, steps]);

  const contentMeta = contentMetaFromArchitecture(architecture);
  const closeModal = () => setAutomatedProgramsModal(false);
  const openModal = () => {
    // if we are not on the first step that means the stepper has been used so we want to open it to the filters section
    if (
      selectedStep !== 0 ||
      dismissHelpUserSetting ||
      type === programActions.automatedEdit
    ) {
      automatedWizardSelectExactStep(
        type === programActions.automatedEdit ? 1 : 2
      );
      automatedWizardCompleteStep(0);
    }

    setAutomatedProgramsModal(true);
  };

  const headerText = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& svg': { marginRight: theme => theme.spacing() }
      }}
    >
      <QuickAutomationIcon />{' '}
      {t('automatedProgramFilter:header.automationRules')}
    </Box>
  );

  const buttonText = t('automatedProgramFilter:edit');

  const handleConfirmFilters = (successFunc, selectedStep) => {
    if (!formErrors.length) {
      setError(false);
      if (successFunc) {
        successFunc();
      }
      if (typeof selectedStep !== 'undefined') {
        automatedWizardCompleteStep(selectedStep);
        Instrumentation.logEvent(
          Instrumentation.Events.ProgramAutomationWizardNextClick,
          {
            step: steps?.[`${selectedStep}`]?.amplitudeKey
          }
        );
      }
      return;
    }

    setError(true);
  };

  if (!formErrors.length && error) {
    setError(false);
  }

  const errors = !!formErrors.length;

  const handleCancelModal = () => {
    handleConfirmFilters(closeModal);
  };

  const StepContentComponent = steps?.[`${selectedStep}`]?.content;

  // redux needs to update the step components first.
  if (!StepContentComponent) {
    return null;
  }

  const loading =
    !StepContentComponent ||
    !blueprints ||
    blueprints.loading ||
    isEmpty(architecture);

  return (
    <div className={classes.container}>
      <Modal
        fullWidth
        headerText={headerText}
        maxWidth="md"
        onClose={handleCancelModal}
        loading={loading}
        open={modalOpen}
        data-cy="automatedProgram-modal"
        FooterComponent={() => {
          return (
            <>
              {errors && (
                <Typography
                  className={`${classes.title} ${classes.error}`}
                  variant="body2"
                  data-cy="ap-sync-error"
                >
                  <Trans i18nKey="automatedProgramFilter:filters.error" />
                </Typography>
              )}
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleConfirmFilters(closeModal, selectedStep)}
                data-cy="wizard-save-button"
                data-amp-program-automation-wizard-save-click
              >
                {t('automatedProgramFilter:wizard.save')}
              </Button>
            </>
          );
        }}
      >
        <AutomatedProgramFilter
          architecture={architecture}
          contentMeta={contentMeta}
          contentNameLowerCase={contentNameLowerCase}
          formErrors={formErrors}
          error={error}
          formName={formName}
          formValues={formValues}
          blueprints={blueprints}
          handleConfirmFilters={() => {
            handleConfirmFilters(closeModal, selectedStep);
          }}
          dismissHelpUserSetting={dismissHelpUserSetting}
          selectedBlueprint={selectedBlueprint}
          handleSelectBlueprint={handleSelectBlueprint}
        />
      </Modal>

      <SelectedFilterList contentMeta={contentMeta} formName={formName} />

      <Button
        color="primary"
        data-amp-program-automation-edit-rules-click
        data-cy="businessObjects-btn"
        disabled={contentMeta.length <= 0}
        onClick={openModal}
        startIcon={<EditIcon />}
      >
        {buttonText}
      </Button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const params = parse(ownProps.location.search.substr(1)) || {};
  const formValues = getFormValues(formName)(state);

  const steps = state?.program?.automatedWizardSteps;

  return {
    formErrors:
      getFormSyncErrors(formName)(state)?.configureStep?.automatedPrograms
        ?.filters,
    selectedStep: state?.program?.automatedWizardSelectedStep,
    steps,
    modalOpen: state?.program?.automatedWizardOpen,
    queryShowWizard: params?.showWizard !== 'false',
    formValues
  };
};

export default flow(
  connect(mapStateToProps, {
    automatedWizardSelectExactStep,
    automatedWizardSelectNextStep,
    automatedWizardSelectPreviousStep,
    automatedWizardCompleteStep,
    touch,
    automatedWizardCompleteAllSteps,
    automatedWizardSetSteps,
    setAutomatedProgramsModal
  }),
  withStyles(styles),
  withRouter,
  withGlobalContext
)(AutomatedProgramFilterWizard);
