import { flow } from 'lodash';
import { TextField, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { getOperatorsByColumn } from './helpers';

const styles = () => ({
  field: {}
});

const RenderFilterOperator = ({
  input: { value },
  selectedColumn,
  classes,
  onChangeOperator
}) => {
  return (
    <TextField
      className={classes.field}
      select
      value={value}
      variant="standard"
      onChange={e => {
        onChangeOperator(e, selectedColumn);
      }}
      data-cy="operator-select"
    >
      {getOperatorsByColumn(selectedColumn).map(column => (
        <MenuItem value={column?.value} key={column?.value}>
          {column?.displayName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default flow(withStyles(styles))(RenderFilterOperator);
