import { Route } from 'react-router-dom';
import { allowListPaths } from 'src/routes/Constants';

// Technically this could be called non-allow-listed as that's what it does
// only show chilren when the path is not allowListed
const LoggedInOnly = ({ children }) => {
  return (
    <Route
      path="*"
      render={({ location: { pathname } }) => {
        if (allowListPaths.filter(url => pathname === url).length) {
          return null;
        }
        return <>{children}</>;
      }}
    />
  );
};

export default LoggedInOnly;
