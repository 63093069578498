import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { flow, throttle } from 'lodash';
import { connect } from 'react-redux';
import { t } from 'i18next';
import ReduxFormSection from 'src/components/ReduxForm/ReduxFormSection';
import { withRouter } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';

import { facebookCreativeTypes } from 'src/common/adChannels';
import AdPreview from 'src/components/AdPreview';
import { showBusinessObjectSelectorModal } from 'src/components/BusinessObjectSelector/actions';
import { useAppSettings } from 'src/AppSettings';
import { useGlobalContext } from 'src/GlobalContextProvider';
import AutomatedProgramName from 'src/pages/Program/ProgramName/AutomatedProgramName';
import useProgram from 'src/pages/Program/utils/useProgram';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import BulkAdPreview from 'src/components/AdPreview/BulkAdPreview';

import {
  PROGRAM_FORM_NAME,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from '../Constants';
import ProgramName from '../ProgramName/ProgramName';
import DrawerToggleButton from './DrawerToggleButton';
import PreviewDrawerHeader from './PreviewDrawerHeader';
import SellingPointsList from './SellingPointsList';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from './constants';
import PreviewDrawerFooter from './PreviewDrawerFooter';
import {
  getProgramNameLabel,
  getProgramNameTranslated
} from '../ProgramName/ProgramNameUtil';
import {
  SummaryLabel,
  SummaryItems,
  EditableSummaryItem
} from './DrawerSummary';

const DrawerDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0)
}));

/*
  Switched this to useStyles for the V3 checkout flow experiment because I needed to pass in variables.
  Converting this to a non-depricated, MUI, styling method would be fairly complex and could
  warrant its own ticket because these checkout flow files are so large. It would be better
  to do this when we clean up the experiment IMO.
*/
export const useStyles = makeStyles(theme => {
  return {
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      maxWidth: theme.evSizes.previewDrawerWidth,
      zIndex: theme.zIndex.appBar - 10
    },
    // drawerPaper only used in treatment
    drawerPaper: {
      paddingTop: 0,
      [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        paddingTop: theme.evSizes.toolBarHeight
      },
      [theme.breakpoints.between(0, theme.evSizes.previewDrawerWidth)]: {
        paddingTop: theme.evSizes.mobileToolBarHeight
      }
    },
    drawerOpen: ({ drawerPosition }) => ({
      transition: theme.transitions.create('width', {
        duration: 0
      }),
      maxWidth: theme.evSizes.previewDrawerWidth,
      width: theme.evSizes.previewDrawerWidth,
      height: '100%',
      zIndex: theme.zIndex.appBar - 10,
      border: 'none',
      background: theme.palette.background.default,
      overflowY: 'scroll',
      paddingTop: drawerPosition === 'fixed' ? 80 : 30,
      position: drawerPosition,
      right: drawerPosition === 'fixed' ? 24 : 0,
      alignSelf: 'flex-start',
      [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        top: 0,
        width: '100%',
        maxWidth: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.modal,
        paddingTop: 0,
        right: 0
      }
    }),
    drawerClose: {
      transition: theme.transitions.create('width', {
        duration: 0
      }),

      width: 0,
      zIndex: theme.zIndex.appBar - 10,
      // Necessary for the toggle button to be visible
      overflowX: 'visible',
      overflowY: 'visible'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      justifyContent: 'flex-start'
    },
    toolbarSpacer: theme.mixins.toolbar,
    selectButtonContainer: {
      textAlign: 'center'
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    image: {
      clipPath: 'inset(10px 10px)',
      width: '100%'
    }
  };
});

const pageText = ({ objectName }) => ({
  adPreviewToggle: t('programPreviewDrawer:adPreview.button'),
  orderSummaryToggle: t('programPreviewDrawer:orderSummary.button'),
  importingUser: t('programPreviewDrawer:importingUserMessage'),
  selectBlueprint: t('programPreviewDrawer:pleaseSelectBlueprint'),
  selectBusinessObject: t('programPreviewDrawer:pleaseSelectBusinessObject', {
    objectName
  }),
  previewLabel: t('programPreviewDrawer:summaryLabel.preview'),
  automationNameLabel: t('programPreviewDrawer:summaryLabel.automationName')
});

const STICKY_DRAWER_OFFSET = 200;
const LOCK_TIME = 5;

const ProgramPreviewDrawer = props => {
  const {
    showBusinessObjectSelectorModal,
    contentName = '',
    selectedBusinessObjects = null,
    loading,
    error,
    defaultFacebookPage = null,
    isContentSelectable,
    blueprintsLoading,
    drawerPosition,
    setDrawerPosition,
    conditionalInputsVisibility,
    handleAdContentChannelValidation,
    setIsPollingPreview,
    isPollingPreview,
    setIsValidatingCreative,
    isHookForm,
    clearUpdatedInputCreativeErrors,
    formValues,
    setCreativeValidationErrors
  } = props;
  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const architecture = useArchitecture();

  const {
    programStepper: { selectExactStep, currentStep },
    selectedBlueprint,
    isAutomated,
    contentColumns,
    previewDrawerOpen,
    togglePreviewDrawer,
    isMultiLocation,
    selectedLocation
  } = useProgram();

  const dynamicUserInputs = formValues?.dynamicUserInputs;

  const locationsOverrideById = formValues?.locationsOverrideById;
  const selectedLocations = formValues?.selectedLocations;

  const programName = formValues?.spendStep?.programName;

  const automatedProgramName = formValues?.spendStep?.automatedProgramName;

  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;

  const isSummary = currentStep === 1;

  const objectName = contentName || t('programPreviewDrawer:items');
  const text = pageText({ objectName });

  const isLargeScreenWidthOrSmaller = useMediaQuery(
    `(max-width:${DRAWER_FULL_SCREEN_BREAKPOINT}px)`
  );
  const isLargeScreenWidthOrLarger = useMediaQuery(
    `(min-width:${DRAWER_FULL_SCREEN_BREAKPOINT}px)`
  );

  const isDrawerWidth = useMediaQuery(theme =>
    theme.breakpoints.between(0, theme.evSizes.previewDrawerWidth)
  );

  const classes = useStyles({
    drawerPosition
  });
  const appSettings = useAppSettings();
  const supportPhone = appSettings?.app?.support?.phone;
  const supportEmail = appSettings?.app?.support?.email;

  const positionLockRef = useRef(false);
  const positionDrawer = useCallback(() => {
    if (positionLockRef.current) return;

    const currentScrollY = window.scrollY;

    if (currentScrollY > STICKY_DRAWER_OFFSET && drawerPosition !== 'fixed') {
      setDrawerPosition('fixed');
      positionLockRef.current = true;
      setTimeout(() => {
        positionLockRef.current = false;
      }, LOCK_TIME);
    } else if (
      currentScrollY < STICKY_DRAWER_OFFSET &&
      drawerPosition !== 'relative'
    ) {
      setDrawerPosition('relative');
      positionLockRef.current = true;
      setTimeout(() => {
        positionLockRef.current = false;
      }, LOCK_TIME);
    }
  }, [setDrawerPosition, drawerPosition]);

  useEffect(() => {
    /*
      If we are resizing up from below and past the large breakpoint and the drawer is closed, open it.
      At these larger screen sizes there is enough room to comfortably view the drawer so the drawer
      will be open and the toggle drawer button will be removed.

      If we are resizing down from above and past the large breakpoint and the drawer is open, close it.
      At these smaller screen sizes the form gets squashed and is better UX with the drawer closed.
    */
    if (
      (isLargeScreenWidthOrSmaller && previewDrawerOpen) ||
      (isLargeScreenWidthOrLarger && !previewDrawerOpen)
    ) {
      togglePreviewDrawer();
      positionDrawer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLargeScreenWidthOrSmaller, isLargeScreenWidthOrLarger]);

  const goToStepOne = () => {
    // Note: Right now, we have hard-coded this to go to step 0 since that's
    //       where the blueprint selector lives. We might need to pass this
    //       in at some point.
    const trackingData = {
      architectureId: architecture?.id,
      productId: selectedBlueprint?.id
    };
    selectExactStep(0, trackingData);
  };

  // First send them to the select step, then open the business object modal.
  const goToSelectBusinessObject = () => {
    goToStepOne();
    showBusinessObjectSelectorModal();
  };

  const renderPleaseSelectBlueprint = () => {
    // this should actually never show up anymore since a user *must* select a default blueprint
    return (
      <div className={classes.selectButtonContainer}>
        <Button color="primary" onClick={() => {}} variant="contained">
          {text.selectBlueprint}
        </Button>
      </div>
    );
  };

  const renderPleaseSelectBusinessObject = () => {
    const creativeType =
      selectedBlueprint?.blueprint?.publishers[0]?.prototype?.code;

    if (
      creativeType === facebookCreativeTypes.dynamicAdCreative ||
      !isContentSelectable
    ) {
      return <span />;
    }

    return (
      <div className={classes.selectButtonContainer}>
        <Button
          color="primary"
          onClick={goToSelectBusinessObject}
          variant="contained"
        >
          {text.selectBusinessObject}
        </Button>
      </div>
    );
  };

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs,
    businessObjects: selectedBusinessObjects?.selectedBusinessObjects,
    selectedBusinessObjects,
    ...(isMultiLocation && { locationsOverrideById, selectedLocations })
  };

  const adPreviewProps = {
    architecture,
    contentName,
    previewData,
    customNoBlueprintMessage: renderPleaseSelectBlueprint(),
    customNoBusinessObjectMessage: renderPleaseSelectBusinessObject(),
    loading,
    error,
    facebookPageName: defaultFacebookPage?.name,
    isAutomated,
    blueprintsLoading,
    conditionalInputsVisibility,
    handleAdContentChannelValidation,
    setIsPollingPreview,
    isPollingPreview,
    setIsValidatingCreative,
    clearUpdatedInputCreativeErrors
  };

  const channels = selectedBlueprint?.blueprint?.channels;
  const programNameLabel = getProgramNameLabel(isMultiLocation);
  const sellingPoints = selectedBlueprint?.messaging?.sellingPoints || [];

  useEffect(() => {
    const throttledPositionDrawer = throttle(positionDrawer, 15);

    document.addEventListener('scroll', throttledPositionDrawer);

    return () => {
      document.removeEventListener('scroll', throttledPositionDrawer);
    };
  }, [positionDrawer]);

  return (
    <>
      <Drawer
        // adding this key here forces the drawer to re-render when the blueprint changes
        // so adpreview etc aren't in stale states
        key={selectedBlueprint?.blueprintId || 'noBlueprint'}
        anchor="right"
        variant="permanent"
        open={previewDrawerOpen}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: previewDrawerOpen,
          [classes.drawerClose]: !previewDrawerOpen
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: previewDrawerOpen,
            [classes.drawerClose]: !previewDrawerOpen,
            [classes.drawerPaper]: true
          })
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        {/* Only show the drawer toggle button when screen is smaller than large break point */}
        {isLargeScreenWidthOrSmaller && (
          <DrawerToggleButton
            isOpen={previewDrawerOpen}
            onClick={togglePreviewDrawer}
            text={
              currentStep === 0 ? text.adPreviewToggle : text.orderSummaryToggle
            }
          />
        )}
        <Box
          sx={theme => ({
            pr: 8,
            pl: 0,
            pb: 4,
            pt: 0,
            overflowY: 'scroll',
            width: '100%',
            position: 'relative',
            [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
              pr: 0,
              pt: 3,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              minHeight: '100vh'
            },

            [theme.breakpoints.between(0, theme.evSizes.previewDrawerWidth)]: {
              px: 2,
              margin: 0
            }
          })}
        >
          {/* This extra box is needed to ensure that the vertical overflow scroll bar is positioned to the far right of the screen */}
          <Box
            sx={theme => ({
              [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
                maxWidth: theme.evSizes.previewDrawerWidth,
                margin: '0 auto',
                width: '100%'
              }
            })}
          >
            <PreviewDrawerHeader
              isSummary={isSummary}
              channels={channels}
              isAutomation={isAutomated}
            />
            {isSummary && (
              <>
                <DrawerDivider />
                <SummaryItems
                  channels={channels}
                  defaultFacebookPage={defaultFacebookPage}
                  isDrawerWidth={isDrawerWidth}
                  selectedBusinessObjects={
                    selectedBusinessObjects?.selectedBusinessObjects
                  }
                  displayNameTemplate={displayNameTemplate}
                  contentName={contentName}
                  programNameField={
                    <ReduxFormSection
                      name={PROGRAM_FORM_SECTION_SPEND_NAME}
                      isHookForm={isHookForm}
                    >
                      <EditableSummaryItem
                        labelText={programNameLabel}
                        isDrawerWidth={isDrawerWidth}
                        uneditableContent={
                          programName !== undefined && programName !== ''
                            ? getProgramNameTranslated(
                                programName,
                                selectedBlueprint,
                                selectedBusinessObjects?.selectedBusinessObjects,
                                displayNameTemplate,
                                userMetadataFields
                              )
                            : ''
                        }
                      >
                        <Box sx={{ width: '100%', mt: -4 }}>
                          <ProgramName
                            showHelpTextAsTooltip
                            contentColumns={contentColumns}
                            businessObjects={
                              selectedBusinessObjects?.selectedBusinessObjects
                            }
                            displayNameTemplate={displayNameTemplate}
                            selectedBlueprint={selectedBlueprint}
                            formName={PROGRAM_FORM_NAME}
                            isAutomated={isAutomated}
                            containerSx={{ padding: 0 }}
                            inputVariant="standard"
                            ariaLabel={programNameLabel}
                            isHookForm={isHookForm}
                            formValues={formValues}
                          />
                        </Box>
                      </EditableSummaryItem>
                    </ReduxFormSection>
                  }
                  automationNameField={
                    isAutomated && (
                      <>
                        <EditableSummaryItem
                          labelText={text.automationNameLabel}
                          isDrawerWidth={isDrawerWidth}
                          uneditableContent={
                            automatedProgramName !== undefined &&
                            automatedProgramName !== ''
                              ? getProgramNameTranslated(
                                  automatedProgramName,
                                  selectedBlueprint,
                                  selectedBusinessObjects?.selectedBusinessObjects,
                                  displayNameTemplate,
                                  userMetadataFields
                                )
                              : ''
                          }
                        >
                          <Box sx={{ width: '100%', mt: -3 }}>
                            <AutomatedProgramName
                              formName={PROGRAM_FORM_NAME}
                              contentName={contentName}
                              architecture={architecture}
                              isSummary
                              ariaLabel={text.automationNameLabel}
                            />
                          </Box>
                        </EditableSummaryItem>
                      </>
                    )
                  }
                />
              </>
            )}
            <DrawerDivider />
            <Grid container spacing={!isSummary ? 0 : 2}>
              {isSummary && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex'
                    }}
                  >
                    <SummaryLabel>{text.previewLabel}</SummaryLabel>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Box sx={{ maxWidth: 360, width: '100%' }}>
                    {isMultiLocation ? (
                      <BulkAdPreview
                        {...adPreviewProps}
                        showLoadingSkeleton
                        // Ensures that the ad previews don't shrink within the phone mock up frame on mobile
                        disableResponsiveStyles
                        // Display ad previews in phone mock up wrapper
                        displayAsPhoneMockUp
                        // Ad preview is fluidly responsive, the parent container sets the width
                        isResponsive
                        setIsValidatingCreative={setIsValidatingCreative}
                        selectedLocation={selectedLocation}
                        setCreativeValidationErrors={
                          setCreativeValidationErrors
                        }
                      />
                    ) : (
                      <AdPreview
                        {...adPreviewProps}
                        showLoadingSkeleton
                        // Ensures that the ad previews don't shrink within the phone mock up frame on mobile
                        disableResponsiveStyles
                        // Display ad previews in phone mock up wrapper
                        displayAsPhoneMockUp
                        // Ad preview is fluidly responsive, the parent container sets the width
                        isResponsive
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {!!sellingPoints.length && (
              <>
                <DrawerDivider />
                <SellingPointsList sellingPoints={sellingPoints} />
              </>
            )}
            {(supportPhone || supportEmail) && (
              <>
                <DrawerDivider />
                <PreviewDrawerFooter
                  supportPhone={supportPhone}
                  supportEmail={supportEmail}
                />
              </>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    previewDrawerOpen: state?.program?.previewDrawerOpen
  };
};

export default flow(
  connect(mapStateToProps, {
    showBusinessObjectSelectorModal
  }),
  withRouter
)(ProgramPreviewDrawer);
