import { SnackbarProvider } from 'notistack';
import { withStyles } from '@mui/styles';

import { Theme } from '@mui/system';
import { ReactNode, CSSProperties } from 'react';
import { WithStyledClasses } from 'src/common/Style';
import AdmiralSnackBar from './AdmiralSnackBar';

const styles = (theme: Theme) => ({
  success: {
    background: 'white',
    border: `3px solid ${theme.palette.success[500]}`,
    color: theme.palette.success[500]
  },
  error: {
    background: 'white',
    border: `3px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  warning: {
    background: 'white',
    border: `3px solid ${theme.palette.warning[500]}`,
    color: theme.palette.warning[500]
  },
  info: {
    background: 'white',
    border: `3px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[500]
  },
  containerRoot: {
    '& .SnackbarContent-root': {
      flexWrap: 'nowrap' as CSSProperties['flexWrap'],
      maxWidth: '680px'
    }
  }
});

export interface AdmiralSnackBarProviderProps {
  children: ReactNode;
  classes: WithStyledClasses<typeof styles>;
}

const AdmiralSnackBarProvider = ({
  children,
  classes
}: AdmiralSnackBarProviderProps) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
        containerRoot: classes.containerRoot
      }}
    >
      <AdmiralSnackBar />
      {children}
    </SnackbarProvider>
  );
};

export default withStyles(styles)(AdmiralSnackBarProvider);
