import { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import { Box, FormHelperText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { ContactGroupCountApiResponse } from 'src/generated/gql/graphql';
import Logger from 'src/common/Logger';
import SentryUtil from 'src/common/SentryUtil';
import useIsMobile from 'src/hooks/useIsMobile';

import TotalExpertIcon from 'src/components/Icons/TotalExpert';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import RenderAutocomplete from 'src/components/ReduxForm/RenderAutocomplete/RenderAutocomplete';
import Loading from 'src/components/Loading';

import { jobStatus } from './constants';
import { crunchHelperTextNumbers } from './helpers';
import {
  fetchEmbeddedLink,
  initialFetchContactGroupCounts,
  fetchContactGroupCounts,
  checkForExistingActiveConnection
} from './queries';

interface WorkatoConnectionProps {
  readOnly?: boolean;
  helperText?: string;
  disabled: boolean;
  label: string;
  extraProps: {
    isMultiSelect: boolean;
  };
  meta: {
    error?: string;
  };
  input: {
    onChange: (value: any) => void;
    value: any;
  };
}

const WorkatoIframe = styled('iframe')<{ isLoading: boolean }>(
  ({ isLoading }) => ({
    border: 'none',
    display: isLoading ? 'none' : 'block'
  })
);

const pollInterval = 1000;

const RenderWorkatoConnection = (autocompleteProps: WorkatoConnectionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showDelayedLoadingText, setShowDelayedLoadingText] = useState(false);

  const { data } = useQuery(checkForExistingActiveConnection);
  const connectionExists =
    data?.checkForExistingActiveConnection?.connectionExists;
  const isActive = data?.checkForExistingActiveConnection?.isActive;

  const [getEmbeddedLink] = useLazyQuery(fetchEmbeddedLink);
  const [getInitialContactGroupCounts] = useLazyQuery(
    initialFetchContactGroupCounts
  );
  const [getContactGroupCounts, { stopPolling }] = useLazyQuery(
    fetchContactGroupCounts
  );

  const [workatoIframeUrl, setWorkatoIframeUrl] = useState<
    string | null | undefined
  >(null);
  const [iframeHeight, setIframeHeight] = useState(300);
  const [workatoError, setWorkatoError] = useState<string | null | undefined>(
    null
  );
  const [options, setOptions] = useState<{ name: any; value: any }[]>([]);
  const [contactGroupCountResponse, setContactGroupCountResponse] = useState<
    ContactGroupCountApiResponse | undefined
  >(undefined);

  const [connectedToWorkato, setConnected] = useState(false);
  const previousConnectedRef = useRef(false);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const setCloseModal = () => {
    setModalOpen(false);
  };

  const getSelectOptionMetadata = async (jobId: string) => {
    setLoading(true);

    try {
      await getContactGroupCounts({
        pollInterval,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
          jobId
        },
        onCompleted: data => {
          const status = data?.fetchContactGroupCounts?.jobStatus;

          // if (status === jobStatus.running) { keep polling }

          if (status === jobStatus.timedOut) {
            stopPolling();
            // we have to restart the job when it times out the BE has a limit of 5 minutes
            getInitialContactGroupCounts()
              .then(contactGroupCounts => {
                const newJobId =
                  contactGroupCounts?.data?.fetchContactGroupCounts?.jobId;

                if (newJobId) {
                  getSelectOptionMetadata(newJobId).catch(() => {
                    // recursive so will be caught by the parent
                  });
                }
              })
              .catch((error: any) => {
                SentryUtil.addBreadcrumb({
                  message:
                    'Failed to get initial contact group counts from Workato. Error fetching data.'
                });
                SentryUtil.captureException(error);
                Logger.error(error);
              });
          }

          if (status === jobStatus.complete) {
            stopPolling();

            const response = data?.fetchContactGroupCounts;

            const formattedOptions = response?.contactGroupCounts?.map(
              (option: any) => {
                const count = option?.size;

                const name = count ? (
                  <>
                    {option?.groupName}{' '}
                    <Box
                      component="span"
                      sx={{
                        fontWeight: '600',
                        marginLeft: theme => theme.spacing(0.5)
                      }}
                    >
                      ({count})
                    </Box>
                  </>
                ) : (
                  option?.groupName
                );

                return {
                  name,
                  value: option?.groupName
                };
              }
            );

            setContactGroupCountResponse(response);
            setOptions(formattedOptions || []);

            if (!isEmpty(formattedOptions)) {
              enqueueSnackbar(t('common:workato.snacks.optionsReady'), {
                variant: 'success'
              });
            }

            setLoading(false);
          }
        }
      });
    } catch (error: any) {
      setLoading(false);
      SentryUtil.addBreadcrumb({
        message:
          'Failed to poll for contact group counts from Workato. Error fetching data.'
      });
      SentryUtil.captureException(error);
      Logger.error(error);
    }
  };

  const initializeSelectOptions = async () => {
    try {
      setLoading(true);
      const contactGroupCounts = await getInitialContactGroupCounts();
      const jobId = contactGroupCounts?.data?.fetchContactGroupCounts?.jobId;

      if (jobId) {
        await getSelectOptionMetadata(jobId);
      } else {
        // should not hit this but just in case
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      SentryUtil.addBreadcrumb({
        message:
          'Failed to get initial contact group counts from Workato. Error fetching data.'
      });
      SentryUtil.captureException(error);
      Logger.error(error);
    }
  };

  const setConnection = (isConnected: boolean) => {
    // only update if the connection status has changed
    if (isConnected) {
      // if we are connected we need to get the select options
      initializeSelectOptions().catch(() => {});
    }
    setConnected(isConnected);
  };

  const connectWorkato = async () => {
    setModalOpen(true);

    setShowDelayedLoadingText(false); // Ensure it starts fresh
    setTimeout(() => {
      setShowDelayedLoadingText(true);
    }, 5000); // 10 seconds delay

    try {
      // get iframe link
      const embeddedLink = await getEmbeddedLink({
        fetchPolicy: 'no-cache'
      });
      // set iframe link
      setWorkatoIframeUrl(embeddedLink?.data?.fetchEmbeddedLink?.link);
    } catch (error: any) {
      SentryUtil.addBreadcrumb({
        message:
          'Failed to get embedded link for Workato iframe. Error fetching link.'
      });
      SentryUtil.captureException(error);
      enqueueSnackbar(t('common:workato.error.link'), {
        variant: 'error'
      });
      Logger.error(error);
      setWorkatoError(t('common:workato.error.link'));
    }
  };

  useEffect(() => {
    if (connectionExists && isActive) {
      setConnection(true);
    }
  }, [connectionExists]);

  // onMount
  useEffect(() => {
    const handleMessage = (event: any) => {
      setWorkatoError(undefined);

      const data =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      if (!event.origin.includes('workato')) {
        // only accept messages from workato
        return;
      }

      const type = data?.type;

      if (type === 'connectionStatusChange') {
        const connected = data?.payload?.connected;
        setConnection(connected);
        previousConnectedRef.current = connected;
      }
      if (type === 'heightChange') {
        const height = data?.payload?.height;

        setIframeHeight(height);
      }

      if (type === 'error') {
        // the error does not report the correct hight for the iframe so setting it here
        setIframeHeight(400);

        // token expired we need to reset the link
        setWorkatoIframeUrl(undefined);

        // reconnect
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        connectWorkato();
      }
    };

    // Note: if we are going to have more than one of these on a page we need
    //       to make sure we are only listening to the correct iframe

    // setup iframe listener
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const { isMultiSelect } = autocompleteProps.extraProps;
  const hasConnection = connectedToWorkato || !isEmpty(options);
  const isMobile = useIsMobile();

  const helperText = (
    <Box
      sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
    >
      <FormHelperText error={!!workatoError}>
        {loading
          ? t('common:workato.modal.importing')
          : t(
              'common:workato.input.helperText',
              crunchHelperTextNumbers({
                isMultiSelect,
                inputValue: autocompleteProps.input.value,
                options,
                contactGroupCountResponse
              })
            )}
      </FormHelperText>

      <LoadingButton
        // loading={loading}
        color={workatoError ? 'error' : 'primary'}
        size="small"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={connectWorkato}
      >
        <LinkOffIcon sx={{ width: '20px', height: '20px' }} />
      </LoadingButton>
    </Box>
  );

  return (
    <Grid container>
      <Grid xs={12} sm={6}>
        <RenderAutocomplete
          {...autocompleteProps}
          {...(workatoError && {
            meta: {
              ...autocompleteProps.meta,
              error: workatoError,
              touched: true
            }
          })}
          // change label if we have no options and are not connected
          {...(!hasConnection && {
            label: t('common:workato.input.loadDataLabel')
          })}
          readOnly={isEmpty(options)} // this is disabled for this input
          options={options}
          customHelperText={hasConnection && helperText}
          enableSelectAll={isMultiSelect}
          enableCheckboxes
          limitTags={5}
        />

        <ConfirmationModal
          cancelButtonText={t('common:workato.modal.cancel')}
          title={loading ? '' : t('common:workato.modal.title')}
          open={modalOpen}
          onClose={setCloseModal}
          showFooter={!loading}
        >
          <>
            <WorkatoIframe
              src={workatoIframeUrl || undefined}
              width={350}
              height={iframeHeight}
              id="workatoId"
              isLoading={loading}
            />
            {loading && (
              <Box
                sx={{
                  width: '420px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Loading size={30} />
                <Box
                  component="span"
                  sx={{
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '18px',
                    marginTop: theme => theme.spacing(1)
                  }}
                >
                  {t('common:workato.modal.importing')}
                </Box>
                <Box
                  component="span"
                  sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: theme => theme.palette.grey[600],
                    marginTop: theme => theme.spacing(1)
                  }}
                >
                  {t('common:workato.modal.gathering')}
                </Box>
                {showDelayedLoadingText && (
                  <Box
                    component="span"
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      color: theme => theme.palette.grey[600],
                      marginTop: theme => theme.spacing(1)
                    }}
                  >
                    {t('common:workato.modal.loadingMayClose')}
                  </Box>
                )}
              </Box>
            )}
          </>
        </ConfirmationModal>

        {!hasConnection && !loading && (
          <LoadingButton
            loading={loading}
            color="primary"
            variant="contained"
            size="small"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={connectWorkato}
            sx={{
              opacity: hasConnection ? 0.5 : 1,
              width: '100%'
            }}
          >
            <Box
              component="span"
              sx={{ marginRight: theme => theme.spacing() }}
            >
              {hasConnection
                ? t('common:workato.manage')
                : t('common:workato.connect')}
            </Box>
            {!loading && <TotalExpertIcon />}
          </LoadingButton>
        )}
      </Grid>

      <Grid sm={6} xs={12}>
        <Box
          sx={{
            padding: theme => theme.spacing(1),
            background: theme => theme.palette.grey[100],
            borderRadius: theme => theme.spacing(1),
            marginLeft: !isMobile ? theme => theme.spacing(1) : 0,
            marginTop: isMobile
              ? theme => theme.spacing(1)
              : theme => theme.spacing(2),
            fontSize: '12px'
          }}
        >
          {t('common:workato.input.sideTip')}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RenderWorkatoConnection;
